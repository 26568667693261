import userRoles, { roleIsAtLeast } from '../../../globals/userRoles';
// Constants
import { HubLayoutConstants } from '../../../globals/hubConstants';

const getUserPanelType = roleName => {
  const {
    PEOPLE_PANEL_TYPES: { REMOVE_STUDENTS, REMOVE_TEACHERS }
  } = HubLayoutConstants;
  const roleType = roleIsAtLeast(userRoles.TEACHER, roleName) ? userRoles.TEACHER : userRoles.LEARNER;
  const panelType = roleType === userRoles.LEARNER ? REMOVE_STUDENTS : REMOVE_TEACHERS;

  return panelType;
};

export default getUserPanelType;
