import React from 'react';
import PropTypes from 'prop-types';
// Constants
import { HubLayoutConstants, HubIllustrationConstants } from '../../../globals/hubConstants';
// Styles
import styles from './HubResourceErrorModal.scss';
// Components
import Button, { buttonTypes } from '../../../components/Button/Button';
import HubModalLayout from '../../../components/HubModalLayout/HubModalLayout';
import HubEmptyStateRestyle from '../../../components/HubEmptyStateRestyle/HubEmptyStateRestyle';

function HubResourceErrorModal({ hubContent, closeModal, isError, downloadType }) {
  const isLorError = isError && downloadType === HubLayoutConstants.DOWNLOAD_TYPES.LOR;

  return (
    <div className={`${styles.outerModal} ${isLorError ? styles.open : ''}`}>
      <div className={styles.innerModal}>
        <HubModalLayout isOpen={isLorError} id="resourceErrorModalId" closeAction={closeModal}>
          <div className={styles.modalContent}>
            <HubEmptyStateRestyle
              iconSrc={HubIllustrationConstants.WARNING}
              bodyText={hubContent.resource_error_modal_text}
              additionalText={hubContent.resource_error_modal_additional_text}
            />
          </div>
          <div className={styles.modalButtonBottom}>
            <Button
              id="OkCloseBtn"
              type={buttonTypes.PRIMARY}
              text={hubContent.ok_button_text}
              onClick={() => closeModal()}
              aria-label={hubContent.ok_button_text}
            />
          </div>
        </HubModalLayout>
      </div>
    </div>
  );
}

HubResourceErrorModal.propTypes = {
  hubContent: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  isError: PropTypes.bool.isRequired,
  downloadType: PropTypes.string.isRequired
};

export default HubResourceErrorModal;
