import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// Components
import HubDropDownMenuWithText from '../../../components/HubDropDownMenuWithText/HubDropDownMenuWithText';
import ClassSubMenuItem from './ClassSubMenuItem';
import TeacherClassCode from '../../../components/TeacherClassCode/TeacherClassCode';
import SVGIcon, { GLYPHS } from '../../../components/SVGIcon/SVGIcon';
// Constants
import userRoles from '../../../globals/userRoles';
import classSubMenuConstants from './ClassSubMenuConstants';
import {
  toggleMaterialsToClassModal,
  openAddTeacherModal,
  toggleAddStudentsModal,
  toggleSetTestModalOpen
} from '../../../redux/actions/hubUi';
// Utils
import { isLocal, isDev, isTest, featureIsEnabled } from '../../../globals/envSettings';
import userHasInteractiveAssessment from '../../../utils/userHasInteractiveAssessment';
// Styles
import styles from './ClassSubMenu.scss';
import { isLtiMode } from '../../../utils/platform';

class ClassSubMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addToClassToggledButton: false
    };
  }

  static getAddToClassMenuItems = (content, canShowAssessments, currentOrganisationLti) => {
    const menuItems = [
      {
        name: content.add_course_material,
        icon: GLYPHS.ICON_COURSE,
        iconColor: '#2ac46a',
        action: () => toggleMaterialsToClassModal('CLASS'),

        showButton: !currentOrganisationLti
      },
      {
        name: content.add_students,
        icon: GLYPHS.ICON_CLASS,
        iconColor: '#fb6d51',
        action: toggleAddStudentsModal,
        showButton: !currentOrganisationLti
      },
      {
        name: content.add_teachers,
        icon: GLYPHS.ICON_USER,
        iconColor: '#a10062',
        action: openAddTeacherModal,
        showButton: !currentOrganisationLti
      }
    ];

    if (featureIsEnabled('hub-set-test') && canShowAssessments) {
      menuItems.splice(1, 0, {
        name: content.set_test,
        icon: GLYPHS.ICON_ASSESSMENT,
        iconColor: '#2ac46a',
        action: toggleSetTestModalOpen,
        showButton: true
      });
    }

    return menuItems;
  };

  closeMenuPanel = e => {
    e.stopPropagation();
    this.setState({ addToClassToggledButton: false });
  };

  toggleAddToClassButton = () => {
    this.setState(state => ({ addToClassToggledButton: !state.addToClassToggledButton }));
  };

  render() {
    const {
      state: { addToClassToggledButton },
      props: {
        classId,
        hubContent,
        pathname,
        orgId,
        isTeacherAssignedToClass,
        processedUserRole,
        openEditClassroomModal,
        userRole,
        classrooms,
        organisations,
        context,
        hubCourses,
        classroomName,
        classUsers,
        currentOrganisationLti
      },
      toggleAddToClassButton,
      closeMenuPanel
    } = this;

    const displayClassCode =
      (userRole === userRoles.ORG_ADMIN || userRole === userRoles.TEACHER_ADMIN) &&
      !(isLtiMode() || currentOrganisationLti);

    const canShowAssessments =
      userRole === userRoles.LEARNER ||
      isLocal() ||
      isDev() ||
      isTest() ||
      userHasInteractiveAssessment(hubCourses.courses);

    const {
      SINGLE_CLASS: {
        showAddToClassButton = false,
        showManageClassButton = false,
        showProgressButton = false,
        showPeopleButton = false,
        showClassworkButton = false,
        showClassAssessmentButton = false
      } = {}
    } = processedUserRole;

    const subMenuItemsParams = {
      showProgressButton,
      showPeopleButton,
      showClassworkButton,
      showClassAssessmentButton: canShowAssessments && showClassAssessmentButton
    };

    const popoutMenuParams = {
      popoutId: 'addToClassPanel',
      popoutAriaLabel: hubContent.add_to_class_menu_aria_label,
      buttonElementId: 'addToClassId'
    };

    const addToClassDropdownMenuItems = ClassSubMenu.getAddToClassMenuItems(
      hubContent,
      canShowAssessments,
      currentOrganisationLti,
      {
        contextName: classroomName,
        selectedUsers: classUsers,
        classId
      }
    );
    const addToClassDropdownMenuShow = !!addToClassDropdownMenuItems.find(menuItem => menuItem.showButton === true);

    return (
      <div className={styles.classSubMenu} role="navigation" aria-label="Class menu">
        {addToClassDropdownMenuShow && showAddToClassButton && isTeacherAssignedToClass && (
          <HubDropDownMenuWithText
            idPanelMenu="addToClassId"
            menuItems={addToClassDropdownMenuItems}
            onTogglePanelMenu={closeMenuPanel}
            toggled={addToClassToggledButton}
            popoutData={popoutMenuParams}
            toggleAddButton={toggleAddToClassButton}
            textButton={hubContent.add_to_class}
          />
        )}

        <ul className={styles.withButton}>
          {classSubMenuConstants
            .getClassSubMenuItems(classId, hubContent, orgId, subMenuItemsParams, context)
            .map(subMenuItem =>
              subMenuItem.showButton ? (
                <ClassSubMenuItem
                  dataTestId={subMenuItem.testHook}
                  subMenuItem={subMenuItem}
                  pathname={pathname}
                  key={subMenuItem.name}
                />
              ) : null
            )}
        </ul>

        {showManageClassButton && isTeacherAssignedToClass && !(isLtiMode() || currentOrganisationLti) && (
          <button
            type="button"
            className={`${styles.classSubMenuItem} ${styles.classManagedItem}`}
            onClick={openEditClassroomModal}
          >
            <SVGIcon glyph={GLYPHS.ICON_SETTINGS} className={styles.manageIcon} />
            <div className={styles.buttonName}>{hubContent.manage_class}</div>
          </button>
        )}
        {displayClassCode && (
          <TeacherClassCode
            joiningCode={(classrooms.data[classId] && classrooms.data[classId].joiningCode) || {}}
            customOrgId={organisations.data[orgId] && organisations.data[orgId].customId}
            hubContent={hubContent}
            labelText={hubContent.class_code_label}
            customClassname="classCodeWideContainer"
            openEditClassroomModal={openEditClassroomModal}
            hasInfoIcon
          />
        )}
      </div>
    );
  }
}
export default connect(({ identity, classrooms, organisations, hubCourses }, props) => {
  const classroom = classrooms?.data?.[props.classId] || {};
  const classUsers = [...(classroom?.studentIdList || []), ...(classroom?.teacherIdList || [])];

  return {
    userRole: identity.role,
    currentOrganisationLti: identity.currentOrganisationLti,
    classrooms,
    organisations,
    hubCourses,
    classroomName: classroom.name,
    classUsers
  };
})(ClassSubMenu);

ClassSubMenu.propTypes = {
  classId: PropTypes.string.isRequired,
  hubContent: PropTypes.object.isRequired,
  pathname: PropTypes.string.isRequired,
  orgId: PropTypes.string.isRequired,
  isTeacherAssignedToClass: PropTypes.bool.isRequired,
  userRole: PropTypes.string,
  classrooms: PropTypes.object,
  organisations: PropTypes.object,
  processedUserRole: PropTypes.object.isRequired,
  openEditClassroomModal: PropTypes.func.isRequired,
  context: PropTypes.string.isRequired,
  hubCourses: PropTypes.object,
  classroomName: PropTypes.string,
  classUsers: PropTypes.array,
  currentOrganisationLti: PropTypes.bool
};
