// Consants
import { GLYPHS } from '../../../../components/SVGIcon/SVGIcon';
import { HubLayoutConstants, licenceStatus } from '../../../../globals/hubConstants';

const getLicencesStatusData = availableCount => {
  const {
    ORG_LICENCES_STATUS: { CHECK_COUNT, WARNING_COUNT, CHECK_COLOUR, WARNING_COLOUR, WARNING_RED_COLOUR }
  } = HubLayoutConstants;
  const { ACTIVE, EXPIRING, NO_LICENCE } = licenceStatus;

  if (availableCount > CHECK_COUNT) {
    return {
      icon: GLYPHS.ICON_CHECK_CIRCLE,
      fill: CHECK_COLOUR,
      status: ACTIVE
    };
  }

  if (availableCount <= CHECK_COUNT && availableCount >= WARNING_COUNT) {
    return {
      icon: GLYPHS.ICON_WARNING_CIRCLE,
      fill: WARNING_COLOUR,
      status: EXPIRING
    };
  }

  return {
    icon: GLYPHS.ICON_ERROR_CIRCLE,
    fill: WARNING_RED_COLOUR,
    status: NO_LICENCE
  };
};

export default getLicencesStatusData;
