import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { get } from 'lodash';
// Redux
import { connect } from 'react-redux';
import { compose } from 'recompose';

import Heading from '@oup/shared-front-end/src/components/Heading';
import APP_CONSTANTS from '@oup/shared-node-browser/constants';
import { setSearchBy } from '../../../redux/reducers/library/library.reducer.js';
import {
  setTerm,
  triggerSearch,
  setSort,
  setPage,
  initialiseInstance
} from '../../../redux/reducers/data/search.reducer.js';
import { getTestCreditsRequest } from '../../../redux/actions/testCredits';

import { showClosePanelModal } from '../../../redux/reducers/archiveUsers.reducer.js';
import { toggleDetailsPopupWithId } from '../../../redux/reducers/assignLearningMaterial.reducer.js';
import { openAddLicensesModal, openRedeemModal } from '../../../redux/actions/hubUi';
// Constants
import userRoles from '../../../globals/userRoles.js';
import colors from '../../../globals/colors';
import { featureIsEnabled } from '../../../globals/envSettings';
import { HubIllustrationConstants, productInformationContext } from '../../../globals/hubConstants';
import { mode } from '../../../redux/reducers/testCredits.reducer.js';
// Hoc
import withLocalizedContent from '../../../language/withLocalizedContent';
import withDataRecency from '../../../dataRecency/withDataRecency';
// Components
import SubSectionSkeletonLoader from '../../../components/SkeletonLoader/SubSectionSkeletonLoader';
import withHubList from '../../HubListPage/withHubList';
import LicenceListItem from '../../../components/ListItems/LicenceListItem/LicenceListItem';
import PaginationButtons from '../../../components/PaginationButtons/PaginationButtons.js';
import getOrgLicencesHeaderItems from '../../HubDashboardLayout/Services/getOrgLicencesHeaderItems';
import DataRefresher from '../../../components/DataRefresher/DataRefresher';
import getOptProductsCatalogueUrl from '../../../utils/opt/getOptProductsCatalogueUrl.js';
import { isLtiMode } from '../../../utils/platform';
import redirectToUrl from '../../../utils/redirectToUrl.js';
import { GLYPHS } from '../../../components/SVGIcon/SVGIcon.js';

const { isPlacementCentre } = require('@oup/shared-node-browser/org.js');

const rolesAllowedToAddLicences = [
  userRoles.TEACHER_ADMIN,
  userRoles.ORG_ADMIN,
  userRoles.OUP_ADMIN,
  userRoles.OUP_SUPPORT,
  userRoles.OUP_SERVICE_MANAGER
];

class HubListPageLicences extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasUpdated: false
    };
  }

  componentDidMount() {
    this.checkOrgConfigAndInitialize();
  }

  componentDidUpdate(prevProps) {
    const { hasUpdated } = this.state;
    if (!hasUpdated) {
      const { orgConfig } = this.props;
      if (prevProps.orgConfig !== orgConfig) {
        this.checkOrgConfigAndInitialize();
      }
      this.setState({ hasUpdated: true });
    }
  }

  checkOrgConfigAndInitialize = () => {
    const { orgConfig } = this.props;
    if (orgConfig) {
      this.initializeList();
    } else {
      setTimeout(this.checkOrgConfigAndInitialize, 100);
    }
  };

  initializeList = async () => {
    const { getTestCredits, initialiseSearch, orgId, orgConfig, searchBy } = this.props;
    const isOrgPlacementCentre = isPlacementCentre(orgConfig);
    if (isOrgPlacementCentre && featureIsEnabled('opt-main-features')) {
      await getTestCredits({ orgId });
    } else {
      initialiseSearch(searchBy, false, { orgId, showOnlyRedeemed: true });
    }
  };

  setPageActionFunc = page => {
    const {
      props: { searchBy, setPageAction }
    } = this;
    setPageAction(searchBy, page);
  };

  render() {
    const {
      props: {
        localizedContent: { hubGlossary: hubContent, placementTests: placementTestsContent },
        userRole,
        products,
        page,
        breakpoint,
        loadingResults,
        loadingAddToLibrary,
        totalResults,
        resultIds,
        orgName,
        actions,
        toggleDetailsPopupWithIdAction,
        userCanAddLicences,
        orgConfig,
        orgCode
      },
      setPageActionFunc,
      initializeList
    } = this;
    const isOrgPlacementCentre = isPlacementCentre(orgConfig);
    const licencesPageTitle = `${orgName} | ${hubContent.org_licences_header_licences}`;
    const EnhancedHubListPage = withHubList(LicenceListItem);

    const dropdownActions = {
      toggleDetails: id =>
        toggleDetailsPopupWithIdAction(id, null, null, productInformationContext.ORGANIZATION_LICENCES)
    };

    const emptyStateData = {
      iconSrc: HubIllustrationConstants.ENTER_CODE,
      title: hubContent.org_licences_empty_state_title,
      subtitle:
        isOrgPlacementCentre && featureIsEnabled('opt-main-features')
          ? placementTestsContent.placement_test_licences_empty_state_subtitle
          : hubContent.org_licences_empty_state_subtitle,
      links:
        isOrgPlacementCentre && featureIsEnabled('opt-main-features')
          ? null
          : {
              text: hubContent.learn_about_licences,
              link: APP_CONSTANTS.HUB_SUPPORT_WHAT_IS_A_LICENSE_LINK
            },
      button: {
        showButton: !isLtiMode(),
        icon:
          isOrgPlacementCentre && featureIsEnabled('opt-main-features') ? GLYPHS.ICON_SHOPPING_CART : GLYPHS.ICON_PLUS,
        text:
          isOrgPlacementCentre && featureIsEnabled('opt-main-features')
            ? hubContent.buy_licences_button
            : hubContent.add_licences,
        action:
          isOrgPlacementCentre && featureIsEnabled('opt-main-features')
            ? () => redirectToUrl(getOptProductsCatalogueUrl(orgCode), true)
            : actions.openAddLicensesModalAction,
        isSvgFillWhite: isOrgPlacementCentre && featureIsEnabled('opt-main-features')
      }
    };

    const showRocketLolly = userRole === userRoles.ORG_ADMIN || userRole === userRoles.TEACHER_ADMIN;
    const headingArgs = {
      text: hubContent.org_licences_header_licences,
      size: 'small'
    };
    return (
      <div>
        <Helmet title={licencesPageTitle} />
        {featureIsEnabled('navigation-changes') && <Heading {...headingArgs} />}
        <DataRefresher
          loading={loadingResults || loadingAddToLibrary}
          noSidePadding
          refreshData={() => initializeList()}
        />
        {loadingResults ? (
          <SubSectionSkeletonLoader
            tabName=""
            panelName=""
            speed={2}
            foregroundColor={colors.COLOR_GREY_DISABLED}
            backgroundColor={colors.COLOR_WHITE}
          />
        ) : (
          <div>
            <EnhancedHubListPage
              lists={isOrgPlacementCentre ? [Object.keys(products)] : [resultIds]}
              headerItems={getOrgLicencesHeaderItems(
                hubContent,
                actions,
                {
                  name: hubContent.org_licences_header_product_licences
                },
                isOrgPlacementCentre,
                productInformationContext.ORGANIZATION_LICENCES,
                orgCode
              )}
              currentUsersList={products}
              breakpoint={breakpoint}
              hubContent={hubContent}
              userRole={userRole}
              hasHeaderButtonAccess={userCanAddLicences}
              showCheckboxHeader={false}
              dropdownActions={dropdownActions}
              materialActions={{ openRedeemModalAction: openRedeemModal }}
              emptyStateData={emptyStateData}
              totalResults={totalResults}
              isUserProfilePage={false}
              dropdownItemsType="licences"
              showRocketLolly={showRocketLolly}
              productInformationContext={productInformationContext.ORGANIZATION_LICENCES}
              isOrgPlacementCentre={isOrgPlacementCentre}
            />
            {totalResults > 10 ? (
              <div className="gin-top2">
                <PaginationButtons
                  idPrefix="orgLicencesSearch"
                  value={page}
                  numberOfPages={Math.ceil(totalResults / 10)}
                  onClick={setPageActionFunc}
                  aria={{ 'aria-controls': 'searchResults' }}
                />
              </div>
            ) : null}
          </div>
        )}
      </div>
    );
  }
}

export default compose(
  withLocalizedContent('hubGlossary', 'placementTests'),
  withDataRecency('orgLicences'),
  connect(
    (state, { orgId }) => {
      const organisation = state.organisations.data[orgId] || {};
      return {
        userCanAddLicences: !isLtiMode() && rolesAllowedToAddLicences.includes(state.identity.role),
        products: isPlacementCentre(organisation.orgConfig) ? state.testCredits.products : state.products.data,
        searchTerm: state.search[state.library.instance]?.term,
        sort: state.search[state.library.instance]?.sort,
        page: state.search[state.library.instance]?.page,
        loadingResults: isPlacementCentre(organisation.orgConfig)
          ? state.testCredits.organization.mode === mode.LOADING
          : state.search[state.library.instance]?.loading,
        totalResults: isPlacementCentre(organisation.orgConfig)
          ? state.testCredits.products.length
          : state.search[state.library.instance]?.totalResults,
        loadingAddToLibrary: state.addToLibrary.productDetailsLoading,
        searchFailed: state.search[state.library.instance]?.error,
        resultIds: state.search[state.library.instance]?.ids,
        searchBy: state.library.instance,
        orgName: organisation.name,
        userRole: get(state, ['identity', 'role']),
        orgIsArchived: organisation.archived,
        showConfirmModal: state.archiveUsers.showModal,
        orgId,
        orgConfig: organisation?.orgConfig,
        orgCode: organisation?.customId
      };
    },
    {
      setSearchByAction: setSearchBy,
      setSearchTerm: setTerm,
      triggerSearchAction: triggerSearch,
      setSortAction: setSort,
      setPageAction: setPage,
      showModalAction: showClosePanelModal,
      toggleDetailsPopupWithIdAction: toggleDetailsPopupWithId,
      initialiseSearch: initialiseInstance,
      openAddLicensesModal,
      openRedeemModal,
      getTestCredits: input => getTestCreditsRequest(input)
    }
  )
)(HubListPageLicences);

HubListPageLicences.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  userRole: PropTypes.string.isRequired,
  products: PropTypes.object.isRequired,
  page: PropTypes.number.isRequired,
  loadingResults: PropTypes.bool.isRequired,
  loadingAddToLibrary: PropTypes.bool,
  resultIds: PropTypes.array.isRequired,
  totalResults: PropTypes.number.isRequired,
  searchBy: PropTypes.string.isRequired,
  orgName: PropTypes.string.isRequired,
  setPageAction: PropTypes.func.isRequired,
  toggleDetailsPopupWithIdAction: PropTypes.func.isRequired,
  breakpoint: PropTypes.string.isRequired,
  actions: PropTypes.object.isRequired,
  userCanAddLicences: PropTypes.bool.isRequired,
  initialiseSearch: PropTypes.func,
  orgId: PropTypes.string,
  getTestCredits: PropTypes.func,
  orgConfig: PropTypes.object,
  orgCode: PropTypes.string
};
