const loadClassDetails = (loadClasses, orgId, userId, endSlice, filters, searchAsYouType = false) => {
  const defaultPeopleConfig = { active: true, orgId };

  const classesType = [
    {
      title: 'orgClasses',
      config: {
        ...defaultPeopleConfig,
        ...(filters && { active: filters.active }),
        ...(filters && { archived: filters.archived }),
        isOrgClassTab: true
      }
    },
    {
      title: 'profileClasses',
      config: {
        ...defaultPeopleConfig,
        userId
      }
    }
  ];

  classesType.forEach(classType => {
    loadClasses(classType.title, searchAsYouType, classType.config, endSlice);
  });
};

export default loadClassDetails;
