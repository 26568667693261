import React from 'react';
import PropTypes from 'prop-types';
import Button from '../Button/Button';
import PopoutPanelIconHeading, {
  types as popoutPanelIconHeadingTypes
} from '../PopoutPanelIconHeading/PopoutPanelIconHeading';

function ContactUsConfirmation({ onReset, title, subtitle, bodyText, buttonText }) {
  return (
    <div className="pad4">
      <PopoutPanelIconHeading type={popoutPanelIconHeadingTypes.VALID} title={title} />
      <p className="row cols-center pad-top2">{subtitle || null}</p>
      <p className="row cols-center pad-top2">{bodyText || null}</p>
      <div className="row cols-center pad-top2">
        <Button id="submit-form" text={buttonText} onClick={onReset} />
      </div>
    </div>
  );
}

ContactUsConfirmation.propTypes = {
  onReset: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  bodyText: PropTypes.string,
  buttonText: PropTypes.string.isRequired
};

export default ContactUsConfirmation;
