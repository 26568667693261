import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useWizard } from '@oup/shared-front-end/src/components/Wizard';
import WizardStep from '@oup/shared-front-end/src/components/Wizard/WizardStep';
import { HubIllustrationConstants, HubIllustrationAltText } from '../../globals/hubConstants';
import styles from './RedeemCodeWizard.scss';

function IntroStep({ content }) {
  const { setIsPrimaryButtonDisabled } = useWizard();

  useEffect(() => {
    setIsPrimaryButtonDisabled(false);
  }, []);

  return (
    <div data-testid="REDEEM_CODE_WIZARD_STEP_1" className={styles.stepContainer}>
      <WizardStep
        titleText={content.redeem_code_intro_step_title}
        subtitleText={content.redeem_code_intro_step_subtitle}
        variant="introduction"
        className={styles.introStep}
        image={HubIllustrationConstants.DASHBOARD}
        imageAlt={HubIllustrationAltText.DASHBOARD}
      >
        <ol>
          <li>{content.redeem_code_intro_step_content_1}</li>
          <li>{content.redeem_code_intro_step_content_2}</li>
          <li>{content.redeem_code_intro_step_content_3}</li>
        </ol>
      </WizardStep>
    </div>
  );
}

IntroStep.propTypes = {
  content: PropTypes.object.isRequired
};

export default IntroStep;
