import React from 'react';
import PropTypes from 'prop-types';
import ScrollContainer from '../ScrollContainer/ScrollContainer';
import PopoutPanelIllustrationHeading from '../PopoutPanelIllustrationHeading/PopoutPanelIllustrationHeading';
import PopoutActionFooter from '../PopoutActionFooter/PopoutActionFooter';
import { HubIllustrationConstants, HubIllustrationAltText } from '../../globals/hubConstants';

function RegenerateSignInCardFailure({ content, regenerateSignInCard }) {
  return (
    <ScrollContainer
      headerContent={
        <div>
          <PopoutPanelIllustrationHeading
            title={content.regenerate_sign_in_card_title_error}
            illustrationSrc={HubIllustrationConstants.ERROR}
            illustrationAltText={HubIllustrationAltText.ERROR}
          />
        </div>
      }
      footerContent={
        <PopoutActionFooter
          showActionTextAndIcon={false}
          primaryButtonAction={regenerateSignInCard}
          primaryButtonText={content.try_again}
        />
      }
    />
  );
}

RegenerateSignInCardFailure.propTypes = {
  content: PropTypes.object.isRequired,
  regenerateSignInCard: PropTypes.func.isRequired
};

export default RegenerateSignInCardFailure;
