import * as t from '../../actionTypes';

const successStatusOptions = { credits: {}, isLoading: false, error: false };
const failureStatusOptions = { credits: {}, isLoading: false, error: true };

const initialState = {
  isLoading: false,
  error: false,
  credits: {}
};

function productCredits(state = initialState, action) {
  switch (action.type) {
    case t.GET_PRODUCT_CREDITS_SUCCESS:
    case t.ADD_PRODUCT_CREDITS_SUCCESS:
      return {
        ...state,
        ...successStatusOptions,
        credits: {
          ...state.credits,
          [action.payload.productId]: action.payload.credits
        },
        error: false
      };
    case t.GET_PRODUCT_CREDITS_FAILURE:
      return {
        credits: {},
        ...state,
        ...failureStatusOptions,
        error: true
      };
    case t.ADD_PRODUCT_CREDITS_FAILURE:
      return { ...state, ...failureStatusOptions, error: true, data: {} };
    default:
      return state;
  }
}

export default productCredits;
