import { takeLatest } from 'redux-saga/effects';
import {
  GET_OFFLINE_PRODUCTS,
  GET_OFFLINE_PROFILES,
  GET_OFFLINE_UNITS,
  ADD_OFFLINE_PRODUCTS,
  ADD_OFFLINE_PROFILES,
  ADD_OFFLINE_UNITS,
  DELETE_OFFLINE_UNITS,
  DELETE_OFFLINE_PROFILES,
  GET_OFFLINE_PLAYER_VERSION,
  UPDATE_OFFLINE_PLAYER_VERSION
} from '../../../../actionTypes';

import addOfflineProducts from './addOfflineProducts';
import addOfflineProfiles from './addOfflineProfiles';
import addOfflineUnits from './addOfflineUnits';
import getOfflineProducts from './getOfflineProducts';
import getOfflineProfiles from './getOfflineProfiles';
import getOfflineUnits from './getOfflineUnits';
import deleteOfflineUnits from './deleteOfflineUnits';
import deleteOfflineProfiles from './deleteOfflineProfiles';
import getOfflinePlayerVersion from './getOfflinePlayerVersion';
import updateOfflinePlayerVersion from './updateOfflinePlayerVersion';

function* root() {
  yield takeLatest(ADD_OFFLINE_PRODUCTS, ({ payload }) => addOfflineProducts(payload));
  yield takeLatest(ADD_OFFLINE_PROFILES, ({ payload }) => addOfflineProfiles(payload));
  yield takeLatest(ADD_OFFLINE_UNITS, ({ payload }) => addOfflineUnits(payload));

  yield takeLatest(GET_OFFLINE_PRODUCTS, ({ payload }) => getOfflineProducts(payload));
  yield takeLatest(GET_OFFLINE_PROFILES, ({ payload }) => getOfflineProfiles(payload));
  yield takeLatest(GET_OFFLINE_UNITS, ({ payload }) => getOfflineUnits(payload));

  yield takeLatest(DELETE_OFFLINE_UNITS, ({ payload }) => deleteOfflineUnits(payload));
  yield takeLatest(DELETE_OFFLINE_PROFILES, ({ payload }) => deleteOfflineProfiles(payload));

  yield takeLatest(GET_OFFLINE_PLAYER_VERSION, ({ payload }) => getOfflinePlayerVersion(payload));
  yield takeLatest(UPDATE_OFFLINE_PLAYER_VERSION, ({ payload }) => updateOfflinePlayerVersion(payload));
}

export default root;
