import { call, put, takeLatest, delay, select } from 'redux-saga/effects';
import * as t from '../../../../actionTypes';

import { storeLineItemsFailure, storeLineItemsSuccess } from '../../../../actions/storeLineItemsActions.js';
import { fetchRefreshedJwt } from '../../../../actions/getJwtAndDeeplinkReturnUrlActions.js';
import processLineItemsBatch from '../../../apiCalls/ngi/processLineItemsBatch.js';

function delayForAttempt(attempt) {
  return 2 ** (attempt + 1) * 1000;
}

function* processLineItemsWithRetry({ orgId, clientId, lmsCourseId, productId, groupGuid, attempts, token }) {
  for (let i = 0; i < attempts; i += 1) {
    try {
      const apiResponse = yield call(processLineItemsBatch, {
        orgId,
        clientId,
        lmsCourseId,
        productId,
        groupGuid: groupGuid || undefined,
        token
      });

      if (apiResponse.signedJwt) {
        yield put(fetchRefreshedJwt(apiResponse.signedJwt));
      }
      return apiResponse;
    } catch (e) {
      if (i < attempts) {
        yield delay(delayForAttempt(i));
      } else {
        yield put(storeLineItemsFailure('Maximum retries exceeded'));
      }
    }
  }

  throw new Error('Line item creation failed');
}

function* processLineItems({ payload: { config, productsWithGradeItems }, callback }) {
  let response;
  try {
    for (let i = 0; i < productsWithGradeItems.length; i += 1) {
      const currentProduct = productsWithGradeItems[i];
      productsWithGradeItems[i].status = 'processing';
      do {
        const { jwt } = yield select(state => state.getJwtAndDeeplinkReturnUrl);

        response = yield call(processLineItemsWithRetry, {
          orgId: config.orgId,
          clientId: config.clientId,
          lmsCourseId: config.lmsCourseId,
          groupGuid: config.groupGuid,
          productId: currentProduct.productId,
          attempts: 4,
          token: jwt
        });
        productsWithGradeItems[i].processed = productsWithGradeItems[i].activitiesNo - response.remaining;
        yield put(storeLineItemsSuccess({ config, productsWithGradeItems }));
      } while (response.remaining !== 0);

      productsWithGradeItems[i].status = 'processed';
    }

    callback();
  } catch (error) {
    yield put(storeLineItemsFailure('Failed to store line items'));
  }
}

function* processLineItemsSaga() {
  yield takeLatest(t.NGI_STORE_LINE_ITEMS_REQUEST, processLineItems);
}

export default processLineItemsSaga;
