import React from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// Redux
import { bulkPasswordResetRequest, bulkPasswordResetStateReset } from '../redux/actions/bulkPasswordReset';
// Components
import { SidePanel } from '../components';
import PopoutPanelIconHeading, { types } from '../components/PopoutPanelIconHeading/PopoutPanelIconHeading.js';
import BulkPasswordResetError from '../components/BulkPasswordReset/BulkPasswordResetError.js';
import BulkPasswordResetConfirmation from '../components/BulkPasswordReset/BulkPasswordResetConfirmation.js';
import BulkPasswordResetSuccess from '../components/BulkPasswordReset/BulkPasswordResetSuccess.js';

function BulkPasswordResetPanel({
  isOpen,
  onClose,
  bulkPasswordResetStateResetAction,
  bulkPasswordResetRequestAction,
  isLoading,
  success,
  error,
  content,
  orgId,
  userIds = []
}) {
  const bulkPasswordReset = () => {
    bulkPasswordResetRequestAction({ orgId, userIds });
  };

  const handleClosePanel = () => {
    bulkPasswordResetStateResetAction();
    onClose();
  };

  const renderContent = () => {
    switch (true) {
      case isLoading:
        return (
          <PopoutPanelIconHeading
            type={types.LOADING}
            title={content.please_wait}
            subtitle={content.bulk_password_reset_loading_subtitle}
            hideCloseButton
          />
        );

      case success:
        return <BulkPasswordResetSuccess content={content} onClose={handleClosePanel} userCount={userIds.length} />;
      case error:
        return <BulkPasswordResetError content={content} bulkPasswordReset={bulkPasswordReset} />;
      default:
        return (
          <BulkPasswordResetConfirmation
            content={content}
            userCount={userIds.length}
            bulkPasswordReset={bulkPasswordReset}
          />
        );
    }
  };
  return (
    <SidePanel
      id="BulkPasswordResetPanel"
      ariaLabel={content.bulk_password_reset_panel_aria_label}
      isOpen={isOpen}
      onClose={handleClosePanel}
    >
      {renderContent()}
    </SidePanel>
  );
}

export default compose(
  connect(
    ({ bulkPasswordReset = {} }) => ({
      isLoading: bulkPasswordReset.isLoading,
      success: bulkPasswordReset.success,
      error: bulkPasswordReset.error
    }),
    {
      bulkPasswordResetRequestAction: bulkPasswordResetRequest,
      bulkPasswordResetStateResetAction: bulkPasswordResetStateReset
    }
  )
)(BulkPasswordResetPanel);

BulkPasswordResetPanel.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  bulkPasswordResetRequestAction: PropTypes.func.isRequired,
  bulkPasswordResetStateResetAction: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  success: PropTypes.bool,
  error: PropTypes.bool,
  content: PropTypes.object.isRequired,
  orgId: PropTypes.string.isRequired,
  userIds: PropTypes.array.isRequired
};
