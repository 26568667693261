import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import { connect, useSelector } from 'react-redux';
import ValidationMessage from '@oup/shared-front-end/src/components/ValidationMessage';
import Button from '@oup/shared-front-end/src/components/Button';
import Footer from '@oup/shared-front-end/src/components/Wizard/Footer/Footer.js';
import HubEmptyStateRestyle from '../../../HubEmptyStateRestyle/HubEmptyStateRestyle';
import ScrollContainer from '../../../ScrollContainer/ScrollContainer.js';
import SVGIcon, { GLYPHS } from '../../../SVGIcon/SVGIcon';
import PanelHeading from '../../../PanelHeading/PanelHeading.js';
import withLocalizedContent from '../../../../language/withLocalizedContent';
import PanelSearchControl from '../../../PanelSearchControl/PanelSearchControl';
import PaginationButtons from '../../../PaginationButtons/PaginationButtons.js';
import { HubLayoutConstants, HubIllustrationConstants, HubIllustrationAltText } from '../../../../globals/hubConstants';
import styles from './ManageAddedStudentsPanel.scss';
import { getPlacementTestSessionRequest } from '../../../../redux/actions/placementTestOnboardingWizard.js';
import UserListing, { ListingTypes } from '../../../UserListing/UserListing.js';

function ManageAddedStudentsPanel({
  placementTestSessionName,
  cancelPanel,
  onAddStudents,
  studentsDetails = {},
  totalResults = 0,
  failed,
  isManageSessionEditPanel,
  localizedContent: {
    placementTests: placementTestsContent,
    studentSelectionPanel: studentSelectionPanelContent,
    hubGlossary: hubContent
  },
  getPlacementTestSessionRequestAction
}) {
  const [currentPage, sertCurrentPage] = useState(1);
  const [selectedStudents, setSelectedStudents] = useState([]);

  const currentOrganisationId = useSelector(state => state.identity.currentOrganisationId);
  const placementTestSessionId = useSelector(state => state.placementTestSessionCreate.placementTestSessionId);

  const addOrRemoveStudentsToSelected = id => {
    setSelectedStudents(prev => {
      if (!prev.includes(id)) {
        return [...prev, id];
      }
      if (prev.includes(id)) {
        const newList = prev.filter(item => item !== id);
        return newList;
      }
      return prev;
    });
  };

  const paginate = pageNumber => sertCurrentPage(pageNumber);

  useEffect(() => {
    const interval = setInterval(() => {
      getPlacementTestSessionRequestAction({ orgId: currentOrganisationId, sessionId: placementTestSessionId });
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  return (
    <ScrollContainer
      headerContent={
        <>
          <PanelHeading
            title={
              isManageSessionEditPanel
                ? placementTestsContent.manage_session_manage_students_panel_title
                : placementTestsContent.manage_added_students_panel_title
            }
            subtitle={
              isManageSessionEditPanel
                ? placementTestsContent.manage_session_manage_students_panel_subtitle.replace(
                    '[sessionName]',
                    `<b>${placementTestSessionName}</b>`
                  )
                : placementTestsContent.manage_added_students_panel_subtitle.replace(
                    '[sessionName]',
                    `<b>${placementTestSessionName}</b>`
                  )
            }
            customClassName={styles.headingStyle}
          />
          {totalResults ? (
            <PanelSearchControl
              panelType={HubLayoutConstants.FILTER_PANEL_TYPES.ADD_STUDENTS_CLASS}
              filterOptions={[
                { text: 'Username', value: 'username' },
                { text: 'Name', value: 'name' }
              ]}
              sortOptions={[
                { text: 'First Name', value: 'firstName' },
                { text: 'Last Name', value: 'lastName' }
              ]}
              placeholder={studentSelectionPanelContent.search_bar_placeholder_secondary}
            />
          ) : null}
        </>
      }
      footerContent={
        <Footer
          primaryButtonLabel={placementTestsContent.placement_test_remove_students}
          secondaryButtonLabel={hubContent.cancel}
          primaryButtonAction={{}}
          secondaryButtonAction={cancelPanel}
          primaryButtonAttributes={{
            dataAttributes: {
              testid: 'PLACEMENT_TEST_PANEL_MANAGE_STUDENTS_REMOVE_STUDENTS_BUTTON'
            },
            disabled: !selectedStudents.length
          }}
          secondaryButtonAttributes={{
            dataAttributes: {
              testid: 'PLACEMENT_TEST_PANEL_MANAGE_STUDENTS_REMOVE_STUDENTS_CANCEL_BUTTON'
            }
          }}
        />
      }
    >
      {!failed && totalResults ? (
        <div className={styles.manageAddedStudentsContainer}>
          <ValidationMessage state="information" className={styles.validationMessage}>
            {placementTestsContent.manage_added_students_panel_information_message}
          </ValidationMessage>
          <div className={styles.manageAddedStudentAddBtnContainer}>
            <h4>{placementTestsContent.placement_test_summary_students.replace(':', '')}</h4>

            <Button
              onClick={onAddStudents}
              text={placementTestsContent.placement_test_add_students}
              variant="outline"
              size="small"
              icon={{ component: <SVGIcon glyph={GLYPHS.ICON_PLUS} /> }}
              dataAttributes={{ testid: 'MANAGE_SESSION_MANAGE_STUDENTS_PANNEL_ADD_BTN' }}
            />
          </div>

          <UserListing
            items={studentsDetails.items}
            selectable={studentsDetails.selectableIds}
            selectAllEnabled
            listingType={ListingTypes.PANEL}
            selectedItems={selectedStudents}
            onItemSelect={addOrRemoveStudentsToSelected}
          />

          {totalResults > 10 ? (
            <div className="gin-top2">
              <PaginationButtons
                idPrefix="placementTestStudentsSearch"
                value={currentPage}
                numberOfPages={Math.ceil(totalResults / 10)}
                onClick={paginate}
                aria={{ 'aria-controls': 'searchPlacementTestStudentsResults' }}
              />
            </div>
          ) : null}
        </div>
      ) : (
        <>
          <HubEmptyStateRestyle
            iconSrc={HubIllustrationConstants.SEARCH}
            iconAlt={HubIllustrationAltText.SEARCH}
            title={studentSelectionPanelContent.no_students_in_org_title}
            bodyText={placementTestsContent.manage_added_students_panel_information_message}
          />
          <div className={styles.centerAddStudentsBtn}>
            <Button
              onClick={onAddStudents}
              text={placementTestsContent.placement_test_add_students}
              variant="outline"
              size="small"
              icon={{ component: <SVGIcon glyph={GLYPHS.ICON_PLUS} /> }}
              dataAttributes={{ testid: 'MANAGE_SESSION_MANAGE_STUDENTS_PANNEL_ADD_BTN' }}
            />
          </div>
        </>
      )}
    </ScrollContainer>
  );
}

ManageAddedStudentsPanel.propTypes = {
  cancelPanel: PropTypes.func,
  onAddStudents: PropTypes.func,
  placementTestSessionName: PropTypes.string.isRequired,
  failed: PropTypes.bool.isRequired,
  studentsDetails: PropTypes.array,
  isManageSessionEditPanel: PropTypes.bool,
  localizedContent: PropTypes.object.isRequired,
  getPlacementTestSessionRequestAction: PropTypes.func,
  totalResults: PropTypes.number
};

export default compose(
  withLocalizedContent('hubGlossary', 'placementTests', 'studentSelectionPanel'),
  connect(null, {
    getPlacementTestSessionRequestAction: getPlacementTestSessionRequest
  })
)(ManageAddedStudentsPanel);
