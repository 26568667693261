import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
// Styles
import styles from './OnlineTestModal.scss';
import colors from '../../globals/colors';
// Components
import HubModalLayout from '../HubModalLayout/HubModalLayout';
import Button, { buttonTypes } from '../Button/Button';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import HubEmptyState from '../HubEmptyState/HubEmptyState';
import { SIZES as thumbnailSizes } from '../Thumbnail/Thumbnail';
// Constants
import { ResourceConstants, TEST_STATUS } from '../../globals/hubConstants';
import userRoles from '../../globals/userRoles';
import breakpoints from '../../globals/breakpoints';
import preventBodyScroll from '../../utils/dom/preventBodyScroll';

function OnlineTestModal({
  closeModal,
  test = {},
  selectedTestId,
  hubContent,
  openWarningModal,
  userRole,
  downloadIsLoading,
  breakpoint
}) {
  const { PREVIEWABLE_FORMATS } = ResourceConstants;

  const isAdaptedTest = test.isAdaptedTest || !!test.parent_resource_id;

  useEffect(() => {
    preventBodyScroll(!!selectedTestId);
  });

  const renderHeader = () => (
    <div className={styles.testModalHeader}>
      <div className={styles.closeButtonContainer}>
        <Button
          text={hubContent.button_close_text}
          type={buttonTypes.CLOSE_WHITE}
          onClick={e => {
            e.stopPropagation();
            if (userRole === userRoles.LEARNER && test.status === TEST_STATUS.ACTIVE) {
              openWarningModal(test.id);
            } else {
              closeModal();
            }
          }}
        />
      </div>
    </div>
  );

  const renderContent = () => (
    <div className={styles.testModal}>
      {downloadIsLoading && (
        <div className={styles.loading}>
          <div className={styles.loaderSvg}>
            <SVGIcon glyph={GLYPHS.ICON_LOADING} />
          </div>
          <h1>{hubContent.loading_resource_title}</h1>
          <p>{hubContent.loading_resource_subtitle}</p>
        </div>
      )}
      <iframe
        name="player"
        title="player"
        src="about:blank"
        // eslint-disable-next-line react/no-unknown-property
        allowTransparency
        className={styles.testModalIframe}
        allowFullScreen
        allow="microphone *"
      />
    </div>
  );

  const renderNonPreviewableContent = () => (
    <div className={styles.resourceModalNonPreviewable}>
      <HubEmptyState
        glyphIcon={GLYPHS.ICON_DOWNLOAD}
        glyphSize={breakpoint === breakpoints.XXS ? thumbnailSizes.MEDIUM : thumbnailSizes.HEADING}
        glyphBackgroundColour={colors.HUB_BLUE}
        title={hubContent.download_file_to_view_it}
        customClassName="resourceModalEmptyState"
      />
    </div>
  );

  return (
    <div className={`${styles.testOuterModal} ${selectedTestId ? styles.open : ''}`}>
      <HubModalLayout
        isOpen={!!selectedTestId}
        id="testModalId"
        closeAction={closeModal}
        ariaLabel={hubContent.preview_assessment_modal_aria.replace(
          '{testTitle}',
          !isAdaptedTest ? test.title : test.resource_title
        )}
      >
        {selectedTestId ? (
          <div>
            {renderHeader()}
            <div className={styles.testModalContent}>
              {PREVIEWABLE_FORMATS.includes((test.format || '').toLowerCase())
                ? renderContent()
                : renderNonPreviewableContent()}
            </div>
          </div>
        ) : null}
      </HubModalLayout>
    </div>
  );
}

OnlineTestModal.propTypes = {
  test: PropTypes.object,
  selectedTestId: PropTypes.string,
  closeModal: PropTypes.func,
  hubContent: PropTypes.object,
  openWarningModal: PropTypes.func,
  userRole: PropTypes.string,
  downloadIsLoading: PropTypes.bool,
  breakpoint: PropTypes.string
};

export default OnlineTestModal;
