export const limitTypes = {
  USERS: 'USERS',
  CLASSES: 'CLASSES',
  USERS_AND_CLASSES: 'USERS_AND_CLASSES',
  NONE: 'NONE'
};

const warningLimits = {
  USERS: 1300,
  CLASSES: 375
};

export const maximumRecommendedLimits = {
  USERS: 1500,
  CLASSES: 400
};

export const getLimitTypeReached = (classesCount, activeUserCount) => {
  let limitTypeReached = '';
  switch (true) {
    case classesCount >= warningLimits.CLASSES && activeUserCount >= warningLimits.USERS:
      limitTypeReached = limitTypes.USERS_AND_CLASSES;
      break;
    case classesCount >= warningLimits.CLASSES && activeUserCount < warningLimits.USERS:
      limitTypeReached = limitTypes.CLASSES;
      break;
    case classesCount < warningLimits.CLASSES && activeUserCount >= warningLimits.USERS:
      limitTypeReached = limitTypes.USERS;
      break;
    default:
      limitTypeReached = limitTypes.NONE;
  }
  return limitTypeReached;
};
