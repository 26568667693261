import * as t from '../actionTypes';

export const initialState = {
  placementTestSessionNameValue: '',
  placementTestSessionPending: false,
  placementTestSessionIdCreated: '',
  placementTestSessionCreationError: false,
  placementTestSessionCreationLoading: false,
  placementTestSessionUpdated: false,
  placemenetTestSessionEnroledUser: '',
  joiningCode: {},
  orgTestCredits: [],
  usersDetails: {
    students: {},
    studentsDataError: false
  },
  learningMaterialDetails: {
    learningMaterials: {},
    learningMaterialsDataError: false
  },
  placementTest: []
};

export default function placementOnboardingWizard(state = initialState, action = {}) {
  switch (action.type) {
    case t.ONBOARDING_WIZARD_CREATE_PLACEMENT_TEST_SESSION_REQUEST:
      return {
        ...state,
        placementTestSessionNameValue: action.payload.placementTestSessionNameValue,
        placementTestSessionCreationError: false,
        placementTestSessionCreationLoading: true,
        placementTestSessionNamePending: true
      };
    case t.ONBOARDING_WIZARD_SET_CREATE_PLACEMENT_TEST_SESSION_SUCCESS:
      return {
        ...state,
        placementTestSessionIdCreated: action.payload.classId,
        placementTestSessionCreationError: false,
        placementTestSessionCreationLoading: false,
        placementTestSessionNamePending: false,
        joiningCode: action.payload.joiningCode,
        userDetails: {
          ...state.userDetails,
          ...action.payload.userDetails
        }
      };
    case t.ONBOARDING_WIZARD_SET_CREATE_PLACEMENT_TEST_SESSION_ERROR:
      return {
        ...state,
        placementTestSessionNamePending: false,
        placementTestSessionCreationError: true,
        placementTestSessionCreationLoading: false,
        placementTestSessionIdCreated: ''
      };
    case t.ONBOARDING_WIZARD_UPDATE_PLACEMENT_TEST_SESSION_REQUEST:
      return {
        ...state,
        placementTestSessionCreationError: false,
        placementTestSessionCreationLoading: true,
        placementTestSessionNamePending: true,
        placementTestSessionUpdated: false
      };
    case t.ONBOARDING_WIZARD_UPDATE_PLACEMENT_TEST_SESSION_REQUEST_SUCCESS:
      return {
        ...state,
        placementTestSessionCreationError: false,
        placementTestSessionCreationLoading: false,
        placementTestSessionNamePending: false,
        placementTestSessionUpdated: true
      };
    case t.ONBOARDING_WIZARD_UPDATE_PLACEMENT_TEST_SESSION_REQUEST_ERROR:
      return {
        ...state,
        placementTestSessionCreationError: true,
        placementTestSessionCreationLoading: false,
        placementTestSessionNamePending: false,
        placementTestSessionUpdated: false
      };
    case t.ONBOARDING_WIZARD_GET_PLACEMENT_TEST_SESSION_REQUEST:
      return {
        ...state
      };
    case t.ONBOARDING_WIZARD_GET_PLACEMENT_TEST_SESSION_REQUEST_SUCCESS:
      return {
        ...state,
        placementTest: action.payload.placementTest,
        placementTestSessionNameValue: action.payload.className,
        joiningCode: action.payload.joiningCode,
        orgTestCredits: action.payload.orgTestCredits
      };
    case t.ONBOARDING_WIZARD_CLEAR_STATE:
      return {
        ...initialState
      };
    default:
      return state;
  }
}
