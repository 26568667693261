import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import TextInput from '@oup/shared-front-end/src/components/TextInput/TextInput';
import {
  setPlacementTestSessionName,
  placementTestSessionNameOnBlur
} from '../../../redux/reducers/placementTestSessionCreate';

function PlacementTestSessionNameInput({
  placementTestSessionNameValue,
  placementTestSessionNameValidationMessage,
  placementTestSessionNameValidationPending,
  placementTestSessionNameValidationIsInvalid,
  placementTestSessionNameValidationIsWarning,
  placementTestSessionNameValidationIsValid,
  placementTestSessionNameLabel,
  placementTestSessionNamePlaceholder,
  isInputDisabled,
  setPlacementTestSessionNameAction,
  placementTestSessionNameOnBlurAction
}) {
  const [fieldState, setFieldState] = useState();

  useEffect(() => {
    if (placementTestSessionNameValidationIsInvalid) setFieldState('invalid');
    if (placementTestSessionNameValidationIsWarning) setFieldState('warning');
    if (placementTestSessionNameValidationIsValid) setFieldState('valid');
    if (placementTestSessionNameValidationPending) setFieldState('loading');
  }, [
    placementTestSessionNameValidationIsInvalid,
    placementTestSessionNameValidationIsWarning,
    placementTestSessionNameValidationIsValid,
    placementTestSessionNameValidationPending
  ]);

  return (
    <TextInput
      id="placementTestSessionName"
      name="placementTestSessionName"
      label={placementTestSessionNameLabel || ''}
      placeholder={placementTestSessionNamePlaceholder}
      maxLength={255}
      value={placementTestSessionNameValue}
      onChange={event => setPlacementTestSessionNameAction(event.target.value)}
      onBlur={placementTestSessionNameOnBlurAction}
      disabled={isInputDisabled}
      state={fieldState}
      validationMessage={placementTestSessionNameValidationMessage}
    />
  );
}

PlacementTestSessionNameInput.propTypes = {
  placementTestSessionNameValue: PropTypes.string.isRequired,
  placementTestSessionNameValidationMessage: PropTypes.string.isRequired,
  placementTestSessionNameValidationPending: PropTypes.bool.isRequired,
  placementTestSessionNameValidationIsInvalid: PropTypes.bool.isRequired,
  placementTestSessionNameValidationIsWarning: PropTypes.bool.isRequired,
  placementTestSessionNameValidationIsValid: PropTypes.bool.isRequired,
  isInputDisabled: PropTypes.bool,
  setPlacementTestSessionNameAction: PropTypes.func.isRequired,
  placementTestSessionNameLabel: PropTypes.string,
  placementTestSessionNamePlaceholder: PropTypes.string,
  placementTestSessionNameOnBlurAction: PropTypes.func
};

export default compose(
  connect(
    state => ({
      placementTestSessionNameValue: state.placementTestSessionCreate.placementTestSessionNameValue,
      placementTestSessionNameValidationMessage:
        state.placementTestSessionCreate.placementTestSessionNameValidationMessage,
      placementTestSessionNameValidationPending:
        state.placementTestSessionCreate.placementTestSessionNameValidationPending,
      placementTestSessionNameValidationIsInvalid:
        state.placementTestSessionCreate.placementTestSessionNameValidationIsInvalid,
      placementTestSessionNameValidationIsWarning:
        state.placementTestSessionCreate.placementTestSessionNameValidationIsWarning,
      placementTestSessionNameValidationIsValid:
        state.placementTestSessionCreate.placementTestSessionNameValidationIsValid
    }),
    {
      setPlacementTestSessionNameAction: setPlacementTestSessionName,
      placementTestSessionNameOnBlurAction: placementTestSessionNameOnBlur
    }
  )
)(PlacementTestSessionNameInput);
