import React from 'react';
import PropTypes from 'prop-types';
import withLocalizedContent from '../../language/withLocalizedContent';
import SVGIcon from '../SVGIcon/SVGIcon.js';
import styles from './HubInfoSection.scss';

function HubInfoSection({ glyph, title, children }) {
  return (
    <div className={styles.welcomePageExplanationContainer}>
      <div className={styles.welcomePageImageContainer}>
        <SVGIcon glyph={glyph} />
      </div>
      <div className={styles.welcomePageTextContainer}>
        <span className={styles.welcomePageTextSubtitleContainer}>{title}</span>
        {children}
      </div>
    </div>
  );
}

HubInfoSection.propTypes = {
  glyph: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node
};

export default withLocalizedContent('hubGlossary')(HubInfoSection);
