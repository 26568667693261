import React from 'react';
import PropTypes from 'prop-types';
import styles from './HubTopRow.scss';
import HubTopBanner from '../HubTopBanner/HubTopBanner.js';
import HubTopContent from '../HubTopContent/HubTopContent.js';

function HubTopRow({ hubContent, homepageContent }) {
  return (
    <div className={styles.homePageSectionOne}>
      <HubTopBanner hubContent={hubContent} homepageContent={homepageContent} />
      <HubTopContent
        text={homepageContent.banner_secondary_items.secondary_text}
        hubContent={hubContent}
        homepageContent={homepageContent}
      />
    </div>
  );
}

HubTopRow.propTypes = {
  hubContent: PropTypes.object.isRequired,
  homepageContent: PropTypes.object.isRequired
};

export default HubTopRow;
