import { put, takeLatest } from 'redux-saga/effects';

import { LOAD_CLASS_DETAILS, classDetailsLoaded, classDetailsLoadError } from '../../../../reducers/data/classrooms.js';
import { storePeople } from '../../../../reducers/data/people.js';

import getClassDetailsApi from '../../../apiCalls/classes/getClassDetails.api.js';

function* loadClassDetails({ orgId, classId }) {
  console.log('[classPageLoader] Loading class for ID: ', classId);

  const apiResults = yield getClassDetailsApi(orgId, classId);

  if (apiResults.error || apiResults.status !== 'success') {
    console.log('[classPageLoader] Error loading class details');
    yield put(classDetailsLoadError(classId));
  } else {
    console.log('[classPageLoader] Loaded class details', apiResults.data);
    if (apiResults.data.userDetails) {
      const isImpersonationMode = !!localStorage.getItem('impersonation-token');
      if (!isImpersonationMode) {
        yield put(storePeople(apiResults.data.userDetails));
      }
    }
    yield put(classDetailsLoaded(classId, apiResults.data));
  }
}

export default function* classPageLoader() {
  console.log('[classPageLoader] Registering action listeners');
  yield takeLatest([LOAD_CLASS_DETAILS], loadClassDetails);
}
