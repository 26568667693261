import { put } from 'redux-saga/effects';
import { loadOfflinePlayerVersion } from '../../../../actions/offlineContentPlayer';

function* getOfflinePlayerVersion() {
  const storedValue = localStorage.getItem('offline_player_version');
  const version = storedValue || '';

  yield put(loadOfflinePlayerVersion(version));
}

export default getOfflinePlayerVersion;
