import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import ThemeProvider from '@oup/shared-front-end/src/components/ThemeProvider/ThemeProvider';
import ErrorPage from '../StatePages/ErrorPage/ErrorPage.js';
import LoadingPage from '../StatePages/LoadingPage/LoadingPage.js';
// Utils
import { setFavicons } from '../../globals/favicons';
import gtmPageview from '../../utils/gtm/pageview.js';

function YoungLearnerLayout({ children, favIcons, orgId, role, userId, appError, appErrorReference, appReady }) {
  const gtmParams = {
    orgId,
    userRole: role,
    userId
  };

  return (
    <div>
      {appError ? <ErrorPage appErrorReference={appErrorReference} showHeader /> : null}
      {!appReady ? <LoadingPage /> : null}
      {!appError && appReady ? (
        <>
          <Helmet htmlAttributes={{ lang: 'en' }} link={favIcons} onChangeClientState={() => gtmPageview(gtmParams)} />
          <ThemeProvider theme="hubYoungLearner">{children}</ThemeProvider>
        </>
      ) : null}
    </div>
  );
}

export default compose(
  connect(
    ({ app: { appError, appErrorReference, appReady }, identity: { userId = '', role, currentOrganisationId } }) => ({
      appError,
      appErrorReference,
      appReady,
      favIcons: setFavicons('hub'),
      userId,
      role,
      orgId: currentOrganisationId
    })
  )
)(YoungLearnerLayout);

YoungLearnerLayout.propTypes = {
  children: PropTypes.node,
  favIcons: PropTypes.array,
  orgId: PropTypes.string,
  role: PropTypes.string,
  userId: PropTypes.string,
  appError: PropTypes.bool.isRequired,
  appErrorReference: PropTypes.string,
  appReady: PropTypes.bool.isRequired
};
