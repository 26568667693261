import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import Footer from '@oup/shared-front-end/src/components/Wizard/Footer/Footer.js';
import { useWizard } from '@oup/shared-front-end/src/components/Wizard';
import { ACCESS_CODE_TYPES } from '@oup/shared-node-browser/constants';

import {
  closeSelfRegisteredWizard,
  openRedeemModal,
  openJoinClassWithCodeModal,
  openJoinPlacementTestWithCodeModal
} from '../../../redux/actions/hubUi';

import withLocalizedContent from '../../../language/withLocalizedContent';

function ChooseRoleWizardFooter({
  localizedContent: { hubGlossary: hubContent },
  selectedStudentCodeOption,
  closeSelfRegisteredWizardAction,
  openRedeemModalAction,
  openJoinPlacementTestWithCodeModalAction,
  openJoinClassWithCodeModalAction
}) {
  const { nextStep, isPrimaryButtonDisabled, isLastStep } = useWizard();

  const setPrimaryButtonLabel = () => hubContent.next;

  const handleStep = () => {
    switch (true) {
      case isLastStep && selectedStudentCodeOption === ACCESS_CODE_TYPES.PRODUCT:
        closeSelfRegisteredWizardAction();
        openRedeemModalAction();
        break;

      case isLastStep && selectedStudentCodeOption === ACCESS_CODE_TYPES.CLASS:
        closeSelfRegisteredWizardAction();
        openJoinClassWithCodeModalAction();
        break;

      case isLastStep && selectedStudentCodeOption === ACCESS_CODE_TYPES.PLACEMENT_TEST:
        closeSelfRegisteredWizardAction();
        openJoinPlacementTestWithCodeModalAction();
        break;

      default:
        nextStep();
        break;
    }
  };

  return (
    <Footer
      primaryButtonAction={handleStep}
      primaryButtonLabel={setPrimaryButtonLabel()}
      primaryButtonAttributes={{
        disabled: isPrimaryButtonDisabled,
        dataAttributes: {
          testid: 'SELF_REGISTERED_USER_ONBOARDING_WIZARD_NEXT_BUTTON'
        }
      }}
    />
  );
}

ChooseRoleWizardFooter.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  selectedStudentCodeOption: PropTypes.string.isRequired,
  closeSelfRegisteredWizardAction: PropTypes.func.isRequired,
  openJoinClassWithCodeModalAction: PropTypes.func.isRequired,
  openJoinPlacementTestWithCodeModalAction: PropTypes.func.isRequired,
  openRedeemModalAction: PropTypes.func.isRequired
};

export default compose(
  withLocalizedContent('hubGlossary'),
  connect(null, {
    closeSelfRegisteredWizardAction: closeSelfRegisteredWizard,
    openRedeemModalAction: openRedeemModal,
    openJoinClassWithCodeModalAction: openJoinClassWithCodeModal,
    openJoinPlacementTestWithCodeModalAction: openJoinPlacementTestWithCodeModal
  })
)(ChooseRoleWizardFooter);
