import { takeLatest } from 'redux-saga/effects';
import { GET_COURSE_PUBLISH_REQUEST, PUBLISH_COURSE_REQUEST } from '../../../../actionTypes';

import getCoursePublishList from './getCoursePublishList';
import publishCourse from './publishCourse.js';

function* root() {
  yield takeLatest(GET_COURSE_PUBLISH_REQUEST, ({ payload }) => getCoursePublishList(payload));
  yield takeLatest(PUBLISH_COURSE_REQUEST, ({ payload }) => publishCourse(payload));
}

export default root;
