import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import Toggle from '@oup/shared-front-end/src/components/Toggle';
import PanelHeading from '../../../../components/PanelHeading/PanelHeading';
import PopoutActionFooter from '../../../../components/PopoutActionFooter/PopoutActionFooter';
import ScrollContainer from '../../../../components/ScrollContainer/ScrollContainer';
import { updatePlacementTestSessionRequest } from '../../../../redux/actions/placementTestOnboardingWizard';
import style from '../../../../components/PlacementTestOnBoardingWizard/PlacementTestShowResults/PlacementTestShowResults.scss';
import { setEditedPlacementTest } from '../../../../redux/reducers/placementTestSessionCreate';
import withLocalizedContent from '../../../../language/withLocalizedContent';

function PlacementTestEditShowResults({
  panelSessionName,
  placementTestSessionId,
  updatePlacementTestSessionRequestAction,
  setEditedPlacementTestAction,
  orgId,
  showResultsToStudents,
  cancelShowResults,
  localizedContent: { placementTests: placementTestsContent, hubGlossary: hubContent }
}) {
  const [editedShowResultsToStudents, setEditedShowResultsToStudents] = useState(showResultsToStudents);
  const [isShowResultsToStudentsTouched, setisShowResultsToStudentsTouched] = useState(false);

  return (
    <ScrollContainer
      headerContent={
        <PanelHeading
          title={placementTestsContent.placement_test_session_manage_results_notifications}
          subtitle={`${hubContent.for_label} <strong>${panelSessionName}</strong>`}
        />
      }
      footerContent={
        <PopoutActionFooter
          wizardButtons
          withIcon={false}
          primaryButtonText={hubContent.save}
          primaryButtonAction={e => {
            updatePlacementTestSessionRequestAction({
              orgId,
              placementTestSessionId,
              placementTest: {
                showResultsToStudents: editedShowResultsToStudents
              }
            });
            setEditedPlacementTestAction({ showResultsToStudents: editedShowResultsToStudents });
            cancelShowResults(e, true);
          }}
          secondaryButtonText={hubContent.cancel}
          secondaryButtonAction={cancelShowResults}
          primaryButtonDisabled={!isShowResultsToStudentsTouched}
        />
      }
    >
      <div className={style.showTestResultsColumn}>
        <div className={style.customiseToggle}>
          <Toggle
            id="placement_test_session_show_results_panel_toggle"
            onChange={() => {
              setisShowResultsToStudentsTouched(true);
              setEditedShowResultsToStudents(!editedShowResultsToStudents);
            }}
            name={placementTestsContent.placement_test_show_results_page_toggle_label}
            toggled={editedShowResultsToStudents}
          />
          {placementTestsContent.placement_test_show_results_page_toggle_label}
        </div>
        <div className={style.noteText}>
          <strong>{hubContent.note_label}</strong> {placementTestsContent.placement_test_show_results_note_text}
        </div>
      </div>
    </ScrollContainer>
  );
}

PlacementTestEditShowResults.propTypes = {
  panelSessionName: PropTypes.string.isRequired,
  placementTestSessionId: PropTypes.string.isRequired,
  updatePlacementTestSessionRequestAction: PropTypes.func.isRequired,
  orgId: PropTypes.string.isRequired,
  setEditedPlacementTestAction: PropTypes.func.isRequired,
  showResultsToStudents: PropTypes.bool,
  cancelShowResults: PropTypes.func.isRequired,
  localizedContent: PropTypes.object
};

export default compose(
  withLocalizedContent('hubGlossary', 'placementTests'),
  connect(
    ({ placementTestSessionCreate, identity }) => ({
      placementTestSessionId: placementTestSessionCreate.placementTestSessionId,
      placementTestSessionNameValue: placementTestSessionCreate.placementTestSessionNameValue,
      orgId: identity.currentOrganisationId,
      showResultsToStudents: placementTestSessionCreate.placementTest.showResultsToStudents
    }),
    {
      updatePlacementTestSessionRequestAction: updatePlacementTestSessionRequest,
      setEditedPlacementTestAction: setEditedPlacementTest
    }
  )
)(PlacementTestEditShowResults);
