import React from 'react';
import PropTypes from 'prop-types';
// Styles
import styles from './TestBuilderModal.scss';
// Components
import HubModalLayout from '../HubModalLayout/HubModalLayout';

function TestBuilderModal({ closeModal, resource, hubContent, resourceId }) {
  const renderPreviewableContent = () => (
    <div className={styles.resourceModalPreviewable}>
      <iframe
        name="player"
        title="player"
        src="about:blank"
        // eslint-disable-next-line react/no-unknown-property
        allowTransparency
        className={styles.resourceModalIframe}
        allowFullScreen
      />
    </div>
  );

  return (
    <div className={`${styles.resourceOuterModal} ${resourceId ? styles.open : ''}`}>
      <HubModalLayout
        isOpen={resourceId}
        id="testBuilderModalId"
        closeAction={closeModal}
        ariaLabel={hubContent.preview_resource_modal_aria.replace('{resourceTitle}', resource?.title)}
      >
        <div className={styles.resourceModalContent}>{renderPreviewableContent()}</div>
      </HubModalLayout>
    </div>
  );
}

TestBuilderModal.propTypes = {
  resource: PropTypes.object,
  resourceId: PropTypes.string,
  closeModal: PropTypes.func,
  hubContent: PropTypes.object
};

export default TestBuilderModal;
