import PropTypes from 'prop-types';
import React, { Component } from 'react';

import PanelNavigationLink from '../../../../../../components/PanelNavigationLink/PanelNavigationLink';
import PopoutNavFooter from '../../../../../../components/PopoutNavFooter/PopoutNavFooter.js';
import PopoutPanelIconHeading from '../../../../../../components/PopoutPanelIconHeading/PopoutPanelIconHeading';
import ScrollContainer from '../../../../../../components/ScrollContainer/ScrollContainer';
import ToolItem from './ToolItem';
import styles from './DeploymentTool.scss';
import { context } from '../../../../../../redux/reducers/hub/hubLMSConfig.reducer';

class Success extends Component {
  render() {
    const { closePanel, mode, tool, tools, hasTools, hubContent } = this.props;

    const ui = {
      ADD: {
        title: hasTools ? 'Deployment added' : 'Integration details saved',
        subtitle: hasTools
          ? `<strong>${tool.displayName}</strong> has been added to your organization`
          : 'Your organizations integration details have been saved'
      },
      EDIT: {
        title: hasTools ? 'Deployment saved' : `${tool.displayName} changes saved`,
        subtitle: `<strong>${tool.displayName}</strong> details have been saved.`
      },
      EDIT_CONFIG: {
        title: `Shared registration details saved`,
        subtitle: 'The details of the following tools have been changed.'
      },
      ARCHIVE: {
        title: hasTools ? 'Deployment archived' : 'Tool archived',
        subtitle: hasTools ? `<strong>${tool.displayName}</strong> has been archived` : 'Your tool has been archived.'
      }
    }[mode];

    return (
      <form onSubmit={this.handleSubmit}>
        <ScrollContainer
          enhancedHeader
          headerContent={
            <div>
              <div className="text-right">
                <PanelNavigationLink isLhs={false} text={hubContent.close_panel_text} action={closePanel} />
              </div>
              <PopoutPanelIconHeading type="VALID" title={ui.title} subtitle={ui.subtitle} />
            </div>
          }
          footerContent={
            <PopoutNavFooter
              nextButtonText="Done"
              nextAction={closePanel}
              nextButtonDisabled={false}
              nextButtonFullWidth
            />
          }
        >
          {mode === context.EDIT_CONFIG && (
            <ol className={styles.toolInfoList}>
              {tools.map(t => (
                <li key={t.tool.toolId}>
                  <ToolItem tool={t.tool} />
                </li>
              ))}
            </ol>
          )}
        </ScrollContainer>
      </form>
    );
  }
}

Success.propTypes = {
  closePanel: PropTypes.func.isRequired,
  mode: PropTypes.bool.isRequired,
  tool: PropTypes.object.isRequired,
  hasTools: PropTypes.bool.isRequired,
  tools: PropTypes.array.isRequired,
  hubContent: PropTypes.object.isRequired
};

export default Success;
