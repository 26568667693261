/* eslint-disable react/no-danger */
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import TextLink from '@oup/shared-front-end/src/components/TextLink';
import { setPlacementTest } from '../../../../../../redux/reducers/placementTestSessionCreate';

import PanelNavigationLink from '../../../../../../components/PanelNavigationLink/PanelNavigationLink.js';
import PopoutActionFooter from '../../../../../../components/PopoutActionFooter/PopoutActionFooter.js';
import { types as iconHeadingTypes } from '../../../../../../components/PopoutPanelIconHeading/PopoutPanelIconHeading.js';
import PopoutPanelIllustrationHeading from '../../../../../../components/PopoutPanelIllustrationHeading/PopoutPanelIllustrationHeading.js';
import ScrollContainer from '../../../../../../components/ScrollContainer/ScrollContainer.js';
import content from '../../../../../../utils/cmsContent.js';
import { HubIllustrationConstants, HubIllustrationAltText } from '../../../../../../globals/hubConstants';
import styles from './enrollUser.scss';
import { isHubMode } from '../../../../../../utils/platform.js';
import { featureIsEnabled } from '../../../../../../globals/envSettings.js';

function EnrolUserBulkConfirmationRestyled({ onComplete, closePanel, userTypeToEnrol, setPlacementTestAction }) {
  const CMS = content.enrolUserPanel;
  const isPlacementTestOverwrite = isHubMode() && featureIsEnabled('opt-main-features') && userTypeToEnrol;
  const popoutTitle = isPlacementTestOverwrite
    ? CMS.confirmation_success_subtitle_usersAdded_opt
    : CMS.confirmation_success_subtitle_usersAdded;

  useEffect(() => {
    if (isPlacementTestOverwrite) {
      setPlacementTestAction({
        studentsBulkImportIsInProgress: true
      });
    }
  }, []);

  return (
    <ScrollContainer
      headerContent={
        <div className="text-right">
          <PanelNavigationLink isLhs={false} text={CMS.close_panel_text} action={closePanel} />
        </div>
      }
      containerClassName={styles.scrollContainerBulkConfirmation}
      footerContent={
        <PopoutActionFooter
          showActionTextAndIcon={false}
          primaryButtonAction={onComplete}
          primaryButtonText={CMS.confirmation_button_done}
        />
      }
    >
      <>
        <div>
          <PopoutPanelIllustrationHeading
            title={CMS.confirmation_success_title_usersAdded}
            subtitle={popoutTitle.replace(
              '{^signInCards}',
              `<strong>${CMS.confirmation_success_subtitle_sign_in_cards}</strong>`
            )}
            type={iconHeadingTypes.VALID}
            illustrationSrc={HubIllustrationConstants.SUCCESS}
            illustrationAltText={HubIllustrationAltText.SUCCESS}
          />
          <div className={styles.linkContainer}>
            <TextLink to={CMS.sign_in_card_administrators_link} target="_blank" rel="noreferrer">
              {CMS.sign_in_card_question}
            </TextLink>
          </div>
        </div>
        <p>
          <span dangerouslySetInnerHTML={{ __html: CMS.confirmation_success_note_usersAdded }} />
        </p>
      </>
    </ScrollContainer>
  );
}

EnrolUserBulkConfirmationRestyled.propTypes = {
  onComplete: PropTypes.func.isRequired,
  closePanel: PropTypes.func.isRequired,
  userTypeToEnrol: PropTypes.string,
  setPlacementTestAction: PropTypes.func
};

export default connect(null, {
  setPlacementTestAction: setPlacementTest
})(EnrolUserBulkConfirmationRestyled);
