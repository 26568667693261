import * as t from '../actionTypes';

export const getCoursePublishRequest = filters => ({
  type: t.GET_COURSE_PUBLISH_REQUEST,
  payload: filters
});

export const getCoursePublishSuccess = data => ({
  type: t.GET_COURSE_PUBLISH_SUCCESS,
  payload: data
});

export const getCoursePublishFailure = () => ({
  type: t.GET_COURSE_PUBLISH_FAILURE
});

export const clearPublishedCourseData = () => ({
  type: t.CLEAR_PUBLISHED_COURSE_DATA
});

export const publishCourseDataRequest = (courseCode, publishType, body) => ({
  type: t.PUBLISH_COURSE_REQUEST,
  payload: { courseCode, publishType, body }
});

export const publishCourseDataStart = () => ({
  type: t.PUBLISH_COURSE_START
});

export const publishCourseDataSuccess = data => ({
  type: t.PUBLISH_COURSE_SUCCESS,
  payload: data
});

export const publishCourseDataFailure = data => ({
  type: t.PUBLISH_COURSE_FAILURE,
  payload: data
});
