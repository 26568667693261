import React from 'react';
import PropTypes from 'prop-types';

import Button from '@oup/shared-front-end/src/components/Button';
import Checkbox from '@oup/shared-front-end/src/components/Checkbox';
import { useLocation } from 'react-router-dom';
import styles from './HubListHeader.scss';

import SVGIcon, { GLYPHS } from '../../components/SVGIcon/SVGIcon';
import { featureIsEnabled } from '../../globals/envSettings';

const isStudentsTab = location => {
  const { pathname } = location;
  const container = pathname?.split('/');
  const path = container?.at(container.length - 1);
  return path === 'students';
};

function HubListHeader({
  item,
  itemIds,
  selectable,
  checkboxData: { selectedIds, onSelectAll, areAllSelected },
  hasHeaderButtonAccess,
  showCheckboxHeader,
  customClassName,
  isPreview,
  isOrgPlacementCentre,
  isPlacementTest,
  productInformationContext
}) {
  const isLicencesTab = !!productInformationContext;

  const getSelectableIds = () => {
    if (!selectable) {
      return [];
    }

    return Array.isArray(selectable) ? selectable : itemIds;
  };
  const location = useLocation();
  const isStudentsPath = isStudentsTab(location);
  const hideAddButton = isOrgPlacementCentre && isStudentsPath;

  const getSelectedAll = () => {
    const selectableItemIds = getSelectableIds();
    // if no items then select all will always be unchecked
    return !selectableItemIds.length || !selectedIds ? false : selectableItemIds.every(id => selectedIds.includes(id));
  };

  return (
    item && (
      <div className={styles.hubListHeader}>
        <div className={`${styles.textContainer} ${styles[customClassName]}`}>
          {showCheckboxHeader && (
            <Checkbox
              id={item.name.replaceAll(' ', '-').toLowerCase()}
              label="Select entity"
              hideLabel
              checked={getSelectedAll()}
              onChange={() => onSelectAll(itemIds, areAllSelected)}
              // disabled={!(Array.isArray(selectable) ? selectable.includes(id) : selectable)}
            />
          )}
          <span>{item.name}</span>
        </div>
        {!isPreview && !isPlacementTest && (
          <>
            {featureIsEnabled('opt-main-features') && item.used && (
              <div className={styles.textContainer}>
                <span>{item.used}</span>
              </div>
            )}
            <div className={styles.textContainer}>
              <span>{item.status}</span>
            </div>
          </>
        )}
        <div className={styles.buttonContainer}>
          {hasHeaderButtonAccess && !hideAddButton && (
            <Button
              onClick={item.action}
              text={item.buttonText}
              variant="filled"
              icon={
                isOrgPlacementCentre && isLicencesTab && featureIsEnabled('opt-main-features')
                  ? { component: <SVGIcon glyph={GLYPHS.ICON_SHOPPING_CART} /> }
                  : { component: <SVGIcon glyph={GLYPHS.ICON_PLUS} /> }
              }
              dataAttributes={item.dataAttributes}
            />
          )}
        </div>
      </div>
    )
  );
}

HubListHeader.defaultProps = {
  itemIds: [],
  selectable: true,
  checkboxData: { selectedIds: [], onSelectAll: false, areAllSelected: false },
  showCheckboxHeader: true,
  customClassName: ''
};

HubListHeader.propTypes = {
  item: PropTypes.object,
  itemIds: PropTypes.array,
  checkboxData: PropTypes.object,
  selectable: PropTypes.bool,
  hasHeaderButtonAccess: PropTypes.bool,
  showCheckboxHeader: PropTypes.bool,
  customClassName: PropTypes.string,
  isPreview: PropTypes.bool,
  isOrgPlacementCentre: PropTypes.bool,
  isPlacementTest: PropTypes.bool,
  productInformationContext: PropTypes.string
};

export default HubListHeader;
