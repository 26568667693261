import React from 'react';
import PropTypes from 'prop-types';
import Button from '@oup/shared-front-end/src/components/Button';
import SVGIcon, { GLYPHS } from '../../SVGIcon/SVGIcon.js';
import copyToClipboard from '../../../structure/HubOrganizationLayout/Utils/copyToClipboard.js';
import styles from './ClassCode.scss';

function ClassCode({ customOrgId, joiningCode, content }) {
  return (
    <div className={styles.classCode}>
      <span>
        <span className={styles.firstPartCode}>{customOrgId}</span>
        <span>-{joiningCode}</span>
      </span>
      <Button
        size="small"
        variant="transparent"
        hideText
        text={content.copy}
        icon={{ component: <SVGIcon glyph={GLYPHS.ICON_COPY} /> }}
        onClick={() => copyToClipboard()(`${customOrgId}-${joiningCode}`)}
        dataAttributes={{ testId: 'CREATE_CLASS_WIZARD_COPY_CLASS_CODE_BUTTON' }}
      />
    </div>
  );
}

ClassCode.propTypes = {
  customOrgId: PropTypes.string.isRequired,
  joiningCode: PropTypes.string.isRequired,
  content: PropTypes.object.isRequired
};

export default ClassCode;
