import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
// Components
import ScrollContainer from '../ScrollContainer/ScrollContainer';
import PopoutPanelIllustrationHeading from '../PopoutPanelIllustrationHeading/PopoutPanelIllustrationHeading';
import PopoutActionFooter from '../PopoutActionFooter/PopoutActionFooter';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
// Constants
import { HubIllustrationConstants, HubIllustrationAltText } from '../../globals/hubConstants';
// Services
import downloadSignInCard from '../../structure/HubOrganizationLayout/Services/downloadSignInCard.js';

function RegenerateSignInCardSuccess({ signInCardUrl, content, onClose }) {
  useEffect(() => {
    if (signInCardUrl) {
      downloadSignInCard(signInCardUrl);
    }
  }, [signInCardUrl]);

  return (
    <ScrollContainer
      headerContent={
        <div>
          <PopoutPanelIllustrationHeading
            title={content.regenerate_sign_in_card_title_success}
            subtitle={content.regenerate_sign_in_card_subtitle_success.replace(
              `${content.sign_in_card}`,
              `<strong>${content.sign_in_card}</strong>`
            )}
            illustrationSrc={HubIllustrationConstants.SUCCESS}
            illustrationAltText={HubIllustrationAltText.SUCCESS}
          />
        </div>
      }
      footerContent={
        <PopoutActionFooter
          showActionTextAndIcon={false}
          primaryButtonAction={onClose}
          primaryButtonText={content.done_button}
          secondaryButtonAction={() => downloadSignInCard(signInCardUrl)}
          secondaryButtonIcon={<SVGIcon glyph={GLYPHS.ICON_DOWNLOAD} />}
          secondaryButtonText={content.download_sign_in_card}
        />
      }
    />
  );
}

RegenerateSignInCardSuccess.propTypes = {
  signInCardUrl: PropTypes.string.isRequired,
  content: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired
};

export default RegenerateSignInCardSuccess;
