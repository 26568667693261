import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import WizardStep from '@oup/shared-front-end/src/components/Wizard/WizardStep';
import withLocalizedContent from '../../../language/withLocalizedContent';
import wizardStyles from '../SelfRegisteredUserOnboardingWizard.scss';
import { showSelfSelectRoleModal } from '../../../redux/actions/hubUi';

function OnboardingWizardSuccess({
  localizedContent: { selfRegisteredUserOnboardingWizard: selfRegisteredUserOnboardingWizardContent }
}) {
  return (
    <div data-testid="SELF_REGISTERED_USER_ONBOARDING_WIZARD_SUCCESS_CONTAINER" className={wizardStyles.step}>
      <WizardStep
        titleText={selfRegisteredUserOnboardingWizardContent.self_registered_user_success_step_title}
        subtitleText={selfRegisteredUserOnboardingWizardContent.self_registered_user_success_step_subtitle}
      />
      <div className={wizardStyles.imageContainer}>
        <img src="/media/feedback/illustration-success.svg" alt="Onboarding successful" />
      </div>
    </div>
  );
}

OnboardingWizardSuccess.propTypes = {
  localizedContent: PropTypes.object.isRequired
};

export default compose(
  connect(null, { showSelfSelectRoleModalAction: showSelfSelectRoleModal }),
  withLocalizedContent('selfRegisteredUserOnboardingWizard')
)(OnboardingWizardSuccess);
