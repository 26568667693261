import React from 'react';
import PropTypes from 'prop-types';
import withLocalizedContent from '../../language/withLocalizedContent';
import styles from './HubActionSection.scss';

function HubActionSection({ buttonText, onClick, children }) {
  return (
    <div className={styles.welcomePageActionContainer}>
      {children}
      <div className={styles.welcomePageButtonContainer}>
        <button type="submit" className={styles.welcomePageActionButton} onClick={onClick}>
          {buttonText}
        </button>
      </div>
    </div>
  );
}

HubActionSection.propTypes = {
  onClick: PropTypes.func,
  buttonText: PropTypes.string,
  children: PropTypes.node
};

export default withLocalizedContent('hubGlossary')(HubActionSection);
