import React from 'react';
import PropTypes from 'prop-types';
import PopoutPanelIconHeading, {
  types
} from '../../../../../../components/PopoutPanelIconHeading/PopoutPanelIconHeading.js';
import cmsContent from '../../../../../../utils/cmsContent.js';

function Loading({ mode, hubContent, hasTools }) {
  const CMS = cmsContent.addToLibrary || {};

  const ui = {
    ADD: {
      title: CMS.loading_page_title,
      subtitle: hasTools
        ? hubContent.tools_page_loading_deployment_add_subtitle
        : hubContent.tools_page_loading_add_subtitle
    },
    EDIT: {
      title: CMS.loading_page_title,
      subtitle: hasTools
        ? hubContent.tools_page_loading_deployment_edit_subtitle
        : hubContent.tools_page_loading_edit_subtitle
    },
    EDIT_CONFIG: {
      title: CMS.loading_page_title,
      subtitle: hubContent.tools_page_loading_edit_config_subtitle
    },
    ARCHIVE: {
      title: CMS.loading_page_title,
      subtitle: hubContent.tools_page_loading_archive_subtitle
    }
  }[mode];

  return <PopoutPanelIconHeading type={types.LOADING} title={ui.title} subtitle={ui.subtitle} />;
}

Loading.propTypes = {
  mode: PropTypes.string.isRequired,
  hubContent: PropTypes.object.isRequired,
  hasTools: PropTypes.bool.isRequired
};

export default Loading;
