export const MENU_ORIENTATION = {
  RIGHT: 'RIGHT',
  LEFT: 'LEFT'
};

export const MODAL_CLASSES = {
  OVERWRITE: 'overwrite',
  OVERWRITE_RIGHT: 'overwriteRight',
  OVERWRITE_LEFT: 'overwriteLeft',
  CP_MODAL_MENU: 'cpMenuModal',
  CP_MODAL_DICTIONARY: 'cpDictionaryModal',
  OCP_PRODUCT_DOWNLOAD_MODAL: 'productDownloadModal'
};

export const selfSelectedUserRoles = {
  SELF_LEARNER: 'SELF_LEARNER',
  SELF_TEACHER: 'SELF_TEACHER'
};

export const RESOURCES_FOLDERS = {
  SONGS: 'songs',
  VIDEOS: 'videos',
  GAMES: 'games',
  VOCABULARY: 'vocabulary',
  LO: 'lo',
  PDF: 'pdf',
  PRESENTATION: '360'
};

export const XAPI_PARAMS = {
  AUTH: 'auth',
  ENDPOINT: 'endpoint',
  PROFILE: 'a5_lo_profile'
};

export const MODAL_CLOSE_LINK_KEY = 'modalCloseLink';
export const COURSE_MODAL_CLOSE_LINK_KEY = 'courseModalCloseLink';
export const SESSION_MENU_ORIENTATION = 'modal-preference-menu-orientation';
export const SESSION_PROD_LINKS = 'modal-product-links';
export const MILISECONDS_TO_AUTOSAVE = 15000;
export const XAPI_ENDPOINT = `/open/player`;
export const PRESENTATION_TYPE = 'presentation';
export const NO_CATEGORY_ITEMS_KEY = 'noCategoryItems';
export const MIN_ZOOM = 1;
export const MAX_ZOOM_MULTIPLIER = 3;

export const role = {
  TEACHER: 'teacher',
  STUDENT: 'student'
};

export const DARK_BLUE_COLOR = '#002147';
export const WHITE_COLOR = '#FFFFFF';

export const PRODUCT_TYPES = {
  STUDENTBOOK: 'studentbook',
  WORKBOOK: 'workbook',
  ONLINEPRACTICE: 'onlinepractice'
};

export const INVALID_ABBREVIATION = 'Invalid abbreviation';
export const TEACHER_VIEW_MODE = 'teacher';
export const STUDENT_VIEW_MODE = 'student';

// The feature is only hidden if the value is N || n
export const FEATURES = {
  HIDE_RESOURCES: 'N',
  HIDE_PRINT_VIEW: 'N',
  HIDE_SWITCH_BOOK: 'N'
};

export const STAGED_DEFAULT_ASSETS_DOMAIN = 'https://uat.account.oup.com';

export const CONTENT_STATES = {
  ALL: 'ALL',
  STAGED: 'STAGED',
  PUBLISHED: 'PUBLISHED'
};

export const CONTENT_STATES_OPTIONS = [
  { value: 'ALL', text: 'ALL' },
  { value: 'STAGED', text: 'STAGED' },
  { value: 'PUBLISHED', text: 'PUBLISHED' }
];

export const CONTENT_PACKAGE_TYPES = {
  STANDARD: 'standard',
  ONECLICK: 'oneclick',
  APPS: 'apps'
};

export const YES = 'Yes';
export const NA = 'N/A';
