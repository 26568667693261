import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Wizard } from '@oup/shared-front-end/src/components/Wizard';
import { connect } from 'react-redux';

import {
  openJoinClassWithCodeModal,
  showSelfSelectRoleModal,
  closeSelfRegisteredWizard
} from '../../../redux/actions/hubUi';

import OnboardingStudentSetupWizardFooter from './OnboardingStudentSetupWizardFooter';
import ChooseRoleWizardHeader from '../ChooseRoleWizardHeader.js';

import OnboardingWizardWelcome from '../OnboardingWizardWelcome/OnboardingWizardWelcome.js';
import ChooseStudentCode from '../ChooseStudentCode/ChooseStudentCode.js';
import EnterACodeModal from '../EnterACodeModal/EnterACodeModal.js';

function OnboardingStudentSetupWizard({
  firstName,
  openJoinClassWithCodeModalAction,
  showSelfSelectRoleModalAction,
  closeSelfRegisteredWizardAction
}) {
  const [isEnterACodeModalOpen, setIsEnterACodeModalOpen] = useState(false);
  const [selectedStudentCodeOption, setSelectedStudentCodeOption] = useState('');

  const enterACodeModalHandleNext = () => {
    setIsEnterACodeModalOpen(false);
    showSelfSelectRoleModalAction(false);
    openJoinClassWithCodeModalAction();
    closeSelfRegisteredWizardAction();
  };

  return (
    <>
      <Wizard
        header={<ChooseRoleWizardHeader />}
        footer={<OnboardingStudentSetupWizardFooter selectedStudentCodeOption={selectedStudentCodeOption} />}
      >
        <OnboardingWizardWelcome firstName={firstName} />
        <ChooseStudentCode
          closeSelfRegisteredWizardAction={closeSelfRegisteredWizardAction}
          selectedStudentCodeOption={selectedStudentCodeOption}
          setSelectedStudentCodeOption={setSelectedStudentCodeOption}
        />
      </Wizard>
      <EnterACodeModal
        isOpen={isEnterACodeModalOpen}
        onClose={() => setIsEnterACodeModalOpen(false)}
        onNext={enterACodeModalHandleNext}
      />
    </>
  );
}

OnboardingStudentSetupWizard.propTypes = {
  firstName: PropTypes.string.isRequired,
  openJoinClassWithCodeModalAction: PropTypes.func.isRequired,
  showSelfSelectRoleModalAction: PropTypes.func.isRequired,
  closeSelfRegisteredWizardAction: PropTypes.func
};

export default connect(null, {
  openJoinClassWithCodeModalAction: openJoinClassWithCodeModal,
  showSelfSelectRoleModalAction: showSelfSelectRoleModal,
  closeSelfRegisteredWizardAction: closeSelfRegisteredWizard
})(OnboardingStudentSetupWizard);
