import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Button from '@oup/shared-front-end/src/components/Button';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import styles from './ImportCTA.scss';
import Tooltip from '../Tooltip/Tooltip';

function ImportCTA({ bulkInputAction, importText, buttonText, disabled = false, tooltipTitle, className }) {
  return (
    <div className={classnames(styles.importCTA)}>
      <p>{importText}</p>

      <Tooltip title={tooltipTitle} className={className}>
        <Button
          id="importCallToActionButton"
          variant="outline"
          text={buttonText}
          icon={{ component: <SVGIcon glyph={GLYPHS.ICON_IMPORT} /> }}
          onClick={bulkInputAction}
          disabled={disabled}
        />
      </Tooltip>
    </div>
  );
}

ImportCTA.propTypes = {
  // Titles
  importText: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  // Button props
  bulkInputAction: PropTypes.func,
  disabled: PropTypes.bool,
  // Tooltip props
  tooltipTitle: PropTypes.string,
  className: PropTypes.string
};
export default ImportCTA;
