import moment from 'moment';
import userRoles from '../../../globals/userRoles';
import { TEST_STATUS } from '../../../globals/hubConstants';
import { GLYPHS } from '../../../components/Button/Button';

export const contextTypes = {
  CLASS_TEST: 'CLASS_TEST',
  INFO_MODAL: 'INFO_MODAL'
};

const getOnTestClickAction = (id, userRole, testStatus, hasLicence, openPreviewTestModal, openTestInfoModal) => {
  const isStudent = userRole === userRoles.LEARNER;
  const allowedStatusToPreview = testStatus === TEST_STATUS.ACTIVE || testStatus === TEST_STATUS.COMPLETED;

  switch (true) {
    case isStudent && allowedStatusToPreview && hasLicence:
      return openPreviewTestModal(id);
    default:
      return openTestInfoModal(id);
  }
};

export default getOnTestClickAction;

export const formatDate = (date, content) => {
  if (!moment(date).isValid()) return '';

  if (moment(date).isSame(new Date(), 'day')) {
    return `${content.today} (${moment(date).format('HH:mm')})`;
  }
  return `${moment(date).format('D MMMM YYYY')} (${moment(date).format('HH:mm')})`;
};

export const getInfoLabelData = (
  content,
  testStatus,
  hasLicence,
  studentsWithoutLicence = [],
  { openRedeemModal = null, closePreviewInfoModal = null, openStudensStatusLicenceTab = null },
  context,
  userRole,
  assessmentTestId = ''
) => {
  let buttonAction;

  if (userRole !== userRoles.LEARNER) {
    buttonAction =
      context === contextTypes.CLASS_TEST
        ? () => openStudensStatusLicenceTab(assessmentTestId)
        : () => {
            closePreviewInfoModal();
            openStudensStatusLicenceTab(assessmentTestId);
          };
  } else {
    buttonAction =
      context === contextTypes.CLASS_TEST
        ? openRedeemModal
        : () => {
            closePreviewInfoModal();
            openRedeemModal();
          };
  }

  if (userRole !== userRoles.LEARNER && studentsWithoutLicence.length > 0) {
    if (testStatus === TEST_STATUS.UPCOMING) {
      return {
        [userRoles.TEACHER]: {
          message: content.teacher_info_label_upcoming,
          buttonText: content.teacher_button_text_students_without_licence,
          buttonAction,
          customClassName: context === contextTypes.CLASS_TEST ? 'classTestStudentView' : 'previewModalLabel'
        },
        [userRoles.TEACHER_ADMIN]: {
          message: content.teacher_info_label_upcoming,
          buttonText: content.teacher_button_text_students_without_licence,
          buttonAction,
          customClassName: context === contextTypes.CLASS_TEST ? 'classTestStudentView' : 'previewModalLabel'
        },
        [userRoles.ORG_ADMIN]: {
          message: content.teacher_info_label_upcoming,
          buttonText: content.teacher_button_text_students_without_licence,
          buttonAction,
          customClassName: context === contextTypes.CLASS_TEST ? 'classTestStudentView' : 'previewModalLabel'
        }
      };
    }
  }

  if (testStatus === TEST_STATUS.COMPLETED && context === contextTypes.INFO_MODAL) {
    return {
      [userRoles.LEARNER]: {
        message: content.info_label_completed,
        customClassName: 'blueInfoLabel',
        glyphIcon: GLYPHS.ICON_INFORMATION_CIRCLE
      },
      [userRoles.TEACHER]: {
        message: content.info_label_completed,
        customClassName: 'blueInfoLabel',
        glyphIcon: GLYPHS.ICON_INFORMATION_CIRCLE
      },
      [userRoles.TEACHER_ADMIN]: {
        message: content.info_label_completed,
        customClassName: 'blueInfoLabel',
        glyphIcon: GLYPHS.ICON_INFORMATION_CIRCLE
      },
      [userRoles.ORG_ADMIN]: {
        message: content.info_label_completed,
        customClassName: 'blueInfoLabel',
        glyphIcon: GLYPHS.ICON_INFORMATION_CIRCLE
      }
    };
  }

  if (!hasLicence) {
    if (testStatus === TEST_STATUS.ACTIVE || testStatus === TEST_STATUS.UPCOMING) {
      return {
        [userRoles.LEARNER]: {
          message: content.student_info_label_active_and_upcoming,
          buttonText: content.enter_an_access_code,
          buttonAction,
          customClassName: context === contextTypes.CLASS_TEST ? 'classTestStudentView' : 'previewModalLabel'
        },
        [userRoles.TEACHER]: {},
        [userRoles.TEACHER_ADMIN]: {},
        [userRoles.ORG_ADMIN]: {}
      };
    }
    if (testStatus === TEST_STATUS.COMPLETED) {
      return {
        [userRoles.LEARNER]: {
          message: content.student_info_label_completed,
          buttonText: content.enter_an_access_code,
          buttonAction,
          customClassName: context === contextTypes.CLASS_TEST ? 'classTestStudentView' : 'previewModalLabel'
        },
        [userRoles.TEACHER]: {},
        [userRoles.TEACHER_ADMIN]: {},
        [userRoles.ORG_ADMIN]: {}
      };
    }
  }

  if (testStatus === TEST_STATUS.UPCOMING && context === contextTypes.INFO_MODAL) {
    return {
      [userRoles.LEARNER]: {
        message: content.student_info_label_upcoming_licenced,
        customClassName: 'blueInfoLabel',
        glyphIcon: GLYPHS.ICON_INFORMATION_CIRCLE
      }
    };
  }

  return {};
};

export const showInfoLabel = (userRole, testData, testStatus) => {
  if (userRole === userRoles.LEARNER && !testData.hasLicence) {
    return true;
  }

  if (userRole !== userRoles.LEARNER && testData?.studentsWithoutLicence?.length) {
    return true;
  }

  if (userRole !== userRoles.LEARNER && testStatus === TEST_STATUS.COMPLETED) {
    return true;
  }

  return false;
};

export const getViewInfoModalData = (
  content,
  { status = '', hasLicence = false },
  { openPreviewTestModal = null, closeTestInfoModal = null },
  userRole,
  classroomId,
  orgId
) => {
  const handleViewProgress = () => {
    closeTestInfoModal();
    window.location.pathname = `myClasses/singleClass/${classroomId}/progress/${orgId}/gradebook`;
  };

  if (status === TEST_STATUS.ACTIVE) {
    if (hasLicence) {
      return {
        [userRoles.LEARNER]: {
          primaryButtonLabel: content.launch_test,
          primaryButtonAction: testId => {
            closeTestInfoModal();
            openPreviewTestModal(testId);
          }
        },
        [userRoles.TEACHER]: {
          primaryButtonLabel: content.preview_test,
          primaryButtonAction: testId => {
            closeTestInfoModal();
            openPreviewTestModal(testId);
          }
        },
        [userRoles.TEACHER_ADMIN]: {
          primaryButtonLabel: content.preview_test,
          primaryButtonAction: testId => {
            closeTestInfoModal();
            openPreviewTestModal(testId);
          }
        },
        [userRoles.ORG_ADMIN]: {
          primaryButtonLabel: content.preview_test,
          primaryButtonAction: testId => {
            closeTestInfoModal();
            openPreviewTestModal(testId);
          }
        }
      };
    }
    return {
      [userRoles.LEARNER]: {
        primaryButtonLabel: content.done,
        primaryButtonAction: closeTestInfoModal
      },
      [userRoles.TEACHER]: {
        primaryButtonLabel: content.preview_test,
        primaryButtonAction: testId => {
          closeTestInfoModal();
          openPreviewTestModal(testId);
        }
      },
      [userRoles.TEACHER_ADMIN]: {
        primaryButtonLabel: content.preview_test,
        primaryButtonAction: testId => {
          closeTestInfoModal();
          openPreviewTestModal(testId);
        }
      },
      [userRoles.ORG_ADMIN]: {
        primaryButtonLabel: content.preview_test,
        primaryButtonAction: testId => {
          closeTestInfoModal();
          openPreviewTestModal(testId);
        }
      }
    };
  }

  if (status === TEST_STATUS.UPCOMING) {
    return {
      [userRoles.LEARNER]: {
        primaryButtonLabel: content.done,
        primaryButtonAction: closeTestInfoModal
      },
      [userRoles.TEACHER]: {
        primaryButtonLabel: content.preview_test,
        primaryButtonAction: testId => {
          closeTestInfoModal();
          openPreviewTestModal(testId);
        }
      },
      [userRoles.TEACHER_ADMIN]: {
        primaryButtonLabel: content.preview_test,
        primaryButtonAction: testId => {
          closeTestInfoModal();
          openPreviewTestModal(testId);
        }
      },
      [userRoles.ORG_ADMIN]: {
        primaryButtonLabel: content.preview_test,
        primaryButtonAction: testId => {
          closeTestInfoModal();
          openPreviewTestModal(testId);
        }
      }
    };
  }

  if (status === TEST_STATUS.COMPLETED) {
    if (hasLicence && userRole === userRoles.LEARNER) {
      return {
        [userRoles.LEARNER]: {
          primaryButtonLabel: content.review_test,
          primaryButtonAction: testId => {
            closeTestInfoModal();
            openPreviewTestModal(testId);
          }
        }
      };
    }
    return {
      [userRoles.LEARNER]: {
        primaryButtonLabel: content.done,
        primaryButtonAction: closeTestInfoModal
      },
      [userRoles.TEACHER]: {
        primaryButtonLabel: content.view_progress,
        primaryButtonAction: handleViewProgress
      },
      [userRoles.TEACHER_ADMIN]: {
        primaryButtonLabel: content.view_progress,
        primaryButtonAction: handleViewProgress
      },
      [userRoles.ORG_ADMIN]: {
        primaryButtonLabel: content.view_progress,
        primaryButtonAction: handleViewProgress
      }
    };
  }

  return {};
};
