import { select, put } from 'redux-saga/effects';

import bulkUploadByUserTypeApi from '../../../../apiCalls/bulkUploadByUserTypeApi.js';
import { bulkShowConfirmation, showErrorReview } from '../../../../../reducers/enrolUser.reducer.js';

export default function* bulkUploadByUserTypeRequest() {
  console.log('[bulkUploadByUserType Saga] Uploading file contents...');
  const {
    groupType,
    groupId,
    userType,
    file: { data: fileContents, name: fileName, type: fileType }
  } = yield select(state => state.enrolUser);

  const result = yield bulkUploadByUserTypeApi(groupType, groupId, userType, {
    fileContents,
    fileName,
    fileType
  });

  if (result.status === 'success') {
    console.log('[bulkUploadByUserType Saga] Showing confirmation screen');
    yield put(bulkShowConfirmation());
  } else {
    const errors = Object.entries(result.data || []).map(error => ({
      row: error[0],
      cmsErrorKeys: error[1]
    }));
    yield put(showErrorReview(errors, result?.maximumAllowedRecords, result?.availableCredits));
  }
}
