import React from 'react';
import PropTypes from 'prop-types';
// Redux
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  cancelInvitation,
  componentStates,
  cancelInvitationStates,
  resetCancelInvitationForm
} from '../../../../redux/reducers/user/userProfile.reducer';
// Components
import SidePanel from '../../../../components/SidePanel/SidePanel';
import CancelInvitationReviewPanel from './CancelInvitationReviewPanel';
import CancelInvitationSuccessPanel from './CancelInvitationSuccessPanel';
import ScrollContainer from '../../../../components/ScrollContainer/ScrollContainer';
import PopoutPanelIconHeading, { types } from '../../../../components/PopoutPanelIconHeading/PopoutPanelIconHeading';
// Constants
import userRoles, { roleIsAtLeast } from '../../../../globals/userRoles';
import CancelInvitationErrorPanel from './CancelInvitationErrorPanel';

function CancelInvitationPanel({
  isOpen,
  closePanel,
  cancelInvitationAction,
  email,
  userDetails,
  hubContent,
  formState,
  cancelInvitationState,
  resetCancelInvitationFormAction
}) {
  const history = useHistory();

  const closeAndResetPanel = () => {
    closePanel();
    resetCancelInvitationFormAction();
  };
  const renderContent = () => {
    switch (true) {
      case formState === componentStates.FETCHING || cancelInvitationState === cancelInvitationStates.PENDING:
        return (
          <ScrollContainer hideCloseButton>
            <PopoutPanelIconHeading type={types.LOADING} title={hubContent.loading} />
          </ScrollContainer>
        );
      case formState === componentStates.PROFILE_COMPLETE && cancelInvitationState === cancelInvitationStates.REVIEWING:
        return (
          <CancelInvitationReviewPanel
            cancelInvitation={() => cancelInvitationAction(history)}
            hubContent={hubContent}
            closePanel={closePanel}
            userDetails={userDetails}
          />
        );
      case cancelInvitationState === cancelInvitationStates.SUCCESS:
        return (
          <CancelInvitationSuccessPanel
            hideCloseButton
            userEmail={email}
            removedUserType={roleIsAtLeast(userRoles.TEACHER, userDetails.role) ? 'staff' : 'students'}
            onComplete={closeAndResetPanel}
            hubContent={hubContent}
          />
        );
      case cancelInvitationState === cancelInvitationStates.ERROR:
        return <CancelInvitationErrorPanel onComplete={closeAndResetPanel} hubContent={hubContent} />;
      default:
        return null;
    }
  };

  return (
    <SidePanel
      id="cancelInvitationPanel"
      ariaLabel={hubContent.cancel_invitation_panel_aria}
      isOpen={isOpen}
      onClose={closeAndResetPanel}
    >
      {renderContent()}
    </SidePanel>
  );
}

export default connect(
  ({
    identity: { email },
    userProfile: { userId, email: selectedUserEmail, firstName, lastName, role, componentState, cancelInvitationState }
  }) => ({
    email,
    userDetails: {
      userId,
      selectedUserEmail,
      firstName,
      lastName,
      role
    },
    formState: componentState,
    cancelInvitationState
  }),
  { cancelInvitationAction: cancelInvitation, resetCancelInvitationFormAction: resetCancelInvitationForm }
)(CancelInvitationPanel);

CancelInvitationPanel.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closePanel: PropTypes.func.isRequired,
  cancelInvitationAction: PropTypes.func.isRequired,
  resetCancelInvitationFormAction: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  userDetails: PropTypes.object.isRequired,
  formState: PropTypes.string.isRequired,
  cancelInvitationState: PropTypes.string.isRequired,
  hubContent: PropTypes.object.isRequired
};
