import React, { Component } from 'react';
import PropTypes from 'prop-types';
import scrollSnapPolyfill from 'css-scroll-snap-polyfill';
import classnames from 'classnames';
import withLocalizedContent from '../../language/withLocalizedContent';
import Button, { buttonTypes, GLYPHS } from '../Button/Button';
import styles from './CarouselSelector.scss';

function numberOfPages(totalNumber, perPage) {
  return Math.ceil(totalNumber / perPage);
}

function splitByChunk(items, chunkSize) {
  return items.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / chunkSize);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [];
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, []);
}

const keyDownHandler = callback => e => {
  if (e.keyCode === 13) callback(e);
};

// eslint-disable-next-line no-useless-escape
const getPageId = () => window.location.pathname.replace(/[\/-]/g, '');

// borrowed from https://gist.github.com/andjosh/6764939
function animateScrollLeft(element, to, duration) {
  const start = element.scrollLeft;
  const change = to - start;
  let currentTime = 0;
  const increment = 20;

  function easeInOutQuad(easeTime, easeStart, easeChange, easeDuration) {
    let time = easeTime;
    time /= easeDuration / 2;
    if (time < 1) return (easeChange / 2) * time * time + easeStart;
    time -= 1;
    return (-easeChange / 2) * (time * (time - 2) - 1) + easeStart;
  }

  function animateScroll() {
    currentTime += increment;
    element.scrollLeft = easeInOutQuad(currentTime, start, change, duration);

    if (currentTime < duration) {
      setTimeout(animateScroll, increment);
    }
  }

  animateScroll();
}

class CarouselSelector extends Component {
  sectionRefs = {};

  constructor(props) {
    super(props);
    this.state = {
      currentPage: 0,
      expanded: localStorage.getItem(`expanded-${getPageId()}`) === 'true'
    };
  }

  componentDidMount() {
    scrollSnapPolyfill();
    window.addEventListener('resize', this.resizeWindow);
  }

  componentDidUpdate(_, prevState) {
    const { currentPage, expanded } = this.state;

    if (prevState.currentPage !== currentPage) {
      const offset = Object.values(this.sectionRefs)
        .filter(ref => !!ref)
        .map(ref => ref.clientWidth + 20)
        .slice(0, currentPage)
        .reduce((a, b) => a + b, 0);

      animateScrollLeft(this.sectionsEl, offset, 300);
    }

    localStorage.setItem(`expanded-${getPageId()}`, `${expanded}`);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeWindow);
  }

  onClickPrev = () => {
    const { currentPage } = this.state;
    this.setState({ currentPage: currentPage - 1 });
  };

  onClickNext = () => {
    const { currentPage } = this.state;
    this.setState({ currentPage: currentPage + 1 });
  };

  onClickPage = pageNumber => {
    this.setState({ currentPage: pageNumber });
  };

  onClickExpander = () => {
    const { expanded } = this.state;
    this.setState({ currentPage: 0, expanded: !expanded });
  };

  resizeWindow = () => {
    if (this.sectionsEl.scrollLeft !== 0) {
      this.sectionsEl.scrollLeft = 0;
    }

    const { currentPage } = this.state;
    if (currentPage !== 0) {
      this.setState({ currentPage: 0 });
    }
  };

  renderPrevButton = () => {
    const {
      localizedContent: { carouselSelector: i18n }
    } = this.props;

    const { currentPage, expanded } = this.state;
    return (
      <div className={classnames(styles.carouselButton, styles.prev)}>
        {currentPage !== 0 && !expanded && (
          <Button
            iconOnly
            text={i18n.previous_button_label}
            type={buttonTypes.NO_BORDER}
            glyph={GLYPHS.CHEVRON_DOWN}
            onClick={this.onClickPrev}
          />
        )}
      </div>
    );
  };

  renderNextButton = () => {
    const {
      items,
      perSection,
      localizedContent: { carouselSelector: i18n }
    } = this.props;
    const { currentPage, expanded } = this.state;
    const isLastPage = items.length === 0 || numberOfPages(items.length, perSection) === currentPage + 1;

    return (
      <div className={classnames(styles.carouselButton, styles.next)}>
        {!isLastPage && !expanded && (
          <Button
            iconOnly
            text={i18n.next_button_label}
            className={styles.next}
            type={buttonTypes.NO_BORDER}
            glyph={GLYPHS.CHEVRON_DOWN}
            onClick={this.onClickNext}
          />
        )}
      </div>
    );
  };

  renderGalleryPrevButton = () => {
    const {
      localizedContent: { carouselSelector: i18n }
    } = this.props;

    const { currentPage } = this.state;
    return (
      <div className={classnames(styles.carouselButton, styles.prev, styles.galleryButton)}>
        {currentPage === 0 ? (
          <Button
            iconOnly
            text={i18n.previous_button_label}
            type={buttonTypes.SECONDARY}
            glyph={GLYPHS.CHEVRON_DOWN}
            disabled
          />
        ) : (
          <Button
            iconOnly
            text={i18n.previous_button_label}
            type={buttonTypes.SECONDARY}
            glyph={GLYPHS.CHEVRON_DOWN}
            onClick={this.onClickPrev}
          />
        )}
      </div>
    );
  };

  renderGalleryNextButton = () => {
    const {
      items,
      perSection,
      localizedContent: { carouselSelector: i18n }
    } = this.props;
    const { currentPage } = this.state;
    const isLastPage = items.length === 0 || numberOfPages(items.length, perSection) === currentPage + 1;

    return (
      <div className={classnames(styles.carouselButton, styles.next, styles.galleryButton)}>
        {isLastPage ? (
          <Button
            iconOnly
            text={i18n.next_button_label}
            type={buttonTypes.SECONDARY}
            glyph={GLYPHS.CHEVRON_DOWN}
            disabled
          />
        ) : (
          <Button
            iconOnly
            text={i18n.next_button_label}
            type={buttonTypes.SECONDARY}
            glyph={GLYPHS.CHEVRON_DOWN}
            onClick={this.onClickNext}
          />
        )}
      </div>
    );
  };

  renderBullets = () => {
    const { items, perSection, mainPageVisible } = this.props;
    const { currentPage } = this.state;

    if (items.length <= perSection) return null;

    return (
      <div className={classnames(styles.paginator, { [styles.inContainer]: mainPageVisible })}>
        {Array.from(Array(numberOfPages(items.length, perSection)).keys()).map(i => (
          <div
            tabIndex={0}
            role="tab"
            onKeyDown={keyDownHandler(() => {
              this.onClickPage.bind(this, i);
            })}
            aria-selected={currentPage === i ? 'selected' : null}
            aria-label={`Carousel page ${i + 1}`}
            className={classnames(styles.dot, styles.gallery, { [styles.active]: currentPage === i })}
            key={i}
            // eslint-disable-next-line react/jsx-no-bind
            onClick={this.onClickPage.bind(this, i)}
          />
        ))}
      </div>
    );
  };

  renderPaginator = () => {
    const {
      items,
      perSection,
      localizedContent: { carouselSelector: i18n },
      mainPageVisible
    } = this.props;
    const { expanded, currentPage } = this.state;

    if (items.length <= perSection) return null;

    return (
      <div className={classnames(styles.paginator, { [styles.inContainer]: mainPageVisible })}>
        {!expanded &&
          Array.from(Array(numberOfPages(items.length, perSection)).keys()).map(i => (
            <div
              tabIndex={0}
              role="tab"
              onKeyDown={keyDownHandler(() => {
                this.onClickPage.bind(this, i);
              })}
              aria-selected={currentPage === i ? 'selected' : null}
              aria-label={`Carousel page ${i + 1}`}
              className={classnames(styles.dot, { [styles.active]: currentPage === i })}
              key={i}
              // eslint-disable-next-line react/jsx-no-bind
              onClick={this.onClickPage.bind(this, i)}
            />
          ))}
        {!expanded && <div className={styles.pipe}>|</div>}
        <Button
          type={expanded ? buttonTypes.ACCENT : buttonTypes.NO_BORDER}
          text={expanded ? i18n.see_less_text : i18n.see_all_text}
          onClick={this.onClickExpander}
        />
      </div>
    );
  };

  renderSection = (items, index) => {
    const { itemRenderer, perSection } = this.props;

    return (
      <section
        key={index}
        style={{ '--per-section': perSection, '--items-in-section': items.length }}
        ref={el => {
          this.sectionRefs[index] = el;
        }}
        role="none"
      >
        {items.map(itemRenderer)}
      </section>
    );
  };

  render() {
    const { items, perSection, mainPageVisible, hidePaginator, multiImageType } = this.props;
    const { expanded } = this.state;

    return (
      <div
        className={classnames(
          styles.container,
          { [styles.multiImageType]: multiImageType },
          {
            [styles.expanded]: expanded || hidePaginator,
            [styles.mainPageVisible]: mainPageVisible
          }
        )}
      >
        <div className={`${styles.wrapper} ${multiImageType && styles.multiImageWrapper}`}>
          <div
            className={styles.sections}
            style={{
              '--number-of-sections': Math.ceil(items.length / perSection),
              '--number-of-items': items.length
            }}
            ref={el => {
              this.sectionsEl = el;
            }}
          >
            {splitByChunk(items, perSection).map(this.renderSection)}
          </div>
        </div>

        {multiImageType && (
          <div className={classnames(styles.footer, styles.multiImageFooter)}>
            {this.renderGalleryPrevButton()}
            {this.renderBullets()}
            {this.renderGalleryNextButton()}
          </div>
        )}

        {!hidePaginator && !multiImageType && (
          <div className={styles.footer}>
            {this.renderPrevButton()}
            {this.renderPaginator()}
            {this.renderNextButton()}
          </div>
        )}
      </div>
    );
  }
}

CarouselSelector.propTypes = {
  items: PropTypes.array.isRequired,
  perSection: PropTypes.number,
  itemRenderer: PropTypes.func.isRequired,
  localizedContent: PropTypes.object.isRequired,
  mainPageVisible: PropTypes.bool,
  hidePaginator: PropTypes.bool,
  multiImageType: PropTypes.bool
};

CarouselSelector.defaultProps = {
  perSection: 3,
  hidePaginator: false
};

export default withLocalizedContent('carouselSelector')(CarouselSelector);
