/* eslint-disable import/prefer-default-export */
import { Link as RouterLink } from 'react-router-dom';
import { HubLayoutConstants } from '../../../globals/hubConstants';
import getSingleCoursePath from './getSingleCoursePath';

const getTitle = camelCaseTitle => camelCaseTitle.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase());

const getCourseLinks = (course, folder) => {
  const pathnames = HubLayoutConstants.PATH_NAMES;
  const singleCoursePath = getSingleCoursePath(course.id);
  const links = {
    courseMaterials: {
      link: `${singleCoursePath}${pathnames.COURSE_MATERIAL}`,
      matcher: `${singleCoursePath}${pathnames.COURSE_MATERIAL}`
    },
    assessment: {
      link: `${singleCoursePath}${pathnames.ASSESSMENT_FOLDER}${pathnames.ASSESSMENTS}${pathnames.ASSESSMENT}${pathnames.ASSESSMENT_HOME}`,
      matcher: `${singleCoursePath}${pathnames.ASSESSMENT_FOLDER}${pathnames.ASSESSMENTS}`
    }
  };
  // it means it's a folder under resourceFolders
  if (!links[folder] && folder) {
    return {
      link: `${singleCoursePath}${pathnames.RESOURCE_FOLDER}/${folder}${pathnames.RESOURCE_HOME}`,
      matcher: `${singleCoursePath}${pathnames.RESOURCE_FOLDER}/${folder}/`
    };
  }
  return links[folder];
};

const getCoursesLinksData = ({ content, course, pathname }) => {
  const linksData = [
    {
      to: getCourseLinks(course, 'courseMaterials').link,
      component: RouterLink,
      primaryText: content.course_material,
      active: pathname === getCourseLinks(course, 'courseMaterials').matcher
    }
  ];
  if (
    course.assessmentFolders?.assessments &&
    course.assessmentFolders?.assessments?.hasLicence &&
    course?.userRole === HubLayoutConstants.COURSE_USER_ROLES.TEACHER
  ) {
    linksData.push({
      to: getCourseLinks(course, 'assessment').link,
      component: RouterLink,
      primaryText: content.assessment,
      active: !!pathname.match(getCourseLinks(course, 'assessment').matcher)
    });
  }
  if (course.resourceFolders) {
    const folders = Object.keys(course.resourceFolders);
    folders.forEach(folder => {
      const isTeacherOnlyFolder =
        folder === 'getStarted' || folder === 'professionalDevelopment' || folder === 'assessments';
      if (
        (isTeacherOnlyFolder && course?.userRole === HubLayoutConstants.COURSE_USER_ROLES.TEACHER) ||
        !isTeacherOnlyFolder
      ) {
        linksData.push({
          to: getCourseLinks(course, folder).link,
          component: RouterLink,
          primaryText: getTitle(folder),
          active: !!pathname.match(getCourseLinks(course, folder).matcher)
        });
      }
    });
  }

  return linksData;
};

export { getCoursesLinksData };
