const getPeopleInitials = (firstname = '', lastname = '') => {
  if (firstname && lastname) {
    return `${firstname.charAt(0)}${lastname.charAt(0)}`;
  }
  if (!lastname) {
    return firstname?.charAt(0);
  }

  return lastname.charAt(0);
};

export default getPeopleInitials;
