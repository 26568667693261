import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Link from '../../../components/Link/Link';
import SVGIcon, { GLYPHS } from '../../../components/SVGIcon/SVGIcon';
import styles from './SecondaryLevelContainer.scss';
import SecondaryLevelCourses from './SecondaryLevelCourses';
import SecondaryLevelClasses from './SecondaryLevelClasses';
import { HubLayoutConstants } from '../../../globals/hubConstants';
import localStorageWithCache from '../../../utils/localStorageWithCache';
import { isLtiMode } from '../../../utils/platform';

function SecondaryLevelContainer({ isSelected, hubContent, handleSelectSecondaryLevel, toggledItems, path, userRole }) {
  const getSecondaryLevelItems = () => {
    if (path === HubLayoutConstants.PATH_NAMES.CLASSES_PATH) {
      return (
        <SecondaryLevelClasses
          hubContent={hubContent}
          handleSelectSecondaryLevel={handleSelectSecondaryLevel}
          userRole={userRole}
        />
      );
    }
    return <SecondaryLevelCourses hubContent={hubContent} handleSelectSecondaryLevel={handleSelectSecondaryLevel} />;
  };

  const { getKey } = localStorageWithCache;
  const { role: selfSelectedRole } = JSON.parse(getKey('selectedRoleData')) || '';

  return (
    <ul
      className={classnames([styles.secondaryLevelContainer], {
        [styles.secondaryLevelContainerOpen]: isSelected,
        [styles.lastItemPadding]: path === HubLayoutConstants.PATH_NAMES.MY_PROGRESS
      })}
    >
      {getSecondaryLevelItems()}
      {toggledItems.showNote && selfSelectedRole === HubLayoutConstants.SELF_SELECT_ROLES[1].key && (
        <li className={classnames([styles.secondaryLevelButton], [styles.selfTeacherNoteContainer])}>
          <div className={styles.selfTeacherNote}>
            <span>Note:</span> {hubContent.no_classes_self_teacher_note}
          </div>
          <Link
            to={hubContent.no_organization_self_teacher_link}
            className={styles.selfTeacherLink}
            openInNewTab
            underlined
          >
            <div className={`${styles.content} ${styles.selfTeacherContent}`}>
              <div className={styles.selfTeacher}>
                <span>
                  {hubContent.no_classes_self_teacher_button_text} <SVGIcon glyph={GLYPHS.ICON_LINK} />
                </span>
              </div>
            </div>
          </Link>
        </li>
      )}
      {!toggledItems.showAllButton && selfSelectedRole === HubLayoutConstants.SELF_SELECT_ROLES[1].key && (
        <li className={`${styles.secondaryLevelButton} ${styles.selfTeacherButtonContainer}`}>
          <Link
            onClick={handleSelectSecondaryLevel}
            to={hubContent.no_classes_self_teacher_second_link}
            className={styles.link}
          >
            <div className={styles.content}>
              <SVGIcon glyph={GLYPHS.ICON_ADD} />
              <div>{hubContent.no_classes_self_teacher_second_button_text}</div>
            </div>
          </Link>
        </li>
      )}
      {toggledItems.showAllButton && (
        <li className={styles.secondaryLevelButton}>
          <Link onClick={handleSelectSecondaryLevel} to={path} className={styles.link}>
            <div className={styles.content}>
              <SVGIcon glyph={toggledItems.showAllIcon} />
              <div>{toggledItems.showAllLabel}</div>
            </div>
          </Link>
        </li>
      )}
      {toggledItems.showActionButton && !isLtiMode() && (
        <li className={styles.secondaryLevelButton}>
          <button
            type="button"
            onClick={toggledItems.handleActionButton}
            data-testid={toggledItems?.actionButtonTestHook}
            className={styles.link}
            aria-label={toggledItems.actionButtonAriaLabel}
          >
            <div className={styles.content}>
              <SVGIcon glyph={GLYPHS.ICON_ADD} />
              <div>{toggledItems.actionButtonLabel}</div>
            </div>
          </button>
        </li>
      )}
      {toggledItems.showJoinClassWithCodeSideButton && !isLtiMode() && (
        <li className={styles.secondaryLevelButton}>
          <button
            type="button"
            onClick={toggledItems.handleJoinClassWithCodeButton}
            className={styles.link}
            aria-label={toggledItems.joinClassWithCodeButtonAriaLabel}
          >
            <div className={styles.content}>
              <SVGIcon glyph={GLYPHS.ICON_ADD} />
              <div>{toggledItems.joinClassWithCodeButtonLabel}</div>
            </div>
          </button>
        </li>
      )}
    </ul>
  );
}

SecondaryLevelContainer.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  hubContent: PropTypes.object.isRequired,
  handleSelectSecondaryLevel: PropTypes.func.isRequired,
  toggledItems: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
  userRole: PropTypes.string.isRequired
};

export default SecondaryLevelContainer;
