import React from 'react';
import PropTypes from 'prop-types';
// Components
import HubTabs from '../HubTabs/HubTabs';
import MyDashboardContent from '../../routes/MyDashboard/MyDashboardContent';

function HubTabsNavigation({ children }) {
  return <div>{children}</div>;
}

HubTabsNavigation.HubTabs = HubTabs;
HubTabsNavigation.MyDashboardContent = MyDashboardContent;

HubTabsNavigation.propTypes = {
  children: PropTypes.array.isRequired
};

export default HubTabsNavigation;
