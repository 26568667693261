// In development the /dev routes should be accessible without auth
// export const PUBLIC_URL_REGEX = __PROD__ ? /^\/(register|support)(\/.*)?$/ : /^\/(register|support|dev)(\/.*)?$/;
// Note this matches root as well. Eg '' or '/' or '/support' or '/support/'.

import { isDev } from './envSettings.js';

function getPublicUrlRegexp() {
  let paths = [
    'register',
    'register-choice',
    'support',
    'reset-password',
    'ngs-demo',
    'orb-region',
    'ngs',
    'redeem',
    'decline-invite',
    'download',
    'terms-and-conditions',
    'form',
    'welcome-to-hub',
    'offlineApp/AUTH_CANCELLED',
    'offlineApp/AUTH_SUCCES',
    'change-username',
    'valid',
    'invalid',
    'error',
    'form',
    'home',
    'seb-login',
    'supportmessage',
    'logged-out',
    'oupadmin',
    'offline-launch',
    'downloads',
    'downloads-embedded',
    'product-download',
    'offline-demo',
    'ebook-sample/register',
    'content',
    'content-selector',
    'login',
    'young-learner/register',
    'young-learner/redeem'
  ];
  if (isDev()) {
    paths = paths.concat(['example', 'dev', 'utility']);
  }
  // Note: matches root path, e.g. '' or '/', and allows trailing slash, e.g. '/support' or '/support/'
  // FIXME: this is far too magical and easy to break, be more explicit, e.g. separate test for '/'
  return new RegExp(`^(/(${paths.join('|')})?(/.*)?)?$`);
}

// FIXME: is this code dead? Can it be deleted? I can't find anything that calls this function
export function isPublicPath(path) {
  const publicUrlRegexp = getPublicUrlRegexp();
  return publicUrlRegexp.test(path);
}

const authSettings = {
  authSettingsLoaded: false,

  // Shibboleth Settings
  idpUrl: null,
  gatewayPath: null,
  // AWS Cognito Settings
  cognitoPoolId: null,
  cognitoUrl: null,

  // PSec Endpoint Settings
  getCredentialsUrl: null,
  rightSuiteLogoutUrl: null,

  // APIG Config for endpoint signing
  gwId: null,
  gwEnv: null,

  // CES API Gateway API Key
  cesApiKey: null,

  // OIDC
  oidcAuthorizeUrl: null,
  oidcToken: null,
  oidcClientId: null,
  oidcIdleSessionTimeout: null,
  oidcIdleSessionPreCallValue: null
};

export default authSettings;

export function setAuthSettings(settings) {
  // Store settings over the existing object

  Object.assign(authSettings, {
    authSettingsLoaded: true,
    ...settings
  });
}
