import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import PopupOverlay from '../PopupOverlay/PopupOverlay';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import styles from './HubDropDownMenuWithText.scss';
import Thumbnail, { SIZES as thumbnailSizes } from '../Thumbnail/Thumbnail';
import { featureIsEnabled } from '../../globals/envSettings';

function HubDropDownMenuWithText({
  idPanelMenu,
  testHook = '',
  menuItems,
  onTogglePanelMenu,
  toggled,
  popoutData,
  textButton,
  toggleAddButton,
  callDropdownAction
}) {
  return (
    <>
      {featureIsEnabled('navigation-changes') ? null : (
        <button
          type="button"
          id={idPanelMenu}
          data-testid={testHook}
          className={classnames([styles.addToButton], {
            [styles.addToButtonActive]: toggled
          })}
          onClick={toggleAddButton}
          aria-label={`${textButton} menu ${toggled ? 'expanded' : 'collapsed'}`}
        >
          <span>{textButton}</span>
          <div className={classnames([styles.thumbnailContainer], { [styles.thumbnailActive]: toggled })}>
            <Thumbnail glyph={GLYPHS.CHEVRON_DOWN_THICK} size={thumbnailSizes.SMALL} />
          </div>
        </button>
      )}

      <PopupOverlay
        id={popoutData.popoutId}
        visible
        customClassName={classnames([styles.hubPanelMenuOverlay], { [styles.opened]: toggled })}
        onTogglePopup={onTogglePanelMenu}
        aria={{
          label: popoutData.popoutAriaLabel
        }}
        buttonElementId={popoutData.buttonElementId}
      >
        <ul className={styles.menuPanelContainer}>
          {menuItems.map(menuItem => {
            if (menuItem.showButton) {
              return (
                <li className={styles.menuItem} key={menuItem.name} aria-label={menuItem.name}>
                  <button
                    type="button"
                    onClick={() => callDropdownAction(menuItem.action)}
                    data-testid={menuItem?.actionTestHook}
                  >
                    <div className={styles.svgContainer} aria-hidden>
                      <SVGIcon glyph={menuItem.icon} fill={menuItem.iconColor} />
                    </div>
                    <span>{menuItem.name}</span>
                  </button>
                </li>
              );
            }
            return null;
          })}
        </ul>
      </PopupOverlay>
    </>
  );
}

export default connect(null, dispatch => ({
  callDropdownAction: actionCallback => {
    dispatch(actionCallback());
  }
}))(HubDropDownMenuWithText);

HubDropDownMenuWithText.propTypes = {
  idPanelMenu: PropTypes.string,
  testHook: PropTypes.string,
  menuItems: PropTypes.array.isRequired,
  onTogglePanelMenu: PropTypes.func.isRequired,
  toggled: PropTypes.bool.isRequired,
  toggleAddButton: PropTypes.func,
  textButton: PropTypes.string,
  popoutData: PropTypes.shape({
    popoutId: PropTypes.string.isRequired,
    popoutAriaLabel: PropTypes.string.isRequired,
    buttonElementId: PropTypes.string.isRequired
  }).isRequired,
  callDropdownAction: PropTypes.func
};
