// CONSTANTS
import userRoles, { roleIsAtLeast } from '../../../../globals/userRoles';
import { HubLayoutConstants } from '../../../../globals/hubConstants';

const hasContextId = ({ userRole, isTeacherAssignedToClass, licencesContext }) => {
  const {
    LICENCES_CONTEXT: { CLASSWORK }
  } = HubLayoutConstants;
  return roleIsAtLeast(userRoles.TEACHER, userRole) && licencesContext === CLASSWORK && isTeacherAssignedToClass;
};

export default hasContextId;
