import React from 'react';
import PropTypes from 'prop-types';
import styles from './HubMiddleRow.scss';

function HubMiddleRowV3({ homepageContent }) {
  return (
    <div className={styles.homePageSectionTwo}>
      <div className={styles.middleRowContainer}>
        {homepageContent.middle_content_items.map((section, index) => (
          <div key={index} className={styles.sectionContainer}>
            <div className={styles.imageContainer}>
              <img aria-hidden src={section.image} alt={section.image_alt} />
            </div>

            <div className={styles.textContainer}>
              <div className={styles.iconContainer}>
                <img aria-hidden src={section.icon} alt={section.icon_alt} />
              </div>
              <span>{section.title}</span>
              <p>{section.paragraph}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

HubMiddleRowV3.propTypes = {
  homepageContent: PropTypes.object.isRequired
};

export default HubMiddleRowV3;
