import React from 'react';
import PropTypes from 'prop-types';
import ScrollContainer from '../ScrollContainer/ScrollContainer';
import PopoutPanelIllustrationHeading from '../PopoutPanelIllustrationHeading/PopoutPanelIllustrationHeading';
import PopoutActionFooter from '../PopoutActionFooter/PopoutActionFooter';
import { HubIllustrationConstants, HubIllustrationAltText } from '../../globals/hubConstants';
import styles from './BulkPasswordReset.scss';

function BulkPasswordResetFailure({ content, bulkPasswordReset }) {
  return (
    <ScrollContainer
      headerContent={
        <div className={styles.headerContainer}>
          <PopoutPanelIllustrationHeading
            title={content.bulk_password_reset_error}
            subtitle={content.please_try_again}
            illustrationSrc={HubIllustrationConstants.ERROR}
            illustrationAltText={HubIllustrationAltText.ERROR}
          />
        </div>
      }
      footerContent={
        <PopoutActionFooter
          primaryButtonAction={bulkPasswordReset}
          primaryButtonText={content.try_again}
          primaryButtonTestHook="bulk-reset-password-try-again"
        />
      }
    />
  );
}

BulkPasswordResetFailure.propTypes = {
  content: PropTypes.object.isRequired,
  bulkPasswordReset: PropTypes.func.isRequired
};

export default BulkPasswordResetFailure;
