import React from 'react';
import TextInputField from '../TextInputField/TextInputField';
import styles from './HubFilterBar.scss';

function HubFilterBarSkeleton() {
  return (
    <div className={styles.filterBarSkeletonControls}>
      <TextInputField id="1" name="One" required={false} disabled customStyleName={styles.skeletonButton} />

      <TextInputField id="2" name="Two" required={false} disabled customStyleName={styles.skeletonButton} />

      <TextInputField id="3" name="Three" required={false} disabled customStyleName={styles.skeletonRoundButton} />
    </div>
  );
}

export default HubFilterBarSkeleton;
