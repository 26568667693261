import jsonFetch from '../util/jsonFetch';

export default async ({ orgId, productId, clientId, lmsCourseId, groupGuid, token }) => {
  const requestPayload = {
    orgId,
    productId,
    clientId,
    lmsCourseId,
    groupGuid,
    token
  };

  const headers = { 'Content-Type': 'application/json' };
  const body = JSON.stringify(requestPayload);

  const url = `${__API_BASE__}/open/ltitool1v3/content-selection-confirmation`;

  const response = await jsonFetch(
    'processLineItemsBatch',
    [url, { method: 'PUT', headers, body }],
    false,
    false,
    true
  );

  if (response.statusCode !== 200) {
    throw new Error('will be improved later');
  }

  return response;
};
