/* eslint-disable react/prefer-stateless-function */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { get } from 'lodash';
import Heading from '@oup/shared-front-end/src/components/Heading';
// Redux
import { connect } from 'react-redux';
import { compose } from 'recompose';
import actions from '../../../redux/actions';
import { hubDownloadResource } from '../../../redux/actions/hubResource';
import { setOnlineTestData } from '../../../redux/actions/onlineTestActions';
import {
  getAdaptedTests,
  setAdaptedTestsUpdated,
  setAdaptedTestsPolling,
  setAdaptedTestsNeedToBeUpdated
} from '../../../redux/actions/adaptedTests';
import { setCourses } from '../../../redux/actions/hubCourses';
// Style
import styles from './AssessmentHome.scss';
import assessmentStyles from '../../../components/AssessmentTest/AssessmentTest.scss';
import colors from '../../../globals/colors';
// Components
import SVGIcon, { GLYPHS } from '../../../components/SVGIcon/SVGIcon';
import { SIZES as thumbnailSizes } from '../../../components/Thumbnail/Thumbnail';
import AssessmentDate from '../UtilComponents/AssessmentDate';
import AssessmentFolder from '../UtilComponents/AssessmentFolder';
import AssessmentTest from '../../../components/AssessmentTest/AssessmentTest';
import Resource from '../../../components/Resource/Resource';
import OnlineTestModal from '../../../components/OnlineTestModal/OnlineTestModal';
import OnlineTestPanel from '../../../panels/OnlineTestPanel';
import ResourceFormHidden from '../../HubProductLayout/Resources/ResourceFormHidden';
import withLocalizedContent from '../../../language/withLocalizedContent';
import TestBuilderModal from '../../../components/TestBuilder/TestBuilderModal';
import HubProductErrorModal from '../../HubProductLayout/HubProductErrorModal/HubProductErrorModal';
import ResourceModal from '../../../components/ResourceModal/ResourceModal';
// Constants
import {
  ErrorModalType,
  HubLayoutConstants,
  RESOURCE_TYPE,
  RESOURCE_DOWNLOAD_TYPE,
  ResourceConstants,
  LTI_ACTIONS
} from '../../../globals/hubConstants';
// Utils
import withBreakpoint from '../../../decorators/withBreakpoint';
import setAdaptedTestsInCourses from '../../HubDashboardLayout/Utils/setAdaptedTestsInCourses';
import getCourseAssessmentLink from '../Services/getCourseAssessmentLink';
import { hasHideableKeywords, hasLockableKeywords, isLockedNode } from '../Utils/isLockedNode';
import getAssessmentCourseTitle from '../Utils/getAssessmentCourseTitle';
import getTestData, { getTestPrintableResourceId, getTestDigitaleResourceId } from '../Services/getTestData';
import downloadOrPreviewAssessment from '../Services/downloadOrPreviewAssessment';
import { featureIsEnabled } from '../../../globals/envSettings';

class AssessmentHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resourceId: '',
      isNotDownloadableAssessmentId: null,
      testBuilderAssessmentId: '',
      errorModalType: ErrorModalType.NONE,
      onlineTestId: null,
      isOnlineTestOpen: false,
      adaptedTestPollingTimer: 30,
      adaptedTestInitialTimer: 10,
      selectedToPreviewTest: null
    };
  }

  componentDidMount() {
    window.addEventListener('message', this._receiveMessage);
    this._getAllAdaptedTests();
  }

  componentDidUpdate() {
    const {
      setCoursesAction,
      hubAdaptedTests,
      courses,
      courseId,
      setAdaptedTestsUpdatedAction,
      setAdaptedTestsNeedToBeUpdatedAction
    } = this.props;
    if (hubAdaptedTests && hubAdaptedTests.ids && hubAdaptedTests.ids.length && !hubAdaptedTests.isPollingInProgress) {
      this._pollHubAdaptedTests(hubAdaptedTests.ids);
    }
    if (hubAdaptedTests.adaptedTestsNeedToBeUpdated) {
      setAdaptedTestsNeedToBeUpdatedAction(false);
      this._getAllAdaptedTests();
    }
    if (hubAdaptedTests && hubAdaptedTests.adaptedTests && !hubAdaptedTests.adaptedTestsRecentlyUpdated) {
      const coursesCopy = setAdaptedTestsInCourses(
        hubAdaptedTests.adaptedTests,
        courses,
        courses[courseId],
        setAdaptedTestsUpdatedAction,
        setCoursesAction
      );
      setAdaptedTestsUpdatedAction();
      setCoursesAction(coursesCopy);
    }
  }

  _getAllAdaptedTests = () => {
    const { getAdaptedTestsAction, setAdaptedTestsPollingAction, userId } = this.props;
    setAdaptedTestsPollingAction(true);
    getAdaptedTestsAction({ userId });
  };

  _receiveMessage = async e => {
    const { adaptedTestInitialTimer } = this.state;
    const { getAdaptedTestsAction, userId } = this.props;
    if (e.data.type === 'closeTestBuilderModal') {
      setTimeout(() => {
        getAdaptedTestsAction({ userId });
      }, adaptedTestInitialTimer * 1000);
      this._closeTestBuilderModal();
    }
  };

  _downloadAssessment = (testData, isAdaptedTest = false) => {
    const { downloadResource, courseId, currentCourse } = this.props;
    const courseCode = currentCourse.courseCode;
    const lorUserRole = HubLayoutConstants.ASSESSMENT_USER_ROLES.INSTRUCTOR;
    const printableResourceId = getTestPrintableResourceId(testData, isAdaptedTest);
    downloadOrPreviewAssessment(
      downloadResource,
      LTI_ACTIONS.DOWNLOAD,
      printableResourceId,
      isAdaptedTest,
      lorUserRole,
      '',
      courseId,
      courseCode,
      testData?.id
    );
  };

  _openPreviewTestModal = (assessmentId, testData = {}, isAdaptedTest = false) => {
    const { setSelectedAssessmentId, downloadResource, currentCourse, courseId } = this.props;
    setSelectedAssessmentId(assessmentId);
    const lorUserRole = HubLayoutConstants.ASSESSMENT_USER_ROLES.INSTRUCTOR;
    this.setState(() => ({
      selectedToPreviewTest: testData
    }));

    const digitalResourceId = getTestDigitaleResourceId(testData, isAdaptedTest);
    const courseCode = currentCourse.courseCode;
    if (digitalResourceId) {
      downloadOrPreviewAssessment(
        downloadResource,
        LTI_ACTIONS.PLAY,
        digitalResourceId,
        isAdaptedTest,
        lorUserRole,
        '',
        courseCode,
        courseId,
        assessmentId
      );
    }
  };

  _closePreviewTestModal = () => {
    const { setSelectedAssessmentId } = this.props;
    setSelectedAssessmentId(null);
    this.setState(() => ({
      selectedToPreviewTest: null
    }));
  };

  _downloadResource = resource => {
    const { downloadResource } = this.props;
    downloadResource({ ...resource }, 'download');
  };

  _openTestBuilderModal = testBuilderAssessmentId => {
    this.setState(() => ({
      testBuilderAssessmentId
    }));
    this._launchTestBuilder(testBuilderAssessmentId);
  };

  _launchTestBuilder = id => {
    const { downloadResource } = this.props;
    const { HUB_RETURN_PATH: hubReturnPath } = HubLayoutConstants.TEST_BUILDER_CONSTANTS;
    const testData = {
      resourceId: id,
      resourceAction: 'play',
      roles: HubLayoutConstants.ASSESSMENT_USER_ROLES.INSTRUCTOR,
      resourceType: RESOURCE_TYPE.ASSESSMENT,
      isAdaptTestFlow: true,
      assessmentEntitlement: id,
      returnPath: hubReturnPath
    };
    downloadResource({ ...testData }, 'play');
  };

  _closeTestBuilderModal = () => {
    this.setState(() => ({
      testBuilderAssessmentId: ''
    }));
  };

  _openResourceModal = resourceId => {
    const { currentCourse, downloadResource } = this.props;
    this.setState(() => ({
      resourceId
    }));
    const resources = get(currentCourse, ['resources']);

    const { PREVIEWABLE_FORMATS } = ResourceConstants;
    if (PREVIEWABLE_FORMATS.includes((resources[resourceId].format || '').toLowerCase())) {
      downloadResource({ ...resources[resourceId] }, 'play');
    }
  };

  _closeResourceModal = () => {
    this.setState(() => ({
      resourceId: ''
    }));
  };

  _pollHubAdaptedTests = ids => {
    const { adaptedTestPollingTimer } = this.state;
    const { getAdaptedTestsAction, userId, setAdaptedTestsPollingAction } = this.props;
    setAdaptedTestsPollingAction(true);
    setTimeout(() => {
      getAdaptedTestsAction({ userId, ids });
    }, adaptedTestPollingTimer * 1000);
  };

  _openSetTest = (testId, test) => {
    const { currentCourse, setOnlineTestDataAction } = this.props;
    const assessmentCourseTitle = getAssessmentCourseTitle(currentCourse);

    this.setState({ onlineTestId: testId });
    this.setState({ isOnlineTestOpen: true });
    setOnlineTestDataAction({ test, assessmentCourseTitle });
  };

  _closeSetTest = () => {
    this.setState({ isOnlineTestOpen: false });
  };

  _closeNotDownloadableModal = () => {
    this.setState(() => ({
      isNotDownloadableAssessmentId: null,
      errorModalType: ErrorModalType.NONE
    }));
  };

  _renderItems = () => {
    const {
      currentAssessment,
      currentCourse,
      localizedContent: { hubGlossary: content },
      breakpoint,
      downloadIsLoading,
      resourceIdsDownloading
    } = this.props;
    const items = [];
    if (currentAssessment && currentAssessment.resources) {
      currentAssessment.resources.forEach(item => {
        if (item.type === HubLayoutConstants.ASSESSMENT_TYPES.INTERACTIVE_FOLDER) {
          if (currentCourse.interactiveFolders && currentCourse.interactiveFolders[item.id]) {
            items.push({ ...currentCourse.interactiveFolders[item.id], id: item.id, type: item.type });
          }
        } else if (item.type === HubLayoutConstants.ASSESSMENT_TYPES.RESOURCE) {
          if (currentCourse.resources && currentCourse.resources[item.id]) {
            items.push({ ...currentCourse.resources[item.id], id: item.id, type: item.type });
          }
        }
      });
    }
    return Object.entries(items).map(([key, value], index) => {
      if (value.type === HubLayoutConstants.ASSESSMENT_TYPES.RESOURCE) {
        const resourceId = value.id;
        return (
          !hasHideableKeywords(value.keywords) && (
            <Resource
              key={resourceId}
              resourceId={resourceId}
              openModal={() => {
                this._openResourceModal(resourceId);
              }}
              startedLicence
              hasLicence
              isExpired={value.keywords && hasLockableKeywords(value.keywords)}
              hubContent={content}
              resource={value}
              breakpoint={breakpoint}
              downloadResource={this._downloadResource}
              downloadIsLoading={downloadIsLoading}
              resourceIdsDownloading={resourceIdsDownloading}
            />
          )
        );
      }
      if (value.type === HubLayoutConstants.ASSESSMENT_TYPES.INTERACTIVE_FOLDER) {
        return (
          !hasHideableKeywords(value.keywords) && (
            <div key={key}>
              <AssessmentTest
                assessmentId={value.id}
                assessmentTest={value}
                content={content}
                title={value.title}
                hubContent={content}
                downloadAssessment={this._downloadAssessment}
                openOnlineTest={this._openSetTest}
                isAdapted={false}
                breakpoint={breakpoint}
                locked={value.keywords && hasLockableKeywords(value.keywords)}
                openModal={this._openPreviewTestModal}
                openTestBuilderModal={() => {
                  this._openTestBuilderModal(value.digitalResourceId);
                }}
                dropdownTop={
                  Object.keys(items).length >= 4 &&
                  (index === Object.keys(items).length - 1 || index === Object.keys(items).length - 2)
                }
              />
              {value.id &&
                value.adaptedTests &&
                value.adaptedTests.length !== 0 &&
                value.adaptedTests.map(item => (
                  <div className={assessmentStyles.adaptedTest} key={item.id}>
                    <SVGIcon className={assessmentStyles.adaptedTestArrow} glyph={GLYPHS.ICON_CHILD} />
                    {item.resource_title && (
                      <AssessmentTest
                        assessmentId={item.associated_resource_id}
                        assessmentTest={item}
                        title={item.resource_title}
                        locked={false}
                        content={content}
                        openModal={this._openPreviewTestModal}
                        openOnlineTest={this._openSetTest}
                        breakpoint={breakpoint}
                        downloadAssessment={this._downloadAssessment}
                        dropdownTop={
                          Object.keys(items).length >= 4 &&
                          (index === Object.keys(items).length - 1 || index === Object.keys(items).length - 2)
                        }
                        openTestBuilderModal={() => {
                          this._openTestBuilderModal(item.associated_resource_id);
                        }}
                        isAdapted
                      />
                    )}
                  </div>
                ))}
            </div>
          )
        );
      }
      return '';
    });
  };

  _renderFolders = () => {
    const {
      currentAssessment,
      match: { params },
      currentCourse,
      localizedContent: { hubGlossary: content }
    } = this.props;

    const folders = [];
    if (currentAssessment) {
      Object.keys(currentAssessment).forEach(key => {
        if (currentAssessment[key]?.title) {
          const assessment = { ...currentAssessment[key] };
          assessment.locked = isLockedNode(currentCourse, currentAssessment[key]);
          folders.push({ ...assessment, subFolderId: key });
        }
      });
    }

    if (!folders.length) return null;

    return (
      <>
        <div>{content.assessment_home_assessment_folders}</div>
        <div className={styles.assessmentHomeFolderContainer}>
          {Object.entries(folders).map(([key, value]) => (
            <AssessmentFolder
              locked={value.locked}
              key={key}
              title={value.title}
              itemLink={`${getCourseAssessmentLink(params.courseId, params.assessmentId, params.folder)}/${
                value.subFolderId
              }`}
            />
          ))}
        </div>
      </>
    );
  };

  render() {
    const {
      currentAssessment,
      currentCourse,
      localizedContent: { hubGlossary: content },
      selectedAssessmentId,
      downloadIsLoading,
      breakpoint
    } = this.props;
    const {
      resourceId,
      selectedToPreviewTest,
      testBuilderAssessmentId,
      onlineTestId,
      isOnlineTestOpen,
      isNotDownloadableAssessmentId,
      errorModalType
    } = this.state;

    const pageTitle = currentCourse ? `${currentCourse.title} | ${content.assessment_home_assessment}` : 'Loading';

    const selectedToPreviewTestData =
      selectedAssessmentId && selectedToPreviewTest ? getTestData(selectedToPreviewTest) : {};
    const showHeading = featureIsEnabled('navigation-changes');
    const headingArgs = {
      text: content.assessment,
      size: 'small',
      variant: 'h1'
    };

    return Object.keys(currentAssessment).length ? (
      <div>
        <Helmet title={pageTitle} />
        {showHeading && <Heading {...headingArgs} />}
        {!showHeading && <div className={styles.assessmentHomeHeader}>{currentAssessment.title || ''}</div>}
        {currentCourse && currentCourse.assessmentFolders && currentCourse.assessmentFolders.assessments && (
          <AssessmentDate date={currentCourse.assessmentFolders.assessments.expiryDate} content={content} />
        )}

        <div> {this._renderItems()}</div>
        <div className={styles.assessmentHomeFolders}>{this._renderFolders()}</div>

        <ResourceFormHidden />

        <OnlineTestModal
          selectedTestId={selectedAssessmentId}
          closeModal={this._closePreviewTestModal}
          test={selectedToPreviewTestData}
          hubContent={content}
          downloadIsLoading={downloadIsLoading}
          breakpoint={breakpoint}
        />
        <ResourceModal
          breakpoint={breakpoint}
          resourceId={resourceId}
          closeModal={this._closeResourceModal}
          downloadResource={
            currentCourse?.resources[resourceId]?.downloadable === RESOURCE_DOWNLOAD_TYPE.YES
              ? this._downloadResource
              : null
          }
          resource={resourceId ? currentCourse.resources[resourceId] : {}}
          hubContent={content}
          downloadIsLoading={downloadIsLoading}
        />
        {testBuilderAssessmentId ? (
          <TestBuilderModal
            resourceId={testBuilderAssessmentId}
            closeModal={this._closeTestBuilderModal}
            resource={testBuilderAssessmentId ? currentCourse.interactiveFolders[testBuilderAssessmentId] : {}}
            hubContent={content}
          />
        ) : null}
        <OnlineTestPanel closePanel={() => this._closeSetTest()} testId={onlineTestId} isOpen={isOnlineTestOpen} />
        <HubProductErrorModal
          isOpen={isNotDownloadableAssessmentId !== null}
          errorModalType={errorModalType}
          hubContent={content}
          glyphIcon={GLYPHS.ICON_CALENDAR}
          glyphSize={thumbnailSizes.SECTION}
          glyphBackgroundColour={colors.HUB_BLUE}
          title={content.assessment_not_available_modal_title}
          subTitle={content.assessment_not_available_modal_subtitle}
          showLink={false}
          actionLabel={content.done_button}
          closeExpiredModal={this._closeNotDownloadableModal}
        />
      </div>
    ) : (
      <div />
    );
  }
}

AssessmentHome.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  currentAssessment: PropTypes.object,
  downloadResource: PropTypes.func,
  setSelectedAssessmentId: PropTypes.func,
  selectedAssessmentId: PropTypes.string,
  match: PropTypes.object,
  currentCourse: PropTypes.object,
  downloadIsLoading: PropTypes.bool,
  breakpoint: PropTypes.string,
  setOnlineTestDataAction: PropTypes.func,
  getAdaptedTestsAction: PropTypes.func,
  userId: PropTypes.string,
  courses: PropTypes.object,
  courseId: PropTypes.string,
  hubAdaptedTests: PropTypes.object,
  setCoursesAction: PropTypes.func,
  setAdaptedTestsUpdatedAction: PropTypes.func,
  setAdaptedTestsNeedToBeUpdatedAction: PropTypes.func,
  setAdaptedTestsPollingAction: PropTypes.func,
  resourceIdsDownloading: PropTypes.object
};
export default compose(
  withLocalizedContent('hubGlossary'),
  withBreakpoint,
  connect(
    ({ courseAssessments, hubResourceDownload, identity, hubAdaptedTests }, ownProps) => {
      const {
        match: { params },
        courses,
        courseId
      } = ownProps;
      const currentCourse = (courses && courses[courseId]) || {};
      const currentAssessment =
        currentCourse && currentCourse.assessmentFolders ? currentCourse.assessmentFolders[params.assessmentId] : {};

      return {
        downloadIsLoading: hubResourceDownload?.downloadIsLoading,
        resourceIdsDownloading: hubResourceDownload?.resourceIdsDownloading,
        selectedAssessmentId: courseAssessments.selectedAssessmentId,
        currentCourse,
        currentAssessment,
        userId: identity.userId,
        hubAdaptedTests
      };
    },
    {
      setSelectedAssessmentId: actions.setSelectedAssessmentId,
      getAdaptedTestsAction: getAdaptedTests,
      downloadResource: hubDownloadResource,
      setOnlineTestDataAction: setOnlineTestData,
      setCoursesAction: setCourses,
      setAdaptedTestsUpdatedAction: setAdaptedTestsUpdated,
      setAdaptedTestsNeedToBeUpdatedAction: setAdaptedTestsNeedToBeUpdated,
      setAdaptedTestsPollingAction: setAdaptedTestsPolling
    }
  )
)(AssessmentHome);
