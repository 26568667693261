import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import Checkbox from '@oup/shared-front-end/src/components/Checkbox';

// Styles
import APP_CONSTANTS from '@oup/shared-node-browser/constants';
import styles from './HubListItem.scss';
// Constants
import breakpoints from '../../globals/breakpoints';
import { HubLayoutConstants } from '../../globals/hubConstants';
import userRoles from '../../globals/userRoles';
// Services
import getPeopleColour from '../HubDashboardLayout/Services/getPeopleColour';
import { getOrgStatusType } from '../HubDashboardLayout/Services/getStatusType';
import getPeopleInitials from '../HubDashboardLayout/Services/getPeopleInitials';
import getDropdownItems from '../../components/HubDropdownMenu/Services/getDropdownItems';
// Components
import SVGIcon from '../../components/SVGIcon/SVGIcon';
import HubDropdownMenu from '../../components/HubDropdownMenu/HubDropdownMenu';
import Tooltip from '../../components/Tooltip/Tooltip';

function HubListItem({
  itemIds,
  checkboxData: { onSelect, selectedIds, checkAllSelected, setAllSelected },
  id,
  currentUsersList,
  breakpoint,
  hubContent,
  userRole,
  dropdownTop,
  onClick,
  actions: {
    removeUser,
    editUser,
    cancelInvitation,
    assignMaterial,
    regenerateSupervisedUserSignInCard,
    bulkPasswordReset
  },
  dropdownType,
  userId = '',
  showDropdown = true,
  currentOrganisationLti = false,
  isPlacementCentre
}) {
  useEffect(() => {
    const allPeopleSelected = itemIds.every(entry => selectedIds.includes(entry));
    setAllSelected(allPeopleSelected);
  }, []);

  const item = get(currentUsersList, id);
  const {
    firstname = '',
    lastname = '',
    username = '',
    roleName = '',
    orgInviteStatus = '',
    orgArchiveStatus = '',
    orgInviteDate = ''
  } = item;
  const peopleColour = getPeopleColour(roleName);
  const initials = getPeopleInitials(firstname, lastname);
  const { icon, iconColour, date, text, subtext = '', status = '' } = getOrgStatusType(item, hubContent);
  const { PENDING, ARCHIVED } = HubLayoutConstants.PEOPLE_STATUS;
  const context = roleName === userRoles.LEARNER ? APP_CONSTANTS.ORG_STUDENTS : APP_CONSTANTS.ORG_STAFF;

  const tooltipData = `${text} ${date} ${subtext}`;
  const itemDeletedOrArchived = status === ARCHIVED;
  const dropdownOptions = {
    isPending: item.orgInviteStatus === PENDING,
    canChangeRole: roleName !== userRoles.LEARNER,
    isSupervisedUser: item.isSupervised,
    isPlacementCentre
  };
  const userDetails = {
    roleName,
    invitationPending: orgInviteStatus === PENDING,
    archived: orgArchiveStatus === ARCHIVED,
    invitationExpiryTimestamp: orgInviteDate,
    firstName: firstname,
    lastName: lastname,
    userName: username,
    email: username
  };

  const getUserName = () => `${firstname || ''} ${lastname || ''}`;

  const dropdownActions = {
    sendInvitationReminder: e =>
      editUser(e, 'editUser/sendInvitationReminder', {
        selectedUserId: id,
        selectedUsers: [id],
        context: '',
        role: roleName,
        userDetails
      }),
    sendPasswordResetEmail: e =>
      editUser(e, 'editUser/sendPasswordResetEmail', {
        selectedUserId: id,
        selectedUsers: [id],
        context: '',
        role: roleName,
        userDetails
      }),
    removeUser: e => removeUser(e, 'archiveUsers', { selectedUsers: [id], context }),
    editUser: e =>
      editUser(e, 'editUser', { selectedUserId: id, selectedUsers: [id], context: '', role: roleName, userDetails }),
    cancelInvitation: e =>
      cancelInvitation(e, 'cancelInvitation', { selectedUsers: [id], context: '', role: roleName }),
    assignMaterial: e =>
      assignMaterial(e, 'assignMaterial', {
        selectedUserId: id,
        context,
        selectedUsers: [id],
        contextName: getUserName()
      }),
    regenerateSupervisedUserSignInCard: e =>
      regenerateSupervisedUserSignInCard(e, 'regenerateSupervisedUserSignInCard', {
        selectedUserId: id,
        selectedUsers: [id],
        userDetails
      }),
    bulkPasswordReset: () => bulkPasswordReset({ selectedUsers: [id] })
  };
  const isSelfSelectedOrgAdmin = userRole === userRoles.ORG_ADMIN && userId === id;

  return (
    <ul>
      <li
        key={id}
        className={`${styles.personContainer} ${
          selectedIds.includes(id) ? styles.active : ''
        } ${itemDeletedOrArchived && styles.personRemoved}`}
        onClick={e => {
          if (
            !isPlacementCentre &&
            !itemDeletedOrArchived &&
            e.target.closest(`.${styles.checkboxContainer}`) === null
          ) {
            onClick(id);
          }
        }}
        role="none"
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={0}
        onKeyDown={undefined}
      >
        <div className={styles.leftContainer}>
          <div className={styles.checkboxContainer}>
            {!itemDeletedOrArchived ? (
              <Checkbox
                id={id}
                label="Select entity"
                hideLabel
                checked={selectedIds.includes(id)}
                onChange={() => {
                  onSelect(id, !selectedIds.includes(id));
                  checkAllSelected(itemIds);
                }}
                tabIndex={0}
              />
            ) : (
              <div className={styles.checkboxPlaceholder} />
            )}
          </div>

          <div className={styles.userDetailsContainer}>
            <div className={`${styles.userInfoDot} ${styles[peopleColour]}`}>{initials}</div>
            <div className={styles.userInfoContainer}>
              <div>
                <span>{`${firstname || ''} ${lastname || ''}`}</span>
              </div>
              <div>
                <span>{username}</span>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.middleContainer}>
          <div className={styles.svgContainer}>
            <Tooltip title={breakpoint === breakpoints.XXS ? tooltipData : ''} className={styles.statusTooltip}>
              <SVGIcon glyph={icon} fill={iconColour} />
            </Tooltip>
          </div>
          <div className={styles.textContainer}>
            <span>{text}</span>
            <span className={`${styles[status.toLowerCase()] || ''}`}>{date}</span>
            {subtext ? <span>{subtext}</span> : null}
          </div>
          {breakpoint === breakpoints.XXS && !itemDeletedOrArchived && showDropdown && (
            <div className={styles.dropdownContainer}>
              <HubDropdownMenu
                dropdownData={getDropdownItems(
                  dropdownType,
                  hubContent,
                  dropdownOptions,
                  dropdownActions,
                  userRole,
                  isSelfSelectedOrgAdmin,
                  currentOrganisationLti
                )}
                parentId={id}
                customClassname={styles.dropDownMenu}
                dropdownTop={dropdownTop}
                tabindex={0}
              />
            </div>
          )}
          {breakpoint === breakpoints.XXS && itemDeletedOrArchived && <div className={styles.dropDownPlaceholder} />}
        </div>
        <div className={styles.rightContainer}>
          {breakpoint !== breakpoints.XXS && !itemDeletedOrArchived && showDropdown && (
            <HubDropdownMenu
              dropdownData={getDropdownItems(
                dropdownType,
                hubContent,
                dropdownOptions,
                dropdownActions,
                userRole,
                isSelfSelectedOrgAdmin,
                currentOrganisationLti
              )}
              parentId={id}
              customClassname={styles.dropDownMenu}
              dropdownTop={dropdownTop}
              tabindex={0}
            />
          )}
        </div>
      </li>
    </ul>
  );
}

HubListItem.defaultProps = {
  actions: {}
};

HubListItem.propTypes = {
  itemIds: PropTypes.array.isRequired,
  hubContent: PropTypes.object.isRequired,
  checkboxData: PropTypes.object.isRequired,
  breakpoint: PropTypes.string.isRequired,
  id: PropTypes.string,
  currentUsersList: PropTypes.object,
  userRole: PropTypes.string,
  dropdownTop: PropTypes.bool,
  actions: PropTypes.object,
  onClick: PropTypes.func,
  dropdownType: PropTypes.string,
  userId: PropTypes.string,
  showDropdown: PropTypes.bool,
  currentOrganisationLti: PropTypes.bool,
  isPlacementCentre: PropTypes.object
};

export default HubListItem;
