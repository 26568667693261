export default function isCourseReadyToLaunch(course) {
  if (course.products && course.products.data && Object.keys(course.products.data).length) {
    const productKeys = Object.keys(course.products.data);
    for (let i = 0; i < productKeys.length; i += 1) {
      if (course.products.data[productKeys[i]].readyToLaunch) {
        // if at least one product is readyToLaunch, it means that the course should be shown
        return true;
      }
    }
    // if it has products and all are not ready to launch
    return false;
  }
  // if it doesn't have products at all, it's ready to launch
  return true;
}
