import { featureIsEnabled } from '../../../globals/envSettings';
import getOptProductsCatalogueUrl from '../../../utils/opt/getOptProductsCatalogueUrl';
import redirectToUrl from '../../../utils/redirectToUrl';

const getOrgLicencesHeaderItems = (
  content,
  { openAddLicensesModalAction = null },
  { name = '' },
  isOrgPlacementCentre,
  isLicencesTab,
  orgCode
) =>
  featureIsEnabled('opt-main-features')
    ? [
        {
          name,
          status: isLicencesTab ? content.org_licences_header_assign_available : content.org_licences_header_licences,
          action: isOrgPlacementCentre
            ? () => redirectToUrl(getOptProductsCatalogueUrl(orgCode), true)
            : openAddLicensesModalAction,
          used: isLicencesTab ? content.org_licences_header_used : null,
          buttonText: isOrgPlacementCentre ? content.buy_licences_button : content.add
        }
      ]
    : [
        {
          name,
          status: content.org_licences_header_licences,
          action: openAddLicensesModalAction,
          buttonText: content.add
        }
      ];

export default getOrgLicencesHeaderItems;
