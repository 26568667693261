import { selfDefinedUserTypes } from '@oup/shared-node-browser/user';

const productMatchesUserType = (product, userId, classStudentsId, classTeachersId) => {
  let matches;
  switch (product.productDetails.target_usertype) {
    case selfDefinedUserTypes.CLASS_STUDENT:
      matches = classStudentsId.includes(userId);
      break;
    case selfDefinedUserTypes.CLASS_TEACHER:
      matches = classTeachersId.includes(userId);
      break;
    default:
      matches = true;
  }
  return matches;
};

const checkAllUsersHaveLicenses = (classAssignments, classStudentsId, classTeachersId) => {
  let allUsersHaveLicense = true;
  const products = Object.values(classAssignments);
  products.forEach(product => {
    const usersWithoutLicense = product.userWithoutLicence;
    if (usersWithoutLicense && usersWithoutLicense.length) {
      usersWithoutLicense.every(userId => {
        if (productMatchesUserType(product, userId, classStudentsId, classTeachersId)) {
          allUsersHaveLicense = false;
          return false;
        }
        return true;
      });
    }
  });
  return allUsersHaveLicense;
};

export default checkAllUsersHaveLicenses;
