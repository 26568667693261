import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Wizard } from '@oup/shared-front-end/src/components/Wizard';

import PlacementTestOnboardingWizardFooter from './PlacementTestOnboardingWizardFooter';
import Header from './Header';
import CreateAPlacementTest from './CreateAPlacementTest/CreateAPlacementTest';
import AddStudents from './AddStudents/AddStudents';
import PlacementTestSchedule from './PlacementTestSchedule/PlacementTestSchedule';
import EmailNotificationsAndReminders from './EmailNotificationsAndReminders/EmailNotificationsAndReminders';
import PlacementTestOnboardingModal from './PlacementTestOnboardingModal/PlacementTestOnboardingModal.js';
import PlacementTestLanguageVariation from './PlacementTestLanguageVariation/PlacementTestLanguageVariation.js';
import PlacementTestShowResults from './PlacementTestShowResults/PlacementTestShowResults.js';
import PlacementTestSessionIntroductionWizardStep from './PlacementTestSessionIntroductionWizardStep/PlacementTestSessionIntroductionWizardStep';
import preventBodyScroll from '../../utils/dom/preventBodyScroll';

import PlacementTestSummary from './PlacementTestSummary/PlacementTestSummary';
import { featureIsEnabled } from '../../globals/envSettings.js';

function PlacementTestOnboardingWizard({ isOpen }) {
  const [isWarningModalOpen, setWarningModalOpen] = useState(false);

  const closeWizardWithWarningPopup = () => {
    setWarningModalOpen(true);
  };

  useEffect(() => {
    preventBodyScroll(isOpen);
    if (localStorage.getItem('selectedOrgType') && isOpen) localStorage.removeItem('selectedOrgType');
  }, [isOpen]);

  if (featureIsEnabled('show-test-results-to-students')) {
    return (
      isOpen && (
        <div data-testid="PLACEMENT_TEST_ONBOARDING_WIZARD_CONTAINER">
          <Wizard
            header={<Header closeWizardWithWarningPopup={closeWizardWithWarningPopup} />}
            footer={<PlacementTestOnboardingWizardFooter />}
          >
            <PlacementTestSessionIntroductionWizardStep />
            <CreateAPlacementTest closeWizardWithWarningPopup={closeWizardWithWarningPopup} />
            <PlacementTestLanguageVariation />
            <PlacementTestSchedule />
            <AddStudents />
            <EmailNotificationsAndReminders />
            <PlacementTestShowResults />
            <PlacementTestSummary />
          </Wizard>
          <PlacementTestOnboardingModal
            isOpen={isWarningModalOpen}
            closeWarningModal={() => setWarningModalOpen(false)}
          />
        </div>
      )
    );
  }
  return (
    isOpen && (
      <div data-testid="PLACEMENT_TEST_ONBOARDING_WIZARD_CONTAINER">
        <Wizard
          header={<Header closeWizardWithWarningPopup={closeWizardWithWarningPopup} />}
          footer={<PlacementTestOnboardingWizardFooter />}
        >
          <PlacementTestSessionIntroductionWizardStep />
          <CreateAPlacementTest closeWizardWithWarningPopup={closeWizardWithWarningPopup} />
          <PlacementTestLanguageVariation />
          <PlacementTestSchedule />
          <AddStudents />
          <EmailNotificationsAndReminders />
          <PlacementTestSummary />
        </Wizard>
        <PlacementTestOnboardingModal
          isOpen={isWarningModalOpen}
          closeWarningModal={() => setWarningModalOpen(false)}
        />
      </div>
    )
  );
}

PlacementTestOnboardingWizard.propTypes = {
  isOpen: PropTypes.bool.isRequired
};

export default PlacementTestOnboardingWizard;
