import moment from 'moment';
import { StatusType, STATUS_ICONS } from '../../../components/StatusIcon/StatusIcon';
import { HubLayoutConstants } from '../../../globals/hubConstants';
import { isLtiMode } from '../../../utils/platform';

// eslint-disable-next-line import/prefer-default-export
export function getOrgStatusType(user, hubContent) {
  const date = moment(user.orgInviteDate).format('D MMMM YYYY');
  const { PEOPLE_STATUS } = HubLayoutConstants;

  switch (true) {
    case user.isDeleted:
      return {
        icon: STATUS_ICONS[StatusType.USER_REMOVED],
        text: hubContent.removed_from_organization_on,
        date,
        iconColour: 'colors.WEAK ',
        status: PEOPLE_STATUS.ARCHIVED
      };
    case user.orgInviteStatus === 'PENDING':
      return {
        icon: STATUS_ICONS[StatusType.USER_INVITED],
        text: isLtiMode() ? hubContent.added_to_organization_on_ltihub : hubContent.added_to_organization_on,
        subtext: hubContent.invitation_sent,
        date,
        iconColour: null,
        status: PEOPLE_STATUS.PENDING
      };
    case user.orgArchiveStatus === 'ARCHIVED':
      return {
        icon: STATUS_ICONS[StatusType.USER_REMOVED],
        text: hubContent.removed_from_organization_on,
        date,
        iconColour: '#c92467 ',
        status: PEOPLE_STATUS.ARCHIVED
      };
    default:
      return {
        icon: STATUS_ICONS[StatusType.ACTIVE],
        text: isLtiMode() ? hubContent.added_to_organization_on_ltihub : hubContent.added_to_organization_on,
        date,
        iconColour: null,
        status: PEOPLE_STATUS.ACTIVE
      };
  }
}
