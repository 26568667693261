import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import compose from 'recompose/compose';
import { Helmet } from 'react-helmet';

import PopupOverlay from '../PopupOverlay/PopupOverlay';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import styles from './LanguageSelector.scss';

import { setStoredLanguage } from '../../redux/reducers/app.reducer';

import env from '../../globals/envSettings';
import { getLanguage, setLanguage } from '../../utils/manageSelectorLanguage';
import withLocalizedContent from '../../language/withLocalizedContent';

function LanguageSelector(props) {
  const {
    buttonId,
    customClassName,
    darkLayout,
    setStoredLanguageAction,
    localizedContent: { languageSelector: content }
  } = props;
  const [options, setOptions] = useState([{}]);
  const [selectedOption, setSelectedOption] = useState({ label: '', shortLabel: '' });
  const [openPopup, setOpenPopup] = useState(false);

  const onToggleSelector = e => {
    const keysAllowedToOpen = ['Enter', 'Space'];
    if ((e.type === 'keydown' && keysAllowedToOpen.includes(e.key)) || e.type === 'click') {
      e.stopPropagation();
      e.preventDefault();

      setOpenPopup(!openPopup);
    }
  };

  const onLanguageSelect = option => {
    if (option.code !== selectedOption.code) {
      setLanguage(option.code);
      setStoredLanguageAction(option.code);
    }

    setOpenPopup(!openPopup);
  };

  useEffect(() => {
    const storedLanguage = getLanguage();
    const languages = env.integrations.rvs.languages.map(({ name, code }) => ({
      code,
      label: content[`${name}_label`],
      shortLabel: content[`${name}_short_label`]
    }));

    const selectedLanguage = languages.find(language => language.code === storedLanguage);
    setSelectedOption(selectedLanguage);
    setOptions(languages);
  }, []);

  return (
    <div className={classnames(styles.languageSelector, { [styles.darkTheme]: darkLayout }, customClassName)}>
      <Helmet htmlAttributes={{ lang: selectedOption.shortLabel === 'ENG' ? 'en-GB' : 'es' }} />
      <button
        type="button"
        id={buttonId}
        className={classnames([styles.selectorButton], {
          [styles.selected]: openPopup
        })}
        onClick={onToggleSelector}
        onKeyDown={onToggleSelector}
        aria-label={content.language_selector_button}
      >
        <SVGIcon glyph={GLYPHS.ICON_GLOBE} />
        <span>{selectedOption.label}</span>
        <span>{selectedOption.shortLabel}</span>
        <SVGIcon glyph={GLYPHS.ICON_DROPLIST} />
      </button>

      <PopupOverlay
        id="languagePanelId"
        visible={openPopup}
        customClassName={classnames([styles.menu], { [styles.opened]: openPopup })}
        onTogglePopup={onToggleSelector}
        buttonElementId={buttonId}
      >
        {openPopup ? (
          <ul className={styles.optionContainer}>
            {options.map((option, key) => (
              <li className={styles.option} key={key} aria-label={option.label}>
                <button type="button" onClick={() => onLanguageSelect(option)}>
                  <span>{option.label}</span>
                </button>
              </li>
            ))}
          </ul>
        ) : null}
      </PopupOverlay>
    </div>
  );
}

LanguageSelector.propTypes = {
  buttonId: PropTypes.string.isRequired,
  customClassName: PropTypes.string,
  darkLayout: PropTypes.bool.isRequired,
  setStoredLanguageAction: PropTypes.func.isRequired,
  localizedContent: PropTypes.object.isRequired
};

export default compose(
  connect(null, {
    setStoredLanguageAction: setStoredLanguage
  }),
  withLocalizedContent('languageSelector')
)(LanguageSelector);
