import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { Wizard } from '@oup/shared-front-end/src/components/Wizard';
import SiteHeader from '@oup/shared-front-end/src/components/SiteHeader';
import Button from '@oup/shared-front-end/src/components/Button/Button.js';
import { ICON_HELP_CIRCLE } from '@oup/shared-front-end/src/svg/oup/index';
import styles from '@oup/shared-front-end/src/components/SiteHeader/SiteHeader.scss';

import ChooseRoleWizardFooter from './ChooseRoleWizardFooter.js';
import ChooseRoleStep from './ChooseRoleStep.js';
import ChooseAgeStep from './ChooseAgeStep.js';
import preventBodyScroll from '../../utils/dom/preventBodyScroll.js';
import withLocalizedContent from '../../language/withLocalizedContent';
import { HubLayoutConstants } from '../../globals/hubConstants';
import useMediaQuery from '../../utils/mediaQuery.js';

function ChooseRoleWizard({ isOpen, onRoleChosen, localizedContent: { redeemCodePage: content } }) {
  const [userType, setUserType] = useState('');
  const [userAge, setUserAge] = useState('');

  const isMobile = useMediaQuery('(max-width: 52.5rem)');

  useEffect(() => {
    preventBodyScroll(isOpen);
  }, [isOpen]);

  return isOpen ? (
    <div data-testid="CHOOSE_ROLE_WIZARD_CONTAINER">
      <Wizard
        header={
          <SiteHeader
            buttons={[
              {
                type: Button,
                props: {
                  variant: 'filled',
                  icon: { component: <ICON_HELP_CIRCLE className={styles.helpIconLoggedOut} /> },
                  text: !isMobile ? content.support_heading_text : '',
                  onClick: () => window.open(HubLayoutConstants.HELP_AND_SUPPORT_URL, '_blank'),
                  className: !isMobile ? styles.helpButtonLarge : styles.helpButtonSmall
                }
              }
            ]}
            secondaryText={content.oxford_english_hub}
          />
        }
        footer={<ChooseRoleWizardFooter content={content} />}
        showProgressBar={false}
      >
        <ChooseRoleStep
          content={content}
          selectedUserType={userType}
          onRoleChosen={onRoleChosen}
          onChange={selectedUserType => {
            setUserType(selectedUserType);
            localStorage.setItem('userType', selectedUserType);
          }}
        />
        <ChooseAgeStep
          content={content}
          selectedUserType={userType}
          selectedUserAge={userAge}
          onRoleChosen={onRoleChosen}
          onChange={selectedUserAge => {
            setUserAge(selectedUserAge);
            localStorage.setItem('userAge', selectedUserAge);
          }}
        />
      </Wizard>
    </div>
  ) : null;
}

ChooseRoleWizard.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  localizedContent: PropTypes.object.isRequired,
  onRoleChosen: PropTypes.func.isRequired
};

export default compose(withLocalizedContent('redeemCodePage'))(ChooseRoleWizard);
