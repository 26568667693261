import React from 'react';
import { pick } from 'lodash';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import withLocalizedContent from '../../../language/withLocalizedContent.js';
import actions from '../../../redux/actions/index.js';
import InvoiceReportForm from './InvoiceReportForm.js';

function InvoiceReportPage({
  localizedContent: { invoiceReportForm: localizedContent },
  formData = {},
  input,
  validate,
  submit,
  errors
}) {
  const { startDate = '', endDate = '' } = formData;

  const submitForm = () => submit(formData);

  const onChangeHandler = field => value => input({ field, value });

  const onBlurHandler = () => () => validate();
  return (
    <>
      <Helmet title="Invoice report" />

      <InvoiceReportForm
        localizedContent={localizedContent}
        startDate={startDate}
        endDate={endDate}
        errors={errors}
        onChangeHandler={onChangeHandler}
        onBlurHandler={onBlurHandler}
        onSubmit={submitForm}
      />
    </>
  );
}
InvoiceReportPage.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  formData: PropTypes.object,
  input: PropTypes.func.isRequired,
  validate: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  errors: PropTypes.object
};
export default compose(
  withRouter,
  withLocalizedContent('invoiceReportForm'),
  connect(
    state => ({
      ...pick(state.invoiceReport, ['errors', 'formData'])
    }),
    dispatch => ({
      input: input => dispatch(actions.setInvoiceReportFromDateInput(input)),
      validate: () => dispatch(actions.invoiceReportValidateInput()),
      submit: input => dispatch(actions.createInvoiceReportRequest(input))
    })
  )
)(InvoiceReportPage);
