import React from 'react';
import PropTypes from 'prop-types';
import SVGIcon from '../SVGIcon/SVGIcon';
import styles from './ActionPanel.scss';

function ActionPanel({ actions }) {
  return (
    <div className={styles.actionPanel}>
      {actions.map(
        action =>
          action.hasAccess && (
            <button
              key={action.tooltip}
              onClick={action.onClick}
              data-tooltip={action.tooltip}
              className={styles.actionButton}
              aria-label={action.tooltip}
              type="button"
            >
              <SVGIcon glyph={action.glyph} />
            </button>
          )
      )}
    </div>
  );
}

export default ActionPanel;

ActionPanel.propTypes = {
  actions: PropTypes.array
};
