import React from 'react';
import PropTypes from 'prop-types';
import WizardStep from '@oup/shared-front-end/src/components/Wizard/WizardStep';
import TextLink from '@oup/shared-front-end/src/components/TextLink';
import { HubIllustrationConstants, HubIllustrationAltText } from '../../../globals/hubConstants';
import styles from './WizardIntroScreen.scss';

function WizardIntroScreen({ content }) {
  return (
    <div data-testid="CREATE_CLASS_WIZARD_INTRO_STEP" className={styles.stepContainer}>
      <WizardStep
        className={styles.introStep}
        titleText={content.set_up_a_class}
        subtitleText={content.create_class_wizard_intro_subheading}
        image={HubIllustrationConstants.ORGANIZING}
        imageAlt={HubIllustrationAltText.ORGANIZING}
        variant="introduction"
      >
        <div className={styles.textContainer}>
          <ol>
            <li>
              <p>{content.create_class_intro_content_1}</p>
              <p>{content.create_class_intro_content_subtext_1}</p>
            </li>
            <li>
              <p>{content.create_class_intro_content_2}</p>
              <p>{content.create_class_intro_content_subtext_2}</p>
            </li>
            <li>
              <p>{content.create_class_intro_content_3}</p>
              <p>{content.create_class_intro_content_subtext_3}</p>
            </li>
          </ol>
          <TextLink to={content.learn_more_about_classes_link} target="_blank">
            {content.learn_more_about_classes_link_text}
          </TextLink>
        </div>
      </WizardStep>
    </div>
  );
}

WizardIntroScreen.propTypes = {
  content: PropTypes.object.isRequired
};

export default WizardIntroScreen;
