import moment from 'moment';
import { GLYPHS } from '../../../components/SVGIcon/SVGIcon';
import { StatusType, STATUS_ICONS } from '../../../components/StatusIcon/StatusIcon';

const getDeletedDate = (deletedDate, className) => {
  if (deletedDate) {
    return deletedDate;
  }
  const lenghtOfArchivedText = 12;
  const lenghtOfDateText = 10;
  const dateIndex = className.indexOf('archived on') + lenghtOfArchivedText;
  return className.slice(dateIndex, dateIndex + lenghtOfDateText) || '';
};

const getOrgClassesStatusData = ({ archived = false, createdDate = '', deletedDate = '', name = '' }, content) => {
  const date = moment(!archived ? createdDate : getDeletedDate(deletedDate, name)).format('D MMMM YYYY');
  if (!archived) {
    return {
      icon: GLYPHS.ICON_CHECK_CIRCLE,
      text: content.org_classes_created_on_text,
      date,
      iconColour: null
    };
  }

  return {
    icon: STATUS_ICONS[StatusType.USER_REMOVED],
    text: content.removed_from_organization_on,
    date,
    iconColour: null
  };
};

export default getOrgClassesStatusData;
