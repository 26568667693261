import {
  DEFAULT_OPTIONS_FILTER_STAFF_AND_STUDENTS_ORGANIZATION,
  MAX_PEOPLE,
  INSTANCE_ROLES
} from '../globals/hubConstants';
import userRoles from '../globals/userRoles';

export default function initialiseDefaultSearch(page, initialiseSearch, orgId) {
  const sort = 'lastName:asc';
  const roles =
    page === INSTANCE_ROLES.ORG_STAFF
      ? [userRoles.TEACHER, userRoles.ORG_ADMIN, userRoles.TEACHER_ADMIN]
      : [userRoles.LEARNER];

  initialiseSearch(
    page,
    true,
    {
      active: DEFAULT_OPTIONS_FILTER_STAFF_AND_STUDENTS_ORGANIZATION.active,
      archived: DEFAULT_OPTIONS_FILTER_STAFF_AND_STUDENTS_ORGANIZATION.archived,
      invited: DEFAULT_OPTIONS_FILTER_STAFF_AND_STUDENTS_ORGANIZATION.invited,
      roles,
      isOrgStaffTab: page === INSTANCE_ROLES.ORG_STAFF,
      isOrgStudentTab: page !== INSTANCE_ROLES.ORG_STAFF,
      orgId,
      hubPlatform: true
    },
    MAX_PEOPLE,
    sort
  );
}
