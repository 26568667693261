import { takeLatest } from 'redux-saga/effects';
import {
  GET_CPT_CONTENT_REQUEST,
  GET_PLAYER_LAUNCH_REQUEST,
  GET_XAPI_LAUNCH_TOKEN_REQUEST,
  GET_DICTIONARY_DATA_REQUEST,
  GET_COURSE_CONTENTS_REQUEST,
  GET_EREADER_CONTENT_REQUEST
} from '../../../../actionTypes';
import getCptContent from './getCptContent';
import getEreaderContent from '../eReader/getReaderContent';
import getPlayerLaunch from './getPlayerLaunch';
import getXApiLaunchToken from './getXApiLaunchToken';
import getDictionaryData from './getDictionaryData';
import getCourseContents from './getCourseContents';

function* root() {
  yield takeLatest(GET_CPT_CONTENT_REQUEST, ({ payload }) => getCptContent(payload));
  yield takeLatest(GET_PLAYER_LAUNCH_REQUEST, ({ payload }) => getPlayerLaunch(payload));
  yield takeLatest(GET_XAPI_LAUNCH_TOKEN_REQUEST, ({ payload }) => getXApiLaunchToken(payload));
  yield takeLatest(GET_DICTIONARY_DATA_REQUEST, ({ payload }) => getDictionaryData(payload));
  yield takeLatest(GET_COURSE_CONTENTS_REQUEST, ({ payload }) => getCourseContents(payload));
  yield takeLatest(GET_EREADER_CONTENT_REQUEST, ({ payload }) => getEreaderContent(payload));
}

export default root;
