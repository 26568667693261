import React from 'react';
import PropTypes from 'prop-types';
// Services
import getDropdownItems from '../../../components/HubDropdownMenu/Services/getDropdownItems';
// Utils
// Styles
import styles from './Tools.scss';
// Components
import breakpoints from '../../../globals/breakpoints';
import SVGIcon, { GLYPHS } from '../../../components/SVGIcon/SVGIcon';
import HubDropdownMenu from '../../../components/HubDropdownMenu/HubDropdownMenu';

function Tools({
  ids,
  items,
  breakpoint,
  hubContent,
  actions: { editDeployment, archiveDeployment },
  userRole,
  dropdownTop
}) {
  return (
    <ul>
      {items.length
        ? items.map((item, index) => {
            const { displayName = '', toolId: id } = item.tool;

            const dropdownActions = {
              editDeployment: e => {
                editDeployment(e, item);
              },
              archiveDeployment: e => {
                archiveDeployment(e, item);
              }
            };

            return (
              <li key={id} className={`${styles.toolsContainer}`} role="none">
                <div className={styles.toolRow}>
                  <button type="button" className={styles.leftContainer} onClick={e => editDeployment(e, item)}>
                    <div className={styles.toolInfoDot}>
                      <SVGIcon glyph={GLYPHS.ICON_INTEGRATION} />
                    </div>
                    <div className={styles.classInfoContainer}>
                      <span>{displayName}</span>
                    </div>
                  </button>

                  <div className={styles.statusContainer}>
                    {breakpoint === breakpoints.XXS && (
                      <div className={styles.dropdownContainer}>
                        <HubDropdownMenu
                          dropdownData={getDropdownItems('lmsConfigTools', hubContent, [], dropdownActions, userRole)}
                          parentId={id}
                          customClassname={styles.dropDownMenu}
                          dropdownTop={
                            /** If total results over 1, last item dropdown render on top */
                            dropdownTop && ids.length >= 2 && (index === ids.length - 1 || index === ids.length - 2)
                          }
                        />
                      </div>
                    )}
                  </div>

                  <div className={styles.rightContainer}>
                    {breakpoint !== breakpoints.XXS && (
                      <HubDropdownMenu
                        dropdownData={getDropdownItems('lmsConfigTools', hubContent, [], dropdownActions, userRole)}
                        parentId={id}
                        customClassname={styles.dropDownMenu}
                        dropdownTop={
                          /** If total results over 1, last item dropdown render on top */
                          dropdownTop && ids.length >= 2 && (index === ids.length - 1 || index === ids.length - 2)
                        }
                      />
                    )}
                  </div>
                </div>
              </li>
            );
          })
        : null}
    </ul>
  );
}

Tools.defaultProps = {
  ids: [],
  items: [],
  hubContent: {}
};

Tools.propTypes = {
  ids: PropTypes.array,
  items: PropTypes.array,
  hubContent: PropTypes.object,
  breakpoint: PropTypes.string.isRequired,
  actions: PropTypes.object.isRequired,
  userRole: PropTypes.string.isRequired,
  dropdownTop: PropTypes.bool
};

export default Tools;
