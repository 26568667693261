import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get, remove, clone, pick } from 'lodash';
import moment from 'moment';
import { Helmet } from 'react-helmet';
// Redux
import { connect } from 'react-redux';
import Heading from '@oup/shared-front-end/src/components/Heading';
import {
  toggleDetailsPopupWithId,
  bypassProductSelection
} from '../../../redux/reducers/assignLearningMaterial.reducer';
import { openRedeemModal, toggleMaterialsToClassModal, openDownloadProductModal } from '../../../redux/actions/hubUi';
import { setMaterialToRemove } from '../../../redux/reducers/removeLearningMaterial.reducer';
import { setProductFinderData } from '../../../redux/actions/productFinderActions';
import { productFinderFormStates } from '../../../redux/reducers/productFinder.reducer';
// Services
import userRoles, { roleIsAtLeast } from '../../../globals/userRoles';
import getClassworkLicencesHeaderItems from '../../HubDashboardLayout/Services/getClassworkLicencesHeaderItems';
import reduceLearningAssignmentsToProductDetails from '../../HubDashboardLayout/Services/reduceLearningAssignmentsToProductDetails';
import filterAssignmentIds from './services/filterAssignmentIds';
import { initialiseInstance, setFilter, setSort } from '../../../redux/reducers/data/search.reducer';
import checkAllUsersHaveLicenses from '../../../utils/licenses/checkAllUsersHaveLicenses';
// Constants
import colors from '../../../globals/colors';
import {
  HubLayoutConstants,
  HubIllustrationConstants,
  productInformationContext,
  HubIllustrationAltText
} from '../../../globals/hubConstants';
import breakpoints from '../../../globals/breakpoints';
import { LICENCE_CONSTANTS } from '../../../globals/appConstants';
// Style
import styles from './Classwork.scss';
// Components
import SVGIcon, { GLYPHS } from '../../../components/SVGIcon/SVGIcon';
import ResourceFormHidden from '../../HubProductLayout/Resources/ResourceFormHidden';
import HubProductErrorModal from '../../HubProductLayout/HubProductErrorModal/HubProductErrorModal';
import SubSectionSkeletonLoader from '../../../components/SkeletonLoader/SubSectionSkeletonLoader';
import withHubList from '../../HubListPage/withHubList';
import LicenceListItem from '../../../components/ListItems/LicenceListItem/LicenceListItem';
import HubFilterBar from '../../../components/HubFilterBar/HubFilterBar';
import DataRefresher from '../../../components/DataRefresher/DataRefresher';
import { classworkFilterOptions, classworkSortOptions } from '../../../globals/hubSearchFilter';
import { featureIsEnabled } from '../../../globals/envSettings';
import { loadCourses } from '../../../redux/actions/hubCourses';
import { isLtiMode } from '../../../utils/platform';

function Classwork({
  orgId,
  classroomId,
  userRole,
  userId,
  classStudentsId,
  classTeachersId,
  classAssignments,
  classAssignmentsIds,
  isClassAssignmentsLoading,
  profileAssignments,
  profileAssignmentIds,
  isProfileAssignmentsLoading,
  hubContent,
  isError,
  downloadType,
  hubDownloadRemoveErrorModal,
  assignmentId,
  getAssignmentsActions,
  processedUserRole,
  classTitle,
  toggleMaterialsToClassModalAction,
  openDownloadProductModalAction,
  breakpoint,
  toggleDetailsPopupWithIdAction,
  openRedeemModalAction,
  initialiseAssignLicence,
  classroomData,
  classworkPageActions: { handleRemoveMaterialsFromClass = null, manageProductLocks = null },
  setFilterAction,
  profileFilters,
  classFilters,
  setSortAction,
  classSort,
  profileSort,
  setMaterialToRemoveAction,
  isTeacherAssignedToClass,
  initialiseSearch,
  setProductFinderDataAction,
  products,
  loadCourseData,
  currentOrganisationLti
}) {
  const [assignmentState, setAssignmentState] = useState(
    roleIsAtLeast(userRoles.TEACHER_ADMIN, userRole) ? 'manage' : 'launch'
  );

  const EnhancedHubListPage = withHubList(LicenceListItem);

  const classPageTitle = `${classTitle} | ${hubContent.classwork}`;

  const { studentIdList = [], teacherIdList = [] } = classroomData[classroomId] || {};

  const showAssignedTeacherData = teacherIdList.includes(userId);

  const totalUsers = [...studentIdList, ...teacherIdList].length;

  const {
    LICENCES_CONTEXT: { CLASSWORK_MANAGE, CLASSWORK },
    CLASSWORK_PANEL_TYPES: { REMOVE_MATERIALS_FROM_CLASS }
  } = HubLayoutConstants;

  const handleAssignmentState = state => {
    setAssignmentState(state);
  };

  const [_classFilters, setClassFilters] = useState({
    haveLicenceAll: true,
    haveLicenceSome: true,
    haveLicenceNone: true
  });

  const getMaxDate = ([...args]) => {
    if (args.includes('Never')) return 'Never';

    const filteredDates = args
      .filter(date => date && Date.parse(date) && moment(date).isValid())
      .map(date => moment(date));

    const maxDate = filteredDates.length ? moment.max(filteredDates) : '';
    return maxDate;
  };

  const unpackCollections = assignmentIds => {
    const assignmentIdsWithLinkedProducts = [];
    let assignmentsWithLinkedProducts = {};

    // eslint-disable-next-line no-unused-vars
    const selfAssignedProducts = Object.entries(profileAssignments).reduce((prev, [_, obj]) => {
      if (!obj.isSelfAssignedProduct) return prev;
      if (!obj.linkedProductDetails) return prev;
      if (!obj.licenceDetails.redeemed) return prev;

      const data = Object.entries(obj.linkedProductDetails).reduce(
        (prevLP, [currLPid, currLP]) =>
          //
          ({
            ...prevLP,
            ...{
              [currLPid]: {
                startDate: obj.startDate,
                endDate: obj.endDate,
                licenceDetails: obj.licenceDetails,
                productDetails: currLP
              }
            }
          }),
        {}
      );

      return { ...prev, ...data };
    }, {});

    assignmentIds.forEach(id => {
      const assignment = selfAssignedProducts[id] || get(profileAssignments, [id], {});
      const hasLicence = !!(
        assignment.licenceDetails ||
        assignment.licenceNotStartedDetails ||
        get(assignment, ['hasLicence'])
      );

      const licenceNotStartedDetails = !!assignment.licenceNotStartedDetails;

      let expiryDate;
      if (hasLicence) {
        if (
          licenceNotStartedDetails &&
          assignment.licenceNotStartedDetails?.licenceType === LICENCE_CONSTANTS.LICENCE_TYPE.ROLLING
        ) {
          expiryDate = null;
        } else {
          expiryDate = get(assignment, ['endDate'], 'Never');
        }
      } else {
        expiryDate = null;
      }

      // If the user has 2 licences for the same product, then display the latest expiryDate
      const existingExpiryDate = get(assignmentsWithLinkedProducts, [id, 'productDetails', 'expiryDate']);
      const maxExpiryDate = existingExpiryDate ? getMaxDate([expiryDate, existingExpiryDate]) : expiryDate;

      assignmentIdsWithLinkedProducts.push(id);
      assignmentsWithLinkedProducts = {
        ...assignmentsWithLinkedProducts,
        [id]: {
          productDetails: { ...assignment.productDetails },
          // linked products list
          linkedProductDetails: get(assignment, ['linkedProductDetails'], undefined),
          hasLicence,
          expiryDate: maxExpiryDate,
          licenceNotStartedDetails
        }
      };

      if (licenceNotStartedDetails) {
        assignmentsWithLinkedProducts[id] = {
          ...assignmentsWithLinkedProducts[id],
          licenceNotStartedDetails: assignment.licenceNotStartedDetails
        };
      }
    });

    // Remove duplicates
    const uniqueAssignmentIds = assignmentIdsWithLinkedProducts.filter(
      (productId, index, productIdsArray) => productIdsArray.indexOf(productId) === index
    );

    return [uniqueAssignmentIds, assignmentsWithLinkedProducts];
  };

  const getProfileAssignments = classId => {
    // Filter assignment ids by current class
    const filteredAssignmentIds = profileAssignmentIds.filter(id => {
      const learningAssignments = get(profileAssignments[id], ['learningAssignments'], [])
        .map(item => get(item, ['groupDetails']))
        .filter(group => group.groupId === classId && group.type === 'CLASS' && group.parentId === orgId);
      return learningAssignments.length;
    });

    // Unpack collections
    const assignmentsWithCollections = unpackCollections(filteredAssignmentIds);
    return assignmentsWithCollections;
  };

  const handleRemoveClassMaterial = productIds => {
    const assignments = productIds.map(id => ({
      ...pick(classAssignments[id], 'assignmentId'),
      productId: id,
      groupId: classroomId
    }));
    setMaterialToRemoveAction(assignments);
    handleRemoveMaterialsFromClass(REMOVE_MATERIALS_FROM_CLASS);
  };

  const isAtLeastTeacher = roleIsAtLeast(userRoles.TEACHER, userRole);
  const [myProfileAssignmentIds, myProfileAssignments] = getProfileAssignments(classroomId) || [];
  const assignments = isAtLeastTeacher && assignmentState === 'manage' ? classAssignments : myProfileAssignments;
  const assignmentIdsAll =
    isAtLeastTeacher && assignmentState === 'manage' ? classAssignmentsIds : myProfileAssignmentIds;
  const assignmentIds = clone(assignmentIdsAll);

  // Enhance assignemnts with filter data
  if (assignmentState === 'manage') {
    const filteredAssignments = assignmentIds
      .map(id => {
        const assignment = get(assignments, [id], {});

        const allUsersWithoutLicence = get(assignment, 'userWithoutLicence', []);
        let usersWithoutLicenceCount = allUsersWithoutLicence.length;
        let targetedUsers = totalUsers;

        if (assignment.productDetails.target_usertype === HubLayoutConstants.TARGET_USERTYPE.STUDENT) {
          usersWithoutLicenceCount = studentIdList.filter(studentId => allUsersWithoutLicence.includes(studentId))
            .length;
          targetedUsers = studentIdList.length;
        } else if (assignment.productDetails.target_usertype === HubLayoutConstants.TARGET_USERTYPE.TEACHER) {
          usersWithoutLicenceCount = teacherIdList.filter(teacherId => allUsersWithoutLicence.includes(teacherId))
            .length;
          targetedUsers = teacherIdList.length;
        }

        if (targetedUsers && !usersWithoutLicenceCount) {
          assignment.strFilterActive = 'haveLicenceAll';
        } else if (usersWithoutLicenceCount < targetedUsers) {
          assignment.strFilterActive = 'haveLicenceSome';
        } else {
          assignment.strFilterActive = 'haveLicenceNone';
        }
        assignment.classAssignmentId = id;
        return assignment;
      })
      .filter(
        assignment =>
          _classFilters[assignment.strFilterActive] ||
          (classFilters.archived && assignment.archiveStatus === 'ARCHIVED')
      )
      .reduce(
        (_assignments, currentAssignment) => ({
          ..._assignments,
          [currentAssignment.classAssignmentId]: currentAssignment
        }),
        {}
      );

    if (!(!classFilters.archived && Object.keys(_classFilters).filter(key => _classFilters[key]).length === 0))
      remove(assignmentIds, id => !get(filteredAssignments, id, null));
    else if (classFilters.active) setFilterAction('classAssignments', 'active', false);
  }
  const areAssignmentsLoading =
    isAtLeastTeacher && assignmentState === 'manage' ? isClassAssignmentsLoading : isProfileAssignmentsLoading;
  const learningAssignments = reduceLearningAssignmentsToProductDetails(assignments);

  let allAssignmentIdsList = isAtLeastTeacher ? [assignmentIds] : [myProfileAssignmentIds];
  if (assignmentState === 'manage') {
    const {
      filteredAllAssignmentIds = [],
      filteredStudentAssignmentIds = [],
      filteredTeacherAssignmentIds = []
    } = filterAssignmentIds(assignmentIds, learningAssignments);

    allAssignmentIdsList = [filteredAllAssignmentIds, filteredStudentAssignmentIds, filteredTeacherAssignmentIds];
  }

  const {
    SINGLE_CLASS: {
      emptyStateClassworkTitle = '',
      emptyStateNgiClassworkTitle = '',
      emptyStateClassworkSubtitle = '',
      showClassworkEmptyStateButton = false
    } = {}
  } = processedUserRole;

  // VST specific data
  const hasVstProducts = assignmentIds.some(prodId => {
    let result = false;
    const linkedProds = get(assignments, [prodId, 'linkedProductDetails'], {});
    // Check if any of the linked products has platform = VST
    if (linkedProds && Object.keys(linkedProds).length) {
      result = Object.keys(linkedProds).some(linkedId => get(linkedProds, [linkedId, 'platform']) === 'VST');
    }
    if (!result) {
      // Check if any of the assignments has platform = VST
      result = get(assignments, [prodId, 'productDetails', 'platform']) === 'VST';
    }
    return result;
  });

  const externalUrl = get(assignments, [assignmentId, 'productDetails', 'externalUrl']);

  const licencesContext = assignmentState === 'launch' ? CLASSWORK : CLASSWORK_MANAGE;

  const [filtersEmptyState, setFiltersEmptyState] = useState(false);

  const toggleMaterialsToClassModalClass = () => {
    setProductFinderDataAction({
      formState: productFinderFormStates.SEARCHING,
      classId: classroomId
    });
    toggleMaterialsToClassModalAction('CLASS');
  };

  const getAssignmentsForUser = () => {
    const targetUsertype =
      userRole === userRoles.LEARNER
        ? HubLayoutConstants.TARGET_USERTYPE.STUDENT
        : HubLayoutConstants.TARGET_USERTYPE.TEACHER;
    return initialiseSearch(
      'profileAssignments',
      false,
      {
        userId,
        targetUsertype,
        searchAssignments: true,
        active: true,
        expired: true,
        noLicence: true,
        expiring: true,
        notStarted: true
      },
      undefined,
      'name:asc'
    );
  };
  const getAssignmentsForClass = () =>
    initialiseSearch(
      'classAssignments',
      false,
      {
        classId: classroomId,
        orgId,
        searchAssignments: true,
        active: true
      },
      undefined,
      'name:asc'
    );

  let emptyStateData =
    assignmentState === 'launch' &&
    !myProfileAssignmentIds.length &&
    classAssignmentsIds.length &&
    isTeacherAssignedToClass
      ? {
          iconSrc: HubIllustrationConstants.INFORMATION,
          title: hubContent.teacher_class_material_classwork_empty_state_title,
          subtitle: hubContent.teacher_class_material_classwork_empty_state_subtitle,
          button: null
        }
      : {
          iconSrc: HubIllustrationConstants.DIGITAL_LIBRARY,
          title: (isLtiMode() || currentOrganisationLti
            ? emptyStateNgiClassworkTitle
            : emptyStateClassworkTitle
          ).replace('{className}', classTitle),
          subtitle: isLtiMode() || currentOrganisationLti ? '' : emptyStateClassworkSubtitle,
          customClassName: 'userTilesEmptyState',
          button:
            isLtiMode() || currentOrganisationLti || !showClassworkEmptyStateButton
              ? null
              : {
                  text: hubContent.add_course_materials,
                  icon: GLYPHS.ICON_PLUS,
                  action: () => toggleMaterialsToClassModalClass()
                }
        };

  if (filtersEmptyState) {
    emptyStateData = {
      iconSrc: HubIllustrationConstants.SEARCH,
      iconAlt: HubIllustrationAltText.SEARCH,
      title: hubContent.class_page_filters_empty_state_title,
      subtitle: hubContent.class_page_filters_empty_state_subtitle,
      customClassName: 'classPageFilteredEmptyState',
      button: {
        text: `${hubContent.class_page_filters_reset}`,
        action: () => {
          if (licencesContext === CLASSWORK_MANAGE) {
            getAssignmentsActions.classAssignments();
            setClassFilters({
              haveLicenceAll: true,
              haveLicenceSome: true,
              haveLicenceNone: true
            });
          } else getAssignmentsActions.profileAssignments();
          setFiltersEmptyState(false);
        },
        icon: GLYPHS.ICON_REFRESH
      },
      buttonAdd: {
        isPrimaryButton: true,
        text: hubContent.add_course_material,
        icon: GLYPHS.ICON_PLUS,
        action: () => toggleMaterialsToClassModalClass()
      }
    };
  }

  const classworkHeaderItemOptions = {
    showAssignedTeacherData,
    userRole,
    breakpoint,
    assignmentState
  };

  const refreshDataHandler = () => {
    if (roleIsAtLeast(userRoles.TEACHER, userRole)) {
      getAssignmentsForClass();
    }
    loadCourseData();
    getAssignmentsForUser();
  };

  const dropdownActions = {
    toggleDetails: toggleDetailsPopupWithIdAction,
    toggleMaterialsToClassModal: id => {
      const {
        [id]: { userWithoutLicence = [], assignmentId: classAssignmentId = '' }
      } = classAssignments;
      initialiseAssignLicence('CLASS_RENEW', classroomId, userWithoutLicence, id, classAssignmentId);

      setProductFinderDataAction({
        formState: productFinderFormStates.REVIEW_LICENCES,
        product: { ...products[id], productid: id },
        existingAssignmentId: classAssignmentId,
        classId: classroomId,
        selectedProducts: [{ ...products[id], productid: id }]
      });
      toggleMaterialsToClassModalAction('CLASS_RENEW');
    },
    removeClassMaterial: handleRemoveClassMaterial,
    manageProductLocks,
    openDownloadProductModal: () => {
      openDownloadProductModalAction();
    }
  };

  const materialActions = {
    openRedeemModalAction
  };

  const classworkPageActions = {
    addClassworkMaterial: toggleMaterialsToClassModalClass
  };

  const showSuccessText = checkAllUsersHaveLicenses(classAssignments, classStudentsId, classTeachersId);

  const btnIdPrefix = `classClassworkSearch-${licencesContext === CLASSWORK_MANAGE ? 'class' : 'profile'}`;
  const filterButtonText =
    licencesContext === CLASSWORK_MANAGE ? hubContent.class_licence_button : hubContent.licence_button;

  const setSortActionClasswork = sort =>
    licencesContext === CLASSWORK_MANAGE
      ? setSortAction('classAssignments', sort[0])
      : setSortAction('profileAssignments', sort[0]);
  const sortValue = licencesContext === CLASSWORK_MANAGE ? classSort : profileSort;

  const setFilterActionClasswork = (valueName, value) => {
    if (licencesContext === CLASSWORK_MANAGE) {
      setFilterAction('classAssignments', valueName, value);
    } else setFilterAction('profileAssignments', valueName, value);
    setFiltersEmptyState(true);
  };
  const setFilterActionClassworkUI = (valueName, value) => {
    setClassFilters({ ..._classFilters, [valueName]: value });
    setFiltersEmptyState(true);
    if (!classFilters.active) setFilterAction('classAssignments', 'active', true);
  };

  const filterOptions =
    licencesContext === CLASSWORK_MANAGE
      ? classworkFilterOptions(
          btnIdPrefix,
          [_classFilters.haveLicenceAll, _classFilters.haveLicenceSome, _classFilters.haveLicenceNone],
          setFilterActionClassworkUI,
          hubContent,
          true
        ).concat(classworkFilterOptions(btnIdPrefix, [classFilters.archived], setFilterActionClasswork, hubContent))
      : classworkFilterOptions(
          btnIdPrefix,
          [
            profileFilters.notStarted,
            profileFilters.active,
            profileFilters.expiring,
            profileFilters.expired,
            profileFilters.noLicence
          ],
          setFilterActionClasswork,
          hubContent
        );
  const showHeading = featureIsEnabled('navigation-changes');
  const headingArgs = {
    text: hubContent.course_materials,
    size: 'small'
  };
  return (
    <div className={styles.classwork}>
      <Helmet title={classPageTitle} />
      {showHeading && <Heading {...headingArgs} />}
      <DataRefresher loading={areAssignmentsLoading} refreshData={refreshDataHandler} noSidePadding={showHeading} />
      <div>
        <HubFilterBar
          key={sortValue}
          idPrefix={btnIdPrefix}
          filterButtonText={breakpoint !== breakpoints.XXS ? filterButtonText : hubContent.filter_button}
          overlayLabelText={filterButtonText}
          breakpoint={breakpoint}
          filterOptions={filterOptions}
          sortOnChange={setSortActionClasswork}
          sortOptions={classworkSortOptions(btnIdPrefix, sortValue, hubContent)}
          ariaControls="searchResults"
          sortValue={sortValue}
        />
      </div>
      {areAssignmentsLoading ? (
        <SubSectionSkeletonLoader
          tabName=""
          panelName=""
          speed={2}
          foregroundColor={colors.COLOR_GREY_DISABLED}
          backgroundColor={colors.COLOR_WHITE}
        />
      ) : (
        <div>
          {hasVstProducts && <ResourceFormHidden />}
          {showAssignedTeacherData && !!Object.entries(classAssignments).length && (
            <div>
              <div className={styles.classworkButtons}>
                <button
                  type="button"
                  className={assignmentState === 'launch' ? styles.active : ''}
                  onClick={() => handleAssignmentState('launch')}
                >
                  {hubContent.classwork_launch_class_assignment_text}
                </button>
                <button
                  type="button"
                  className={assignmentState === 'manage' ? styles.active : ''}
                  onClick={() => handleAssignmentState('manage')}
                >
                  {!showSuccessText && <SVGIcon glyph={GLYPHS.ICON_WARNING_CIRCLE} />}
                  {hubContent.classwork_manage_class_assignment_text}
                </button>
              </div>
            </div>
          )}
          <EnhancedHubListPage
            lists={allAssignmentIdsList}
            headerItems={getClassworkLicencesHeaderItems(hubContent, classworkPageActions, classworkHeaderItemOptions)}
            currentUsersList={learningAssignments}
            userAssignments={assignments}
            classStudentsId={classStudentsId}
            classTeachersId={classTeachersId}
            breakpoint={breakpoint}
            hubContent={hubContent}
            userRole={userRole}
            hasHeaderButtonAccess={!isLtiMode() && isAtLeastTeacher}
            showCheckboxHeader={false}
            dropdownActions={dropdownActions}
            materialActions={materialActions}
            emptyStateData={emptyStateData}
            totalResults={assignmentIds.length}
            licencesContext={licencesContext}
            isTeacherAssignedToClass={showAssignedTeacherData}
            dropdownItemsType="classworkLicences"
            customClassName="textContainerClasswork"
            totalUsers={totalUsers}
            classroomId={classroomId}
            isUserProfilePage={false}
            currentOrganisationLti={currentOrganisationLti}
            productInformationContext={productInformationContext.CLASS_MATERIALS}
          />
        </div>
      )}

      <HubProductErrorModal
        isError={isError}
        downloadType={downloadType}
        hubContent={hubContent}
        closeModal={hubDownloadRemoveErrorModal}
        title={hubContent.product_modal_error_header}
        subTitle={hubContent.product_modal_error_subtitle}
        externalUrl={externalUrl || null}
      />
    </div>
  );
}

export default connect(
  ({
    identity: { role: userRole, userId = '', currentOrganisationLti = false },
    search: {
      classAssignments: {
        data: classAssignments = {},
        ids: classAssignmentsIds = [],
        loading: isClassAssignmentsLoading = false,
        sort: classSort = 'name:asc',
        filters: classFilters = {}
      } = {},
      profileAssignments: {
        data: profileAssignments = {},
        ids: profileAssignmentIds = [],
        loading: isProfileAssignmentsLoading = false,
        sort: profileSort = 'name:asc',
        filters: profileFilters = {}
      } = {},
      classStudents: { ids: classStudentsId = [] } = {},
      classTeachers: { ids: classTeachersId = [] } = {}
    } = {},
    products: { data: products = {} } = {},
    classrooms: { data: classroomData = {} } = {}
  }) => ({
    userId,
    userRole,
    currentOrganisationLti,
    classStudentsId,
    classTeachersId,
    classAssignments,
    classAssignmentsIds,
    isClassAssignmentsLoading,
    profileAssignments,
    profileAssignmentIds,
    isProfileAssignmentsLoading,
    classroomData,
    classSort,
    classFilters,
    profileSort,
    profileFilters,
    products
  }),
  {
    toggleDetailsPopupWithIdAction: toggleDetailsPopupWithId,
    setProductFinderDataAction: setProductFinderData,
    openRedeemModalAction: openRedeemModal,
    toggleMaterialsToClassModalAction: toggleMaterialsToClassModal,
    openDownloadProductModalAction: openDownloadProductModal,
    initialiseAssignLicence: bypassProductSelection,
    setMaterialToRemoveAction: setMaterialToRemove,
    setFilterAction: setFilter,
    setSortAction: setSort,
    initialiseSearch: initialiseInstance,
    loadCourseData: loadCourses
  }
)(Classwork);

Classwork.propTypes = {
  orgId: PropTypes.string.isRequired,
  userRole: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  classroomId: PropTypes.string.isRequired,
  classStudentsId: PropTypes.array.isRequired,
  classTeachersId: PropTypes.array.isRequired,
  classAssignments: PropTypes.object.isRequired,
  classAssignmentsIds: PropTypes.array.isRequired,
  isClassAssignmentsLoading: PropTypes.bool.isRequired,
  profileAssignments: PropTypes.object.isRequired,
  profileAssignmentIds: PropTypes.array.isRequired,
  isProfileAssignmentsLoading: PropTypes.bool.isRequired,
  assignmentId: PropTypes.string,
  classSort: PropTypes.string,
  profileSort: PropTypes.string,
  isError: PropTypes.bool.isRequired,
  hubDownloadRemoveErrorModal: PropTypes.func,
  downloadType: PropTypes.string.isRequired,
  processedUserRole: PropTypes.object.isRequired,
  hubContent: PropTypes.object,
  getAssignmentsActions: PropTypes.object,
  classTitle: PropTypes.string,
  toggleMaterialsToClassModalAction: PropTypes.func.isRequired,
  openDownloadProductModalAction: PropTypes.func.isRequired,
  breakpoint: PropTypes.string.isRequired,
  toggleDetailsPopupWithIdAction: PropTypes.func.isRequired,
  openRedeemModalAction: PropTypes.func.isRequired,
  initialiseAssignLicence: PropTypes.func.isRequired,
  setFilterAction: PropTypes.func.isRequired,
  setSortAction: PropTypes.func.isRequired,
  classroomData: PropTypes.object.isRequired,
  classFilters: PropTypes.object,
  profileFilters: PropTypes.object,
  classworkPageActions: PropTypes.object.isRequired,
  setMaterialToRemoveAction: PropTypes.func.isRequired,
  isTeacherAssignedToClass: PropTypes.bool.isRequired,
  initialiseSearch: PropTypes.func,
  setProductFinderDataAction: PropTypes.func,
  products: PropTypes.object,
  loadCourseData: PropTypes.func,
  currentOrganisationLti: PropTypes.bool
};
