import PropTypes from 'prop-types';
import React from 'react';
import UserListing, { ListingTypes } from '../UserListing/UserListing';

function StudentsWithoutLicenceGroup({ title, items, withoutLicence }) {
  return (
    <UserListing
      className="gin-top2"
      heading={title}
      listingType={ListingTypes.PANEL}
      items={items}
      licenceStatusItem={withoutLicence}
    />
  );
}

StudentsWithoutLicenceGroup.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.object.isRequired,
  withoutLicence: PropTypes.bool
};

export default StudentsWithoutLicenceGroup;
