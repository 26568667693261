const coursesWithInteractiveAssessments = [
  'LIFE-VISION-ELEMENTARY-PL',
  'LIFE-VISION-PRE-INTERMEDIATE-PL',
  'LIFE-VISION-INTERMEDIATE-PL',
  'LIFE-VISION-INTERMEDIATE-PLUS-PL',
  'PROD-TEST-COURSE-PUBLISH-TO-PROD-ONLY',
  'HUB-UAT-FRONTLIST-COURSE-V13'
];

const userHasInteractiveAssessment = courses => {
  if (!courses) return false;

  const invalidListType = typeof courses !== 'object';
  const userHasNoCourses = !Object.keys(courses).length;

  if (invalidListType || userHasNoCourses) return false;

  const userCourses = Object.values(courses);
  return userCourses.some(({ courseCode }) => coursesWithInteractiveAssessments.includes(courseCode));
};

export default userHasInteractiveAssessment;
