import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { compose } from 'recompose';
import PanelNavigationLink from '../../../../../../components/PanelNavigationLink/PanelNavigationLink';
import PopoutNavFooter from '../../../../../../components/PopoutNavFooter/PopoutNavFooter.js';
import PopoutPanelIconHeading from '../../../../../../components/PopoutPanelIconHeading/PopoutPanelIconHeading';
import ScrollContainer from '../../../../../../components/ScrollContainer/ScrollContainer';

class Error extends Component {
  static handleSubmit = event => {
    event.preventDefault();
  };

  render() {
    const { closePanel, hubContent, mode, children, hasTools, error } = this.props;

    const ui = {
      ADD: {
        title: hasTools
          ? 'There was a problem. Deployment was not added.'
          : 'There was a problem, integration details were not saved',
        subtitle: error || 'Please try again.'
      },
      EDIT: {
        title: hasTools
          ? 'There was a problem. Deployment was not saved.'
          : 'There was a problem. Details were not saved.',
        subtitle: 'Please try again'
      },
      EDIT_CONFIG: {
        title: 'The details have not been changed.'
      },
      ARCHIVE: {
        title: hasTools
          ? 'There was a problem. The deployment was not archived'
          : 'There was a problem. Tool has not been archived.',
        subtitle: 'Please try again'
      }
    }[mode];

    return (
      <form onSubmit={this.handleSubmit}>
        <ScrollContainer
          enhancedHeader
          headerContent={
            <div>
              <div className="text-right">
                <PanelNavigationLink isLhs={false} text={hubContent.close_panel_text} action={closePanel} />
              </div>
              <PopoutPanelIconHeading type="ERROR" title={ui.title} subtitle={ui.subtitle} />
            </div>
          }
          footerContent={<PopoutNavFooter nextButtonText="Try again" nextAction={closePanel} nextButtonFullWidth />}
        >
          {children}
        </ScrollContainer>
      </form>
    );
  }
}

Error.propTypes = {
  closePanel: PropTypes.func.isRequired,
  hubContent: PropTypes.object.isRequired,
  mode: PropTypes.string.isRequired,
  children: PropTypes.any,
  hasTools: PropTypes.bool.isRequired,
  error: PropTypes.string
};
export default compose()(Error);
