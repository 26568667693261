import userRoles, { roleIsAtLeast } from '../../../globals/userRoles';
import { HubLayoutConstants } from '../../../globals/hubConstants';

export const filterPeople = (ids = [], people = {}, role = '', classroomId = '') => {
  const {
    PEOPLE_STATUS: { ARCHIVED }
  } = HubLayoutConstants;

  const peopleToDisplay = ids.filter(itemId => {
    if (role === userRoles.TEACHER) {
      return roleIsAtLeast(userRoles.TEACHER, people[itemId].roleName);
    }
    return people[itemId].roleName === role;
  });

  const peopleWithCheckbox = peopleToDisplay.filter(
    itemId =>
      (people[itemId].orgArchiveStatus !== ARCHIVED &&
        people[itemId][`classArchiveStatus-${classroomId}`] !== ARCHIVED) ||
      people[itemId].isDeleted
  );

  return {
    peopleToDisplay,
    peopleWithCheckbox
  };
};

export const filterItems = (ids, itemList) =>
  ids.filter(
    id => itemList[id].orgArchiveStatus !== HubLayoutConstants.PEOPLE_STATUS.ARCHIVED || itemList[id].isDeleted
  );
