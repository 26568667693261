import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { get, has } from 'lodash';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { userRoles } from '@oup/shared-node-browser/user';
import { isPlacementCentre } from '@oup/shared-node-browser/org';
import { featureIsEnabled } from '../../globals/envSettings';

// Styles
import styles from './MyDashboard.scss';
// Redux
import {
  openRedeemModal,
  openJoinClassWithCodeModal,
  toggleArchiveClassModal,
  selectMyDashboardView,
  openOnboardingWizard,
  openSelfRegisteredWizard,
  showSelfSelectRoleModal,
  openPlacementTestOnboardingWizard
} from '../../redux/actions/hubUi';
// HOC
import withLocalizedContent from '../../language/withLocalizedContent';
import withBreakpoint from '../../decorators/withBreakpoint';
import { setClassroomsToArchive } from '../../redux/reducers/classroomArchive';
// Constants
import {
  getDashboardTabs,
  getOrgPlacementTestCenterTabs,
  HubLayoutConstants,
  HubIllustrationConstants
} from '../../globals/hubConstants';
// Services
import getClassLink from '../../structure/HubDashboardLayout/Services/getClassLink';
import processUserRole from '../../structure/HubDashboardLayout/Services/processUserRole';
import getCourseLink from '../../structure/HubDashboardLayout/Services/getCourseLink';
import { loadCourses } from '../../redux/actions/hubCourses';
// Components
import HubTabsNavigation from '../../components/HubTabsNavigation/HubTabsNavigation';
import CourseTile from '../../components/CourseTile/CourseTile';
import DataRefresher from '../../components/DataRefresher/DataRefresher';
import ClassTile from '../../components/ClassTile/ClassTile';
import TileContainer from '../../components/TileContainer/TileContainer';
import MyDashboardButton from './MyDashboardButton';
import { GLYPHS } from '../../components/SVGIcon/SVGIcon';

import getOptProductsCatalogueUrl from '../../utils/opt/getOptProductsCatalogueUrl';
import { isLtiMode } from '../../utils/platform';
import redirectToUrl from '../../utils/redirectToUrl';

function MyDashboardTeacherView({
  userRole,
  userPlatformStatistics,
  classes,
  courseIds,
  courses,
  areClassesLoading,
  areCoursesLoading,
  breakpoint,
  windowWidth,
  sideNavOpen,
  myDashboardView,
  loadCoursesData,
  userId,
  currentOrganisationId,
  organization,
  showSelfSelectRoleModalAction,
  openSelfRegisteredWizardAction,
  openOnboardingWizardAction,
  openPlacementTestOnboardingWizardAction,
  selectMyDashboardViewAction,
  openRedeemModalAction,
  openJoinClassWithCodeModalAction,
  archiveClassModalAction,
  setClassToArchiveAction,
  localizedContent: { hubGlossary: hubContent }
}) {
  const selectView = view => {
    selectMyDashboardViewAction(view);
  };
  useEffect(() => {
    selectView(myDashboardView);
  }, [myDashboardView]);

  const setClassToArchive = classId => {
    if (classId && currentOrganisationId) setClassToArchiveAction(classId, currentOrganisationId);
    archiveClassModalAction();
  };

  useEffect(() => () => setClassToArchiveAction([], currentOrganisationId), []);

  const history = useHistory();
  const getDashboardViewData = (processedUserRole = {}) => {
    const {
      CLASSES: {
        emptyStateTitle = '',
        emptyStateSubtitle = '',
        showFirstEmptyStateButton = false,
        firstButtonText = '',
        firstButtonLink = '',
        firstButtonIcon = '',
        onClickFirstButton = openOnboardingWizardAction,
        isFirstButtonPrimary = false,
        showSecondEmptyStateButton = false,
        secondButtonText = '',
        secondButtonLink = '',
        secondButtonTarget = '_self',
        isSecondButtonPrimary = false,
        showEmptyStateJoinClassWithCodeButton = false,
        joinClassWithCodeButtonText = '',
        onClickJoinClassWithCodeButton = openJoinClassWithCodeModalAction,
        hasPlaceholder = !isLtiMode() &&
          !!(userRole === userRoles.TEACHER || userRole === userRoles.TEACHER_ADMIN || userRole === userRoles.ORG_ADMIN)
      } = {},
      COMMON: { showCreateClassButton = false, showJoinClassWithCodeCommonButton = false } = {}
    } = processedUserRole;

    const classesIds = Object.keys(classes);

    const { PATH_NAMES } = HubLayoutConstants;

    return {
      myClasses: {
        isLoading: areClassesLoading,
        ids: classesIds,
        iconSrc: HubIllustrationConstants.CLASS_STUDENTS,
        title: emptyStateTitle,
        subtitle: emptyStateSubtitle,
        // this can be removed with featureIsEnabled('learner-dashboard-refresh')
        showDashboardButton:
          userRole === userRoles.USER || userRole === userRoles.LEARNER
            ? showJoinClassWithCodeCommonButton
            : showCreateClassButton,
        showFirstEmptyStateButton,
        firstButtonText,
        firstButtonLink,
        firstButtonIcon,
        onClickFirstButton,
        isFirstButtonPrimary,
        showSecondEmptyStateButton,
        secondButtonText,
        secondButtonLink,
        secondButtonTarget,
        isSecondButtonPrimary,
        showEmptyStateJoinClassWithCodeButton,
        joinClassWithCodeButtonText,
        onClickJoinClassWithCodeButton,
        items: classes,
        getLink: getClassLink,
        limitCardLink: HubLayoutConstants.PATH_NAMES.CLASSES_PATH,
        limitCardText: hubContent.show_all_classes,
        Tile: ClassTile,
        hasPlaceholder,
        setClassToArchive,
        firstButtonTestHook: 'OPEN_ONBOARDING_WIZARD_BUTTON_CREATE_A_CLASS_TILE',
        firstButtonTestHookEmptyState: 'OPEN_ONBOARDING_WIZARD_BUTTON_DASHBOARD'
      },
      myCourses: {
        isLoading: areCoursesLoading,
        ids: courseIds,
        iconSrc: HubIllustrationConstants.DIGITAL_LIBRARY,
        title: hubContent.no_courses_text,
        subtitle: hubContent.no_courses_access_code_text,
        showDashboardButton: true,
        showFirstEmptyStateButton: true,
        firstButtonText: featureIsEnabled('opt-main-features') ? hubContent.enter_a_code : hubContent.enter_a_code_text,
        firstButtonIcon,
        onClickFirstButton: openRedeemModalAction,
        isFirstButtonPrimary: true,
        getLink: getCourseLink,
        items: courses,
        limitCardLink: HubLayoutConstants.PATH_NAMES.COURSES_PATH,
        limitCardText: hubContent.show_all_courses,
        Tile: CourseTile
      },
      sessions: {
        iconSrc: HubIllustrationConstants.LEARNING,
        title: hubContent.opt_organization_homepage_empty_state_no_courses_title,
        ids: [],
        showDashboardButton: false,
        showFirstEmptyStateButton: true,
        position: 'bottom',
        firstButtonText: hubContent.organization_page_create_placement_test_session,
        firstButtonIcon: GLYPHS.ICON_PLUS,
        onClickFirstButton: openPlacementTestOnboardingWizardAction,
        isFirstButtonPrimary: true,
        showSecondEmptyStateButton: true,
        secondButtonText: hubContent.opt_organization_homepage_empty_state_no_courses_link,
        secondButtonLink: `${PATH_NAMES.ORGANIZATION_PATH}${PATH_NAMES.PLACEMENT_TESTS}`,
        secondButtonOpenInNewTab: false,
        isSecondButtonPrimary: false,
        showEmptyStateOutlineButton: true,
        outlineButtonText: hubContent.organization_page_purchase_licences,
        outlineButtonIcon: GLYPHS.ICON_SHOPPING_CART,
        onClickOutlineButton: () => {
          const placementProductsUrl = getOptProductsCatalogueUrl(organization?.customId);
          redirectToUrl(placementProductsUrl, true);
        }
      },
      emptyDashboard: {
        iconSrc: HubIllustrationConstants.DIGITAL_LIBRARY,
        title: hubContent.empty_dashboard_title,
        ids: [],
        subtitle: hubContent.empty_dashboard_subtitle,
        showDashboardButton: true,
        showFirstEmptyStateButton: true,
        showSecondEmptyStateButton: true,
        position: 'bottom',
        firstButtonText: hubContent.empty_dashboard_first_button_text,
        secondButtonText: hubContent.empty_dashboard_support_link_text,
        secondButtonLink: hubContent.no_classes_self_teacher_link,
        onClickFirstButton: () =>
          featureIsEnabled('opt-main-features')
            ? openSelfRegisteredWizardAction(true)
            : showSelfSelectRoleModalAction(true),
        isFirstButtonPrimary: true,
        isSecondButtonPrimary: false
      }
    };
  };

  const isSelfLearner = () =>
    (userRole === userRoles.USER && userRole === userRoles.USER) || userRole === userRoles.LEARNER;

  const getDashboardButtonData = classesIds => ({
    myClasses: isSelfLearner()
      ? {
          isLoading: areClassesLoading,
          ids: classesIds,
          onClick: openJoinClassWithCodeModalAction,
          text: hubContent.join_class_with_code,
          ariaLabel: hubContent.join_class_with_code_screen_reader
        }
      : {
          isLoading: areClassesLoading,
          ids: classesIds,
          onClick: openOnboardingWizardAction,
          text: hubContent.create_class,
          ariaLabel: hubContent.opens_in_a_panel
        },
    myCourses: {
      isLoading: areCoursesLoading,
      ids: courseIds,
      onClick: openRedeemModalAction,
      text: featureIsEnabled('opt-main-features') ? hubContent.enter_a_code : hubContent.enter_a_code_text,
      ariaLabel: hubContent.opens_in_a_panel
    }
  });

  const userHasLicences =
    has(userPlatformStatistics, 'error') &&
    !userPlatformStatistics.error &&
    Object.keys(userPlatformStatistics.redeemed).length > 0;

  const processedUserRole = processUserRole(
    userRole,
    hubContent,
    featureIsEnabled('opt-main-features') && userHasLicences
  );
  const { CLASSES: { showMyDashboardTabs = false } = {} } = processedUserRole;
  const classesIds = Object.keys(classes);
  const isOrgPlacementCentre = featureIsEnabled('opt-main-features') && isPlacementCentre(organization?.orgConfig);
  const tabs = isOrgPlacementCentre
    ? getOrgPlacementTestCenterTabs(hubContent, false, courseIds.length, false, areCoursesLoading)
    : getDashboardTabs(hubContent, classesIds.length, courseIds.length, areClassesLoading, areCoursesLoading);
  const showMyCoursesTitle = !areCoursesLoading && !!courseIds.length;
  const { DASHBOARD_SESSIONS_VIEW } = HubLayoutConstants;
  const userWithNoOrgAndWithLicence =
    Object.keys(organization).length === 0 && userHasLicences && featureIsEnabled('opt-main-features');

  useEffect(() => {
    if (isOrgPlacementCentre) selectView(DASHBOARD_SESSIONS_VIEW);
  }, [isOrgPlacementCentre]);

  const tabsNavigationContent = (
    <div>
      <HubTabsNavigation>
        <div>
          <HubTabsNavigation.HubTabs tabs={tabs} selectView={selectView} view={myDashboardView} />
        </div>
        <div>
          <HubTabsNavigation.MyDashboardContent
            dashboardButtonData={getDashboardButtonData(classesIds)[myDashboardView]}
            tileContainerData={getDashboardViewData(processedUserRole)[myDashboardView]}
            hubContent={hubContent}
            breakpoint={breakpoint}
            windowWidth={windowWidth}
            sideNavOpen={sideNavOpen}
            history={history}
            userId={userId}
            userRole={userRole}
            currentOrganisationId={currentOrganisationId}
            myDashboardView={myDashboardView}
            customOrgId={organization?.customId}
          />
        </div>
        <div />
        <div />
      </HubTabsNavigation>
    </div>
  );

  const orgPlacementTestContent = userHasLicences ? (
    tabsNavigationContent
  ) : (
    <TileContainer
      data={getDashboardViewData().sessions}
      hubContent={hubContent}
      breakpoint={breakpoint}
      windowWidth={windowWidth}
      sideNavOpen={sideNavOpen}
      isLimited
      history={history}
    />
  );

  const defaultContent = (
    <div>
      {showMyDashboardTabs ? (
        tabsNavigationContent
      ) : (
        <div>
          {showMyCoursesTitle && (
            <div className={styles.dashboardFeatureFlagContent}>
              <h2>{hubContent.my_courses}</h2>
              <MyDashboardButton data={getDashboardButtonData().myCourses} />
            </div>
          )}
          {featureIsEnabled('opt-main-features') ? (
            <TileContainer
              data={getDashboardViewData().emptyDashboard}
              hubContent={hubContent}
              breakpoint={breakpoint}
              windowWidth={windowWidth}
              sideNavOpen={sideNavOpen}
              isLimited
              history={history}
            />
          ) : (
            <>
              <DataRefresher loading={areCoursesLoading} refreshData={() => loadCoursesData()} />
              <TileContainer
                data={getDashboardViewData().myCourses}
                hubContent={hubContent}
                breakpoint={breakpoint}
                windowWidth={windowWidth}
                sideNavOpen={sideNavOpen}
                isLimited
                history={history}
              />
            </>
          )}
        </div>
      )}
    </div>
  );

  return isOrgPlacementCentre || userWithNoOrgAndWithLicence ? orgPlacementTestContent : defaultContent;
}

MyDashboardTeacherView.propTypes = {
  breakpoint: PropTypes.string.isRequired,
  courses: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  openRedeemModalAction: PropTypes.func.isRequired,
  openJoinClassWithCodeModalAction: PropTypes.func.isRequired,
  selectMyDashboardViewAction: PropTypes.func.isRequired,
  areCoursesLoading: PropTypes.bool.isRequired,
  areClassesLoading: PropTypes.bool.isRequired,
  courseIds: PropTypes.array,
  windowWidth: PropTypes.number.isRequired,
  sideNavOpen: PropTypes.bool.isRequired,
  myDashboardView: PropTypes.string.isRequired,
  userId: PropTypes.string,
  userRole: PropTypes.string,
  currentOrganisationId: PropTypes.string.isRequired,
  loadCoursesData: PropTypes.func,
  organization: PropTypes.object,
  showSelfSelectRoleModalAction: PropTypes.func,
  openSelfRegisteredWizardAction: PropTypes.func,
  openPlacementTestOnboardingWizardAction: PropTypes.func,
  userPlatformStatistics: PropTypes.object,
  openOnboardingWizardAction: PropTypes.func,
  archiveClassModalAction: PropTypes.func,
  setClassToArchiveAction: PropTypes.func,
  localizedContent: PropTypes.object.isRequired
};

export default compose(
  connect(
    ({
      hubCourses: { courses = {}, loading = true, courseIds = [] } = {},
      search: { profileClasses: { currentClassList = {}, ids = [], loading: areClassesLoading = false } = {} } = {},
      identity: { userId, role: userRole, currentOrganisationId },
      hubUi: { sideNavOpen, myDashboardView },
      people: { data },
      organisations: { data: orgData },
      userPlatformStatistics
    }) => {
      // Display only active classes
      const activeClasses = Object.keys(currentClassList).reduce((res, classId) => {
        if (!currentClassList[classId].archived) {
          res[classId] = currentClassList[classId];
        }
        return res;
      }, {});
      return {
        organization: orgData[currentOrganisationId] || {},
        courses,
        courseIds,
        areCoursesLoading: loading,
        areClassesLoading,
        classes: activeClasses,
        classesIds: ids,
        sideNavOpen,
        userId,
        userRole,
        currentOrganisationId,
        orgName: get(orgData, [currentOrganisationId, 'name'], ''),
        firstName: get(data, [userId, 'firstname']),
        myDashboardView,
        userPlatformStatistics
      };
    },
    {
      openRedeemModalAction: openRedeemModal,
      openJoinClassWithCodeModalAction: openJoinClassWithCodeModal,
      selectMyDashboardViewAction: selectMyDashboardView,
      loadCoursesData: loadCourses,
      setClassToArchiveAction: setClassroomsToArchive,
      archiveClassModalAction: toggleArchiveClassModal,
      openOnboardingWizardAction: openOnboardingWizard,
      showSelfSelectRoleModalAction: showSelfSelectRoleModal,
      openSelfRegisteredWizardAction: openSelfRegisteredWizard,
      openPlacementTestOnboardingWizardAction: openPlacementTestOnboardingWizard
    }
  ),
  withLocalizedContent('hubGlossary'),
  withBreakpoint
)(MyDashboardTeacherView);
