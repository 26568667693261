import React from 'react';
import PropTypes from 'prop-types';
// Components
import PopoutPanel from '../../../components/PopoutPanel/PopoutPanel';
import EnrolUser from '../../../routes/MySchool/tabs/StaffTab/panels/EnrolUser/EnrolUser';
import AddToLibrary from '../../../routes/MySchool/tabs/LibraryTab/panels/AddToLibrary/AddToLibrary';
import UserArchivePanel from '../../../panels/UserArchivePanel';
import OrgUserEditPanel from '../../../panels/OrgUserEditPanel';
import CancelInvitationPanel from './CancelInvitation/CancelInvitationPanel';
import AssignLearningMaterial from '../../../routes/panels/AssignLearningMaterial/AssignLearningMaterial';
import RegenerateSupervisedUserSignInCardPanel from '../../../panels/RegenerateSupervisedUserSignInCardPanel';
import DeploymentToolPanel from '../../../routes/MySchool/tabs/LMSDeploymentsTab/panels/DeploymentTool/DeploymentTool';
import { featureIsEnabled } from '../../../globals/envSettings';
import ProductFinderPanel from '../../../components/ProductFinder/ProductFinderPanel';
import BulkPasswordResetPanel from '../../../panels/BulkPasswordResetPanel';
import userRoles from '../../../globals/userRoles';
import { roleIsAtLeast } from '../../../../sharedNodeBrowser/user';
import { HubLayoutConstants } from '../../../globals/hubConstants';

function HubOrgPanelContainer({
  hubContent,
  content,
  orgId = '',
  currentOrganisationName,
  selectedUserId = '',
  selectedUserType,
  panelName,
  panelContext,
  panelsActions: {
    addLicensesModalOpen,
    deploymentToolModal,
    closeAddLicensesModalAction,
    closeOrgModal,
    onCompleteModal,
    navigateArchiveOrgUser,
    toggleDeploymentToolModalAction,
    resetDeploymentToolModalAction
  },
  panelData,
  people,
  panelAccess: { canRemoveOtherUser, canManageAssignments = false }
}) {
  const {
    PEOPLE_PANEL_TYPES: {
      REGENERATE_SUPERVISED_USER_SIGN_IN_CARD,
      ADD_STUDENTS,
      ADD_STAFF,
      ASSIGN_MATERIAL,
      ARCHIVE_USERS,
      BULK_PASSWORD_RESET
    }
  } = HubLayoutConstants;

  const _getUsersByRole = (userIds = []) => {
    const usersByRole = { teacherIdList: [], studentIdList: [] };
    userIds.forEach(userId => {
      if (roleIsAtLeast(userRoles.TEACHER, people[userId]?.roleName)) {
        usersByRole.teacherIdList.push(userId);
      } else {
        usersByRole.studentIdList.push(userId);
      }
    });
    return usersByRole;
  };
  return (
    <div>
      <PopoutPanel
        id="enrolUserPopout"
        ariaLabel={hubContent.org_staff_add_staff_aria_label}
        isOpen={panelName === ADD_STUDENTS || panelName === ADD_STAFF}
      >
        <EnrolUser
          orgId={orgId}
          context={panelContext}
          closePanel={closeOrgModal}
          onComplete={() => onCompleteModal(panelName)}
        />
      </PopoutPanel>
      <PopoutPanel id="addToLibrary" ariaLabel={content.aria_label_add_licences} isOpen={addLicensesModalOpen}>
        <AddToLibrary
          closePanel={closeAddLicensesModalAction}
          onComplete={closeAddLicensesModalAction}
          orgName={currentOrganisationName}
        />
      </PopoutPanel>
      <PopoutPanel id="deploymentToolPanel" ariaLabel={content.aria_label_add_licences} isOpen={deploymentToolModal}>
        <DeploymentToolPanel
          closePanel={() => {
            toggleDeploymentToolModalAction();
            resetDeploymentToolModalAction();
          }}
          onComplete={resetDeploymentToolModalAction}
          orgName={currentOrganisationName}
          hubContent={hubContent}
        />
      </PopoutPanel>

      <PopoutPanel
        id="assignToStudentsPopout"
        ariaLabel={content.aria_label_assign_learning_material}
        isOpen={panelContext && panelName === ASSIGN_MATERIAL}
      >
        {canManageAssignments && featureIsEnabled('product-finder-refactor') ? (
          <ProductFinderPanel
            orgId={orgId}
            selectedUsers={_getUsersByRole(panelData.selectedUsers)}
            contextName={panelData.contextName}
            onClose={closeOrgModal}
            onComplete={closeOrgModal}
            // needed for polling. will be removed when polling is removed
            context={panelContext}
          />
        ) : (
          <AssignLearningMaterial
            context={panelContext}
            orgId={orgId}
            closePopoutAction={closeOrgModal}
            onComplete={closeOrgModal}
          />
        )}
      </PopoutPanel>

      <UserArchivePanel
        id="archiveUsersPopout"
        ariaLabel={content.aria_label_archive_users}
        orgId={orgId}
        userType={selectedUserType}
        open={panelName === ARCHIVE_USERS}
        onClosePanel={closeOrgModal}
        onComplete={closeOrgModal}
      />
      <OrgUserEditPanel
        orgId={orgId}
        userId={selectedUserId}
        isOpen={panelName === 'editUser'}
        closePanel={closeOrgModal}
        canRemoveUser={canRemoveOtherUser}
        archive={navigateArchiveOrgUser}
      />
      <CancelInvitationPanel
        isOpen={panelName === 'cancelInvitation'}
        closePanel={closeOrgModal}
        hubContent={hubContent}
      />
      <RegenerateSupervisedUserSignInCardPanel
        isOpen={panelName === REGENERATE_SUPERVISED_USER_SIGN_IN_CARD}
        onClose={closeOrgModal}
      />

      <BulkPasswordResetPanel
        isOpen={panelName === BULK_PASSWORD_RESET}
        content={hubContent}
        orgId={orgId}
        onClose={() => closeOrgModal(false)}
        userIds={panelData.selectedUserIds}
      />
    </div>
  );
}

HubOrgPanelContainer.propTypes = {
  hubContent: PropTypes.object.isRequired,
  content: PropTypes.object.isRequired,
  orgId: PropTypes.string.isRequired,
  panelName: PropTypes.string.isRequired,
  selectedUserId: PropTypes.string.isRequired,
  selectedUserType: PropTypes.string.isRequired,
  panelContext: PropTypes.string.isRequired,
  currentOrganisationName: PropTypes.string.isRequired,
  panelsActions: PropTypes.object.isRequired,
  panelAccess: PropTypes.object.isRequired,
  panelData: PropTypes.object,
  people: PropTypes.object
};

export default HubOrgPanelContainer;
