import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import Footer from '@oup/shared-front-end/src/components/Wizard/Footer';
import PanelHeading from '../../../../components/PanelHeading/PanelHeading';
import ScrollContainer from '../../../../components/ScrollContainer/ScrollContainer';
import { updatePlacementTestSessionRequest } from '../../../../redux/actions/placementTestOnboardingWizard';
import { setPlacementTest } from '../../../../redux/reducers/placementTestSessionCreate';
import withLocalizedContent from '../../../../language/withLocalizedContent';
import NotificationsAndReminders from '../../../../components/PlacementTestOnBoardingWizard/EmailNotificationsAndReminders/NotificationsAndReminders';
import style from '../../../../components/PlacementTestOnBoardingWizard/EmailNotificationsAndReminders/NotificationsAndReminders.scss';

function PlacementTestEditNotificationsAndReminders({
  panelSessionName,
  placementTestSessionId,
  placementTestSessionNameValue,
  updatePlacementTestSessionRequestAction,
  orgId,
  setPlacementTestAction,
  cancelNotificationsAndReminders,
  localizedContent: { placementTests: placementTestsContent, hubGlossary: hubContent }
}) {
  const [isNotificationsAndRemindersTouched, setIsNotificationsAndRemindersTouched] = useState(false);
  const [isNotificationsAndRemindersValid, setIsNotificationsAndRemindersValid] = useState(false);

  const [editedSendEmailsToStudents, setEditedSendEmailsToStudents] = useState(true);
  const [editedNotificationReminders, setEditedNotificationReminders] = useState([]);

  const isButtonDisabled = !isNotificationsAndRemindersTouched || !isNotificationsAndRemindersValid;

  return (
    <ScrollContainer
      headerContent={
        <PanelHeading
          title={placementTestsContent.placement_test_manage_notifications_and_reminders}
          subtitle={`${hubContent.for_label} <strong>${panelSessionName}</strong>`}
        />
      }
      footerContent={
        <Footer
          primaryButtonLabel={hubContent.save}
          secondaryButtonLabel={hubContent.cancel}
          primaryButtonAction={e => {
            updatePlacementTestSessionRequestAction({
              orgId,
              sessionId: placementTestSessionId,
              placementTestSessionNameValue,
              placementTest: {
                sendEmailsToStudents: editedSendEmailsToStudents,
                notificationReminders: editedNotificationReminders
              }
            });
            setPlacementTestAction({
              sendEmailsToStudents: editedSendEmailsToStudents,
              notificationReminders: editedNotificationReminders
            });
            cancelNotificationsAndReminders(e, true);
          }}
          secondaryButtonAction={cancelNotificationsAndReminders}
          primaryButtonAttributes={{
            disabled: isButtonDisabled
          }}
        />
      }
    >
      <div className={style.notificationsAndRemindersContainer}>
        <NotificationsAndReminders
          setIsTouched={setIsNotificationsAndRemindersTouched}
          setIsValid={setIsNotificationsAndRemindersValid}
          sendEmailsToStudents={editedSendEmailsToStudents}
          notificationReminders={editedNotificationReminders}
          setSendEmailsToStudents={setEditedSendEmailsToStudents}
          setNotificationReminders={setEditedNotificationReminders}
        />
      </div>
    </ScrollContainer>
  );
}

PlacementTestEditNotificationsAndReminders.propTypes = {
  panelSessionName: PropTypes.string.isRequired,
  placementTestSessionId: PropTypes.string.isRequired,
  placementTestSessionNameValue: PropTypes.string,
  updatePlacementTestSessionRequestAction: PropTypes.func.isRequired,
  orgId: PropTypes.string.isRequired,
  setPlacementTestAction: PropTypes.bool,
  cancelNotificationsAndReminders: PropTypes.func.isRequired,
  localizedContent: PropTypes.object
};

export default compose(
  withLocalizedContent('hubGlossary', 'placementTests'),
  connect(
    ({ placementTestSessionCreate, identity }) => ({
      placementTestSessionId: placementTestSessionCreate.placementTestSessionId,
      placementTestSessionNameValue: placementTestSessionCreate.placementTestSessionNameValue,
      orgId: identity.currentOrganisationId,
      placementTest: placementTestSessionCreate.placementTest
    }),
    {
      updatePlacementTestSessionRequestAction: updatePlacementTestSessionRequest,
      setPlacementTestAction: setPlacementTest
    }
  )
)(PlacementTestEditNotificationsAndReminders);
