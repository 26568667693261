import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { getIdpLoginUrl } from '../../../../redux/sagas/preRender/auth/getCredentials/redirectToIdP.saga.js';
import styles from './HubTopBanner.scss';
import breakpoints from '../../../../globals/breakpoints';

function HubTopBanner({ hubContent, homepageContent, withButton, isWelcomePage, breakpoint, customClassName }) {
  return (
    <div
      className={customClassName ? classnames(styles.sectionOneGrid, customClassName) : styles.sectionOneGrid}
      id="hero-banner"
    >
      <div className={styles.bannerContainer}>
        <div className={styles.bannerText}>
          <h1>
            {isWelcomePage && breakpoint === breakpoints.XXS ? (
              <span>{homepageContent.banner_heading_welcome_text}</span>
            ) : (
              <span>{homepageContent.banner_heading_text}</span>
            )}
          </h1>
          <p>{homepageContent.banner_body_text}</p>
          {withButton && (
            <button
              type="submit"
              id="signInSecondButton"
              onClick={async () => {
                const url = await getIdpLoginUrl(null);
                window.location.href = url;
              }}
            >
              {hubContent.heroBanner_link_text_register_or_signin}
            </button>
          )}
        </div>
      </div>

      <div className={styles.primaryImgContainer}>
        {homepageContent.banner_image && (
          <img aria-hidden="true" alt={homepageContent.banner_image_alt} src={homepageContent.banner_image} />
        )}
      </div>
    </div>
  );
}

HubTopBanner.defaultProps = {
  withButton: true,
  isWelcomePage: false
};
HubTopBanner.propTypes = {
  hubContent: PropTypes.object.isRequired,
  homepageContent: PropTypes.object.isRequired,
  withButton: PropTypes.bool,
  customClassName: PropTypes.string,
  breakpoint: PropTypes.string,
  isWelcomePage: PropTypes.bool
};

export default HubTopBanner;
