import getClassLink from '../../HubDashboardLayout/Services/getClassLink';
import getOrganizationClassLink from '../../HubDashboardLayout/Utils/getOrganizationClassLink';
import getProgressLink from '../../HubDashboardLayout/Services/getProgressLink';
import { SINGLE_CLASS_PAGES_CONTEXT } from '../../../globals/hubConstants';

const classSubMenuConstants = {
  getClassSubMenuItems: (classId, content, orgId, params, context) => [
    {
      name: content.progress,
      path:
        context === SINGLE_CLASS_PAGES_CONTEXT.CLASS_CONTEXT
          ? getProgressLink(getClassLink(classId), orgId)
          : getProgressLink(getOrganizationClassLink(classId), orgId),
      showButton: params.showProgressButton
    },
    {
      name: content.course_materials,
      path:
        context === SINGLE_CLASS_PAGES_CONTEXT.CLASS_CONTEXT
          ? `${getClassLink(classId)}/classwork`
          : `${getOrganizationClassLink(classId)}/classwork`,
      showButton: params.showClassworkButton,
      testHook: 'CLASS_SUBMENU_LEARNING_MATERIAL'
    },
    {
      name: content.assessment,
      path:
        context === SINGLE_CLASS_PAGES_CONTEXT.CLASS_CONTEXT
          ? `${getClassLink(classId)}/assessment`
          : `${getOrganizationClassLink(classId)}/assessment`,
      showButton: params.showClassAssessmentButton
    },
    {
      name: content.people,
      path:
        context === SINGLE_CLASS_PAGES_CONTEXT.CLASS_CONTEXT
          ? `${getClassLink(classId)}/people`
          : `${getOrganizationClassLink(classId)}/people`,
      showButton: params.showPeopleButton,
      testHook: 'CLASS_SUBMENU_PEOPLE'
    }
  ]
};

export default classSubMenuConstants;
