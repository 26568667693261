import React from 'react';
import PropTypes from 'prop-types';
// Styles
import styles from './HubTabs.scss';
// Components
import Tab from './Tab';

function HubTabs({ tabs = [], selectView, view }) {
  return (
    <div className={styles.tabsContainer}>
      {tabs &&
        tabs.length &&
        tabs.map(tab => (
          <div className={styles.tabContainer} key={tab.name}>
            <Tab tab={tab} selectView={selectView} view={view} isLoading={tab.isLoading} />
          </div>
        ))}
    </div>
  );
}

HubTabs.propTypes = {
  tabs: PropTypes.array.isRequired,
  selectView: PropTypes.func.isRequired,
  view: PropTypes.string.isRequired
};

export default HubTabs;
