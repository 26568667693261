const filterAssignmentIds = (assignmentIds = [], assignments = {}) => {
  const filteredStudentAssignmentIds = assignmentIds.filter(id => assignments[id].target_usertype === 'student');
  const filteredAllAssignmentIds = assignmentIds.filter(id => !assignments[id].target_usertype);
  const filteredTeacherAssignmentIds = assignmentIds.filter(id => assignments[id].target_usertype === 'teacher');

  return {
    filteredAllAssignmentIds,
    filteredStudentAssignmentIds,
    filteredTeacherAssignmentIds
  };
};

export default filterAssignmentIds;
