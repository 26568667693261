import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import breakpoints from '../../globals/breakpoints';
import CarouselSelector from '../CarouselSelector/CarouselSelector';
import styles from './CarouselGallery.scss';

function CarouselGallery({ imageList, breakpoint }) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const { MD, LG } = breakpoints;
  const carouselWidthPercentage = [MD, LG].includes(breakpoint) ? 70 : 100;
  const carouselWidth = Math.ceil((windowWidth * carouselWidthPercentage) / 100);
  const numberOfItemsPerSlide = Math.floor(carouselWidth / 140);
  const images = imageList.map(item => item.image);

  const carouselData = {
    items: images,
    // eslint-disable-next-line react/no-unstable-nested-components
    itemRenderer: item => (
      <div className={styles.carouselItem}>
        <img src={item} alt="Carousel item" />
      </div>
    )
  };

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div style={{ width: `${carouselWidthPercentage}vw` }} className={styles.carouselContainer}>
      <CarouselSelector
        items={carouselData.items}
        perSection={numberOfItemsPerSlide}
        itemRenderer={carouselData.itemRenderer}
        mainPageVisible
        multiImageType
      />
    </div>
  );
}

CarouselGallery.propTypes = {
  imageList: PropTypes.array.isRequired,
  breakpoint: PropTypes.string.isRequired
};

export default CarouselGallery;
