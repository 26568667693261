import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { compose } from 'redux';
// Redux
import { connect } from 'react-redux';
import withLocalizedContent from '../../language/withLocalizedContent';
import { closeEditMyAccountModal } from '../../redux/actions/hubUi';
// Styles
import styles from './HubSupport.scss';
// Components
import HubSupportContactUsHeader from './HubSupportContactUsHeader';
import HubSupportContactUs from './HubSupportContactUs';
import gtmPageview from '../../utils/gtm/pageview';
import mountLiveChat, { unmountLiveChat } from '../../utils/chat/liveChat';
import MyProfileEditPanel from '../../panels/MyProfileEditPanel';

function HubSupportPage({
  localizedContent: { hubGlossary: hubContent },
  editAccountModalOpen,
  closeEditAccountModal
}) {
  useEffect(() => {
    mountLiveChat();
    return () => unmountLiveChat();
  }, []);
  return (
    <div className={styles.hubSupport}>
      <Helmet
        title={hubContent.hub_support_page_title_header}
        titleTemplate={hubContent.page_title_template}
        onChangeClientState={() => gtmPageview()}
      />
      <HubSupportContactUsHeader content={hubContent} />
      <HubSupportContactUs />
      <MyProfileEditPanel isOpen={editAccountModalOpen} closePanel={closeEditAccountModal} />
    </div>
  );
}

export default compose(
  withLocalizedContent('hubGlossary'),
  connect(
    ({ hubUi: { editAccountModalOpen } }) => ({
      editAccountModalOpen
    }),
    {
      closeEditAccountModal: closeEditMyAccountModal
    }
  )
)(HubSupportPage);

HubSupportPage.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  editAccountModalOpen: PropTypes.bool.isRequired,
  closeEditAccountModal: PropTypes.func.isRequired
};
