import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { validUserRoles } from '../globals/userRoles.js';
import { HubLayoutConstants } from '../globals/hubConstants';
import { formStates } from '../redux/reducers/invitationsBanner.reducer';
import { queryFilter } from '../utils/url.js';

// Site homepage
import HubHomepage from './HubHomepage/HubHomepage.js';

class HubIndexRedirectHandler extends Component {
  componentWillMount() {
    let nextPage;
    const { history, userRole } = this.props;
    const urlParams = window.location.search;
    const returnTo = urlParams && (urlParams.match(/returnTo=([^&$]+)/) || [])[1];
    const nextPathFromSessionStorage = sessionStorage.getItem('goto-path');

    if (nextPathFromSessionStorage && nextPathFromSessionStorage !== '/login') {
      nextPage = nextPathFromSessionStorage;
    } else if (returnTo && returnTo !== encodeURIComponent('/') && returnTo !== encodeURIComponent('/login')) {
      // Redirect to where the user originally wanted to go:
      nextPage = decodeURIComponent(returnTo);
    } else if (!userRole) {
      // Redirect to homepage if user is not logged in:
      nextPage = HubLayoutConstants.PATH_NAMES.HOME_PAGE;
    } else {
      // Logged in users should be redirected to the dashboard
      nextPage = HubLayoutConstants.PATH_NAMES.DASHBOARD_PATH;
    }

    if (nextPathFromSessionStorage) sessionStorage.removeItem('goto-path');

    const filteredParams = queryFilter(urlParams, ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content']);
    const newParams = filteredParams ? `?${filteredParams}` : '';
    if (nextPage.startsWith('http://') || nextPage.startsWith('https://')) {
      window.location.href = `${nextPage}${newParams}`;
    } else {
      history.replace(`${nextPage}${newParams}`);
    }
  }

  componentDidUpdate(prevProps) {
    const { invites, invitesFetchStatus, userRole, history } = this.props;
    // Redirect to dashboard after accepting all invites
    if (prevProps.invites.length !== invites && invites.length === 0 && !invitesFetchStatus && !!userRole) {
      history.replace(HubLayoutConstants.PATH_NAMES.DASHBOARD_PATH);
    }
  }

  // This only happens when one of the conditions above is NOT causing a REDIRECT:
  render() {
    return <HubHomepage />;
  }
}

HubIndexRedirectHandler.propTypes = {
  /** This is the React Router context object. Provided by the withRouter decorator */
  history: PropTypes.object.isRequired,
  /** Our current user's role */
  userRole: PropTypes.oneOf(validUserRoles),
  invitesFetchStatus: PropTypes.bool,
  invites: PropTypes.array
};

export default connect(state => ({
  userRole: state.identity.role,
  invitesFetchStatus: state.invites.formState !== formStates.INPUTTING && !!state.identity.role,
  invites: state.invites.invites
}))(withRouter(HubIndexRedirectHandler));
