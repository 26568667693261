import React from 'react';
import PropTypes from 'prop-types';
import SVGIcon, { GLYPHS } from '../../../../../../components/SVGIcon/SVGIcon';
import styles from './DeploymentTool.scss';

function ToolItem({ tool }) {
  return (
    <div className={styles.toolInfoContainer}>
      <div className={styles.toolInfoDot}>
        <SVGIcon glyph={GLYPHS.ICON_INTEGRATION} />
      </div>
      <span>{tool.displayName}</span>
    </div>
  );
}

ToolItem.propTypes = {
  tool: PropTypes.object.isRequired
};

export default ToolItem;
