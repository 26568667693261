import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './RoleTile.scss';

function RoleTile({ item, selectRole, wizardMode = false, isSelected = false }) {
  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      selectRole(item.key, wizardMode);
    }
  };

  return (
    <li
      className={classnames(styles.roleTile, { [styles.wizardMode]: wizardMode, [styles.selected]: isSelected })}
      data-testid={`${item.key}_TILE`}
    >
      <a role="button" tabIndex={0} onClick={() => selectRole(item.key)} onKeyDown={handleKeyDown}>
        <div className={styles.content}>
          <img src={item.illustrationSrc} alt={item.illustrationAlt} />
          <div className={styles.textContent}>
            <span>{item.title}</span>
            {item.subTitle && <p>{item.subTitle}</p>}
          </div>
        </div>
      </a>
    </li>
  );
}

RoleTile.propTypes = {
  item: PropTypes.object.isRequired,
  selectRole: PropTypes.func.isRequired,
  wizardMode: PropTypes.bool,
  isSelected: PropTypes.bool
};

export default RoleTile;
