import React from 'react';
import PropTypes from 'prop-types';
// Components
import { connect } from 'react-redux';
import HubModalLayout from '../../HubModalLayout/HubModalLayout';
import Button, { buttonTypes } from '../../Button/Button';
// Styles
import styles from './ClassOnboardingWarningModal.scss';

import { closeOnboardingWizard } from '../../../redux/actions/hubUi';
import { clearWizardState } from '../../../redux/actions/classOnboardingWizard';
import { closeForm } from '../../../redux/reducers/classroomCreate.js';

function ClassOnboardingWarningModal({
  isOpen,
  content,
  closeWarningModal,
  closeWizardAction,
  clearWizardStateAction,
  clearClassroomCreateStateAction
}) {
  return (
    <div className={`${styles.outerWizardWarningModal} ${isOpen ? styles.open : ''}`}>
      <HubModalLayout isOpen={isOpen} closeAction={closeWarningModal} ariaLabel={content.warning_wizard_arialabel}>
        <div
          data-testid="ONBOARDING_WIZARD_WARNING_MODAL"
          className={`${styles.modalContainer} ${isOpen ? styles.animationFadeIn : ''}`}
          aria-hidden={!isOpen}
        >
          <div className={styles.modalHeader}>
            <Button
              dataTestId="ONBOARDING_WIZARD_WARNING_MODAL_CLOSE_BUTTON"
              text={content.close}
              type={buttonTypes.CLOSE_BORDER_GRAY}
              onClick={e => {
                e.stopPropagation();
                closeWarningModal();
              }}
            />
          </div>

          <div className={styles.modalContent}>
            <p className={styles.title}>{content.warning_modal_title}</p>
            <p className={styles.subtitle}>
              {content.warning_modal_subtext} <span> {content.my_classes}</span>
            </p>
          </div>

          <div className={styles.modalFooter}>
            <Button
              dataTestId="ONBOARDING_WIZARD_WARNING_MODAL_CANCEL_BUTTON"
              text={content.cancel}
              type={buttonTypes.CANCEL}
              onClick={() => closeWarningModal()}
            />
            <Button
              dataTestId="ONBOARDING_WIZARD_WARNING_MODAL_CONFIRM_BUTTON"
              text={content.yes}
              type={buttonTypes.PRIMARY}
              onClick={() => {
                closeWarningModal();
                closeWizardAction();
                clearWizardStateAction();
                clearClassroomCreateStateAction();
              }}
            />
          </div>
        </div>
      </HubModalLayout>
    </div>
  );
}
ClassOnboardingWarningModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  content: PropTypes.object.isRequired,
  closeWarningModal: PropTypes.func.isRequired,
  closeWizardAction: PropTypes.func.isRequired,
  clearWizardStateAction: PropTypes.func.isRequired,
  clearClassroomCreateStateAction: PropTypes.func.isRequired
};

export default connect(null, {
  clearWizardStateAction: clearWizardState,
  clearClassroomCreateStateAction: closeForm,
  closeWizardAction: closeOnboardingWizard
})(ClassOnboardingWarningModal);
