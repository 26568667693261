/* eslint-disable import/prefer-default-export */

export const RvsLayoutConstants = {
  PATH_NAMES: {
    HOME_PAGE: '/',
    VALID_CERTIFICATE_PAGE: '/valid',
    INVALID_CERTIFICATE_PAGE: '/invalid',
    ERROR_PAGE: '/error',
    MANUAL_FORM: '/form',
    SUPPORT: '/support',
    SUPPORT_MESSAGE_PAGE: '/supportmessage'
  }
};
