import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useWizard } from '@oup/shared-front-end/src/components/Wizard';
import WizardStep from '@oup/shared-front-end/src/components/Wizard/WizardStep';
import TextInput from '@oup/shared-front-end/src/components/TextInput/TextInput.js';
import InformationBox from '@oup/shared-front-end/src/components/InformationBox';
import TextLink from '@oup/shared-front-end/src/components/TextLink';
import styles from './YoungLearnerRedeemCodeWizard.scss';

function YoungLearnerEnterRedeemCodeStep({ content }) {
  const { setIsPrimaryButtonDisabled } = useWizard();
  const [redeemCodeValue, setRedeemCodeValue] = useState('');

  useEffect(() => {
    setIsPrimaryButtonDisabled(!redeemCodeValue);
  }, [redeemCodeValue]);

  return (
    <div
      data-testid="YL_REDEEM_CODE_WIZARD_STEP_2"
      className={`${styles.stepContainer} ${styles.enterRedeemCodeStepContainer}`}
    >
      <WizardStep titleText={content.check_your_access_code} className={styles.enterCodeContent}>
        <TextInput
          id="redeemCode"
          name="redeemCode"
          label={content.enter_access_code}
          value={redeemCodeValue}
          onChange={e => setRedeemCodeValue(e.target.value)}
        />
      </WizardStep>

      <div className={styles.rightColumnContainer}>
        <InformationBox title={content.information_about_codes}>
          <div>
            <p>{content.codes_look_like_this}</p>
            <p>{content.have_problems}</p>
            <div className={styles.linksContainer}>
              <span>
                <TextLink to={content.need_help_with_your_code_link} target="_blank">
                  {content.need_help_with_your_code}
                </TextLink>
              </span>
              <span>
                <TextLink to={content.buy_a_code_link} target="_blank">
                  {content.buy_a_code}
                </TextLink>
              </span>
            </div>
          </div>
        </InformationBox>
      </div>
    </div>
  );
}

YoungLearnerEnterRedeemCodeStep.propTypes = {
  content: PropTypes.object.isRequired
};

export default YoungLearnerEnterRedeemCodeStep;
