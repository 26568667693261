import React from 'react';

// Styles
import styles from './MyDashboardStudentView.scss';

// Components
import LastOpened from './LastOpened';
import WordOfTheDay from './WordOfTheDay';

function MyDashboardStudentView() {
  return (
    <div className={styles.dashboardContainer}>
      <div className={styles.lastOpenArea}>
        <LastOpened />
      </div>
      <div className={styles.wordOfTheDay}>
        <WordOfTheDay />
      </div>
    </div>
  );
}

export default MyDashboardStudentView;
