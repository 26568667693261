import * as t from '../actionTypes';

export const handlePeopleSelectionPage = page => ({
  type: t.HUB_HANDLE_PEOPLE_PAGE_SELECTION,
  page
});

export const resetPagination = () => ({
  type: t.HUB_RESET_PAGINATION
});

export default handlePeopleSelectionPage;
