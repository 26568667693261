import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Wizard } from '@oup/shared-front-end/src/components/Wizard';
import { orgRoles } from '@oup/shared-node-browser/org';
import NewAdminProvidesOrganizationDetails from './NewAdminProvidesOrganizationDetails/NewAdminProvidesOrganizationDetails.js';
import OrganizationSetupWizardFooter from './OrganizationSetupWizardFooter.js';
import OnboardingWizardAddStaff from './OnboardingWizardAddStaff/OnboardingWizardAddStaff.js';
import OnboardingWizardSuccess from './OnboardingWizardSuccess/OnboardingWizardSuccess.js';
import CreateFirstPlacementTestOrClassModal from './CreateFirstPlacementTestOrClassModal/CreateFirstPlacementTestOrClassModal.js';
import { showSelfSelectRoleModal } from '../../redux/actions/hubUi.js';
import OrganizationSetupWizardHeader from './OrganizationSetupWizardHeader.js';
import StopSettingUpOrgModal from './StopSettingUpOrgModal/StopSettingUpOrgModal.js';
import { HubLayoutConstants } from '../../globals/hubConstants.js';
import OnboardingWizardWelcome from './OnboardingWizardWelcome/OnboardingWizardWelcome.js';
import ChooseOrgType from './ChooseOrgType/ChooseOrgType.js';

const { SECONDARY_SCHOOL } = orgRoles;

function OrganizationSetupWizard({ showSelfSelectRoleModalAction, firstName }) {
  const [isOpenStopSettingUpOrgModal, setIsOpenStopSettingUpOrgModal] = useState(false);
  const [isOpenCreateFirstPlacementTestOrClassModal, setIsOpenCreateFirstPlacementTestOrClassModal] = useState(false);
  const [selectedOrgType, setSelectedOrgType] = useState('');

  const [formData, setFormData] = useState({
    name: '',
    countryCode: '',
    primaryEmail: '',
    role: SECONDARY_SCHOOL
  });

  return (
    <>
      <Wizard
        header={
          <OrganizationSetupWizardHeader
            secondWizardType={selectedOrgType}
            setIsOpenStopSettingUpOrgModal={setIsOpenStopSettingUpOrgModal}
            setIsOpenCreateFirstPlacementTestOrClassModal={setIsOpenCreateFirstPlacementTestOrClassModal}
          />
        }
        footer={
          <OrganizationSetupWizardFooter
            branch={selectedOrgType}
            formData={formData}
            setIsOpenCreateFirstPlacementTestOrClassModal={setIsOpenCreateFirstPlacementTestOrClassModal}
            showSelfSelectRoleModalAction={showSelfSelectRoleModalAction}
          />
        }
      >
        <OnboardingWizardWelcome firstName={firstName} />
        <ChooseOrgType selectedOrgType={selectedOrgType} setSelectedOrgType={setSelectedOrgType} />

        <NewAdminProvidesOrganizationDetails branch={selectedOrgType} formData={formData} setFormData={setFormData} />
        <OnboardingWizardAddStaff branch={selectedOrgType} />
        <OnboardingWizardSuccess />
      </Wizard>
      <StopSettingUpOrgModal
        isOpen={isOpenStopSettingUpOrgModal}
        closeWarningModal={() => setIsOpenStopSettingUpOrgModal(false)}
      />
      <CreateFirstPlacementTestOrClassModal
        isOpen={isOpenCreateFirstPlacementTestOrClassModal}
        onClose={() => {
          setIsOpenCreateFirstPlacementTestOrClassModal(false);
          showSelfSelectRoleModalAction(false);
        }}
        onNext={() => {
          localStorage.setItem('selectedOrgType', selectedOrgType);
          window.location = HubLayoutConstants.PATH_NAMES.DASHBOARD_PATH;
        }}
        wizardType={selectedOrgType}
      />
    </>
  );
}

OrganizationSetupWizard.propTypes = {
  showSelfSelectRoleModalAction: PropTypes.func,
  firstName: PropTypes.string.isRequired
};

export default compose(
  connect(null, {
    showSelfSelectRoleModalAction: showSelfSelectRoleModal
  })
)(OrganizationSetupWizard);
