import { isEmpty, reduce } from 'lodash';

const reduceLearningAssignmentsToProductDetails = assignments =>
  reduce(
    assignments,
    (result, product, key) => {
      if (!product.isSelfAssignedProduct && isEmpty(product.subscriptions) && product.productDetails) {
        result[key] = {
          ...product.productDetails,
          archiveStatus: product.archiveStatus,
          archiveDate: product.archiveDate
        };
        if (product.linkedProductDetails) {
          result[key].linkedProductDetails = product.linkedProductDetails;
        }
      }
      return result;
    },
    {}
  );

export default reduceLearningAssignmentsToProductDetails;
