import React from 'react';
import PropTypes from 'prop-types';
// Components
import ScrollContainer from '../../../../components/ScrollContainer/ScrollContainer';
import PopoutPanelIllustrationHeading from '../../../../components/PopoutPanelIllustrationHeading/PopoutPanelIllustrationHeading';
import PopoutNavFooter from '../../../../components/PopoutNavFooter/PopoutNavFooter';
import PersonRepresentation from '../../../../components/PersonRepresentation/PersonRepresentation';
import { SIZES as thumbnailSizes } from '../../../../components/Thumbnail/Thumbnail';
// Utils
import { toInitials } from '../../../../utils/string';
// Constants
import { COLOR_FOR_ROLE } from '../../../../globals/colors';
import { HubIllustrationConstants, HubIllustrationAltText } from '../../../../globals/hubConstants';
// Styles
import styles from './CancelInvitationPanel.scss';

function CancelInvitationReviewPanel({
  hubContent,
  closePanel,
  cancelInvitation,
  userDetails: { userId, selectedUserEmail, firstName, lastName, role }
}) {
  return (
    <ScrollContainer
      headerContent={
        <div>
          <PopoutPanelIllustrationHeading
            title={hubContent.cancel_invitation_panel_heading}
            subtitle={hubContent.review_remove_user_confirmation_subtitle}
            illustrationSrc={HubIllustrationConstants.WARNING}
            illustrationAltText={HubIllustrationAltText.WARNING}
          />
        </div>
      }
      footerContent={
        <PopoutNavFooter
          nextButtonText={hubContent.cancel_invitation}
          nextAction={cancelInvitation}
          backButtonText={hubContent.cancel}
          backAction={closePanel}
        />
      }
    >
      <div className={styles.personeRepresentation}>
        <PersonRepresentation
          key={userId}
          thumbnailSize={thumbnailSizes.MEDIUM}
          name={`${firstName} ${lastName}`}
          initials={toInitials(firstName, lastName)}
          email={selectedUserEmail}
          thumbnailColor={COLOR_FOR_ROLE[role] || COLOR_FOR_ROLE.USER}
        />
      </div>
    </ScrollContainer>
  );
}

export default CancelInvitationReviewPanel;

CancelInvitationReviewPanel.propTypes = {
  cancelInvitation: PropTypes.func.isRequired,
  closePanel: PropTypes.func.isRequired,
  userDetails: PropTypes.object.isRequired,
  hubContent: PropTypes.object.isRequired
};
