import * as t from '../actionTypes';

export const addOfflineProducts = products => ({
  type: t.ADD_OFFLINE_PRODUCTS,
  payload: products
});

export const getOfflineProducts = (searchParams = null) => ({
  type: t.GET_OFFLINE_PRODUCTS,
  payload: searchParams
});

export const loadOfflineProducts = products => ({
  type: t.LOAD_OFFLINE_PRODUCTS,
  payload: products
});

export const addOfflineProfiles = profiles => ({
  type: t.ADD_OFFLINE_PROFILES,
  payload: profiles
});

export const deleteOfflineProfiles = profileIds => ({
  type: t.DELETE_OFFLINE_PROFILES,
  payload: profileIds
});

export const getOfflineProfiles = (searchParams = null) => ({
  type: t.GET_OFFLINE_PROFILES,
  payload: searchParams
});

export const loadOfflineProfiles = profiles => ({
  type: t.LOAD_OFFLINE_PROFILES,
  payload: profiles
});

export const addOfflineUnits = units => ({
  type: t.ADD_OFFLINE_UNITS,
  payload: units
});

export const deleteOfflineUnits = unitsIds => ({
  type: t.DELETE_OFFLINE_UNITS,
  payload: unitsIds
});

export const getOfflineUnits = (searchParams = null) => ({
  type: t.GET_OFFLINE_UNITS,
  payload: searchParams
});

export const loadOfflineUnits = units => ({
  type: t.LOAD_OFFLINE_UNITS,
  payload: units
});

export const getOfflinePlayerVersion = () => ({
  type: t.GET_OFFLINE_PLAYER_VERSION
});

export const updateOfflinePlayerVersion = version => ({
  type: t.UPDATE_OFFLINE_PLAYER_VERSION,
  payload: version
});

export const loadOfflinePlayerVersion = version => ({
  type: t.LOAD_OFFLINE_PLAYER_VERSION,
  payload: version
});
