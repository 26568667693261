import React, { useState } from 'react';
import PropTypes from 'prop-types';
// Components
import { GLYPHS } from '../Button/Button';
import HubDropdownMenu from '../HubDropdownMenu/HubDropdownMenu';
import InformLabel from '../InformLabel/InformLabel.js';
import { AssessmentEditPencil as AssessmentImage } from '../HubImage';
// Services
import getDropdownItems from '../HubDropdownMenu/Services/getDropdownItems';
import {
  formatDate,
  getInfoLabelData,
  contextTypes,
  showInfoLabel
} from '../../structure/HubClassLayout/Services/getClassTestData.js';
// Constants
import { HubLayoutConstants, TEST_STATUS, SINGLE_CLASS_PAGES_CONTEXT } from '../../globals/hubConstants';
import userRoles from '../../globals/userRoles';
// Styles
import styles from './ClassAssessmentTest.scss';

function ClassAssessmentTest({
  id,
  orgId,
  classroomId,
  testData,
  status,
  onTestClick = null,
  openPreviewTestModal = null,
  openTestInfoModal = null,
  openRedeemModal = null,
  openStudensStatusLicenceTab = null,
  userRole,
  content,
  dropdownTop,
  showFirstButtonInformLabel,
  openEditTestPanel,
  openCancelTestPanel,
  context
}) {
  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      onTestClick(id);
    }
  };

  const [isHover, setIsHover] = useState(false);

  const toggleHover = () => setIsHover(prevHover => !prevHover);

  const getInfoLabel = () => {
    const { message, buttonText, buttonAction, customClassName } = getInfoLabelData(
      content,
      status,
      testData.hasLicence,
      testData?.studentsWithoutLicence,
      { openRedeemModal, openStudensStatusLicenceTab },
      contextTypes.CLASS_TEST,
      userRole,
      id
    )[userRole];

    return (
      <div className={styles.infoLabelContainer}>
        <InformLabel
          message={message}
          glyphIcon={GLYPHS.ICON_WARNING_CIRCLE}
          buttonText={buttonText}
          buttonAction={buttonAction}
          customClassName={customClassName}
          showFirstButtonInformLabel={showFirstButtonInformLabel}
        />
      </div>
    );
  };

  const dropdownActions = {
    previewTest: e => {
      e.stopPropagation();
      openPreviewTestModal(id);
    },
    viewTestInfo: e => {
      e.stopPropagation();
      openTestInfoModal(id);
    },
    editTestSettings: e => {
      e.stopPropagation();
      openEditTestPanel(id);
    },
    cancelTest: e => {
      e.stopPropagation();
      openCancelTestPanel(id);
    },
    viewScores: e => {
      e.stopPropagation();
      const contextPage =
        context === SINGLE_CLASS_PAGES_CONTEXT.ORG_CONTEXT
          ? HubLayoutConstants.PATH_NAMES.ORGANIZATION_PATH
          : HubLayoutConstants.PATH_NAMES.CLASSES_PATH;
      window.location.pathname = `${contextPage}/singleClass/${classroomId}/progress/${orgId}/gradebook`;
    }
  };

  const dropdownOptions = {
    testStatus: status,
    hasLicence: testData.hasLicence
  };

  return (
    <li>
      <div
        className={styles.classTest}
        onClick={() => {
          if (status !== TEST_STATUS.COMPLETED) {
            onTestClick(id);
          }
        }}
        onMouseEnter={toggleHover}
        onMouseLeave={toggleHover}
        onKeyDown={handleKeyDown}
        role="button"
        tabIndex={0}
      >
        <div className={`${styles.testContainer} ${styles[status]}`}>
          <div className={styles.testInfoContainer}>
            <AssessmentImage active={isHover} />
            <div className={styles.mainInfoContainer}>
              <div className={styles.topInfo}>
                <div className={styles.statusContainer}>
                  <span className={styles.pulse} /> <span>{status}</span>
                </div>
                <span className={styles.textTitle}>
                  {testData.title} ({testData.assessmentTitle})
                </span>
              </div>
              <div className={styles.dateContainer}>
                <p>
                  <span>{content.start}</span> {formatDate(testData?.testStartDate, content)}
                </p>
                <p>
                  <span>{content.due}</span> {formatDate(testData?.testEndDate, content)}
                </p>
              </div>
            </div>
          </div>

          {testData.status === TEST_STATUS.COMPLETED && userRole !== userRoles.LEARNER ? (
            <div className={styles.scoreContainer}>
              <span>{testData.percentageOfTestsCompleted}</span>
              <span>%</span>
            </div>
          ) : null}

          <div className={`${styles.dropdownMenu} ${styles.dropdownTopClassAssessmentTests}`}>
            <HubDropdownMenu
              dropdownData={getDropdownItems(
                'classAssessmentTests',
                content,
                dropdownOptions,
                dropdownActions,
                userRole
              )}
              parentId={id}
              dropdownTop={dropdownTop}
              customClassname={styles.classTestDropdown}
              isAssessmentTestComponent
            />
          </div>
        </div>
        {showInfoLabel(userRole, testData) && getInfoLabel()}
      </div>
    </li>
  );
}

ClassAssessmentTest.propTypes = {
  id: PropTypes.string.isRequired,
  orgId: PropTypes.string.isRequired,
  classroomId: PropTypes.string.isRequired,
  testData: PropTypes.object.isRequired,
  status: PropTypes.string.isRequired,
  onTestClick: PropTypes.func.isRequired,
  openPreviewTestModal: PropTypes.func.isRequired,
  openTestInfoModal: PropTypes.func.isRequired,
  openRedeemModal: PropTypes.func.isRequired,
  openStudensStatusLicenceTab: PropTypes.func,
  userRole: PropTypes.string.isRequired,
  content: PropTypes.object.isRequired,
  dropdownTop: PropTypes.bool,
  showFirstButtonInformLabel: PropTypes.bool,
  openEditTestPanel: PropTypes.func,
  openCancelTestPanel: PropTypes.func,
  context: PropTypes.string
};

export default ClassAssessmentTest;
