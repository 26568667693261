import { call, put, takeLatest } from 'redux-saga/effects';
import * as t from '../../../../actionTypes.js';
import { findProductFailure, findProductSuccess } from '../../../../actions/findProductActions.js';
import findProductApi from '../../../apiCalls/product/findProductApi.js';

function* findProduct(action) {
  try {
    const response = yield call(findProductApi, action.payload);
    yield put(findProductSuccess(response.data));
  } catch (error) {
    yield put(findProductFailure('Failed to find products'));
  }
}

function* findProductSaga() {
  yield takeLatest(t.FIND_PRODUCT_REQUEST, findProduct);
}

export default findProductSaga;
