import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
// Redux
// Constants
// Utils
import { getDayMonthYearFormat } from '../../../globals/dateFormats';
// Style
import styles from './AssessmentUtil.scss';
// Components
import SVGIcon, { GLYPHS } from '../../../components/SVGIcon/SVGIcon';

class AssessmentDate extends PureComponent {
  render() {
    const { date, content } = this.props;

    return (
      <div className={styles.dateRow}>
        <SVGIcon className={styles.dateCheckIcon} glyph={GLYPHS.ICON_CHECK_CIRCLE} />
        {date && date !== 'Never' ? (
          <>
            <div className={styles.dateExpiresText}>{content.assessment_home_expires_on}</div>
            <div className={styles.dateText}>{getDayMonthYearFormat(new Date(date))}</div>
          </>
        ) : (
          <div className={styles.dateText}>{content.unlimited_licence}</div>
        )}
      </div>
    );
  }
}

AssessmentDate.propTypes = {
  content: PropTypes.object.isRequired,
  date: PropTypes.string
};

export default AssessmentDate;
