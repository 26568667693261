import { EPS_ASSETS_BASE_ELT_MISC } from '../../../../sharedNodeBrowser/constants';
import { STAGED_DEFAULT_ASSETS_DOMAIN } from '../../../globals/cptConstants';
import isLorResourceIdFormat from './isLorResourceIdFormat';
import { isLocal } from '../../../globals/envSettings.js';

const CONTENT_PATH = isLocal()
  ? `${STAGED_DEFAULT_ASSETS_DOMAIN}${EPS_ASSETS_BASE_ELT_MISC}`
  : `https://${window.location.hostname}${EPS_ASSETS_BASE_ELT_MISC}`;

export default function getCourseCoverImage(course) {
  if (!course?.id) {
    return null;
  }
  const courseIsLor = isLorResourceIdFormat(course.id);
  const courseIsNgc = course.properties && course.properties.defaultContentPackage;

  if (courseIsLor) {
    return course.properties.coverImage;
  }

  if (courseIsNgc) {
    return `${CONTENT_PATH}/${course.properties.defaultContentPackage}/${course.properties.coverImage}`;
  }

  return null;
}
