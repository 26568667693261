import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import styles from './OrganisationAdmin.scss';
import PageWrapper from '../PageWrapper/PageWrapper';
import Button, { buttonTypes } from '../Button/Button';
import { featureIsEnabled } from '../../globals/envSettings';

function OrganisationAdmin({ localizedContent }) {
  const renderEpsOpsConsole = () => {
    if (featureIsEnabled('enable-eps-admin-console')) {
      return (
        <Button
          to="/dashboard_eps/eps_ops"
          type={buttonTypes.SECONDARY}
          text={localizedContent.eps_ops}
          fullWidth
          customClassName={styles.button}
        />
      );
    }
    return <div />;
  };
  return (
    <PageWrapper>
      <Helmet title={localizedContent.helmet_title} />
      <h1>{localizedContent.title}</h1>
      <span className={styles.subheading}>{localizedContent.subtitle}</span>
      <div className={styles.linkContainer}>
        <div className={styles.btnGroup}>
          <Button
            to="/dashboard/org/admin/register"
            type={buttonTypes.SECONDARY}
            text={localizedContent.create_org_button_text}
            fullWidth
            customClassName={styles.button}
          />

          <Button
            to="/dashboard/org/organizations"
            type={buttonTypes.SECONDARY}
            text={localizedContent.view_organizations_text}
            fullWidth
            customClassName={styles.button}
          />
        </div>
        {renderEpsOpsConsole()}
        <Button
          to="/dashboard/user/impersonate"
          type={buttonTypes.SECONDARY}
          text={localizedContent.impersonate_user_button_text}
          fullWidth
          customClassName={styles.button}
        />
        <Button
          to="/dashboard/org/licence"
          type={buttonTypes.SECONDARY}
          text={localizedContent.add_licence_button_text}
          fullWidth
          customClassName={styles.button}
        />
        <Button
          to="/dashboard/org/update-licence"
          type={buttonTypes.SECONDARY}
          text={localizedContent.update_licence_button_text}
          fullWidth
          customClassName={styles.button}
        />
        {featureIsEnabled('enable-remove-user-license') && (
          <Button
            to="/dashboard/user/remove-licence"
            type={buttonTypes.SECONDARY}
            text={localizedContent.remove_user_licence_button_text}
            fullWidth
            customClassName={styles.button}
          />
        )}
        <Button
          to="/dashboard/org/test-credits"
          type={buttonTypes.SECONDARY}
          text={localizedContent.add_test_credits_button_text}
          fullWidth
          customClassName={styles.button}
        />
        <Button
          to="/dashboard/org/link-oic-orgs-to-janison"
          type={buttonTypes.SECONDARY}
          text={localizedContent.link_oic_organizations_to_janison_text}
          fullWidth
          customClassName={styles.button}
        />
        <Button
          to="/dashboard/org/pre-seeding-organisations"
          type={buttonTypes.SECONDARY}
          text={localizedContent.pre_seeding_organisations}
          fullWidth
          customClassName={styles.button}
        />
        {featureIsEnabled('csv-user-data-upload') && (
          <Button
            to="/dashboard/csv-user-data-upload"
            type={buttonTypes.SECONDARY}
            text={localizedContent.upload_a_file_for_data_transfer}
            fullWidth
            customClassName={styles.button}
          />
        )}
        <Button
          to="/reports/generate-report"
          type={buttonTypes.SECONDARY}
          text={localizedContent.generate_report}
          fullWidth
          customClassName={styles.blueButton}
        />
        <Button
          to="/reports/invoice-report"
          type={buttonTypes.SECONDARY}
          text={localizedContent.form_submit_button}
          fullWidth
          customClassName={styles.blueButton}
        />
        <div className={styles.btnGroup}>
          <Button
            to="/dashboard/email-tools"
            type={buttonTypes.SECONDARY}
            text={localizedContent.email_tools}
            fullWidth
            customClassName={styles.button}
          />
          <Button
            to="/dashboard/oic-email-tools"
            type={buttonTypes.SECONDARY}
            text={localizedContent.oic_email_tools}
            fullWidth
            customClassName={styles.button}
          />
        </div>
        <Button
          to="/dashboard/system-notification"
          type={buttonTypes.SECONDARY}
          text={localizedContent.system_notification}
          fullWidth
          customClassName={styles.button}
        />
        <Button
          to="/oup/content/resources/eps-content/area/products"
          type={buttonTypes.SECONDARY}
          text={localizedContent.eps_content_area_products}
          fullWidth
          customClassName={styles.button}
        />
      </div>
    </PageWrapper>
  );
}

OrganisationAdmin.propTypes = {
  localizedContent: PropTypes.object
};

export default OrganisationAdmin;
