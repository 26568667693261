import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import SVGIcon, { GLYPHS } from '../../SVGIcon/SVGIcon';
import styles from './TileLoader.scss';

function TileLoader({ customClassName, columnNumber = 4 }) {
  return (
    <div className={classnames(styles.tileSkeleton, styles[customClassName])}>
      {[...Array(columnNumber)].map((value, index) => (
        <SVGIcon key={index} glyph={GLYPHS.ICON_HUB_SKELETON_LOADING} />
      ))}
    </div>
  );
}

TileLoader.propTypes = {
  customClassName: PropTypes.string,
  columnNumber: PropTypes.number
};

export default TileLoader;
