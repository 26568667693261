import React from 'react';
import PropTypes from 'prop-types';
import ScrollContainer from '../../ScrollContainer/ScrollContainer';
import PopoutPanelIllustrationHeading from '../../PopoutPanelIllustrationHeading/PopoutPanelIllustrationHeading';
import PopoutActionFooter from '../../PopoutActionFooter/PopoutActionFooter';
import { HubIllustrationConstants, HubIllustrationAltText } from '../../../globals/hubConstants';

function ManageProductLocksError({ content, resetStateAction, closePanelAction }) {
  return (
    <ScrollContainer
      headerContent={
        <div>
          <PopoutPanelIllustrationHeading
            title={content.manage_students_update_error_heading}
            illustrationSrc={HubIllustrationConstants.ERROR}
            illustrationAltText={HubIllustrationAltText.ERROR}
          />
        </div>
      }
      footerContent={
        <PopoutActionFooter
          secondaryButtonText={content.button_back_text}
          secondaryButtonAction={resetStateAction}
          primaryButtonText={content.done_button}
          primaryButtonAction={closePanelAction}
          wizardButtons
        />
      }
    />
  );
}

ManageProductLocksError.propTypes = {
  content: PropTypes.object.isRequired,
  resetStateAction: PropTypes.func.isRequired,
  closePanelAction: PropTypes.func.isRequired
};

export default ManageProductLocksError;
