import React from 'react';
import PropTypes from 'prop-types';
import { getIdpLoginUrl } from '../../../../redux/sagas/preRender/auth/getCredentials/redirectToIdP.saga.js';
import CarouselGallery from '../../../../components/CarouselGallery/CarouselGallery';
import Button, { buttonTypes } from '../../../../components/Button/Button.js';
import Link from '../../../../components/Link/Link';
import styles from './HubCarouselRow.scss';

function HubCarouselRow({ homepageContent, breakpoint }) {
  const {
    title,
    freeTrialButtonText,
    freeTrialButtonUrl,
    alreadyUsing,
    signInText,
    toAccess
  } = homepageContent.carousel;

  const handleFreeTrial = () => {
    window.location.href = freeTrialButtonUrl;
  };

  const handleSignIn = async () => {
    const url = await getIdpLoginUrl(null);
    window.location.href = url;
  };

  return (
    <div className={styles.carouselRowContainer}>
      <div className={styles.titleSection}>
        <span>{title}</span>
      </div>

      <div className={styles.carouselSection}>
        <CarouselGallery imageList={homepageContent.carousel_images} breakpoint={breakpoint} />
      </div>

      <div className={styles.actionSection}>
        <Button
          id="getFreeTrialButton"
          customClassName={styles.signInButton}
          type={buttonTypes.NO_BORDER}
          text={freeTrialButtonText}
          onClick={handleFreeTrial}
        />
      </div>

      <div className={styles.bottomSection}>
        <span>
          {alreadyUsing}{' '}
          <Link id="signInLink" onClick={handleSignIn} className={styles.signInLink}>
            {signInText}
          </Link>{' '}
          {toAccess}
        </span>
      </div>
    </div>
  );
}

HubCarouselRow.propTypes = {
  homepageContent: PropTypes.object.isRequired,
  breakpoint: PropTypes.string.isRequired
};

export default HubCarouselRow;
