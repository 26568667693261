import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Button from '@oup/shared-front-end/src/components/Button';
import ValidationMessage from '@oup/shared-front-end/src/components/ValidationMessage';
import IconSettings from '@oup/shared-front-end/src/svg/oup/icon-settings-24.svg';

import { toggleOrgUsagePanel } from '../../redux/actions/hubUi';
import { getLimitTypeReached, limitTypes } from '../../structure/HubDashboardLayout/Utils/getOrgUsageLimits.js';
import styles from './OrgUsageBanner.scss';

function OrgUsageBanner({ content, classesCount, activeUserCount, toggleOrgUsagePanelAction }) {
  const limitTypeReached = getLimitTypeReached(classesCount, activeUserCount);

  const getOrgLimitsWarningMessage = limitReached => {
    switch (limitReached) {
      case limitTypes.USERS:
        return content.validation_message_dashboard_reaching_users_org_limits;
      case limitTypes.CLASSES:
        return content.validation_message_dashboard_reaching_classes_org_limits;
      case limitTypes.USERS_AND_CLASSES:
        return content.validation_message_dashboard_reaching_users_classes_org_limits;
      default:
        return '';
    }
  };
  return limitTypeReached !== limitTypes.NONE ? (
    <div className={styles.validationMessageContainer}>
      <ValidationMessage state="warning">
        <span>{getOrgLimitsWarningMessage(limitTypeReached)}</span>
        <Button
          icon={{ placement: 'left', component: <IconSettings /> }}
          text={content.organization_usage}
          onClick={() => toggleOrgUsagePanelAction()}
          dataAttributes={{ testid: 'open-organization-usage-panel' }}
        />
      </ValidationMessage>
    </div>
  ) : null;
}

export default connect(({ orgUsage: { classesCount, activeUserCount } }) => ({ classesCount, activeUserCount }), {
  toggleOrgUsagePanelAction: toggleOrgUsagePanel
})(OrgUsageBanner);

OrgUsageBanner.propTypes = {
  content: PropTypes.object,
  classesCount: PropTypes.number,
  activeUserCount: PropTypes.number,
  toggleOrgUsagePanelAction: PropTypes.func
};
