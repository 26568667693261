import React from 'react';
import PropTypes from 'prop-types';
// Style
import styles from './SelfSelectRoleModal.scss';
// Constants
import { HubLayoutConstants } from '../../globals/hubConstants';
// Components
import RoleTile from '../../components/RoleTile/RoleTile';

function SelfSelectRoleModal({ hubContent, firstName, selectRole }) {
  return (
    <div className={styles.outerModal}>
      <div className={styles.innerModal}>
        <div className={styles.textContainer}>
          <h1>{`${(hubContent.self_select_role_title || '').replace('{firstName}', firstName)}`}</h1>
          <p>{hubContent.self_select_role_subtitle}</p>
        </div>
        <div className={styles.itemsContainer}>
          <ul>
            {HubLayoutConstants.SELF_SELECT_ROLES.map(item => (
              <RoleTile key={item.title} item={item} selectRole={selectRole} />
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

SelfSelectRoleModal.propTypes = {
  hubContent: PropTypes.object.isRequired,
  firstName: PropTypes.string.isRequired,
  selectRole: PropTypes.func.isRequired
};

export default SelfSelectRoleModal;
