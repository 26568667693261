import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
// Components
import GradebookV2 from '../../routes/Gradebook/V2';
import HubEmptyStateRestyle from '../../components/HubEmptyStateRestyle/HubEmptyStateRestyle';
import { HubIllustrationConstants } from '../../globals/hubConstants.js';
import { isLtiMode } from '../../utils/platform';

function HubGradebook({
  match: { params },
  hasProductsInGradebook,
  isGradebookLoading = true,
  hubContent,
  classTitle
}) {
  const classPageTitle = `${classTitle} | ${hubContent.progress}`;
  const currentOrganisationLti = useSelector(state => state.identity.currentOrganisationLti);

  return (
    <div>
      <Helmet title={classPageTitle} />
      {/* when panelName exists then an existing product is selected */}
      <GradebookV2 params={params} />
      {!isGradebookLoading && !hasProductsInGradebook && !params.panelName && (
        <HubEmptyStateRestyle
          iconSrc={HubIllustrationConstants.PROGRESS}
          title={hubContent.no_progress_title.replace('{className}', classTitle)}
          additionalText={hubContent.no_progress_subtitle}
          bodyText={isLtiMode || currentOrganisationLti ? '' : hubContent.no_progress_subtitle}
        />
      )}
    </div>
  );
}

HubGradebook.propTypes = {
  match: PropTypes.object.isRequired,
  hasProductsInGradebook: PropTypes.bool,
  isGradebookLoading: PropTypes.bool,
  hubContent: PropTypes.object,
  classTitle: PropTypes.string
};

export default HubGradebook;
