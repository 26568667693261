import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Footer from '@oup/shared-front-end/src/components/Wizard/Footer';
import TextLink from '@oup/shared-front-end/src/components/TextLink';
import { Link as RouterLink } from 'react-router-dom';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import HubModalLayout from '../../HubModalLayout/HubModalLayout';
import Button, { buttonTypes } from '../../Button/Button';
import { closeSelfRegisteredWizard, showSelfSelectRoleModal } from '../../../redux/actions/hubUi';
import styles from '../CreateFirstPlacementTestOrClassModal/CreateFirstPlacementTestOrClassModal.scss';
import modalStyle from './EnterACodeModal.scss';
import withLocalizedContent from '../../../language/withLocalizedContent';

function EnterACodeModal({
  isOpen,
  onClose,
  onNext,
  showSelfSelectRoleModalAction,
  localizedContent: { selfRegisteredUserOnboardingWizard: content, hubGlossary: hubContent },
  closeSelfRegisteredWizardAction
}) {
  const modalRef = useRef(null);
  const [selectedOption, setSelectedOption] = useState(false);
  const isDisabledNextBtn = !selectedOption;

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      setSelectedOption(true);
    }
  };

  const handleClose = () => {
    setSelectedOption(false);
    onClose();
  };

  useEffect(() => {
    const handleClickOutside = event => {
      if (modalRef.current && !modalRef.current.contains(event.target)) handleClose();
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div className={`${styles.outerWizardWarningModal} ${isOpen ? styles.open : ''}`}>
      <HubModalLayout
        isOpen={isOpen}
        closeAction={onClose}
        focusOnFirstField={false}
        ariaLabel={content.self_registered_user_what_to_do_next_title}
      >
        <div
          data-testid="SELF_REGISTERED_USER_ENTER_A_CODE_MODAL"
          className={`${styles.modalContainer} ${isOpen ? styles.animationFadeIn : ''}`}
          aria-hidden={!isOpen}
          ref={modalRef}
        >
          <div className={styles.modalHeader}>
            <Button
              dataTestId="SELF_REGISTERED_USER_ENTER_A_CODE_MODAL_CLOSE_BUTTON"
              text={hubContent.close}
              type={buttonTypes.CLOSE_BORDER_GRAY}
              onClick={handleClose}
            />
            <h2 className={styles.title}>{content.self_registered_user_what_to_do_next_title}</h2>
          </div>
          <div className={styles.modalContent}>
            <div className={styles.option}>
              <div
                onClick={() => setSelectedOption(true)}
                onKeyDown={handleKeyDown}
                role="button"
                tabIndex={0}
                className={`${modalStyle.optionBox} ${selectedOption ? modalStyle.selected : ''}`}
                id="self-registered-user-enter_a_code"
              >
                <p>{content.self_registered_user_enter_a_code_modal_option_title}</p>
                <p>{content.self_registered_user_enter_a_code_modal_option_subtitle}</p>
              </div>
            </div>
            <TextLink
              component={RouterLink}
              onClick={e => {
                e.preventDefault();
                showSelfSelectRoleModalAction(false);
                closeSelfRegisteredWizardAction();
              }}
            >
              {content.self_registered_user_what_to_do_next_continue_to_hub}
            </TextLink>
          </div>
          <Footer
            primaryButtonLabel={hubContent.next}
            secondaryButtonLabel={hubContent.cancel}
            primaryButtonAction={onNext}
            secondaryButtonAction={handleClose}
            primaryButtonAttributes={{
              dataAttributes: {
                testid: 'SELF_REGISTERED_USER_ENTER_A_CODE_MODAL_NEXT_BUTTON'
              },
              disabled: isDisabledNextBtn
            }}
            secondaryButtonAttributes={{
              dataAttributes: {
                testid: 'SELF_REGISTERED_USER_ENTER_A_CODE_MODAL_CANCEL_BUTTON'
              }
            }}
          />
        </div>
      </HubModalLayout>
    </div>
  );
}

EnterACodeModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  showSelfSelectRoleModalAction: PropTypes.func.isRequired,
  localizedContent: PropTypes.object.isRequired,
  closeSelfRegisteredWizardAction: PropTypes.func
};

export default compose(
  withLocalizedContent('selfRegisteredUserOnboardingWizard', 'hubGlossary'),
  connect(null, {
    showSelfSelectRoleModalAction: showSelfSelectRoleModal,
    closeSelfRegisteredWizardAction: closeSelfRegisteredWizard
  })
)(EnterACodeModal);
