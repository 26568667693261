import { createAuth0Client } from '@auth0/auth0-spa-js';
import randomBytes from 'randombytes';
import authSettings from './authSettings.js';
import envSettings from './envSettings';
// eslint-disable-next-line import/no-cycle
import { replaceCharacters } from '../redux/sagas/preRender/auth/getCredentials/redirectToIdP.saga.js';

// the auth0-spa-js library doesn't give us yet a way to overwrite/extract the CACHE_KEY_PREFIX
// loading it hardcoded here
export const CACHE_KEY_PREFIX = '@@auth0spajs@@';

export const LINK_ACCOUNTS_CUSTOM_SCOPE = 'openidsocial';
export const USER_ID_METADATA_KEY = 'https://edu.oup.com/UserId';
export const LINK_ACCOUNT_STATUS_KEY = 'https://edu.oup.com/linkAccountStatus';
export const LINK_ACCOUNTS_STATUS = {
  success: 'success',
  alreadyLinked: 'alreadyLinked',
  error: 'error'
};

async function initAuth0(linkAccounts = false, options = {}) {
  const oidcClientId = options.client_id || authSettings.oidcClientId;
  const pathname = options.redirect_uri || `${window.location.protocol}//${window.location.host}`;
  const nonce = replaceCharacters(randomBytes(32));
  const parsedDomain = authSettings.idpUrl.replace(/(^\w+:|^)\/\//, '');
  const audience = options.audience || envSettings.idp.auth0.audience;
  const params = {
    domain: parsedDomain,
    clientId: oidcClientId,
    useRefreshTokens: true,
    useCookiesForTransactions: true,
    authorizationParams: {
      redirect_uri: pathname,
      audience,
      nonce,
      ...options
    }
  };

  // keeping it outside of the params object because of typescript lint error
  params.cacheLocation = 'localstorage';

  if (linkAccounts) {
    params.authorizationParams.scope = LINK_ACCOUNTS_CUSTOM_SCOPE;
  }

  const auth0 = await createAuth0Client(params);
  return auth0;
}

export default initAuth0;
