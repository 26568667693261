const loadLMSConfig = (loadLMSConfigTools, orgId, userId) => {
  const defaultPeopleConfig = { orgId, userId };
  const searchAsYouType = false;

  const lmsConfigTypes = [
    {
      title: 'lmsConfig',
      config: {
        ...defaultPeopleConfig
      }
    }
  ];

  lmsConfigTypes.forEach(lmsConfigType => {
    loadLMSConfigTools(lmsConfigType.title, searchAsYouType, lmsConfigType.config);
  });
};

export default loadLMSConfig;
