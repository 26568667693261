// CONSTANTS
import userRoles from '../../../../globals/userRoles';
import { HubLayoutConstants } from '../../../../globals/hubConstants';

const hasExternalLink = ({ userRole, hasLink, isTeacherAssignedToClass, licencesContext }) => {
  const {
    LICENCES_CONTEXT: { CLASSWORK }
  } = HubLayoutConstants;
  return (
    (userRole === userRoles.LEARNER && hasLink) ||
    (hasLink && licencesContext === CLASSWORK && isTeacherAssignedToClass)
  );
};

export default hasExternalLink;
