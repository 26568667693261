import React from 'react';
import PropTypes, { oneOfType } from 'prop-types';
import classnames from 'classnames';
import { Link as RouterLink } from 'react-router-dom';

import Link from '@oup/shared-front-end/src/components/Link';
import isExternalLink from '@oup/shared-front-end/src/utils/isExternalLink';
import isResourceLink from '@oup/shared-front-end/src/utils/isResourceLink';

import styles from './LastOpenedItem.scss';

function LastOpenedItem({ title = '', subtitle = '', to = '', imageOrIcon, onClick = null }) {
  return (
    <Link
      className={styles.container}
      to={to}
      target={isResourceLink(to) ? '_self' : '_blank'}
      component={isExternalLink(to) ? 'a' : RouterLink}
      onClick={onClick}
    >
      <div className={styles.childContainer}>{imageOrIcon}</div>
      <div>
        <div className={classnames(styles.title, { [styles.noSubtitle]: !subtitle })}>{title}</div>
        {subtitle && (
          <div className={styles.subtitleContainer}>
            <div className={styles.subtitle}>{subtitle}</div>
          </div>
        )}
      </div>
    </Link>
  );
}

LastOpenedItem.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  to: PropTypes.string.isRequired,
  imageOrIcon: PropTypes.node,
  onClick: oneOfType([PropTypes.func, null])
};

export default LastOpenedItem;
