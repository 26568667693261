import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useWizard } from '@oup/shared-front-end/src/components/Wizard';
import { InformationBox } from '@oup/shared-front-end/src';
import WizardStep from '@oup/shared-front-end/src/components/Wizard/WizardStep';
import TextInput from '@oup/shared-front-end/src/components/TextInput/TextInput.js';
import TextLink from '@oup/shared-front-end/src/components/TextLink';
import styles from './RedeemCodeWizard.scss';

function EnterRedeemCodeStep({ content }) {
  const { setIsPrimaryButtonDisabled } = useWizard();
  const [redeemCodeValue, setRedeemCodeValue] = useState('');

  useEffect(() => {
    setIsPrimaryButtonDisabled(!redeemCodeValue);
  }, [redeemCodeValue]);

  return (
    <div data-testid="REDEEM_CODE_WIZARD_STEP_2" className={styles.enterRedeemCodeStepContainer}>
      <WizardStep titleText={content.check_your_access_code} className={styles.enterCodeContent}>
        <TextInput
          id="redeemCode"
          name="redeemCode"
          label={content.enter_access_code}
          value={redeemCodeValue}
          onChange={e => setRedeemCodeValue(e.target.value)}
        />
      </WizardStep>

      <div className={styles.rightColumnContainer}>
        <InformationBox title={content.about_codes}>
          <div>
            <p>{content.about_codes_content_1}</p>
            <p>{content.about_codes_content_2}</p>
            <p>{content.about_codes_content_3}</p>
            <div className={styles.linksContainer}>
              <span>
                <TextLink to={content.need_help_with_your_code_link} target="_blank">
                  {content.need_help_with_your_code}
                </TextLink>
              </span>
              <span>
                <TextLink to={content.buy_a_code_link} target="_blank">
                  {content.buy_a_code}
                </TextLink>
              </span>
            </div>
          </div>
        </InformationBox>
      </div>
    </div>
  );
}

EnterRedeemCodeStep.propTypes = {
  content: PropTypes.object.isRequired
};

export default EnterRedeemCodeStep;
