import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import moment from 'moment';
import classnames from 'classnames';
import { compose } from 'recompose';
import { connect, useSelector } from 'react-redux';
// Style
import styles from '../ListItems.scss';
// Utils
import getOptProductsCatalogueUrl from '../../../utils/opt/getOptProductsCatalogueUrl';
import openProductPage from '../../../utils/openProductPage';
import getProductUrl from '../../../utils/getProductUrl';
// Constants
import { licenceStatus as LICENCE_STATUS, PLATFORMS, HubLayoutConstants } from '../../../globals/hubConstants.js';
import { ARCHIVE_STATUS } from '../../../globals/appConstants';
import colors from '../../../globals/colors.js';

// Services
import { sanitizeUrl } from '../../../utils/url';
import getLicencesStatusData from '../../../structure/HubOrganizationLayout/OrganizationLicences/services/getLicencesStatusData';
import getDropdownItems from '../../HubDropdownMenu/Services/getDropdownItems';
import getUserLicencesStatusData from '../../../structure/HubOrganizationLayout/OrganizationLicences/services/getUserLicencesStatusData';
import hasExternalLink from '../../../structure/HubOrganizationLayout/OrganizationLicences/services/hasExternalLink';
import hasContextId from '../../../structure/HubOrganizationLayout/OrganizationLicences/services/hasContextId';
import getManageClassAssignmentStatus from '../../../structure/HubOrganizationLayout/OrganizationLicences/services/getManageClassAssignmentStatus';
// Components
import HubDropdownMenu from '../../HubDropdownMenu/HubDropdownMenu';
import HubLicenceStatusItem from '../../../structure/HubOrganizationLayout/OrganizationLicences/HubLicenceStatusItem';
import RocketLolly from '../../RocketLolly/RocketLolly';
import Link from '../../Link/Link';
import HubLabel from '../../HubLabel/HubLabel';
import SVGIcon, { GLYPHS } from '../../SVGIcon/SVGIcon';
import {
  HubFallbackImg as FallbackImage,
  HubAssessmentFallbackImgSmallCircle as AssessmentFallbackImage
} from '../../HubImage';
import { featureIsEnabled, getPlatformBaseUrl } from '../../../globals/envSettings.js';
import { getCurrentPlatform, isHubMode, isLtiMode } from '../../../utils/platform';
import { openDownloadProductModal } from '../../../redux/actions/hubUi.js';
import { loadCptContentRequest, setContent } from '../../../redux/actions/structuredContentPlayer';

const { isTeacherOrAbove } = require('@oup/shared-node-browser/user.js');

function LicenceListItem({
  currentUsersList: currentLicencesList,
  userAssignments = {},
  classTeachersId,
  classStudentsId,
  id,
  breakpoint,
  hubContent,
  licencesContext,
  materialActions,
  dropdownActions: {
    toggleDetails,
    toggleMaterialsToClassModal,
    recallLicence,
    removeClassMaterial,
    manageProductLocks
  },
  isTeacherAssignedToClass,
  userRole,
  dropdownItemsType,
  totalUsers,
  classroomId,
  dropdownTop,
  isUserProfilePage,
  showStatus = true,
  showDropdown = true,
  showRocketLolly = false,
  currentOrganisationLti = false,
  productInformationContext = '',
  isOrgPlacementCentre,
  openDownloadProductModalAction,
  loadCptContentRequestAction
}) {
  const OPT_MAIN_FEATURE = featureIsEnabled('opt-main-features');
  const currentOrganisationId = useSelector(state => state.identity.currentOrganisationId);
  const { customId: customOrgId = '' } = useSelector(state => state.organisations.data[currentOrganisationId]);
  const item = get(currentLicencesList, id);
  const {
    title = '',
    series = '',
    coverImage = '',
    cover = '',
    availableCount = 0,
    assignedCount = 0,
    redeemedCount,
    credits = 0,
    allocatedCredits = 0,
    consumedCredits = 0,
    isbn = '',
    target_usertype: usertype = '',
    productLaunchUrl = '',
    platform = '',
    linkedProductDetails = {},
    archiveStatus,
    archiveDate
  } = item;

  const {
    LICENCES_CONTEXT: { CLASS, ORG, CLASSWORK, CLASSWORK_MANAGE, CLASS_ONBOARDING_WIZARD },
    TARGET_USERTYPE,
    DOWNLOAD_TYPES
  } = HubLayoutConstants;
  const { EXPIRED, NO_LICENCE, ACTIVE, EXPIRING, NOT_STARTED } = LICENCE_STATUS;
  const hasLinkedProducts = !!Object.keys(linkedProductDetails).length;
  const [linkedProdsDisplay, setLinkedProdsDisplay] = useState(false);

  const groupContextId = hasContextId({ userRole, isTeacherAssignedToClass, licencesContext }) ? classroomId : null;
  const { openRedeemModalAction } = materialActions || {};

  const itemLabel = (itemType = '') => {
    let label;

    switch (true) {
      case !hasLinkedProducts && usertype === TARGET_USERTYPE.TEACHER:
        label = hubContent.classwork_teacher_label;
        break;
      case !hasLinkedProducts && usertype === TARGET_USERTYPE.STUDENT:
        label = hubContent.classwork_student_label;
        break;
      case !itemType && hasLinkedProducts && usertype === TARGET_USERTYPE.TEACHER:
        label = hubContent.teacher_collection_label;
        break;
      case !itemType && hasLinkedProducts && usertype === TARGET_USERTYPE.STUDENT:
        label = hubContent.student_collection_label;
        break;
      case !itemType && hasLinkedProducts:
        label = hubContent.classwork_collection_label;
        break;
      default:
    }

    return label;
  };

  const { userWithoutLicence = [] } = userAssignments[id] || {};
  const { icon = '', fill = '', text = '', date = '', status = '', hasLink = false } = licencesContext
    ? getUserLicencesStatusData(
        licencesContext === CLASS ||
          licencesContext === ORG ||
          licencesContext === CLASSWORK ||
          licencesContext === CLASS_ONBOARDING_WIZARD
          ? userAssignments[id]
          : userAssignments[id].productDetails,
        hubContent
      )
    : getLicencesStatusData(OPT_MAIN_FEATURE && isOrgPlacementCentre ? credits : availableCount);

  const isLicenceRemoved = archiveStatus === ARCHIVE_STATUS.ARCHIVED;

  const { manageIcon, manageFill, manageText, manageLicenceStatus } = getManageClassAssignmentStatus({
    usertype,
    userWithoutLicence,
    totalUsers,
    hubContent,
    isLicenceRemoved,
    classTeachersId,
    classStudentsId
  });

  const licenceStatus = licencesContext === CLASSWORK_MANAGE ? manageLicenceStatus : status;
  const isLicenceActive = licenceStatus === ACTIVE || licenceStatus === EXPIRING || licenceStatus === NOT_STARTED;
  const isLicenceExpired = licenceStatus === EXPIRED || licenceStatus === NO_LICENCE;

  const getLicenceData = (productInfo, productId) => {
    const licence =
      productInfo &&
      productInfo[productId] &&
      (productInfo[productId].licenceDetails || productInfo[productId].licenceNotStartedDetails);
    const activationCode = licence && licence.activationCode;
    const assignments = productInfo && productInfo[productId] && productInfo[productId].learningAssignments;
    const finalAssignment = assignments.filter(
      assignment =>
        assignment.activationCode === activationCode && assignment.learningAssignmentId === licence.licenceId
    )[0];
    return {
      learningAssignmentId: finalAssignment.learningAssignmentId,
      activationCode
    };
  };

  const actions = {
    toggleMaterialsToClassModal: () => toggleMaterialsToClassModal(id),
    redirectToBuyMoreLicences:
      OPT_MAIN_FEATURE && isOrgPlacementCentre
        ? getOptProductsCatalogueUrl(customOrgId)
        : `https://elt.oup.com/searchresults?q=${isbn}`,
    toggleDetails: e => {
      e.preventDefault();
      e.stopPropagation();
      toggleDetails(id, null, null, productInformationContext);
    },
    recallLicenceAction: () => recallLicence(getLicenceData(userAssignments, id)),
    assignLicencesToClass: e => {
      e.preventDefault();
      e.stopPropagation();
      toggleMaterialsToClassModal(id);
    },
    downloadProduct: e => {
      e.preventDefault();
      e.stopPropagation();

      const image = coverImage || cover;
      const role = isTeacherOrAbove(userRole) ? TARGET_USERTYPE.TEACHER : TARGET_USERTYPE.STUDENT;

      loadCptContentRequestAction({ contentCode: item.contentCode, role, isOcp: true });
      openDownloadProductModalAction(item.contentCode, title, image, item.isbn);
    },
    removeMaterialFromClass: e => {
      e.preventDefault();
      e.stopPropagation();
      removeClassMaterial([id]);
    },
    manageStudentsAccess: e => {
      e.preventDefault();
      e.stopPropagation();
      manageProductLocks({ isbn, coverImage, title });
    }
  };

  const launchVstProduct = (
    platformContext = platform,
    isbnContext = isbn,
    vstDomain = get(item, 'vstDomain'),
    assignmentId = id,
    contextId = groupContextId
  ) => {
    if (vstDomain || platformContext === 'VST') {
      const queryParams = {
        domain: vstDomain,
        assignmentId,
        contextId
      };
      const productUrl = getProductUrl(`/product/${DOWNLOAD_TYPES.VST}/${isbnContext}`, queryParams);
      openProductPage(productUrl);
    }
  };

  const handleClick = e => {
    e.stopPropagation();
    e.preventDefault();

    if (isLtiMode()) {
      return;
    }

    if (hasLinkedProducts) {
      setLinkedProdsDisplay(!linkedProdsDisplay);
      return;
    }

    if (!hasLink && licencesContext === CLASSWORK) {
      openRedeemModalAction();
      return;
    }

    if (licencesContext === CLASSWORK_MANAGE) {
      toggleMaterialsToClassModal(id);
      return;
    }

    if (!hasLink) {
      return;
    }

    if (!isUserProfilePage) {
      launchVstProduct();
    }

    if (platform === PLATFORMS.ELTCORE) {
      const currentPlatform = getCurrentPlatform();
      const platformBaseUrl = getPlatformBaseUrl(currentPlatform);
      window.open(`${platformBaseUrl}/launch/${item.target_usertype}/${item.contentCode}`, '_blank');
    }
  };

  const handleLinkedProductClick = (e, productDetails, linkedProductId) => {
    e.stopPropagation();

    if (licencesContext === CLASSWORK && productDetails.platform === PLATFORMS.CBA) {
      e.preventDefault();
    }

    if (licencesContext === CLASSWORK && (status === EXPIRED || status === NO_LICENCE)) {
      e.preventDefault();
      openRedeemModalAction();
      return;
    }

    if (licencesContext === CLASSWORK_MANAGE) {
      e.preventDefault();
      toggleMaterialsToClassModal(id);
      return;
    }

    if (!hasLink) {
      e.preventDefault();
      return;
    }

    if (!isUserProfilePage && (productDetails?.vstDomain || productDetails.platform === PLATFORMS.VST)) {
      e.preventDefault();
      launchVstProduct(productDetails.platform, productDetails.isbn, productDetails?.vstDomain, linkedProductId);
    }
  };

  let statusDetails = {
    icon,
    fill,
    text,
    date,
    assignedCount,
    availableCount,
    redeemedCount,
    isLicenceRemoved,
    status,
    archiveDate: moment(archiveDate).format('L'),
    manageClassData: {
      manageIcon,
      manageFill,
      manageText,
      manageLicenceStatus
    }
  };
  if (OPT_MAIN_FEATURE && isOrgPlacementCentre)
    statusDetails = {
      ...statusDetails,
      assignedCount: allocatedCredits,
      availableCount: credits,
      redeemedCount: consumedCredits
    };
  const showManageProductLocksOption =
    featureIsEnabled('content-lock-unlock') && platform === PLATFORMS.ELTCORE && usertype !== TARGET_USERTYPE.TEACHER;

  const showDownloadProductOption =
    featureIsEnabled('hub-offline-downloads') && platform === PLATFORMS.ELTCORE && isHubMode();

  const dropdownOptions = {
    status,
    isTeacherAssignedToClass,
    isLicenceRemoved,
    showManageProductLocksOption,
    showDownloadProductOption
  };

  const showLink = hasExternalLink({ userRole, hasLink, isTeacherAssignedToClass, licencesContext });
  const removedStyle = isLicenceRemoved ? styles.imgRemovedLicence : '';
  const showLockImageStyle =
    !isOrgPlacementCentre &&
    isLicenceExpired &&
    licencesContext !== CLASSWORK_MANAGE &&
    licencesContext !== CLASS_ONBOARDING_WIZARD;
  const image = coverImage || cover;
  const linkTo = showLink && !hasLinkedProducts && !isUserProfilePage ? productLaunchUrl : null;
  const linkOnClick =
    !showLink || hasLinkedProducts || platform === 'VST' || platform === PLATFORMS.ELTCORE ? handleClick : null;
  return (
    <div data-list="product" data-testid="LICENCE_LIST_ITEM" className={!linkedProdsDisplay ? styles.collapsed : ''}>
      <Link
        to={isLtiMode() ? null : linkTo}
        onClick={isLtiMode() ? null : linkOnClick}
        className={classnames(
          styles.product,
          styles.topLevel,
          { [styles[licenceStatus] || '']: licenceStatus },
          { [styles.onboardingWizardLicence]: licencesContext === CLASS_ONBOARDING_WIZARD }
        )}
      >
        <>
          <div className={!showStatus && !showDropdown ? styles.leftContainerWide : styles.leftContainer}>
            <div
              className={`${styles.imgContainer} ${
                hasLinkedProducts ? `${styles.hasLinkedProducts} ${removedStyle}` : ''
              } ${OPT_MAIN_FEATURE && isOrgPlacementCentre ? styles.optProductCover : styles.nonOptProductCover}`}
            >
              {showLockImageStyle && (
                <div className={styles.lockIconContainer}>
                  <SVGIcon glyph={GLYPHS.ICON_LOCK_24} />
                </div>
              )}
              {platform === 'CBA' && (
                <AssessmentFallbackImage
                  customClassname="svgFullWidth"
                  className={showLockImageStyle ? styles.lockedImage : ''}
                  breakpoint={breakpoint}
                />
              )}

              {image && platform !== 'CBA' && (
                <img
                  className={showLockImageStyle ? styles.lockedImage : ''}
                  src={sanitizeUrl(image)}
                  alt={`${title} ${series}`}
                />
              )}

              {!image && platform !== 'CBA' && (
                <FallbackImage
                  customClassname="svgFullWidth"
                  className={showLockImageStyle ? styles.lockedImage : ''}
                  breakpoint={breakpoint}
                />
              )}
            </div>
            <div
              className={`${styles.textContainer} ${
                showLink && !hasLinkedProducts && !isUserProfilePage ? styles.licenceInfoContainerLink : ''
              }`}
            >
              {itemLabel() && (
                <div className={styles.labelsContainer}>
                  <HubLabel
                    customClassName={styles.labelPill}
                    text={itemLabel()}
                    isCollapsed={!linkedProdsDisplay}
                    PrefixIcon={hasLinkedProducts && <SVGIcon glyph={GLYPHS.CHEVRON_DOWN} />}
                  />
                  {hasLinkedProducts && (
                    <span className={styles.linkedProductsIcon}>
                      <SVGIcon glyph={GLYPHS.ICON_COURSE} />
                      {Object.keys(linkedProductDetails).length}
                    </span>
                  )}
                </div>
              )}
              <div className={classnames(styles.innerTextContainer, isLicenceActive ? styles.activeLinkText : '')}>
                <span>
                  {title}
                  {!currentOrganisationLti && hasLink && !hasLinkedProducts && !isUserProfilePage && (
                    <SVGIcon glyph={GLYPHS.ICON_LINK} />
                  )}
                </span>
              </div>
              {licencesContext === CLASS_ONBOARDING_WIZARD ? (
                <div className={styles.pendingStateInfo}>
                  <SVGIcon glyph={GLYPHS.ICON_INFORMATION_CIRCLE} />
                  <span>{hubContent.assigning_material_to_the_class}</span>
                </div>
              ) : null}
            </div>
          </div>
          {showStatus && OPT_MAIN_FEATURE && (
            <div className={styles.statusUsedLicencesContainer}>
              <span> {statusDetails.redeemedCount}</span>
            </div>
          )}
          {showStatus && showRocketLolly ? (
            <div className={styles.statusLicenceContainer}>
              {OPT_MAIN_FEATURE ? (
                <RocketLolly
                  items={[
                    {
                      text: hubContent.product_not_started_text,
                      value: statusDetails.assignedCount,
                      color: colors.DARK_BLUE
                    },
                    {
                      text: hubContent.product_avalible_text,
                      value: statusDetails.availableCount,
                      color: colors.LIGHT_BLUE
                    }
                  ]}
                />
              ) : (
                <RocketLolly
                  items={[
                    {
                      text: hubContent.product_started_text,
                      value: statusDetails.redeemedCount
                    },
                    {
                      text: hubContent.product_not_started_text,
                      value: statusDetails.assignedCount
                    },
                    {
                      text: hubContent.product_avalible_text,
                      value: statusDetails.availableCount
                    }
                  ]}
                />
              )}
            </div>
          ) : null}
          {showStatus && !showRocketLolly ? (
            <div className={styles.statusLicenceContainer}>
              <HubLicenceStatusItem
                licencesContext={licencesContext}
                statusDetails={statusDetails}
                hubContent={hubContent}
                styles={styles}
                breakpoint={breakpoint}
              />
            </div>
          ) : null}
          {showDropdown ? (
            <div className={styles.dropdownContainer}>
              <HubDropdownMenu
                dropdownData={getDropdownItems(
                  dropdownItemsType,
                  hubContent,
                  dropdownOptions,
                  actions,
                  userRole,
                  false,
                  currentOrganisationLti
                )}
                parentId={id}
                customClassname={styles.dropDownMenu}
                dropdownTop={dropdownTop}
              />
            </div>
          ) : null}
        </>
      </Link>

      {hasLinkedProducts && (
        <div className={styles.linkedProductsContainer}>
          <div className={styles.innerLinkedContainer}>
            {Object.keys(linkedProductDetails).map(linkedProductId => (
              <Link
                key={linkedProductId}
                to={showLink && !isUserProfilePage ? linkedProductDetails[linkedProductId].productLaunchUrl : null}
                onClick={e => handleLinkedProductClick(e, linkedProductDetails[linkedProductId], linkedProductId)}
                className={styles.product}
              >
                <div className={styles.linkedProductItem}>
                  <div className={styles.leftContainer}>
                    <div className={styles.leftContainerContent}>
                      <div className={`${styles.imgContainer} ${removedStyle}`}>
                        {isLicenceExpired && licencesContext !== CLASSWORK_MANAGE && (
                          <div className={styles.lockIconContainer}>
                            <SVGIcon glyph={GLYPHS.ICON_LOCK_24} />
                          </div>
                        )}
                        {linkedProductDetails[linkedProductId].platform === 'CBA' && (
                          <AssessmentFallbackImage
                            customClassname="svgFullWidth"
                            className={
                              isLicenceExpired && licencesContext !== CLASSWORK_MANAGE ? styles.lockedImage : ''
                            }
                            breakpoint={breakpoint}
                          />
                        )}

                        {image && linkedProductDetails[linkedProductId].platform !== 'CBA' && (
                          <img
                            className={
                              isLicenceExpired && licencesContext !== CLASSWORK_MANAGE ? styles.lockedImage : ''
                            }
                            src={sanitizeUrl(linkedProductDetails[linkedProductId].coverImage)}
                            alt={`${linkedProductDetails[linkedProductId].title}`}
                          />
                        )}

                        {!image && linkedProductDetails[linkedProductId].platform !== 'CBA' && (
                          <FallbackImage
                            customClassname="svgFullWidth"
                            className={
                              isLicenceExpired && licencesContext !== CLASSWORK_MANAGE ? styles.lockedImage : ''
                            }
                            breakpoint={breakpoint}
                          />
                        )}
                      </div>
                      <div
                        className={`${styles.licenceInfoContainer} ${
                          showLink && !isUserProfilePage ? styles.licenceInfoContainerLink : ''
                        }`}
                      >
                        <div className={styles.licenceTitle}>
                          {itemLabel('collectionItem') && (
                            <HubLabel
                              text={itemLabel('collectionItem')}
                              isCollapsed={!linkedProdsDisplay}
                              PrefixIcon={hasLinkedProducts && <SVGIcon glyph={GLYPHS.CHEVRON_DOWN} />}
                            />
                          )}
                        </div>
                        <div className={styles.licenceTitle}>
                          <span>
                            {linkedProductDetails[linkedProductId].title}
                            {hasLink &&
                              !isUserProfilePage &&
                              linkedProductDetails[linkedProductId].platform !== PLATFORMS.CBA && (
                                <SVGIcon glyph={GLYPHS.ICON_LINK} />
                              )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

LicenceListItem.defaultProps = {
  licencesContext: '',
  userAssignments: {},
  dropdownActions: {}
};

LicenceListItem.propTypes = {
  currentUsersList: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  classStudentsId: PropTypes.array,
  classTeachersId: PropTypes.array,
  hubContent: PropTypes.object.isRequired,
  breakpoint: PropTypes.string.isRequired,
  dropdownActions: PropTypes.object,
  dropdownTop: PropTypes.bool,
  materialActions: PropTypes.object,
  licencesContext: PropTypes.string,
  userAssignments: PropTypes.object,
  isTeacherAssignedToClass: PropTypes.bool.isRequired,
  userRole: PropTypes.string,
  dropdownItemsType: PropTypes.string,
  totalUsers: PropTypes.number,
  classroomId: PropTypes.string,
  isUserProfilePage: PropTypes.bool,
  showStatus: PropTypes.bool,
  showDropdown: PropTypes.bool,
  showRocketLolly: PropTypes.bool,
  currentOrganisationLti: PropTypes.bool,
  productInformationContext: PropTypes.string,
  isOrgPlacementCentre: PropTypes.bool,
  openDownloadProductModalAction: PropTypes.func,
  loadCptContentRequestAction: PropTypes.func
};

const mapStateToProps = ({ hubUi: { downloadProductModalOpen } }) => ({
  downloadProductModalOpen
});

const mapDispatchToProp = {
  openDownloadProductModalAction: openDownloadProductModal,
  loadCptContentRequestAction: loadCptContentRequest,
  setContentAction: setContent
};

export default compose(connect(mapStateToProps, mapDispatchToProp))(LicenceListItem);
