import React from 'react';
import PropTypes from 'prop-types';
import TextLink from '@oup/shared-front-end/src/components/TextLink';
import ScrollContainer from '../ScrollContainer/ScrollContainer';
import PopoutPanelIllustrationHeading from '../PopoutPanelIllustrationHeading/PopoutPanelIllustrationHeading';
import PopoutActionFooter from '../PopoutActionFooter/PopoutActionFooter';
import { HubIllustrationConstants, HubIllustrationAltText } from '../../globals/hubConstants';
import styles from './BulkPasswordReset.scss';

function BulkPasswordResetConfirmation({ content, userCount, bulkPasswordReset }) {
  return (
    <ScrollContainer
      headerContent={
        <div className={styles.headerContainer}>
          <PopoutPanelIllustrationHeading
            title={content.bulk_password_reset_confirmation_title.replace('[nrOfStudents]', `${userCount}`)}
            subtitle={content.bulk_password_reset_confirmation_subtitle.replace(
              `[${content.sign_in_cards}]`,
              `<strong>${content.sign_in_cards}</strong>`
            )}
            illustrationSrc={HubIllustrationConstants.WARNING}
            illustrationAltText={HubIllustrationAltText.WARNING}
          />
          <div className={styles.linkContainer}>
            <TextLink to={content.what_is_sign_in_card_support_link} target="_blank" rel="noreferrer">
              {content.what_is_sign_in_card}
            </TextLink>
          </div>
        </div>
      }
      footerContent={
        <PopoutActionFooter
          primaryButtonAction={bulkPasswordReset}
          primaryButtonText={content.reset_passwords}
          primaryButtonTestHook="bulk-reset-password-button"
        />
      }
    />
  );
}

BulkPasswordResetConfirmation.propTypes = {
  content: PropTypes.object.isRequired,
  bulkPasswordReset: PropTypes.func.isRequired,
  userCount: PropTypes.string.isRequired
};

export default BulkPasswordResetConfirmation;
