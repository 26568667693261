import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import styles from './ProductDownload.scss';
import { closeDownloadProductModal } from '../../redux/actions/hubUi.js';
import withLocalizedContent from '../../language/withLocalizedContent.js';
import { getPlatformBaseUrl } from '../../globals/envSettings.js';
import PopoutPanel from '../PopoutPanel/PopoutPanel';
import OfflineCover from '../OfflineCover/OfflineCover';
import { MODAL_CLASSES } from '../../globals/cptConstants.js';
import Button, { buttonTypes } from '../Button/Button.js';
import InformationBox from '../InformationBox/InformationBox.js';

function ProductDownload({
  localizedContent: { productDownload: offlineContent },
  isOpen,
  content,
  closeDownloadProductModalAction,
  contentCode,
  productTitle,
  isbn,
  cptContent,
  userId,
  firstName,
  lastName
}) {
  const iframeRef = useRef(null);
  const [iframeReady, setIframeReady] = useState(false);

  const handleClosePanel = () => {
    closeDownloadProductModalAction();
  };

  useEffect(() => {
    const handleMessage = event => {
      if (event.data.type === 'IFRAME_READY') {
        setIframeReady(true);
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  useEffect(() => {
    const sendMessageToIframe = () => {
      const iframeOrigin = getPlatformBaseUrl('ocp');

      const message = {
        type: 'PRODUCT_DOWNLOAD_CONTENT_CODE',
        payload: { contentCode, productTitle, userId, cptContent, firstName, lastName }
      };
      iframeRef.current.contentWindow.postMessage(message, iframeOrigin);
    };

    if (cptContent && iframeReady) {
      sendMessageToIframe();
    }
  }, [cptContent, iframeReady]);

  const iframeUrl = `${getPlatformBaseUrl('ocp')}/product-download/`;

  return (
    <div className={styles.productDownloadContainer}>
      <PopoutPanel
        id="productDownloadModal"
        ariaLabel={content.renegerate_sign_in_card_aria_label}
        isOpen={isOpen}
        onClose={() => {
          handleClosePanel();
        }}
        className={isOpen ? MODAL_CLASSES.OCP_PRODUCT_DOWNLOAD_MODAL : ''}
      >
        <div className={styles.lockContentContainer}>
          <div className={styles.productDownloadCloseButtonContainer}>
            <Button
              dataTestId="ONBOARDING_WIZARD_WARNING_MODAL_CLOSE_BUTTON"
              text={content.close}
              type={buttonTypes.CLOSE_WHITE}
              onClick={e => {
                e.stopPropagation();
                handleClosePanel();
              }}
            />
          </div>
          <div className={styles.headerContent}>
            <p>{offlineContent.title}</p>
            <p>{offlineContent.description}</p>
          </div>
          <div className={styles.productDetailsContainer}>
            <div className={styles.imageContainer}>
              <OfflineCover isbn={isbn} alt={productTitle} />
            </div>
            <div className={styles.titleContainer}>
              <p>{productTitle}</p>
            </div>
          </div>
          <div className={styles.informationBarContainer}>
            <InformationBox
              title={offlineContent.info_text_line_1.replace(
                'MY_DOWNLOADS',
                `${getPlatformBaseUrl('ocp')}/downloads/${userId}/`
              )}
              firstMessage={offlineContent.info_text_line_2}
              isFirstHtml
              isTitleHtml
              isTitleBold={false}
            />
          </div>
          <div className={styles.tabContainer}>
            <iframe ref={iframeRef} className={styles.iframe} src={iframeUrl} title="product_download" />
          </div>
        </div>
      </PopoutPanel>
    </div>
  );
}

ProductDownload.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  content: PropTypes.object.isRequired,
  closeDownloadProductModalAction: PropTypes.func,
  contentCode: PropTypes.string,
  productTitle: PropTypes.string,
  isbn: PropTypes.string,
  userId: PropTypes.string,
  cptContent: PropTypes.object,
  firstName: PropTypes.string,
  lastName: PropTypes.string
};

const mapStateToProps = ({
  hubUi: { contentCode, productTitle, isbn },
  userProfile: { userId, firstName, lastName },
  structuredContentPlayer: { cptContent }
}) => ({
  contentCode,
  productTitle,
  isbn,
  userId,
  cptContent,
  firstName,
  lastName
});

const mapDispatchToProp = {
  closeDownloadProductModalAction: closeDownloadProductModal
};

export default compose(
  withLocalizedContent('productDownload'),
  connect(mapStateToProps, mapDispatchToProp)
)(ProductDownload);
