import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import pick from '../../utils/object/pick';
import actions from '../../redux/actions';
// Components
import ContactUsOlb from '../../components/ContactUsOlb/ContactUsOlb';

class HubSupportContactUs extends Component {
  componentDidMount() {
    const { selectProduct } = this.props;
    selectProduct('HUB');
  }

  render() {
    const {
      props: {
        validateInput,
        submit,
        reset,
        products: {
          HUB: { submitting, failure, success, errors }
        }
      }
    } = this;
    return (
      <div>
        <ContactUsOlb
          validateInput={validateInput}
          submit={submit}
          reset={reset}
          submitting={submitting}
          failure={failure}
          success={success}
          errors={errors}
        />
      </div>
    );
  }
}

HubSupportContactUs.propTypes = {
  products: PropTypes.object.isRequired,
  validateInput: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  selectProduct: PropTypes.func.isRequired
};

export default connect(
  state => pick(state.contactUs, ['selectedProduct', 'products']),
  dispatch => ({
    selectProduct: input => {
      dispatch(actions.setContactUsChangeProductSelection(input));
    },
    validateInput: input => {
      dispatch(actions.validateContactUsInputFragment(input));
    },
    submit: input => {
      dispatch(actions.submitContactUsRequest(input));
    },
    reset: platform => {
      dispatch(actions.resetContactUs(platform));
    }
  })
)(HubSupportContactUs);
