import React from 'react';
import PropTypes from 'prop-types';
import PopoutPanelIconHeading, { types } from '../PopoutPanelIconHeading/PopoutPanelIconHeading.js';

function RegenerateSignInCardProcessing({ content }) {
  return (
    <PopoutPanelIconHeading type={types.LOADING} title={content.please_wait} subtitle={content.creating_sign_in_card} />
  );
}

RegenerateSignInCardProcessing.propTypes = {
  content: PropTypes.object.isRequired
};

export default RegenerateSignInCardProcessing;
