import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Table from '../Table/Table';
import styles from './ProductSelection.scss';

/**
 * Quick component to display different content based
 * off breakpoints.
 */
function AdaptiveColumn({ children, large }) {
  return (
    <span>
      <span className="sm-hide">{children}</span>
      <span className="xs-hide sm-show">{large}</span>
    </span>
  );
}
/**
 * Component renders a progress bar based on a completed/total value.
 */
function ProductSelectionV2({
  products,
  gradedReaders = false,
  heading,
  localizedContent,
  isMyProgress,
  enhancedLayout = false
}) {
  const workbookProductColumns = [
    { heading: <AdaptiveColumn large={heading}>{heading}</AdaptiveColumn>, noBorderHeader: true },
    {
      heading: isMyProgress ? (
        <AdaptiveColumn large={localizedContent.completion}>{localizedContent.completionShort}</AdaptiveColumn>
      ) : (
        <AdaptiveColumn large={localizedContent.averageCompletion}>
          {localizedContent.averageCompletionShort}
        </AdaptiveColumn>
      ),
      centeredHeader: true
    },
    {
      heading: isMyProgress ? (
        <AdaptiveColumn large={localizedContent.score}>{localizedContent.scoreShort}</AdaptiveColumn>
      ) : (
        <AdaptiveColumn large={localizedContent.averageScore}>{localizedContent.averageScoreShort}</AdaptiveColumn>
      )
    }
  ];

  const gradedReadersProductColumns = [
    { heading },
    { heading: localizedContent.averageCompletion },
    {
      heading: (
        <AdaptiveColumn large={localizedContent.averageBooks}>{localizedContent.averageBooksShort}</AdaptiveColumn>
      )
    },
    {
      heading: (
        <AdaptiveColumn large={localizedContent.mostReadLevel}>{localizedContent.averageLevelShort}</AdaptiveColumn>
      )
    }
  ];

  const columns = gradedReaders ? gradedReadersProductColumns : workbookProductColumns;

  return (
    <div className="row">
      <Table
        columns={columns}
        rows={products}
        customClass={classnames(styles.productSelectionTable, {
          [styles.gradedReaders]: gradedReaders,
          [styles.enhancedLayout]: enhancedLayout
        })}
        persistantColumns
      />
      {!products.length ? <span className="pad-top1">{localizedContent.noProductsFound}</span> : null}
    </div>
  );
}

ProductSelectionV2.propTypes = {
  localizedContent: PropTypes.object,
  products: PropTypes.array.isRequired,
  gradedReaders: PropTypes.bool,
  heading: PropTypes.string,
  isMyProgress: PropTypes.bool,
  enhancedLayout: PropTypes.bool
};

AdaptiveColumn.propTypes = {
  children: PropTypes.string.isRequired,
  large: PropTypes.string
};

export default ProductSelectionV2;
