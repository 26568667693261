const getPeopleCheckboxData = (
  role,
  selectStudent,
  selectedStudentIds,
  selectAllStudents = null,
  areAllStudentsSelected = false,
  checkIfAllStudentsAreSelectedAction,
  setAllStudentsSelectedAction,
  selectTeacher,
  selectedTeacherIds,
  selectAllTeachers = null,
  areAllTeachersSelected = false,
  checkIfAllTeachersAreSelectedAction,
  setAllTeachersSelectedAction
) => {
  const data = {
    LEARNER: {
      onSelect: selectStudent,
      onSelectAll: selectAllStudents,
      checkAllSelected: checkIfAllStudentsAreSelectedAction,
      areAllSelected: areAllStudentsSelected,
      selectedIds: selectedStudentIds,
      setAllSelected: setAllStudentsSelectedAction
    },
    TEACHER: {
      onSelect: selectTeacher,
      onSelectAll: selectAllTeachers,
      checkAllSelected: checkIfAllTeachersAreSelectedAction,
      areAllSelected: areAllTeachersSelected,
      selectedIds: selectedTeacherIds,
      setAllSelected: setAllTeachersSelectedAction
    }
  };

  return data[role];
};

export default getPeopleCheckboxData;
