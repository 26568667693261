import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import classnames from 'classnames';
import { orgRoles, orgRoleDescriptions, ORG_STATUS } from '@oup/shared-node-browser/org';
import { useParams } from 'react-router-dom';
import { userRoleNameDefaults, getPluralFormOfRole } from '@oup/shared-node-browser/user';
import { isEmpty } from 'lodash';
import PageHeading from '../PageHeading/PageHeading';
import Button, { buttonTypes } from '../Button/Button';
import OrganizationStatus from '../OrganizationStatus/OrganizationStatus';
import withLocalizedContent from '../../language/withLocalizedContent';
import actions from '../../redux/actions';
import { mode } from '../../redux/reducers/orgDetails';
import CopyToClipboardButton from '../CopyToClipboardButton/CopyToClipboardButton';
import Badge from '../Badge/Badge';
import colors from '../../globals/colors';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import styles from './OrganizationDetails.scss';
import LinkWithIcon from '../LinkWithIcon/LinkWithIcon';
import { EditOrgPanel } from '../../panels';
import { setActiveOrgId } from '../../redux/reducers/organisationPage.reducer';

function Contact({ localizedContent, data }) {
  return (
    <div className="col md4">
      <PageHeading subtitle={localizedContent.heading_contact} />
      {data ? (
        <a className="gin-top1" href={`mailto:${data}`} title={data}>
          {data}
        </a>
      ) : (
        <span className={styles.muted}>{localizedContent.message_no_contact_details}</span>
      )}
    </div>
  );
}

Contact.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired
};

function Users({ localizedContent, data }) {
  return (
    <div className="col md4">
      <PageHeading subtitle={localizedContent.heading_users} />
      {!isEmpty(data) ? (
        <div className="gin-top1">
          {Object.keys(data).map(el => (
            <div key={el}>
              {getPluralFormOfRole(userRoleNameDefaults[el] || el, 's')}{' '}
              <Badge key={el} backgroundColor={colors[el] || colors.PRIMARY_BLUE_HOVER} value={data[el].length} />
            </div>
          ))}
        </div>
      ) : (
        <span className={styles.muted}>{localizedContent.message_no_users}</span>
      )}
    </div>
  );
}

Users.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired
};

function Actions({ localizedContent, orgId, orgRole }) {
  const isOicSchool = orgRole === orgRoles.OIC_SCHOOL;

  const actionsList = [
    {
      title: localizedContent.action_view_organization,
      link: `/org/${orgId}`,
      visible: true
    },
    {
      title: localizedContent.action_add_licence,
      link: `/dashboard/org/${orgId}/licence`,
      visible: true
    },
    {
      title: localizedContent.action_update_licence,
      link: `/dashboard/org/${orgId}/update-licence`,
      visible: true
    },
    {
      title: localizedContent.action_remove_licence,
      link: '/dashboard/user/remove-licence',
      visible: true
    },
    {
      title: localizedContent.action_manage_test_credits,
      link: `/dashboard/org/${orgId}/test-credits`,
      visible: isOicSchool
    },
    {
      title: localizedContent.action_manage_credits,
      link: `/dashboard/org/${orgId}/licence-credits`,
      visible: true
    }
  ];

  return (
    <div className="col">
      <PageHeading subtitle="Actions" />
      <ol className="gin-top2">
        {actionsList
          .filter(({ visible }) => visible)
          .map(({ title, link }) => (
            <li key={link}>
              <LinkWithIcon
                to={link}
                text={title}
                customClassName={styles.link}
                externalLink
                glyph={GLYPHS.ICON_LINK}
              />
            </li>
          ))}
      </ol>
    </div>
  );
}

Actions.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  orgId: PropTypes.string.isRequired,
  orgRole: PropTypes.string.isRequired
};

function OrgConfig({ localizedContent, data }) {
  if (!data) return null;

  const code = JSON.stringify(data, null, 2);

  return (
    <div className="col">
      <div className="flex-row">
        <PageHeading subtitle={localizedContent.heading_configurations} />
      </div>
      <details className={classnames(['gin-top2', styles.codeDetails])}>
        <summary>{localizedContent.subheading_configuration}</summary>
        <div className={styles.codeWrapper}>
          <CopyToClipboardButton
            classCode={code}
            label={localizedContent.action_copy}
            customClassname={styles.copyCodeButton}
          />
          <code className={classnames(['gin-top2', styles.code])}>
            <pre>{code}</pre>
          </code>
        </div>
      </details>
    </div>
  );
}

OrgConfig.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired
};

function Details({
  localizedContent,
  data: { orgId, role, countryCode, registrationStatus, archived, customId, name, primaryEmail, users, orgConfig },
  refetch
}) {
  // -- cast status flag to value
  const orgStatus = archived ? ORG_STATUS.ARCHIVED : ORG_STATUS.ACTIVE;
  const [showEdit, setShowEdit] = useState(false);

  const toggleEditPanel = () =>
    setShowEdit(prev => {
      if (prev) refetch();

      return !prev;
    });

  return (
    <>
      <Helmet title={name} />
      <div className="grid pad-top2">
        <div className="sm10">
          <Button
            text={`‹ ${localizedContent.action_go_back}`}
            to={{ pathname: '/dashboard/org/organizations', state: { keepSearchState: true } }}
            customClassName={styles.backButton}
            type={buttonTypes.ACTION_NO_ICON}
          />
          <br />
          <div className={styles.heading}>
            <PageHeading title={name} />
            <OrganizationStatus registrationStatus={registrationStatus} status={orgStatus} />
            <Button
              id="entityEditButtonAdminOrgPage"
              onClick={toggleEditPanel}
              text={localizedContent.action_edit_organization}
              type={buttonTypes.PRIMARY_BLUE_HOVER}
              glyph={GLYPHS.ICON_EDIT}
              dataTestId="ENTITY_HEADER_DASHBOARD_ORG_PAGE_OPEN_EDIT_ACCOUNT"
            />
          </div>
          <span>
            {localizedContent.label_type}: <strong>{orgRoleDescriptions[role] || role || '-'}</strong>,{' '}
            {localizedContent.label_country}: <strong>{countryCode}</strong>
          </span>
          <pre className={styles.muted}>
            {localizedContent.label_code}: {customId} <br />
            {localizedContent.label_id}: {orgId}
          </pre>
        </div>
      </div>

      <div className="grid pad-top2 pad-bot4">
        <div className="row">
          <Users localizedContent={localizedContent} data={users} />
          <Contact localizedContent={localizedContent} data={primaryEmail} />
        </div>
        <div className="row gin-top4">
          <Actions localizedContent={localizedContent} orgId={orgId} orgRole={role} />
        </div>
        <div className="row gin-top4">
          <OrgConfig localizedContent={localizedContent} data={orgConfig} />
        </div>
      </div>

      <EditOrgPanel opened={showEdit} close={toggleEditPanel} />
    </>
  );
}

Details.propTypes = {
  data: PropTypes.object.isRequired,
  localizedContent: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired
};

function Loading() {
  return (
    <div className="gin-top8 text-center">
      <div style={{ width: '5rem', margin: '2rem auto' }}>
        <SVGIcon glyph={GLYPHS.ICON_LOADING} />
      </div>
    </div>
  );
}

Loading.propTypes = {};

function Failure({ localizedContent }) {
  return (
    <div id="searchResults" role="region" aria-live="polite" aria-atomic="true" className="text-center gin-top8">
      <div style={{ width: '5rem', margin: '2rem auto 0' }}>
        <SVGIcon glyph={GLYPHS.ICON_ERROR_CIRCLE} />
      </div>
      <p className="gin2">{localizedContent.message_error}</p>
      <Button text={localizedContent.action_go_back} to="/dashboard/org/organizations" />
    </div>
  );
}

Failure.propTypes = {
  localizedContent: PropTypes.object.isRequired
};

function OrganizationDetails({
  localizedContent: { organizationPage: localizedContent },
  getOrgDetails,
  resetState,
  orgDetails = {},
  setActiveOrgIdAction
}) {
  const { orgId } = useParams();

  useEffect(() => {
    if (orgId) {
      getOrgDetails(orgId);
      setActiveOrgIdAction(orgId);
    }

    return () => resetState();
  }, [orgId]);

  const refetch = () => getOrgDetails(orgId);

  const Component = {
    [mode.NOOP]: null,
    [mode.SUCCESS]: (
      <Details localizedContent={localizedContent} refetch={refetch} data={{ orgId, ...orgDetails.data }} />
    ),
    [mode.LOADING]: <Loading />,
    [mode.ERROR]: <Failure localizedContent={localizedContent} />
  }[orgDetails.mode];

  return (
    <>
      <Helmet title={localizedContent?.page_title} />
      {Component}
    </>
  );
}

OrganizationDetails.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  getOrgDetails: PropTypes.func.isRequired,
  resetState: PropTypes.func.isRequired,
  orgDetails: PropTypes.object.isRequired,
  setActiveOrgIdAction: PropTypes.func.isRequired
};

export default compose(
  withLocalizedContent('organizationPage'),
  connect(
    state => ({
      userRole: state.identity.role,
      orgDetails: state.orgDetails
    }),
    {
      getOrgDetails: actions.getOrgRequest,
      resetState: actions.getOrgReset,
      setActiveOrgIdAction: setActiveOrgId
    }
  )
)(OrganizationDetails);
