import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import Footer from '@oup/shared-front-end/src/components/Wizard/Footer/Footer.js';
import { useWizard } from '@oup/shared-front-end/src/components/Wizard';
import withLocalizedContent from '../../language/withLocalizedContent';

function ChooseRoleWizardFooter({ localizedContent: { hubGlossary: hubContent }, setChooseRoleWizardCompleted }) {
  const { nextStep, isPrimaryButtonDisabled, isLastStep } = useWizard();

  const setPrimaryButtonLabel = () => hubContent.next;

  const handleStep = () => {
    switch (true) {
      case isLastStep:
        setChooseRoleWizardCompleted(true);
        break;

      default:
        nextStep();
        break;
    }
  };

  return (
    <Footer
      primaryButtonAction={handleStep}
      primaryButtonLabel={setPrimaryButtonLabel()}
      primaryButtonAttributes={{
        disabled: isPrimaryButtonDisabled,
        dataAttributes: {
          testid: 'SELF_REGISTERED_USER_ONBOARDING_WIZARD_NEXT_BUTTON'
        }
      }}
    />
  );
}

ChooseRoleWizardFooter.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  setChooseRoleWizardCompleted: PropTypes.func.isRequired
};

export default compose(withLocalizedContent('hubGlossary'))(ChooseRoleWizardFooter);
