// Constants
import { licenceStatus as LICENCE_STATUS, HubLayoutConstants } from '../../../../globals/hubConstants';
import { GLYPHS } from '../../../../components/SVGIcon/SVGIcon';
// Services
import getManageClassStatusText from './getManageClassStatusText';

function areUserTypeWithoutLicence(usertype, classStudentsId, classTeachersId, allClassUsersWithoutLicence) {
  if (usertype === HubLayoutConstants.TARGET_USERTYPE.STUDENT) {
    return classStudentsId.some(studentId => allClassUsersWithoutLicence.includes(studentId));
  }
  return classTeachersId.some(teacherId => allClassUsersWithoutLicence.includes(teacherId));
}

const getManageClassAssignmentStatus = ({
  usertype,
  userWithoutLicence = [],
  totalUsers = 0,
  hubContent = {},
  isLicenceRemoved = false,
  classTeachersId = [],
  classStudentsId = []
}) => {
  const usersWithoutLicenceCount = userWithoutLicence.length;
  const {
    ORG_LICENCES_STATUS: { CHECK_COLOUR, BLUE_COLOR }
  } = HubLayoutConstants;
  const { NO_LICENCE, ACTIVE } = LICENCE_STATUS;
  const { greenText, blueText } = getManageClassStatusText(usertype, hubContent);

  if (isLicenceRemoved) {
    return {
      manageIcon: GLYPHS.ICON_REMOVED_CIRCLE,
      manageFill: BLUE_COLOR,
      manageText: hubContent.classwork_removed_from_class,
      manageLicenceStatus: NO_LICENCE
    };
  }
  if (
    (totalUsers && !usersWithoutLicenceCount) ||
    (usertype && !areUserTypeWithoutLicence(usertype, classStudentsId, classTeachersId, userWithoutLicence))
  ) {
    return {
      manageIcon: GLYPHS.ICON_CHECK_CIRCLE,
      manageFill: CHECK_COLOUR,
      manageText: greenText,
      manageLicenceStatus: ACTIVE
    };
  }

  return {
    manageIcon: GLYPHS.ICON_INFORMATION_CIRCLE,
    manageFill: BLUE_COLOR,
    manageText: blueText,
    manageLicenceStatus: NO_LICENCE
  };
};

export default getManageClassAssignmentStatus;
