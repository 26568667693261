import React, { Component } from 'react';
import PropTypes from 'prop-types';
// Styles
import styles from './TileContainer.scss';
// Constants
import breakpoints from '../../globals/breakpoints';
import { HubLayoutConstants } from '../../globals/hubConstants';
import userRoles from '../../globals/userRoles';
// Services
import getTileLimit from '../../structure/HubDashboardLayout/Services/getTileLimit';
// Components
import ViewAllTilesCard from '../ViewAllTilesCard/ViewAllTilesCard';
import HubEmptyStateRestyle from '../HubEmptyStateRestyle/HubEmptyStateRestyle';
import PlaceholderClassTile from '../ClassTile/PlaceholderClassTile';
import TileLoader from '../SkeletonLoader/Hub/TileLoader';
import { isLtiMode } from '../../utils/platform';

class TileContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slicedIds: [],
      slicedAmount: null
    };
  }

  componentDidMount() {
    this.sliceIds();
  }

  componentDidUpdate(prevProps) {
    const {
      props: {
        breakpoint,
        windowWidth,
        sideNavOpen,
        data: { ids },
        myDashboardView
      }
    } = this;

    if (prevProps.sideNavOpen !== sideNavOpen && breakpoint === breakpoints.LG) {
      setTimeout(() => this.sliceIds(), HubLayoutConstants.NAVBAR_TRANSITION_TIME + 1);
    }

    if (prevProps.data.ids.length !== ids.length || prevProps.windowWidth !== windowWidth) {
      this.sliceIds();
    }

    // Calling sliceIds when myDashboard tab is selected
    if (prevProps.myDashboardView !== myDashboardView) this.sliceIds();
  }

  sliceIds = () => {
    const {
      props: {
        data: { ids, hasPlaceholder },
        breakpoint
      }
    } = this;
    const nrOfIds = ids.length;

    if (nrOfIds) {
      const itemLimit =
        (breakpoint === breakpoints.XXS ? HubLayoutConstants.TILE_LIMIT_MOBILE : getTileLimit()) -
        (hasPlaceholder ? 1 : 0);
      if (itemLimit >= nrOfIds) {
        this.setState(() => ({ slicedIds: ids, slicedAmount: null }));

        return;
      }
      const slicedIds = ids.slice(0, itemLimit - 1);
      this.setState(() => ({ slicedIds, slicedAmount: nrOfIds - itemLimit + 1 }));
    }
  };

  // eslint-disable-next-line class-methods-use-this
  openLink = (link, target) => window.open(link, target);

  render() {
    const {
      props: {
        data: {
          iconSrc,
          isLoading,
          ids,
          items,
          onClickFirstButton,
          firstButtonTestHook,
          firstButtonTestHookEmptyState,
          title,
          subtitle,
          showFirstEmptyStateButton,
          Tile,
          firstButtonText,
          firstButtonLink,
          firstButtonIcon,
          isFirstButtonPrimary,
          showSecondEmptyStateButton = false,
          secondButtonText,
          secondButtonLink,
          secondButtonTarget,
          secondButtonOpenInNewTab,
          position,
          isSecondButtonPrimary,
          onClickJoinClassWithCodeButton,
          showEmptyStateJoinClassWithCodeButton,
          joinClassWithCodeButtonText,
          showEmptyStateOutlineButton,
          outlineButtonText,
          outlineButtonIcon,
          onClickOutlineButton,
          getLink,
          limitCardLink,
          limitCardText,
          setClassToArchive,
          hasPlaceholder
        },
        hubContent,
        breakpoint,
        isLimited,
        history,
        currentOrganisationId,
        currentOrganisationLti,
        userId,
        userRole,
        customOrgId,
        openEditClassroom
      },
      state: { slicedIds, slicedAmount }
    } = this;
    const displayClassCode = !isLtiMode() && (userRole === userRoles.ORG_ADMIN || userRole === userRoles.TEACHER_ADMIN);
    const limitedIds = isLimited && slicedIds.length > 0 ? slicedIds : ids;
    const emptyStateButton = [
      {
        showButton: showFirstEmptyStateButton && !isLtiMode(),
        text: firstButtonText,
        link: firstButtonLink || null,
        action: onClickFirstButton,
        testHook: firstButtonTestHookEmptyState,
        isPrimaryButton: isFirstButtonPrimary,
        icon: firstButtonIcon || null
      },
      {
        showButton: showSecondEmptyStateButton,
        text: secondButtonText,
        link: secondButtonLink,
        action: () => this.openLink(secondButtonLink, secondButtonTarget),
        target: secondButtonTarget,
        openInNewTab: secondButtonOpenInNewTab,
        isPrimaryButton: isSecondButtonPrimary,
        position
      },
      {
        showButton: !isLtiMode() ? showEmptyStateJoinClassWithCodeButton : false,
        text: joinClassWithCodeButtonText,
        action: onClickJoinClassWithCodeButton,
        isPrimaryButton: true,
        icon: firstButtonIcon || null
      },
      {
        showButton: showEmptyStateOutlineButton,
        text: outlineButtonText,
        action: onClickOutlineButton,
        icon: outlineButtonIcon || null
      }
    ];

    const getButton = () => {
      if (showEmptyStateJoinClassWithCodeButton && emptyStateButton && emptyStateButton[2]) {
        return emptyStateButton[2];
      }
      if (emptyStateButton && emptyStateButton[0]?.isPrimaryButton && emptyStateButton.length > 0) {
        return emptyStateButton[0];
      }
      if (emptyStateButton && emptyStateButton[1]) {
        return emptyStateButton[1];
      }
      return null;
    };

    return (
      <div className={styles.userCoursesMain}>
        {!ids.length && isLoading ? (
          <TileLoader />
        ) : (
          <div>
            {ids.length ? (
              <div className={styles.courses}>
                {hasPlaceholder && (
                  <div data-list="tile">
                    <PlaceholderClassTile
                      onClickFirstButton={onClickFirstButton}
                      firstButtonTestHook={firstButtonTestHook}
                      firstButtonText={firstButtonText}
                    />
                  </div>
                )}

                {limitedIds.map(id => (
                  <Tile
                    key={id}
                    id={id}
                    breakpoint={breakpoint}
                    item={items[id]}
                    hubContent={hubContent}
                    to={getLink(id)}
                    history={history}
                    currentOrganisationId={currentOrganisationId}
                    userId={userId}
                    userRole={userRole}
                    setClassToArchive={setClassToArchive}
                    customOrgId={customOrgId}
                    displayClassCode={displayClassCode}
                    openEditClassroom={openEditClassroom}
                  />
                ))}
                {slicedAmount && isLimited && (
                  <ViewAllTilesCard
                    slicedAmount={slicedAmount}
                    limitCardLink={limitCardLink}
                    limitCardText={limitCardText}
                  />
                )}
              </div>
            ) : (
              <div className={styles.hubEmptyState}>
                {hasPlaceholder && !currentOrganisationLti && (
                  <PlaceholderClassTile
                    onClickFirstButton={onClickFirstButton}
                    firstButtonTestHook={firstButtonTestHook}
                    firstButtonText={firstButtonText}
                  />
                )}
                <div className={styles.hubEmptyStateMessage}>
                  <HubEmptyStateRestyle
                    iconSrc={iconSrc}
                    title={!currentOrganisationLti && title}
                    bodyText={!currentOrganisationLti && subtitle}
                    link={
                      emptyStateButton &&
                      !emptyStateButton[0].isPrimaryButton &&
                      !showEmptyStateJoinClassWithCodeButton &&
                      !currentOrganisationLti
                        ? emptyStateButton[0]
                        : emptyStateButton[1]
                    }
                    btnFilledBase={!currentOrganisationLti && getButton()}
                    buttonTestHook={firstButtonTestHookEmptyState}
                    btnOutlineBase={showEmptyStateOutlineButton ? emptyStateButton[3] : null}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

TileContainer.propTypes = {
  hubContent: PropTypes.object,
  breakpoint: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  windowWidth: PropTypes.number,
  sideNavOpen: PropTypes.bool,
  isLimited: PropTypes.bool,
  isLoading: PropTypes.bool,
  history: PropTypes.object,
  currentOrganisationId: PropTypes.string,
  currentOrganisationLti: PropTypes.bool,
  userId: PropTypes.string,
  userRole: PropTypes.string,
  myDashboardView: PropTypes.string,
  customOrgId: PropTypes.string,
  position: PropTypes.string,
  openEditClassroom: PropTypes.func
};

export default TileContainer;
