import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'recompose';
import Button from '@oup/shared-front-end/src/components/Button';
import Select from '@oup/shared-front-end/src/components/Select';
import { ICON_DELETE } from '@oup/shared-front-end/src/svg/oup';
import classnames from 'classnames';
import { DropdownOption as DropdownOptionPropType } from '../../../../types';
import styles from './SelectInputs.scss';
import withLocalizedContent from '../../../../language/withLocalizedContent';

function SelectInputs({
  id,
  intervalValue,
  momentValue,
  label,
  intervalOptions = [],
  momentOptions = [],
  onChange = () => {},
  labelHidden,
  customLabelClass,
  onRemove = () => {},
  localizedContent: { hubGlossary: hubContent }
}) {
  return (
    <div className={styles.selectInputs}>
      <label
        htmlFor={id}
        className={classnames(labelHidden ? 'a11y-hide' : '', customLabelClass, styles.defaultLabel)}
        aria-label={label}
      >
        {label}
      </label>

      <div className={styles.selectInputsContainer}>
        <div className={styles.selectInputsIntervalContainer}>
          <Select
            id="timePeriod"
            name="timePeriod"
            value={intervalValue}
            onChange={onChange}
            options={intervalOptions}
            ariaAttributes={{ label: 'time period' }}
          />
        </div>
        <div className={styles.selectInputsMomentContainer}>
          <Select
            id="reminderEvent"
            name="reminderEvent"
            value={momentValue}
            onChange={onChange}
            options={momentOptions}
            ariaAttributes={{ label: 'reminder event' }}
          />
        </div>
        <div className={styles.removeBtnContainer}>
          <Button text={hubContent.remove} onClick={onRemove} variant="outline" icon={{ component: <ICON_DELETE /> }} />
        </div>
      </div>
    </div>
  );
}

SelectInputs.propTypes = {
  /** the id of the input field needs to be unique */
  id: PropTypes.string.isRequired,
  intervalValue: PropTypes.string,
  momentValue: PropTypes.string,
  /** the inputs text label */
  label: PropTypes.string.isRequired,
  /** Options are required */
  intervalOptions: PropTypes.arrayOf(DropdownOptionPropType).isRequired,
  momentOptions: PropTypes.arrayOf(DropdownOptionPropType).isRequired,
  /** function that if the input is touched, will be called */
  onChange: PropTypes.func.isRequired,
  /** The SelectInputs label can be hidden to users and only visible to screen readers */
  labelHidden: PropTypes.bool,
  /** function that will be triggered when the removed button is clicked */
  onRemove: PropTypes.func,
  customLabelClass: PropTypes.string,
  localizedContent: PropTypes.object.isRequired
};

export default compose(withLocalizedContent('hubGlossary'))(SelectInputs);
