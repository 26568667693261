import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PopoutPanelIconHeading, {
  types
} from '../../../../../../components/PopoutPanelIconHeading/PopoutPanelIconHeading.js';
import cmsContent from '../../../../../../utils/cmsContent.js';
import ScrollContainer from '../../../../../../components/ScrollContainer/ScrollContainer.js';
import PanelNavigationLink from '../../../../../../components/PanelNavigationLink/PanelNavigationLink.js';
import PopoutNavFooter from '../../../../../../components/PopoutNavFooter/PopoutNavFooter.js';
import { deleteLMSConfigRequest } from '../../../../../../redux/actions/lmsConfig.js';
import ToolItem from './ToolItem.js';

export function Archive({ closePanel, tool, orgId, submitFormAction, hubContent }) {
  const CMS = cmsContent.addToLibrary || {};
  return (
    <ScrollContainer
      headerContent={
        <div>
          <div className="text-right">
            <PanelNavigationLink isLhs={false} text={CMS.close_panel_text} action={closePanel} />
          </div>
          <PopoutPanelIconHeading
            type={types.WARNING}
            title={hubContent.tools_page_archive_deployment_title}
            subtitle={hubContent.tools_page_archive_deployment_subtitle}
          />
        </div>
      }
      footerContent={
        <PopoutNavFooter
          nextButtonText={hubContent.archive_deployment}
          nextAction={() => submitFormAction(orgId, tool.toolId)}
          backAction={closePanel}
        />
      }
    >
      <ToolItem tool={tool} />
    </ScrollContainer>
  );
}

Archive.propTypes = {
  closePanel: PropTypes.func.isRequired,
  tool: PropTypes.object.isRequired,
  orgId: PropTypes.string.isRequired,
  submitFormAction: PropTypes.func.isRequired,
  hubContent: PropTypes.object.isRequired
};

export default connect(
  state => ({
    tool: state.createLMSConfig.formData,
    orgId: state.identity.currentOrganisationId,
    userId: state.identity.userId
  }),
  {
    submitFormAction: deleteLMSConfigRequest
  }
)(Archive);
