import React from 'react';
import PropTypes from 'prop-types';

import SVGIcon, { GLYPHS } from '../../../SVGIcon/SVGIcon';
import styles from './StudentsCounting.scss';

function StudentsCounting({
  label,
  onIncrease = () => {},
  onDecrease = () => {},
  value = 0,
  hasError = false,
  errorMessage,
  disabled = false,
  disabledClass = ''
}) {
  return (
    <div className={styles.container}>
      {label && (
        <label htmlFor="counter" className={styles.label}>
          {label}
        </label>
      )}
      <div className={`${styles.counterContainer} ${disabled ? disabledClass : ''}`} id="counter">
        <button
          disabled={disabled}
          type="button"
          className={styles.changeCounterBtn}
          onClick={onDecrease}
          aria-label="decrease number of students"
        >
          <SVGIcon glyph={GLYPHS.ICON_MINUS} className={styles.changeCounterIcon} />
        </button>
        <div className={styles.showNumber}>
          <p className={hasError ? styles.showNumberValue : ''}>{value}</p>
          {hasError && <SVGIcon glyph={GLYPHS.ICON_ERROR_CIRCLE} className={styles.errorIcon} />}
        </div>
        <button
          disabled={disabled}
          type="button"
          className={styles.changeCounterBtn}
          onClick={onIncrease}
          aria-label="increase number of students"
        >
          <SVGIcon glyph={GLYPHS.ICON_ADD} className={styles.changeCounterIcon} />
        </button>
      </div>
      {hasError && <p className={styles.errorMsg}>{errorMessage}</p>}
    </div>
  );
}

StudentsCounting.propTypes = {
  label: PropTypes.string,
  onIncrease: PropTypes.func,
  onDecrease: PropTypes.func,
  value: PropTypes.number,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  disabled: PropTypes.bool,
  disabledClass: PropTypes.string
};

export default StudentsCounting;
