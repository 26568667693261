import moment from 'moment';
import { GLYPHS } from '../../../components/SVGIcon/SVGIcon';
import { HubLayoutConstants } from '../../../globals/hubConstants';
import colors from '../../../globals/colors';

const getPeopleStatusIconData = (user, classroomId, hubContent) => {
  const { orgInviteStatus, orgArchiveStatus, classInviteStatus } = user;
  const {
    PEOPLE_STATUS: { ACTIVE, PENDING, ARCHIVED }
  } = HubLayoutConstants;

  const date = moment(user[`classStartDate-${classroomId}`]).format('D MMMM YYYY');
  const isActiveInClass = classroomId && user[`classArchiveStatus-${classroomId}`] !== ARCHIVED;
  const isArchivedInOrg = classroomId && orgArchiveStatus === ARCHIVED;
  const isPending = classroomId && (orgInviteStatus === PENDING || classInviteStatus === PENDING);

  if (isActiveInClass && !isArchivedInOrg && !isPending) {
    return {
      icon: GLYPHS.ICON_CHECK_CIRCLE,
      text: hubContent.people_added_to_class_on_text,
      date,
      iconColour: null,
      status: ACTIVE
    };
  }

  if (isActiveInClass && isPending && !isArchivedInOrg) {
    return {
      icon: GLYPHS.ICON_INVITED,
      text: hubContent.people_invited_on_text,
      date,
      iconColour: null,
      subtext: hubContent.invitation_sent,
      status: PENDING
    };
  }

  return {
    icon: GLYPHS.ICON_REMOVED_CIRCLE,
    text: hubContent.people_removed_from_class_on_text,
    date,
    iconColour: colors.WEAK,
    status: ARCHIVED
  };
};

export default getPeopleStatusIconData;
