import React from 'react';
import PropTypes from 'prop-types';
import ScrollContainer from '../../ScrollContainer/ScrollContainer';
import PopoutPanelIllustrationHeading from '../../PopoutPanelIllustrationHeading/PopoutPanelIllustrationHeading';
import PopoutNavFooter from '../../PopoutNavFooter/PopoutNavFooter';
import { HubIllustrationConstants, HubIllustrationAltText } from '../../../globals/hubConstants';

function ManageProductLocksLoadingError({ content, resetStateAction }) {
  return (
    <ScrollContainer
      headerContent={
        <div>
          <PopoutPanelIllustrationHeading
            title={content.something_went_wrong}
            illustrationSrc={HubIllustrationConstants.ERROR}
            illustrationAltText={HubIllustrationAltText.ERROR}
          />
        </div>
      }
      footerContent={<PopoutNavFooter nextButtonText={content.try_again} nextAction={resetStateAction} />}
    />
  );
}

ManageProductLocksLoadingError.propTypes = {
  content: PropTypes.object.isRequired,
  resetStateAction: PropTypes.func.isRequired
};

export default ManageProductLocksLoadingError;
