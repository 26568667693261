import classNames from 'classnames';
import { identity, pull, isEmpty, mapValues } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import APP_CONSTANTS from '@oup/shared-node-browser/constants';
import Button, { buttonTypes } from '../Button/Button';
import Checkbox from '../Checkbox/Checkbox';
import { GLYPHS } from '../SVGIcon/SVGIcon';
import styles from './EntityListing.scss';

/**
 * A tabulated listing to present records in rows with supplementary content
 * as columns. Optionally allows expandable item content and checkboxes to
 * select multiple items.
 */
class EntityListing extends Component {
  _appendExpandColumn = (item, id) => {
    const { expandedItems = [], onItemExpandToggle, localizedContent } = this.props;

    return {
      ...item,
      Details: (
        <Button
          type={buttonTypes.NO_BORDER}
          text={localizedContent && localizedContent.productListing && localizedContent.productListing.heading_expand}
          glyph={GLYPHS[expandedItems.includes(id) ? 'CHEVRON_UP' : 'CHEVRON_DOWN']}
          iconOnly
          onClick={() => onItemExpandToggle(id)}
        />
      )
    };
  };

  _renderItemRow = (item, id, orgPending) => {
    const {
      selectable = false,
      expandable = false,
      selectedItems = [],
      expandedItems = [],
      onItemSelect,
      type = null,
      filteredItems = [],
      renderItemContent = () => null,
      renderItemList = () => null
    } = this.props;

    return [
      <tr
        key={`${id}-main`}
        className={classNames(styles.tr, {
          [styles.selectDisabled]: Array.isArray(selectable) && !selectable.includes(id),
          [styles.bottomBorder]: !expandedItems.includes(id) && item.Category === APP_CONSTANTS.COLLECTION_LIST,
          [styles.boxShadow]: expandedItems.includes(id) && item.Category === APP_CONSTANTS.COLLECTION_LIST
        })}
      >
        {Object.values(item).map((content, i) =>
          // eslint-disable-next-line no-nested-ternary
          ![APP_CONSTANTS.COLLECTION_LIST, APP_CONSTANTS.PRODUCT_LIST].includes(content) ? (
            item.Action === null && content === null ? null : (
              <td key={i} colSpan={i === 0 && item.Action === null ? 2 : 0}>
                <div className={styles[i ? 'td' : 'entity']}>
                  {selectable && i === 0 ? (
                    <div className={styles.checkbox}>
                      {!orgPending ? (
                        <Checkbox
                          label="Select entity"
                          labelHidden
                          value={selectedItems.includes(id)}
                          onChange={() => onItemSelect(id)}
                          disabled={
                            filteredItems.map(hit => hit.userHitId).includes(id) ||
                            !(Array.isArray(selectable) ? selectable.includes(id) : selectable)
                          }
                        />
                      ) : null}
                    </div>
                  ) : null}
                  {content}
                </div>
              </td>
            )
          ) : null
        )}
      </tr>,
      ...(expandable && (!type || type === APP_CONSTANTS.PRODUCT_LIST)
        ? [
            <tr key={`${id}-content`} className={!expandedItems.includes(id) ? styles.collapsed : null}>
              <td colSpan={Object.keys(item).length}>
                <div className={styles.content}>{renderItemContent(id)}</div>
              </td>
            </tr>
          ]
        : []),
      ...(expandable && type === APP_CONSTANTS.COLLECTION_LIST
        ? [
            <tr key={`${id}-list`} className={!expandedItems.includes(id) ? styles.collapsed : null}>
              <td colSpan={Object.keys(item).length}>
                <div className={styles.entityForCollection}>{renderItemList(id)}</div>
              </td>
            </tr>
          ]
        : [])
    ];
  };

  _getSelectableIds = () => {
    const { items, selectable } = this.props;

    if (!selectable) {
      return [];
    }

    return Array.isArray(selectable) ? selectable : Object.keys(items);
  };

  _getSelectedAll = () => {
    const { selectedItems = [] } = this.props;
    const itemIds = this._getSelectableIds();
    // if no items then select all will always be unchecked
    return !itemIds.length ? false : itemIds.every(id => selectedItems.includes(id));
  };

  _handleSelectAll = () => {
    const { selectedItems = [], onItemSelect } = this.props;
    const selectedAll = this._getSelectedAll();

    this._getSelectableIds().forEach(id => {
      // deselect all items if selectedAll is true or select item if not yet selected
      if (selectedAll || !selectedItems.includes(id)) {
        onItemSelect(id);
      }
    });
  };

  render() {
    const {
      className,
      items,
      expandable = false,
      renderMultiItemRow,
      selectAllEnabled = false,
      selectable,
      orgPending,
      type = null,
      localizedContent
    } = this.props;

    const data = !type ? mapValues(items, expandable ? this._appendExpandColumn : identity) : items;

    const headings = type === APP_CONSTANTS.PRODUCT_LIST || isEmpty(data) ? [] : Object.keys(Object.values(data)[0]);
    if (
      localizedContent &&
      localizedContent.productListing &&
      headings.includes(localizedContent.productListing.category)
    ) {
      pull(headings, localizedContent.productListing.category);
    }

    if (window.location.pathname === '/myProfile/learningMaterial')
      headings[headings.indexOf(localizedContent.productListing.heading_actions)] = '';
    return (
      <table className={classNames(styles.table, className)}>
        <thead className={styles.thead}>
          <tr>
            {headings.map((heading, i) => (
              <th key={i} className={!i ? styles.control : null} scope="col">
                <div className={styles[i ? 'th' : 'header']}>
                  <div className={!i ? styles.heading : styles.column}>
                    {selectable && selectAllEnabled && i === 0 ? (
                      <div className={styles.checkbox}>
                        {!orgPending ? (
                          <Checkbox
                            label={`Select ${heading}`}
                            labelHidden
                            value={this._getSelectedAll()}
                            onChange={this._handleSelectAll}
                            dataTestId="ENTITY_LISTING_SELECT_ALL_CHECKBOX"
                          />
                        ) : null}
                      </div>
                    ) : null}
                    {heading}
                  </div>
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Object.entries(data).reduce(
            (rows, [id, item]) =>
              rows.concat(
                renderMultiItemRow
                  ? renderMultiItemRow(Object.values(item)[0])
                  : this._renderItemRow(item, id, orgPending)
              ),
            []
          )}
        </tbody>
      </table>
    );
  }
}

EntityListing.propTypes = {
  className: PropTypes.string,
  items: PropTypes.object.isRequired,
  selectable: PropTypes.oneOfType([PropTypes.bool, PropTypes.arrayOf(PropTypes.string)]),
  filteredItems: PropTypes.array,
  expandable: PropTypes.bool,
  selectAllEnabled: PropTypes.bool,
  selectedItems: PropTypes.arrayOf(PropTypes.string),
  expandedItems: PropTypes.arrayOf(PropTypes.string),
  onItemSelect: PropTypes.func,
  onItemExpandToggle: PropTypes.func,
  renderItemContent: PropTypes.func,
  renderItemList: PropTypes.func,
  renderMultiItemRow: PropTypes.func,
  orgPending: PropTypes.bool,
  type: PropTypes.string,
  localizedContent: PropTypes.object
};

export default EntityListing;
