const getPeopleColour = userRole => {
  const data = {
    LEARNER: 'student',
    TEACHER: 'teacher',
    TEACHER_ADMIN: 'teacherAdmin',
    ORG_ADMIN: 'orgAdmin'
  };
  return data[userRole];
};

export default getPeopleColour;
