import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { Wizard } from '@oup/shared-front-end/src/components/Wizard';
import SiteHeader from '@oup/shared-front-end/src/components/SiteHeader';
import Button from '@oup/shared-front-end/src/components/Button/Button.js';
import { ICON_HELP_CIRCLE } from '@oup/shared-front-end/src/svg/oup/index';
import styles from '@oup/shared-front-end/src/components/SiteHeader/SiteHeader.scss';
import YoungLearnerRedeemCodeWizardFooter from './YoungLearnerRedeemCodeWizardFooter.js';
import { HubLayoutConstants } from '../../globals/hubConstants';
import withLocalizedContent from '../../language/withLocalizedContent';
import YoungLearnerIntroStep from './YoungLearnerIntroStep';
import YoungLearnerEnterRedeemCodeStep from './YoungLearnerEnterRedeemCodeStep.js';
import useMediaQuery from '../../utils/mediaQuery.js';

function YoungLearnerRedeemCodeWizard({ localizedContent: { redeemCodePage: content } }) {
  const isMobile = useMediaQuery('(max-width: 920px)');

  return (
    <div data-testid="YL_REDEEM_CODE_WIZARD_CONTAINER">
      <Wizard
        header={
          <SiteHeader
            secondaryText={content.oxford_english_hub}
            buttons={[
              {
                type: Button,
                props: {
                  variant: 'filled',
                  icon: { component: <ICON_HELP_CIRCLE className={styles.helpIconLoggedOut} /> },
                  text: !isMobile ? content.help_and_support_button_text : '',
                  onClick: () => window.open(HubLayoutConstants.HELP_AND_SUPPORT_URL, '_blank'),
                  className: !isMobile ? styles.helpButtonLarge : styles.helpButtonSmall
                }
              }
            ]}
          />
        }
        footer={<YoungLearnerRedeemCodeWizardFooter content={content} />}
        activeStepClassName="youngLearnerStepContainer"
      >
        <YoungLearnerIntroStep content={content} />
        <YoungLearnerEnterRedeemCodeStep content={content} />
      </Wizard>
    </div>
  );
}

YoungLearnerRedeemCodeWizard.propTypes = {
  localizedContent: PropTypes.object.isRequired
};

export default compose(withLocalizedContent('redeemCodePage'))(YoungLearnerRedeemCodeWizard);
