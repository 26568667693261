import React from 'react';
import PropTypes from 'prop-types';
import styles from './HubTopContent.scss';

function HubTopContent({ text, homepageContent, customTextClassName }) {
  return (
    <div className={styles.sectionTwoGrid}>
      <div className={styles.middleTextContainer}>
        <span className={customTextClassName || ''}>{text}</span>
      </div>
      <div className={styles.middleImgContainer}>
        <img
          aria-hidden="true"
          alt={homepageContent.banner_secondary_items.seconday_img_1_alt}
          src={homepageContent.banner_secondary_items.seconday_img_1}
        />
        <img
          aria-hidden="true"
          alt={homepageContent.banner_secondary_items.seconday_img_2_alt}
          src={homepageContent.banner_secondary_items.seconday_img_2}
        />
      </div>
    </div>
  );
}
HubTopContent.propTypes = {
  text: PropTypes.string,
  customTextClassName: PropTypes.string,
  homepageContent: PropTypes.object.isRequired
};

export default HubTopContent;
