import { put } from 'redux-saga/effects';
import { loadOfflinePlayerVersion } from '../../../../actions/offlineContentPlayer';

function* updateOfflinePlayerVersion(version) {
  localStorage.setItem('offline_player_version', version);

  yield put(loadOfflinePlayerVersion(version));
}

export default updateOfflinePlayerVersion;
