import PropTypes from 'prop-types';
import React from 'react';
import { SidePanel } from '../../../../components';
import AssessmentLicenceStatusPanel from '../../../../panels/AssessmentLicenceStatusPanel';
import USER_ROLES from '../../../../globals/userRoles';

function StudentsAssessmentLicenceStatusTab({ isOpen, testData, orgId, closePanel }) {
  return (
    <SidePanel isOpen={isOpen} onClose={closePanel}>
      <AssessmentLicenceStatusPanel
        orgId={orgId}
        backAction={closePanel}
        initialRoles={[USER_ROLES.LEARNER, USER_ROLES.MANAGED_USER]}
        testData={testData}
      />
    </SidePanel>
  );
}

StudentsAssessmentLicenceStatusTab.propTypes = {
  orgId: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  closePanel: PropTypes.func.isRequired,
  testData: PropTypes.object
};

export default StudentsAssessmentLicenceStatusTab;
