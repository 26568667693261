import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useWizard } from '@oup/shared-front-end/src/components/Wizard';
import WizardStep from '@oup/shared-front-end/src/components/Wizard/WizardStep';
import { HubIllustrationConstants, HubIllustrationAltText } from '../../globals/hubConstants';
import styles from './YoungLearnerRedeemCodeWizard.scss';

function YoungLearnerIntroStep({ content }) {
  const { setIsPrimaryButtonDisabled } = useWizard();

  useEffect(() => {
    setIsPrimaryButtonDisabled(false);
  }, []);

  return (
    <div data-testid="YL_REDEEM_CODE_WIZARD_STEP_1" className={`${styles.stepContainer} ${styles.introStep}`}>
      <WizardStep
        titleText={content.young_learners_intro_title}
        variant="introduction"
        image={HubIllustrationConstants.ORANGE_MONSTER}
        imageAlt={HubIllustrationAltText.ORANGE_MONSTER}
      >
        <ol>
          <li>{content.young_learner_redeem_code_intro_step_content_1}</li>
          <li>{content.young_learner_redeem_code_intro_step_content_2}</li>
          <li>{content.young_learner_redeem_code_intro_step_content_3}</li>
        </ol>
      </WizardStep>
    </div>
  );
}

YoungLearnerIntroStep.propTypes = {
  content: PropTypes.object.isRequired
};

export default YoungLearnerIntroStep;
