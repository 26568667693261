import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useParams, useLocation } from 'react-router-dom';
// Redux
import { connect } from 'react-redux';
import { hubDownloadRemoveErrorModal } from '../../redux/actions/hubResource';
// Constants
import breakpoints from '../../globals/breakpoints';
// Style
import styles from './HubProductLayout.scss';
// Services
import getCourseLink from '../HubDashboardLayout/Services/getCourseLink';
// Components
import CourseComponentsMenu from './HubSubSideNavbar/CourseComponentsMenu';
import HubProductErrorModal from './HubProductErrorModal/HubProductErrorModal';
import HubResourceErrorModal from './HubResourceErrorModal/HubResourceErrorModal';
import HubPageLayout from '../HubPageLayout/HubPageLayout';
import { featureIsEnabled } from '../../globals/envSettings';

function HubProductLayout({
  breakpoint,
  courses,
  hubContent,
  children,
  isError,
  downloadType,
  downloadResourceId,
  // eslint-disable-next-line no-shadow
  hubDownloadRemoveErrorModal,
  userRole,
  pageLayout
}) {
  const params = useParams();
  const location = useLocation();

  const { courseId } = params;
  const { pathname } = location;
  const product = get(courses, [courseId, 'products', 'data', downloadResourceId], {});

  const renderProductLayout = () => (
    <div className={styles.hubProductLayout}>
      {!featureIsEnabled('navigation-changes') &&
      (breakpoint !== breakpoints.XXS || getCourseLink(courseId) === pathname) ? (
        <div className={styles.hubProductLayoutSubSidenavbar}>
          <CourseComponentsMenu
            params={params}
            course={courses[courseId]}
            hubContent={hubContent}
            pathname={pathname}
            userRole={userRole}
          />
        </div>
      ) : null}

      {breakpoint !== breakpoints.XXS || getCourseLink(courseId) !== pathname ? (
        <div className={styles.hubProductChildrenContainer}>
          {children &&
            React.cloneElement(children, {
              courses,
              courseId,
              breakpoint,
              hubContent,
              userRole
            })}
        </div>
      ) : null}
      <HubProductErrorModal
        isError={isError}
        downloadType={downloadType}
        hubContent={hubContent}
        closeModal={hubDownloadRemoveErrorModal}
        title={hubContent.product_modal_error_header}
        subTitle={hubContent.product_modal_error_subtitle}
        externalUrl={product.externalUrl ? product.externalUrl : null}
      />

      <HubResourceErrorModal
        isError={isError}
        downloadType={downloadType}
        hubContent={hubContent}
        closeModal={hubDownloadRemoveErrorModal}
      />
    </div>
  );

  return featureIsEnabled('navigation-changes') ? (
    <HubPageLayout {...pageLayout}>{renderProductLayout()}</HubPageLayout>
  ) : (
    renderProductLayout()
  );
}

const mapStateToProps = ({
  hubResourceDownload: { isError, downloadType, downloadResourceId },
  identity: { role = '' }
}) => ({
  isError,
  downloadType,
  downloadResourceId,
  userRole: role
});

const mapDispatchToProps = { hubDownloadRemoveErrorModal };

export default connect(mapStateToProps, mapDispatchToProps)(HubProductLayout);

HubProductLayout.propTypes = {
  breakpoint: PropTypes.string.isRequired,
  courses: PropTypes.object.isRequired,
  hubContent: PropTypes.object.isRequired,
  children: PropTypes.object,
  isError: PropTypes.bool.isRequired,
  hubDownloadRemoveErrorModal: PropTypes.func.isRequired,
  downloadResourceId: PropTypes.string,
  downloadType: PropTypes.string.isRequired,
  userRole: PropTypes.string,
  pageLayout: PropTypes.shape({
    ...HubPageLayout.propTypes,
    showPageInnerHero: PropTypes.bool
  })
};
