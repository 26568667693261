import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import WizardStep from '@oup/shared-front-end/src/components/Wizard/WizardStep/WizardStep.js';
import styles from './OnboardingWizardWelcome.scss';
import withLocalizedContent from '../../../language/withLocalizedContent';

function OnboardingWizardWelcome({
  localizedContent: { selfRegisteredUserOnboardingWizard: content, hubGlossary: hubContent },
  firstName
}) {
  return (
    <div
      data-testid="SELF_REGISTERED_USER_ONBOARDING_WIZARD_WELCOME_CONTAINER"
      className={styles.introductionContainer}
    >
      <WizardStep
        titleText={`${hubContent.self_select_role_title.replace('{firstName}', firstName)}`}
        subtitleText={content.self_registered_user_onboarding_wizard_set_up}
        image="/media/feedback/illustration-welcome.svg"
        imageAlt="Welcome to Oxford English Hub"
        variant="introduction"
        headingSize="large"
        className={styles.yellowBackground}
      />
    </div>
  );
}

OnboardingWizardWelcome.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  firstName: PropTypes.string.isRequired
};

export default compose(withLocalizedContent('selfRegisteredUserOnboardingWizard', 'hubGlossary'))(
  OnboardingWizardWelcome
);
