import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import APP_CONSTANTS from '@oup/shared-node-browser/constants';
import store from '../../../redux/store';
// Components
import HubDropDownMenuWithText from '../../../components/HubDropDownMenuWithText/HubDropDownMenuWithText';
import Link from '../../../components/Link/Link';
import SVGIcon, { GLYPHS } from '../../../components/SVGIcon/SVGIcon';
// Constants
import userRoles from '../../../globals/userRoles';
import orgSubMenuConstants from './OrganizationSubMenuConstants';
// Styles
import styles from './OrganizationSubMenu.scss';
import { featureIsEnabled } from '../../../globals/envSettings';
import { openPlacementTestOnboardingWizard, toggleDeploymentToolModal } from '../../../redux/actions/hubUi';

class OrganizationSubMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addToOrgToggledButton: false
    };
  }

  closeMenuPanel = e => {
    e.stopPropagation();
    this.setState({ addToOrgToggledButton: false });
  };

  toggleAddToOrgButton = () => {
    this.setState(state => ({ addToOrgToggledButton: !state.addToOrgToggledButton }));
  };

  getAddToOrgMenuItems = (
    isAdmin = false,
    isClassAdmin = false,
    { addStaff, addStudents, openAddLicensesModalAction, openOnboardWizardAction }
  ) => {
    const { hubContent: content, isLmsLtiTool, currentOrganisationLti } = this.props;
    const menuItems = [
      {
        name: content.add_students,
        icon: GLYPHS.ICON_CLASS,
        iconColor: '#fb6d51',
        action: addStudents,
        showButton: (isAdmin || isClassAdmin) && !currentOrganisationLti
      },
      {
        name: content.add_staff,
        icon: GLYPHS.ICON_USER,
        iconColor: '#a10062',
        action: addStaff,
        showButton: isAdmin && !currentOrganisationLti
      },
      {
        name: content.add_classes,
        icon: GLYPHS.ICON_CLASS,
        iconColor: '#a10062',
        action: openOnboardWizardAction,
        actionTestHook: 'OPEN_ONBOARDING_WIZARD_BUTTON_ORGANIZATION_DROPDOWN',
        showButton: !currentOrganisationLti
      },
      {
        name: content.add_licences,
        icon: GLYPHS.ICON_COURSE,
        iconColor: '#2ac46a',
        action: openAddLicensesModalAction,
        showButton: true
      }
    ];

    if (featureIsEnabled('hub-self-service') && isLmsLtiTool && currentOrganisationLti) {
      menuItems.splice(0, 0, {
        name: content.add_deployments,
        icon: GLYPHS.ICON_INTEGRATION,
        iconColor: 'colors.YELLOW',
        action: toggleDeploymentToolModal,
        showButton: true
      });
    }

    if (featureIsEnabled('opt-main-features')) {
      menuItems.splice(-1, 0, {
        name: content.add_placement_test,
        icon: GLYPHS.ICON_PROGRESS,
        iconColor: '#47CF88',
        action: openPlacementTestOnboardingWizard,
        showButton: true
      });
    }

    return menuItems;
  };

  render() {
    const {
      state: { addToOrgToggledButton },
      props: {
        hubContent,
        pathname,
        userRole,
        isLmsLtiTool = false,
        currentOrganisationLti,
        actions: {
          addStaff,
          addStudents,
          openAddLicensesModalAction,
          openCreateClassModalAction,
          openOnboardWizardAction
        }
      },
      toggleAddToOrgButton,
      closeMenuPanel
    } = this;

    const isOrgAdmin = userRole === userRoles.ORG_ADMIN;
    const isClassAdmin = userRole === userRoles.TEACHER_ADMIN;

    const popoutMenuParams = {
      popoutId: 'addToOrgPanel',
      popoutAriaLabel: hubContent.add_to_org_menu_aria_label,
      buttonElementId: 'addToOrgId'
    };

    const panelActions = {
      addStaff: e => addStaff(e, 'addStaff', { selectedUserId: '', context: 'ORG_STAFF' }),
      addStudents: e =>
        addStudents(e, 'addStudents', {
          selectedUserId: '',
          context: APP_CONSTANTS.ORG_STUDENTS,
          role: userRoles.LEARNER
        }),
      openAddLicensesModalAction,
      openCreateClassModalAction,
      openOnboardWizardAction
    };

    const dropDownMenu = featureIsEnabled('navigation-changes') ? null : (
      <HubDropDownMenuWithText
        idPanelMenu="addToOrgId"
        testHook="HUB_ADD_TO_ORGANIZATION_DROPDOWN"
        menuItems={this.getAddToOrgMenuItems(isOrgAdmin, isClassAdmin, panelActions)}
        onTogglePanelMenu={closeMenuPanel}
        toggled={addToOrgToggledButton}
        popoutData={popoutMenuParams}
        toggleAddButton={toggleAddToOrgButton}
        textButton={hubContent.add_to_organization}
      />
    );

    return (
      <div className={styles.organizationSubMenu} role="navigation" aria-label="Class menu">
        {dropDownMenu}
        <ul className={styles.withButton}>
          {orgSubMenuConstants
            .getOrgSubMenuItems(hubContent, { isLmsLtiTool, currentOrganisationLti })
            .map(subMenuItem => (
              <li key={subMenuItem.name}>
                <div>
                  <Link
                    to={subMenuItem.path}
                    className={classnames([styles.subMenuItem], {
                      [styles.subMenuItemActive]: pathname.startsWith(subMenuItem.path)
                    })}
                  >
                    <div className={styles.buttonName}>{subMenuItem.name}</div>
                  </Link>
                </div>
              </li>
            ))}
        </ul>

        {isOrgAdmin &&
          orgSubMenuConstants.getManageOrgItems(hubContent).map(item => (
            <button
              type="button"
              className={`${styles.subMenuItem} ${styles.manageOrgItem}`}
              key={item.name}
              onClick={() => store.dispatch(item.action())}
            >
              <SVGIcon glyph={GLYPHS.ICON_SETTINGS} className={styles.manageOrgIcon} />
              <div className={styles.buttonName}>{item.name}</div>
            </button>
          ))}
      </div>
    );
  }
}

export default compose(
  connect(state => ({
    currentOrganisationLti: state.identity.currentOrganisationLti
  }))
)(OrganizationSubMenu);

OrganizationSubMenu.propTypes = {
  hubContent: PropTypes.object.isRequired,
  pathname: PropTypes.string.isRequired,
  userRole: PropTypes.string.isRequired,
  actions: PropTypes.object.isRequired,
  openAddLicensesModalAction: PropTypes.func,
  isLmsLtiTool: PropTypes.bool,
  currentOrganisationLti: PropTypes.bool
};
