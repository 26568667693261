import { put, all, takeLatest, fork } from 'redux-saga/effects';
import uuid from 'uuid';

import { PUBLISH_COURSE_START } from '../../../../actionTypes';
import { pollStartAction, pollStopAction } from '../../../../reducers/polling.reducer';
import { publishCourseDataSuccess, publishCourseDataFailure } from '../../../../actions/coursePublish';

import publishCourseApi from '../../../apiCalls/coursePublish/publishCourse';
import getCoursePublishStatusApi from '../../../apiCalls/coursePublish/getCoursePublishStatus';

const STATUS_SUCCESS = 'success';

function* processHandler(publishId, publishType) {
  const res = yield getCoursePublishStatusApi(publishId, publishType);

  if (res?.error) {
    yield all([put(pollStopAction()), put(publishCourseDataFailure({ status: 'error', error: res.error }))]);
  } else if (res?.status === STATUS_SUCCESS) {
    yield all([put(pollStopAction()), put(publishCourseDataSuccess(res))]);
  }
}

function* pollingStart(publishId, publishType) {
  yield all([processHandler(publishId, publishType), put(pollStartAction('PUBLISH_COURSE_PROCESS'))]);
}

function* publishCourse({ courseCode, publishType, body }) {
  const publishId = uuid.v4();
  body.publishId = publishId;

  yield fork(publishCourseApi, { courseCode, body });

  yield pollingStart(publishId, publishType);
  yield takeLatest(PUBLISH_COURSE_START, () => processHandler(publishId, publishType));
}

export default publishCourse;
