import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { get } from 'lodash';
// Components
import Link from '../../../components/Link/Link';
import SVGIcon from '../../../components/SVGIcon/SVGIcon';
import { GLYPHS } from '../../../components/Button/Button';
// Constants
import { HubLayoutConstants } from '../../../globals/hubConstants';
// Utils
import getClassLink from '../Services/getClassLink';
import getClassLinkByRole from '../Services/getClassLinkByRole';
// HOC
import withBreakpoint from '../../../decorators/withBreakpoint';
// Style
import styles from './SecondaryLevelClasses.scss';

const CLASS_LIMIT = HubLayoutConstants.SUBMENU_ITEMS_LIMIT;

function SecondaryLevelClasses({ hubContent, handleSelectSecondaryLevel, classes, classesIds, orgId, userRole }) {
  const getTeacherLabel = classId => {
    const nrOfTeachers = get(classes[classId], ['amountOfTeachers']);
    if (nrOfTeachers > 1) {
      return <div className={styles.teacher}>{nrOfTeachers} teachers</div>;
    }
    if (nrOfTeachers === 1 && get(classes[classId], ['teacherNameList', 'length']) === 1) {
      return <div className={styles.teacher}>Teacher: {get(classes[classId], ['teacherNameList'])}</div>;
    }
    return null;
  };

  return (
    <li className={styles.secondaryLevelClasses}>
      {classesIds
        .filter(id => classes[id])
        .slice(0, CLASS_LIMIT)
        .map(id => (
          <Link
            key={id}
            onClick={handleSelectSecondaryLevel}
            to={getClassLinkByRole(getClassLink(id), orgId, userRole)}
            className={styles.secondaryLevelClass}
          >
            <div className={styles.imgContainer}>
              <div className={styles.classImage}>
                <SVGIcon glyph={GLYPHS.ICON_CLASS} />
              </div>
            </div>
            <div className={styles.textContainer}>
              <div className={styles.class}>{classes[id].name}</div>
              {getTeacherLabel(id)}
            </div>
          </Link>
        ))}

      {classesIds.length > CLASS_LIMIT ? (
        <Link
          onClick={handleSelectSecondaryLevel}
          className={styles.secondaryLevelClass}
          to={HubLayoutConstants.PATH_NAMES.CLASSES_PATH}
        >
          <div className={styles.secondaryLevelHiddenClass}>
            <div>+{classesIds.length - CLASS_LIMIT}</div>
          </div>
          <div className={styles.hiddenClassText}>{hubContent.more_text}</div>
        </Link>
      ) : null}
    </li>
  );
}

SecondaryLevelClasses.propTypes = {
  classesIds: PropTypes.array.isRequired,
  classes: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  hubContent: PropTypes.object.isRequired,
  handleSelectSecondaryLevel: PropTypes.func,
  orgId: PropTypes.string.isRequired,
  userRole: PropTypes.string.isRequired
};

export default compose(
  connect(
    ({
      search: { profileClasses: { currentClassList = {} } = {} } = {},
      identity: { currentOrganisationId: orgId }
    }) => {
      const classesIds = Object.keys(currentClassList);

      return {
        classes: currentClassList,
        classesIds,
        orgId
      };
    }
  ),
  withBreakpoint
)(SecondaryLevelClasses);
