import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// Redux
import { resetEditCurrentUserDetailsState } from '../../redux/actions/editCurrentUserDetails.js';
import { editUserGetDetailsRequest } from '../../redux/actions/editUser.js';
// Constants
import { formStates } from '../../redux/reducers/editCurrentUserDetails.js';
// Components
import EditUserAccountForm from './EditUserAccountForm.js';
import EditUserAccountConfirmation from './EditUserAccountConfirmation.js';
import EditUserAccountError from './EditUserAccountError.js';
import PopoutPanelIconHeading, { types } from '../PopoutPanelIconHeading/PopoutPanelIconHeading.js';
// Styles
import styles from './EditUserAccount.scss';

function EditUserAccount({
  hubContent,
  formState,
  resetEditCurrentUserDetailsStateAction,
  editUserGetDetailsRequestAction,
  userId
}) {
  const getContent = () => {
    switch (formState) {
      case formStates.EDITING: {
        return <EditUserAccountForm hubContent={hubContent} />;
      }
      case formStates.SUBMITTING: {
        return <PopoutPanelIconHeading type={types.LOADING} title={hubContent.loading_resource_subtitle} />;
      }
      case formStates.CONFIRMATION: {
        return (
          <EditUserAccountConfirmation
            hubContent={hubContent}
            onSuccessContinue={() => editUserGetDetailsRequestAction('', userId)}
          />
        );
      }
      case formStates.ERROR: {
        return <EditUserAccountError hubContent={hubContent} onTryAgain={resetEditCurrentUserDetailsStateAction} />;
      }
      default:
        <EditUserAccountForm hubContent={hubContent} />;
    }
    return null;
  };

  return <div className={styles.modalContainer}>{getContent()}</div>;
}

EditUserAccount.propTypes = {
  hubContent: PropTypes.object.isRequired,
  formState: PropTypes.string.isRequired,
  resetEditCurrentUserDetailsStateAction: PropTypes.func.isRequired,
  editUserGetDetailsRequestAction: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired
};

export default connect(
  ({ editCurrentUserDetails, identity }) => ({
    formState: editCurrentUserDetails.formState,
    userId: identity.userId
  }),
  {
    resetEditCurrentUserDetailsStateAction: resetEditCurrentUserDetailsState,
    editUserGetDetailsRequestAction: editUserGetDetailsRequest
  }
)(EditUserAccount);
