import React from 'react';
import PropTypes from 'prop-types';
import ScrollContainer from '../../ScrollContainer/ScrollContainer';
import PopoutPanelIllustrationHeading from '../../PopoutPanelIllustrationHeading/PopoutPanelIllustrationHeading';
import PopoutNavFooter from '../../PopoutNavFooter/PopoutNavFooter';
import { HubIllustrationConstants, HubIllustrationAltText } from '../../../globals/hubConstants';

function ManageProductLocksConfirmation({ content, closePanelAction }) {
  return (
    <ScrollContainer
      headerContent={
        <div>
          <PopoutPanelIllustrationHeading
            title={content.managed_students_update_confirmation_heading}
            illustrationSrc={HubIllustrationConstants.SUCCESS}
            illustrationAltText={HubIllustrationAltText.SUCCESS}
          />
        </div>
      }
      footerContent={<PopoutNavFooter nextButtonText={content.done_button} nextAction={closePanelAction} />}
    />
  );
}

ManageProductLocksConfirmation.propTypes = {
  content: PropTypes.object.isRequired,
  closePanelAction: PropTypes.func.isRequired
};

export default ManageProductLocksConfirmation;
