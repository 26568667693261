import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import WizardStep from '@oup/shared-front-end/src/components/Wizard/WizardStep';

import TextLink from '@oup/shared-front-end/src/components/TextLink';
import { BUY_OXFORD_PLACEMENT_TESTS_LINK } from '@oup/shared-node-browser/constants';
import withLocalizedContent from '../../../language/withLocalizedContent';
import { clearWizardState } from '../../../redux/actions/placementTestOnboardingWizard';
import { closeForm } from '../../../redux/reducers/placementTestSessionCreate';
import { closePlacementTestOnboardingWizard } from '../../../redux/actions/hubUi';

import styles from './PlacementTestSessionIntroductionWizardStep.scss';
import { HubIllustrationAltText, HubIllustrationConstants } from '../../../globals/hubConstants';

function PlacementTestSessionIntroductionWizardStep({
  localizedContent: { hubGlossary: hubContent, placementTests: placementTestsContent }
}) {
  return (
    <div
      data-testid="ONBOARDING_WIZARD_CREATE_A_PLACEMENT_TEST_SESSION_INTRODUCTION_WIZARD_STEP_CONTAINER"
      className={styles.introductionContainer}
    >
      <WizardStep
        titleText={placementTestsContent.placement_test_session_creation_introduction_step_title}
        subtitleText={placementTestsContent.placement_test_session_creation_introduction_step_subtitle}
        image={HubIllustrationConstants.SETUP}
        imageAlt={HubIllustrationAltText.SETUP}
        variant="introduction"
        headingSize="large"
        className={styles.yellowBackground}
      >
        <ol className={styles.organizationSetupSteps}>
          <li>
            <p>{placementTestsContent.placement_test_session_creation_introduction_step_first_step_bold}</p>
            <p>{placementTestsContent.placement_test_session_creation_introduction_step_first_step}</p>
          </li>
          <li>
            <p>{placementTestsContent.placement_test_session_creation_introduction_step_second_step_bold}</p>
            <p>{placementTestsContent.placement_test_session_creation_introduction_step_second_step}</p>
          </li>
          <li>
            <p>{placementTestsContent.placement_test_session_creation_introduction_step_third_step_bold}</p>
            <p>{placementTestsContent.placement_test_session_creation_introduction_step_third_step}</p>
          </li>

          <li>
            <p>{placementTestsContent.placement_test_session_creation_introduction_step_forth_step_bold}</p>
            <p>{placementTestsContent.placement_test_session_creation_introduction_step_forth_step}</p>
          </li>
        </ol>
        <TextLink to={hubContent.help_and_support_link_to} target="_blank">
          {hubContent.organization_page_learn_more_about_placement_tests}
        </TextLink>
        <br /> <br />
        <TextLink to={BUY_OXFORD_PLACEMENT_TESTS_LINK} target="_blank">
          {placementTestsContent.placement_test_session_creation_introduction_step_second_step_bold}
        </TextLink>
      </WizardStep>
    </div>
  );
}

PlacementTestSessionIntroductionWizardStep.propTypes = {
  localizedContent: PropTypes.object.isRequired
};

export default compose(
  withLocalizedContent('hubGlossary', 'placementTests'),
  connect(null, {
    closeWizardAction: closePlacementTestOnboardingWizard,
    clearWizardStateAction: clearWizardState,
    clearPlacementTestSessionCreateStateAction: closeForm
  })
)(PlacementTestSessionIntroductionWizardStep);
