import userRoles from '../../../globals/userRoles';

// this needs to be a number that exceeds the number of people possible in a class.
// in the case endSlice is not provided ( and in most places is not, it defaults to 10)
// causing the function to get maximum 10 of each role per class
const MAX_PEOPLE = 500;
const loadPeopleDetails = (loadPeople, orgId, classroomId, endSlice) => {
  const defaultPeopleConfig = {
    active: true,
    invited: true,
    archived: false,
    orgId
  };

  const peopleTypes = [
    {
      title: 'classStudents',
      config: {
        ...defaultPeopleConfig,
        roles: [userRoles.LEARNER],
        classId: classroomId
      },
      sort: 'firstName:asc'
    },
    {
      title: 'classTeachers',
      config: {
        ...defaultPeopleConfig,
        roles: [userRoles.TEACHER],
        classId: classroomId
      },
      sort: 'firstName:asc'
    }
  ];

  peopleTypes.forEach(personType => {
    loadPeople(personType.title, false, personType.config, endSlice || MAX_PEOPLE, personType.sort);
  });
};

export default loadPeopleDetails;
