import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { get } from 'lodash';
import PropTypes from 'prop-types';

// Constants
import colors from '../../../globals/colors';
import { HubLayoutConstants, HubIllustrationConstants } from '../../../globals/hubConstants';

// Components
import Classes from '../../HubOrganizationLayout/OrganizationClasses/Classes';
import HubListHeader from '../HubListHeader';
import SubSectionSkeletonLoader from '../../../components/SkeletonLoader/SubSectionSkeletonLoader';

// HOC
import withBreakpoint from '../../../decorators/withBreakpoint';
import { initialiseInstance } from '../../../redux/reducers/data/search.reducer';
import { toggleArchiveClassModal } from '../../../redux/actions/hubUi';
import { setClassroomsToArchive } from '../../../redux/reducers/classroomArchive';

// Services
import redirectToGradebookProgress from '../../HubDashboardLayout/Services/redirectToGradebookProgress';
import getClassLink from '../../HubDashboardLayout/Services/getClassLink';
import HubEmptyStateRestyle from '../../../components/HubEmptyStateRestyle/HubEmptyStateRestyle';

class HubPersonProfileCLasses extends Component {
  componentDidMount() {
    const { personId, loadClasses, currentOrganisationId } = this.props;

    loadClasses('userClasses', false, {
      orgId: currentOrganisationId,
      userId: personId,
      active: true
    });
  }

  _setClassToArchive = classId => {
    const { currentOrganisationId, archiveClassModalAction, setClassToArchiveAction } = this.props;
    if (classId && currentOrganisationId) setClassToArchiveAction(classId, currentOrganisationId);
    archiveClassModalAction();
  };

  render() {
    const {
      areClassesLoading,
      hubContent,
      breakpoint,
      resultIds = [],
      classrooms,
      currentOrganisationId,
      history: { push },
      personFirstname,
      userRole
    } = this.props;

    const { PATH_NAMES } = HubLayoutConstants;

    const actions = {
      setClassToArchive: this._setClassToArchive,
      redirectToProgress: (event, id) =>
        redirectToGradebookProgress(event, getClassLink(id), currentOrganisationId, push)
    };

    const emptyStateButton = {
      showButton: true,
      text: hubContent.go_to_my_classes,
      action: () => push(PATH_NAMES.ORGANIZATION_PATH + PATH_NAMES.CLASSES)
    };

    return (
      <div>
        {areClassesLoading ? (
          <SubSectionSkeletonLoader
            tabName=""
            panelName=""
            speed={2}
            foregroundColor={colors.COLOR_GREY_DISABLED}
            backgroundColor={colors.COLOR_WHITE}
          />
        ) : (
          <div>
            {resultIds.length > 0 ? (
              <div>
                <HubListHeader
                  item={{ name: hubContent.organization_classes, status: hubContent.people_header_status_text }}
                  showCheckboxHeader={false}
                  hasHeaderButtonAccess={false}
                />
                <Classes
                  actions={actions}
                  breakpoint={breakpoint}
                  ids={resultIds}
                  items={classrooms}
                  hubContent={hubContent}
                  userRole={userRole}
                />
              </div>
            ) : (
              <HubEmptyStateRestyle
                iconSrc={HubIllustrationConstants.WELCOME}
                title={hubContent.org_students_classes_tab_empty_state_title.replace('{firstName}', personFirstname)}
                bodyText={hubContent.org_students_classes_tab_empty_state_subtitle.replace(
                  '{firstName}',
                  personFirstname
                )}
                btnFilledBase={emptyStateButton}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

export default compose(
  withRouter,
  withBreakpoint,
  connect(
    state => ({
      resultIds: get(state, ['search', 'userClasses', 'ids']),
      currentOrganisationId: state.identity.currentOrganisationId,
      classrooms: state.classrooms.data,
      areClassesLoading: get(state, ['search', 'userClasses', 'loading'])
    }),
    {
      loadClasses: initialiseInstance,
      setClassToArchiveAction: setClassroomsToArchive,
      archiveClassModalAction: toggleArchiveClassModal
    }
  )
)(HubPersonProfileCLasses);

HubPersonProfileCLasses.propTypes = {
  breakpoint: PropTypes.string,
  currentOrganisationId: PropTypes.string,
  personId: PropTypes.string,
  classrooms: PropTypes.object,
  areClassesLoading: PropTypes.bool,
  resultIds: PropTypes.array,
  loadClasses: PropTypes.func,
  hubContent: PropTypes.object,
  setClassToArchiveAction: PropTypes.func.isRequired,
  archiveClassModalAction: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  personFirstname: PropTypes.string.isRequired,
  userRole: PropTypes.string.isRequired
};
