import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import colors from '../../globals/colors';
import Button, { buttonTypes } from '../Button/Button';
import ContentWithThumbnail from '../ContentWithThumbnail/ContentWithThumbnail';
import { GLYPHS } from '../SVGIcon/SVGIcon';
import { SIZES as thumbnailSizes } from '../Thumbnail/Thumbnail';
import styles from './SupportPageHeader.scss';

function SupportPageHeader({ title, subtitle, backButtonId, backButtonText, backButtonTo, glyph }) {
  return (
    <div className={classnames(styles.supportPageHeader, 'color-support-dark-bg')}>
      <div className={styles.scrollContainer}>
        <div className="grid">
          <div className="row">
            <div className="col">
              <ContentWithThumbnail size={thumbnailSizes.HEADING} glyph={glyph} backgroundColor={colors.SUPPORT_ICON}>
                {backButtonText && (
                  <div className={styles.linkContainer}>
                    <Button
                      to={backButtonTo}
                      id={backButtonId}
                      text={backButtonText}
                      type={buttonTypes.GHOST}
                      glyph={GLYPHS.ICON_LEFT}
                      disableExternalBehaviour
                    />
                  </div>
                )}
                <div className={styles.heading}>
                  <h1>{title}</h1>
                </div>
                {subtitle && <p className={styles.subheading}>{subtitle}</p>}
              </ContentWithThumbnail>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

SupportPageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  backButtonId: PropTypes.string,
  backButtonTo: PropTypes.string,
  backButtonText: PropTypes.string,
  glyph: PropTypes.any
};

SupportPageHeader.defaultProps = {
  glyph: GLYPHS.ICON_HELP
};

export default SupportPageHeader;
