/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import PropTypes from 'prop-types';
import CopyToClipboardButton from '../CopyToClipboardButton/CopyToClipboardButton';
import Tooltip from '../Tooltip/Tooltip';
import tooltipTitleText from '../Tooltip/TooltipUtils';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import styles from './TeacherClassCode.scss';

function TeacherClassCode({
  joiningCode = {},
  customOrgId = '',
  displayCopyButton = true,
  hubContent,
  labelText,
  customClassname = '',
  openEditClassroomModal,
  hasInfoIcon = false,
  isClassTile = false
}) {
  const onTurnOnClick = () => {
    openEditClassroomModal();
  };

  const classCode = joiningCode ? `${customOrgId}-${joiningCode?.code}` : '';

  return (
    <div className={`${styles.classCodeContainer} ${customClassname ? styles[customClassname] : ''}`}>
      <div>
        <div>
          {labelText && !isClassTile && <div>{labelText}</div>}
          {hasInfoIcon && !isClassTile && (
            <div className={styles.classCodeIconContainer}>
              <Tooltip title={tooltipTitleText(hubContent)} className={styles.largeTooltip}>
                <SVGIcon glyph={GLYPHS.ICON_INFORMATION_CIRCLE} className={styles.infoIcon} />
              </Tooltip>
            </div>
          )}
        </div>
        {joiningCode && joiningCode.enabled ? (
          <>
            {isClassTile ? (
              <div className={styles.classCodeContainerInside} style={{}}>
                <div className={styles.classCodeTextInClassTile}>{labelText}</div>
                <div className={styles.classCodeCustomContainer}>
                  <span className={styles.organizationCodeTextInClassTile}>{customOrgId}</span>-{joiningCode.code}
                </div>
              </div>
            ) : (
              <div>
                <span className={styles.firstPartCode}>{customOrgId}</span>
                <span>-{joiningCode.code}</span>
                {displayCopyButton && <CopyToClipboardButton classCode={classCode} />}
              </div>
            )}
          </>
        ) : (
          <>
            <span className={`${styles.disabledClassCode} ${isClassTile && styles.disabledInTile}`}>
              {hubContent.class_code_turned_off}
            </span>
            <button type="button" className={styles.classCodeLink} onClick={onTurnOnClick}>
              {hubContent.turn_on}
            </button>
          </>
        )}
      </div>
    </div>
  );
}

TeacherClassCode.propTypes = {
  joiningCode: PropTypes.object.isRequired,
  customOrgId: PropTypes.string.isRequired,
  displayCopyButton: PropTypes.bool,
  hubContent: PropTypes.object,
  labelText: PropTypes.string,
  customClassname: PropTypes.string,
  openEditClassroomModal: PropTypes.func,
  hasInfoIcon: PropTypes.bool,
  isClassTile: PropTypes.bool
};

export default TeacherClassCode;
