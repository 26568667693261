import React from 'react';
import PropTypes from 'prop-types';
// Components
import HubModalLayout from '../HubModalLayout/HubModalLayout';
import Button, { buttonTypes, GLYPHS } from '../Button/Button';
import SVGIcon from '../SVGIcon/SVGIcon';
import InformLabel from '../InformLabel/InformLabel.js';
// Services
import {
  formatDate,
  getInfoLabelData,
  contextTypes,
  showInfoLabel
} from '../../structure/HubClassLayout/Services/getClassTestData.js';
// Styles
import styles from './HubPreviewInfoModal.scss';

function HubPreviewInfoModal({
  testId,
  testData,
  modalData = {},
  ariaLabel,
  userRole,
  closeModal,
  openRedeemModal,
  hubContent,
  showFirstButtonInformLabel = false,
  openStudensStatusLicenceTab
}) {
  const { primaryButtonLabel, primaryButtonAction } = modalData;

  const getInfoLabel = () => {
    const { message, buttonText, buttonAction, customClassName, glyphIcon } = getInfoLabelData(
      hubContent,
      testData.status,
      testData.hasLicence,
      testData?.studentsWithoutLicence,
      { openRedeemModal, closePreviewInfoModal: closeModal, openStudensStatusLicenceTab },
      contextTypes.INFO_MODAL,
      userRole,
      testId
    )[userRole];

    return (
      <div className={styles.infoLabelContainer}>
        <InformLabel
          message={message}
          glyphIcon={glyphIcon || GLYPHS.ICON_WARNING_CIRCLE}
          buttonText={buttonText}
          buttonAction={buttonAction}
          customClassName={customClassName}
          showFirstButtonInformLabel={showFirstButtonInformLabel}
        />
      </div>
    );
  };

  return (
    <div className={`${styles.outerPreviewInfoModal} ${testId ? styles.open : ''}`}>
      <HubModalLayout isOpen={!!testId} id="previewInfoId" closeAction={closeModal} ariaLabel={ariaLabel}>
        {testId ? (
          <div className={styles.modalContainer}>
            <div className={styles.modalHeader}>
              <Button
                text={hubContent.close}
                type={buttonTypes.CLOSE_BORDER_GRAY}
                onClick={e => {
                  e.stopPropagation();
                  closeModal();
                }}
              />
            </div>

            <div className={styles.contentContainer}>
              <div className={styles.topInfo}>
                <div className={styles.imageContainer}>
                  <SVGIcon glyph={GLYPHS.OUP_LOGO_O} />
                </div>
                <div className={styles.textContainer}>
                  <div className={styles.firstInfoRow}>
                    <div className={`${styles.statusContainer} ${styles[testData.status]}`}>
                      <span className={styles.pulse} /> <span>{testData.status}</span>
                    </div>
                    <span className={styles.testTitle}>{testData.title}</span>
                  </div>
                  <div className={styles.secondInfoRow}>{testData.assessmentTitle}</div>
                </div>
              </div>

              {showInfoLabel(userRole, testData, testData.status) && getInfoLabel()}

              <div className={styles.dateContainer}>
                <p>
                  <span>{hubContent.start}</span> {formatDate(testData?.testStartDate, hubContent)}
                </p>
                <p>
                  <span>{hubContent.due}</span> {formatDate(testData?.testEndDate, hubContent)}
                </p>
              </div>
            </div>

            <div className={styles.modalFooter}>
              <Button text={hubContent.cancel} type={buttonTypes.CANCEL} onClick={closeModal} />
              <Button
                text={primaryButtonLabel}
                type={buttonTypes.PRIMARY}
                onClick={() => primaryButtonAction(testId)}
              />
            </div>
          </div>
        ) : null}
      </HubModalLayout>
    </div>
  );
}
HubPreviewInfoModal.propTypes = {
  openStudensStatusLicenceTab: PropTypes.func,
  testId: PropTypes.string.isRequired,
  testData: PropTypes.object,
  modalData: PropTypes.object.isRequired,
  ariaLabel: PropTypes.string,
  closeModal: PropTypes.func.isRequired,
  openRedeemModal: PropTypes.func.isRequired,
  userRole: PropTypes.string.isRequired,
  hubContent: PropTypes.object.isRequired,
  showFirstButtonInformLabel: PropTypes.bool
};

export default HubPreviewInfoModal;
