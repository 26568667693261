import React from 'react';
import PropTypes from 'prop-types';
import TabNumberLoader from '../SkeletonLoader/Hub/TabNumberLoader/TabNumberLoader';

// Styles
import styles from './HubTabs.scss';

function Tab({ tab: { name, count = 0, key }, selectView, view, isLoading }) {
  const counter = isLoading ? <TabNumberLoader /> : <span>{count}</span>;
  return (
    <button
      type="submit"
      className={`${styles.tabButton} ${key === view ? styles.active : ''}`}
      onClick={() => selectView(key)}
    >
      <div className={styles.contentButton}>
        {name}
        {count !== false && counter}
      </div>
    </button>
  );
}

Tab.propTypes = {
  tab: PropTypes.object.isRequired,
  selectView: PropTypes.func.isRequired,
  view: PropTypes.string.isRequired,
  isLoading: PropTypes.bool
};

export default Tab;
