import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { formStates } from '../../../../../../redux/reducers/hub/hubLMSConfig.reducer';
import ConnectedForm from './Form';
import Loading from './Loading';
import Error from './Error';
import Success from './Success';
import ConnectedArchive from './Archive';

import { triggerSearch } from '../../../../../../redux/reducers/data/search.reducer';

class DeploymentTool extends React.Component {
  handleOnComplete = () => {
    const { resetAction, onComplete, triggerSearchAction, closePanel } = this.props;
    onComplete();
    triggerSearchAction();
    setTimeout(resetAction, 300);
    closePanel();
  };

  render() {
    const { closePanel, formState, orgName, mode, formData, hubContent, hasTools, tools, error } = this.props;

    const screen = {
      [formStates.INPUTTING]: (
        <ConnectedForm
          closePanel={closePanel}
          orgName={orgName}
          onComplete={this.handleOnComplete}
          hubContent={hubContent}
          mode={mode}
          formData={formData}
          hasTools={hasTools}
        />
      ),
      [formStates.LOADING]: <Loading mode={mode} hubContent={hubContent} hasTools={hasTools} />,
      [formStates.SUCCESS]: (
        <Success
          closePanel={this.handleOnComplete}
          mode={mode}
          tool={formData}
          onComplete={this.handleOnComplete}
          tools={tools}
          hasTools={hasTools}
          hubContent={hubContent}
        />
      ),
      [formStates.ERROR]: (
        <Error closePanel={closePanel} hubContent={hubContent} mode={mode} hasTools={hasTools} error={error} />
      ),
      [formStates.ARCHIVE]: <ConnectedArchive closePanel={closePanel} hubContent={hubContent} />
    }[formState];

    return screen;
  }
}

DeploymentTool.propTypes = {
  orgName: PropTypes.string.isRequired,
  formState: PropTypes.string.isRequired,
  formData: PropTypes.object.isRequired,
  mode: PropTypes.string.isRequired,
  resetAction: PropTypes.func.isRequired,
  closePanel: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  triggerSearchAction: PropTypes.func.isRequired,
  hubContent: PropTypes.object.isRequired,
  hasTools: PropTypes.bool.isRequired,
  tools: PropTypes.array.isRequired,
  error: PropTypes.any
};

export default connect(
  state => ({
    formState: state.createLMSConfig.formState,
    error: state.createLMSConfig.error,
    mode: state.createLMSConfig.context,
    formData: state.createLMSConfig.formData,
    hasTools: state.search.lmsConfig.totalResults > 0,
    tools: state.search.lmsConfig.data
  }),
  {
    triggerSearchAction: () => triggerSearch('lmsConfig')
  }
)(DeploymentTool);
