export default function getTileLimit() {
  const dashboardPadding = 24;
  const courseGridGap = 12;
  const tileWidth = document.querySelector('[data-list="tile"]').offsetWidth;
  const dashboardWidth = document.querySelector('[data-dashboard="dashboard"]').offsetWidth;

  const tileLimit = Math.floor((dashboardWidth - dashboardPadding) / (tileWidth + courseGridGap) + 0.1);

  return tileLimit;
}
