import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import withLocalizedContent from '../../language/withLocalizedContent';
import { getCurrentPlatform, isHubMode } from '../../utils/platform.js';
import SVGIcon, { GLYPHS } from '../../components/SVGIcon/SVGIcon';
import RegisterForm from './RegisterForm.js';

function RegisterPage({ match, location, localizedContent: { registrationPage: content }, notifyUserIfHasSession }) {
  return (
    <div className="register">
      {isHubMode() ? (
        <Helmet title={content.pageTitle} titleTemplate="%s | Oxford University Press" />
      ) : (
        <Helmet title={content.pageTitle} />
      )}
      {notifyUserIfHasSession ? (
        <div style={{ textAlign: 'center' }}>
          <div style={{ width: '5rem', margin: '2rem auto 0' }}>
            <SVGIcon glyph={GLYPHS.ICON_ERROR_CIRCLE} />
          </div>
          <h1>{content.error_already_login}</h1>
          <p>{content.error_already_login_please_logout}</p>
        </div>
      ) : (
        <div className="grid pad-top2 pad-bot4">
          <RegisterForm platformCode={getCurrentPlatform()} token={match.params.token || ''} location={location} />
        </div>
      )}
    </div>
  );
}

RegisterPage.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  localizedContent: PropTypes.object.isRequired,
  notifyUserIfHasSession: PropTypes.bool
};

export default withLocalizedContent('registrationPage')(RegisterPage);
