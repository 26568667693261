import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import Button from '@oup/shared-front-end/src/components/Button';
import ValidationMessage from '@oup/shared-front-end/src/components/ValidationMessage';
import Toggle from '@oup/shared-front-end/src/components/Toggle';
import SelectInputs from './SelectInputs/SelectInputs';
import Tooltip from '../../Tooltip/Tooltip';
import SVGIcon, { GLYPHS } from '../../SVGIcon/SVGIcon';
import style from './NotificationsAndReminders.scss';
import { DEFAULT_REMINDERS, INTERVAL_OPTIONS, MOMENT_OPTIONS } from '../utils/constants';
import withLocalizedContent from '../../../language/withLocalizedContent';
import { setEditedPlacementTest } from '../../../redux/reducers/placementTestSessionCreate';

function NotificationsAndReminders({
  localizedContent: { placementTests: placementTestsContent },
  placementTestSessionId,
  placementTest,
  sendEmailsToStudents,
  notificationReminders,
  setSendEmailsToStudents,
  setNotificationReminders,
  setIsTouched = () => {},
  setIsValid = () => {},
  setEditedPlacementTestAction
}) {
  useEffect(() => {
    const findEmptySelect = notificationReminders.find(item => item.timePeriod === 'select');
    if (sendEmailsToStudents) setIsValid(!findEmptySelect);
    else setIsValid(true);
  }, [sendEmailsToStudents, notificationReminders]);

  const handleSelectChange = (pairIndex, selectName, value) => {
    const updatedPairs = [...notificationReminders];
    updatedPairs[pairIndex] = {
      ...updatedPairs[pairIndex],
      [selectName]: selectName === 'timePeriod' && value !== 'select' ? Number(value) : value
    };
    setNotificationReminders(updatedPairs);
    setEditedPlacementTestAction({ notificationReminders: updatedPairs });
    setIsTouched(true);
  };

  const handleRemove = index => {
    const updatedState = [...notificationReminders];
    updatedState.splice(index, 1);
    setNotificationReminders(updatedState);
    setEditedPlacementTestAction({ notificationReminders: updatedState });
    setIsTouched(true);
  };

  const addNewInputPair = () => {
    const updatedState = [...notificationReminders, DEFAULT_REMINDERS[0]];
    setNotificationReminders(updatedState);
    setIsTouched(true);
  };

  const onToggle = () => {
    setEditedPlacementTestAction({ sendEmailsToStudents: !sendEmailsToStudents });
    setSendEmailsToStudents(!sendEmailsToStudents);
    if (sendEmailsToStudents && !placementTest.notificationReminders.length) {
      setNotificationReminders(DEFAULT_REMINDERS);
    } else {
      setNotificationReminders([]);
    }
    setIsTouched(true);
  };

  useEffect(() => {
    if (placementTestSessionId) {
      setSendEmailsToStudents(placementTest.sendEmailsToStudents);
      setNotificationReminders(placementTest.notificationReminders);
    } else if (placementTest?.placementTestType === 'OPT_YL') {
      setSendEmailsToStudents(false);
    } else {
      setSendEmailsToStudents(true);
      if (sendEmailsToStudents) setNotificationReminders(DEFAULT_REMINDERS);
    }
  }, []);

  const ToggleComponent = useCallback(() => <Toggle onChange={onToggle} toggled={sendEmailsToStudents} />, [
    sendEmailsToStudents
  ]);

  return (
    <div className={style.notificationsColumn}>
      <div className={style.customiseToggle}>
        <ToggleComponent />
        <span className={style.notificationText}>
          {placementTestsContent.placement_test_notification_page_toggle_label}
        </span>
        <Tooltip
          className={style.orgStatusLicenceItemTooltip}
          title={placementTestsContent.placement_test_notification_page_tooltip_text}
        >
          <SVGIcon glyph={GLYPHS.ICON_HELP_CIRCLE} className={style.infoIcon} />
        </Tooltip>
      </div>
      {sendEmailsToStudents && (
        <>
          <div className={style.selectionContainer}>
            {notificationReminders.map((pair, index) => (
              <SelectInputs
                label={index !== 0 ? 'and' : 'Set reminders'}
                intervalValue={pair.timePeriod}
                momentValue={pair.reminderEvent}
                intervalOptions={INTERVAL_OPTIONS}
                momentOptions={MOMENT_OPTIONS}
                id={`${index}-selector`}
                key={index}
                customLabelClass={index !== 0 && style.labelThinWeight}
                onChange={({ target: { value, name } }) => handleSelectChange(index, name, value)}
                onRemove={() => handleRemove(index)}
              />
            ))}
            <div className="gin-top3">
              <Button
                variant="outline"
                text={placementTestsContent.placement_test_notification_page_button_label}
                icon={{ component: <SVGIcon glyph={GLYPHS.ICON_PLUS} /> }}
                onClick={() => addNewInputPair()}
              />
            </div>
          </div>
          <div className="gin-top6">
            <ValidationMessage state="information">
              <div className={style.noteText}>{placementTestsContent.placement_test_notification_page_note_text}</div>
            </ValidationMessage>
          </div>
        </>
      )}
    </div>
  );
}

NotificationsAndReminders.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  placementTestSessionId: PropTypes.string.isRequired,
  placementTest: PropTypes.object.isRequired,
  sendEmailsToStudents: PropTypes.bool,
  notificationReminders: PropTypes.array,
  setSendEmailsToStudents: PropTypes.func,
  setNotificationReminders: PropTypes.func,
  setIsTouched: PropTypes.func,
  setIsValid: PropTypes.func,
  setEditedPlacementTestAction: PropTypes.func
};

export default compose(
  withLocalizedContent('placementTests'),
  connect(
    ({ placementTestSessionCreate }) => ({
      placementTest: placementTestSessionCreate.placementTest,
      placementTestSessionId: placementTestSessionCreate.placementTestSessionId
    }),
    { setEditedPlacementTestAction: setEditedPlacementTest }
  )
)(NotificationsAndReminders);
