import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import Footer from '@oup/shared-front-end/src/components/Wizard/Footer/Footer.js';
import PlacementTestStartEndDate from '../../../../components/PlacementTestOnBoardingWizard/PlacementTestSchedule/PlacementTestStartEndDate';
import PlacementTestExtraTime from '../../../../components/PlacementTestOnBoardingWizard/PlacementTestSchedule/PlacementTestExtraTime';

import PanelHeading from '../../../../components/PanelHeading/PanelHeading';
import ScrollContainer from '../../../../components/ScrollContainer/ScrollContainer';
import { updatePlacementTestSessionRequest } from '../../../../redux/actions/placementTestOnboardingWizard';
import { setPlacementTest } from '../../../../redux/reducers/placementTestSessionCreate';

import withLocalizedContent from '../../../../language/withLocalizedContent';

function PlacementTestEditTestSchedule({
  panelSessionName,
  updatePlacementTestSessionRequestAction,
  cancelTestSchedulePanel,
  orgId,
  placementTestSessionId,
  localizedContent: { placementTests: placementTestsContent, hubGlossary: hubContent },
  testStartDate,
  testEndDate,
  extraTimePerItem,
  setPlacementTestAction,
  placementTestStatus
}) {
  const [initialState, setInitialState] = useState(null);
  const [isTestScheduleTouched, setIsTestScheduleTouched] = useState(false);

  const [editedStartDate, setEditedStartDate] = useState(testStartDate);
  const [editedEndDate, setEditedEndDate] = useState(testEndDate);
  const [editedExtraTimePerItem, setEditedExtraTimePerItem] = useState(extraTimePerItem);

  const [editedStartDareIsInvalid, setEditedStartDateIsInvalid] = useState(false);
  const [editedEndDateIsInvalid, setEditedEndDateIsInvalid] = useState(false);
  const [editedExtraTimePerItemIsInvalid, setEditedExtraTimePerItemIsInvalid] = useState(false);

  useEffect(() => {
    setInitialState({
      initialTestStartDate: testStartDate,
      initialTestEndDate: testEndDate,
      initialExtraTimePerItem: extraTimePerItem
    });
  }, []);

  useEffect(() => {
    if (
      initialState &&
      (editedStartDate !== initialState?.initialTestStartDate ||
        editedEndDate !== initialState?.initialTestEndDate ||
        editedExtraTimePerItem !== initialState?.initialExtraTimePerItem)
    ) {
      setIsTestScheduleTouched(true);
    } else {
      setIsTestScheduleTouched(false);
    }
  }, [editedStartDate, editedEndDate, editedExtraTimePerItem]);

  return (
    <ScrollContainer
      headerContent={
        <PanelHeading
          title={placementTestsContent.placement_test_session_manage_test_schedule}
          subtitle={`${hubContent.for_label} <strong>${panelSessionName}</strong>`}
        />
      }
      footerContent={
        <Footer
          primaryButtonLabel={hubContent.save}
          primaryButtonAction={e => {
            updatePlacementTestSessionRequestAction({
              orgId,
              sessionId: placementTestSessionId,
              placementTest: {
                testStartDate: editedStartDate,
                testEndDate: editedEndDate,
                extraTimePerItem: editedExtraTimePerItem
              }
            });
            setPlacementTestAction({
              testStartDate: editedStartDate,
              testEndDate: editedEndDate,
              extraTimePerItem: editedExtraTimePerItem
            });
            cancelTestSchedulePanel(e, true);
          }}
          secondaryButtonLabel={hubContent.cancel}
          secondaryButtonAction={cancelTestSchedulePanel}
          primaryButtonAttributes={{
            dataAttributes: {
              testid: 'PLACEMENT_TEST_PANEL_EDIT_TEST_SCHEDULE_SAVE_BUTTON'
            },
            disabled:
              !isTestScheduleTouched ||
              !!editedStartDareIsInvalid ||
              !!editedEndDateIsInvalid ||
              !!editedExtraTimePerItemIsInvalid
          }}
          secondaryButtonAttributes={{
            dataAttributes: {
              testid: 'PLACEMENT_TEST_PANEL_EDIT_TEST_SCHEDULE_CANCEL_BUTTON'
            }
          }}
        />
      }
    >
      <div className="pad2">
        <PlacementTestStartEndDate
          testStartDate={editedStartDate}
          setTestStartDate={setEditedStartDate}
          setTestStartDateIsInvalid={setEditedStartDateIsInvalid}
          testEndDate={editedEndDate}
          setTestEndDate={setEditedEndDate}
          setTestEndDateIsInvalid={setEditedEndDateIsInvalid}
          placementTestStatus={placementTestStatus}
        />

        <PlacementTestExtraTime
          extraTimePerItem={editedExtraTimePerItem}
          setExtraTimePerItem={setEditedExtraTimePerItem}
          setExtraTimePerItemIsInvalid={setEditedExtraTimePerItemIsInvalid}
          placementTestStatus={placementTestStatus}
        />
      </div>
    </ScrollContainer>
  );
}

PlacementTestEditTestSchedule.propTypes = {
  panelSessionName: PropTypes.string.isRequired,
  testStartDate: PropTypes.string,
  testEndDate: PropTypes.string,
  extraTimePerItem: PropTypes.number,
  updatePlacementTestSessionRequestAction: PropTypes.func,
  orgId: PropTypes.string.isRequired,
  placementTestSessionId: PropTypes.string.isRequired,
  cancelTestSchedulePanel: PropTypes.func.isRequired,
  localizedContent: PropTypes.object.isRequired,
  setPlacementTestAction: PropTypes.func,
  placementTestStatus: PropTypes.string
};

export default compose(
  withLocalizedContent('hubGlossary', 'placementTests'),
  connect(
    ({ placementTestSessionCreate, identity }) => ({
      placementTestStatus: placementTestSessionCreate.placementTest.status,
      testStartDate: placementTestSessionCreate.placementTest.testStartDate,
      testEndDate: placementTestSessionCreate.placementTest.testEndDate,
      extraTimePerItem: placementTestSessionCreate.placementTest.extraTimePerItem,
      placementTest: placementTestSessionCreate.placementTest,
      orgId: identity.currentOrganisationId,
      placementTestSessionId: placementTestSessionCreate.placementTestSessionId
    }),
    {
      updatePlacementTestSessionRequestAction: updatePlacementTestSessionRequest,
      setPlacementTestAction: setPlacementTest
    }
  )
)(PlacementTestEditTestSchedule);
