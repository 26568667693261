import React, { useEffect } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import withBreakpoint from '../../decorators/withBreakpoint';
import { isLtiMode } from '../../utils/platform';

// HOC
import withLocalizedContent from '../../language/withLocalizedContent';
// Services
import homepageContentV4 from './homepageContentV4.json';
// Components
import HubTopRow from './parts/HubTopRow/HubTopRow';
import HubMiddleRow from './parts/HubMiddleRow/HubMiddleRow';
import HubCarouselRow from './parts/HubCarouselRow/HubCarouselRow';
import mountLiveChat, { unmountLiveChat } from '../../utils/chat/liveChat';

function HubHomepage({ localizedContent: { hubGlossary: hubContent }, breakpoint }) {
  if (isLtiMode()) {
    if (localStorage.getItem('lti-token')) {
      localStorage.removeItem('impersonation-token');
    } else {
      localStorage.setItem('lti-token', 'no-session-found');
    }
    window.location.href = '/myDashboard';
    return <div className="homepage-disabled-for-lti-mode" />;
  }

  useEffect(() => {
    mountLiveChat();
    return () => unmountLiveChat();
  }, []);

  return (
    <div>
      <Helmet title={hubContent.home_page_title} titleTemplate={hubContent.page_title_template} />
      <HubTopRow hubContent={hubContent} homepageContent={homepageContentV4} />
      <HubMiddleRow homepageContent={homepageContentV4} />
      <HubCarouselRow homepageContent={homepageContentV4} breakpoint={breakpoint} />
    </div>
  );
}

export default compose(withLocalizedContent('hubGlossary'), withBreakpoint)(HubHomepage);

HubHomepage.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  breakpoint: PropTypes.string.isRequired
};
