import React from 'react';
import PropTypes from 'prop-types';
// Components
import ScrollContainer from '../../../../components/ScrollContainer/ScrollContainer';
import PopoutPanelIllustrationHeading from '../../../../components/PopoutPanelIllustrationHeading/PopoutPanelIllustrationHeading';
import PopoutActionFooter from '../../../../components/PopoutActionFooter/PopoutActionFooter';
// Constants
import { HubIllustrationConstants, HubIllustrationAltText } from '../../../../globals/hubConstants';

function CancelInvitationErrorPanel({ onComplete, hubContent }) {
  return (
    <ScrollContainer
      footerContent={<PopoutActionFooter primaryButtonText={hubContent.done} primaryButtonAction={onComplete} />}
    >
      <PopoutPanelIllustrationHeading
        title={hubContent.cancel_invitation_error_text}
        illustrationSrc={HubIllustrationConstants.ERROR}
        illustrationAltText={HubIllustrationAltText.ERROR}
      />
    </ScrollContainer>
  );
}

export default CancelInvitationErrorPanel;

CancelInvitationErrorPanel.propTypes = {
  onComplete: PropTypes.func.isRequired,
  hubContent: PropTypes.object.isRequired
};
