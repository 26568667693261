import { HubLayoutConstants } from '../../../globals/hubConstants.js';
import { isLtiMode } from '../../../utils/platform.js';

// Evaluating Header Props, Footer Props and Page Title
const evaluateLayoutProps = ({
  pathname,
  search,
  registrationComplete,
  userSignedIn,
  termsAndConditionsAccepted,
  isVisibleSelfSelectedRoleModal = false
}) => {
  const {
    PATH_NAMES: {
      HOME_PAGE,
      WELCOME_HOME_PAGE,
      COURSES_PATH,
      DASHBOARD_PATH,
      REGISTER_PATH,
      REGISTER_CHOICE_PATH,
      RESET_PASSWORD,
      REGISTER_ORGANIZATION,
      DECLINE_ORG_INVITE,
      REDEEM_PATH,
      SUPPORT_PATH,
      CLASSES_PATH,
      SINGLE_COURSE_PATH,
      SINGLE_CLASS_PATH,
      PROGRESS,
      STUDENT_ANSWER,
      PEOPLE,
      CLASSWORK,
      ORGANIZATION_PATH,
      STUDENTS,
      STAFF,
      CLASSES,
      PLACEMENT_TESTS,
      LICENCES,
      CLASS_ASSESSMENT_PATH,
      PRODUCT_PAGE
    }
  } = HubLayoutConstants;

  const layoutProps = {
    pageTitle: 'ELT Hub',
    headerProps: {
      isEmbedded: false,
      hideRegisterLink: false,
      hideSignInLink: false,
      hideWidgetDropDown: false,
      hideSubHeader: true,
      disableLogoLink: false,
      hideHelpLink: false,
      hideMyAccountText: true,
      hideBurgerMenu: false,
      darkLayout: false
    },
    footerProps: {
      hideFooter: false,
      hideUpperFooter: true,
      darkLayout: false
    }
  };

  switch (true) {
    case pathname === REGISTER_ORGANIZATION:
      layoutProps.headerProps.hideBurgerMenu = true;
      layoutProps.footerProps.hideUpperFooter = false;
      break;

    // Hide both sign in and register links on registration complete state for both partial (/register/:token) and full reg (/register)
    case pathname.startsWith(REGISTER_PATH) && registrationComplete:
      layoutProps.headerProps.hideRegisterLink = true;
      layoutProps.headerProps.darkLayout = true;
      layoutProps.footerProps.darkLayout = true;
      layoutProps.headerProps.disableLogoLink = true;
      layoutProps.footerProps.hideUpperFooter = false;
      break;
    case pathname === REGISTER_CHOICE_PATH:
      layoutProps.screenTitle = 'register_screen_title';
      layoutProps.headerProps.hideRegisterLink = true;
      layoutProps.headerProps.hideSignInLink = true;
      layoutProps.headerProps.darkLayout = true;
      layoutProps.footerProps.hideUpperFooter = false;
      layoutProps.footerProps.darkLayout = true;
      break;
    case pathname.includes(REGISTER_PATH):
      layoutProps.screenTitle = 'register_screen_title';
      layoutProps.headerProps.hideRegisterLink = true;
      layoutProps.headerProps.darkLayout = true;
      layoutProps.footerProps.hideUpperFooter = false;
      layoutProps.footerProps.darkLayout = true;
      // Verify if there is any regstration token in the pathname
      if (new RegExp(`${REGISTER_PATH}/.+`, 'g').test(pathname)) {
        layoutProps.headerProps.disableLogoLink = true;
      }
      break;
    case pathname === DECLINE_ORG_INVITE:
      layoutProps.pageTitle = 'decline_invitation_screen_title';
      layoutProps.footerProps.hideUpperFooter = false;
      break;
    case pathname.includes(RESET_PASSWORD):
      layoutProps.headerProps.hideRegisterLink = true;
      layoutProps.footerProps.hideUpperFooter = false;
      layoutProps.headerProps.darkLayout = true;
      layoutProps.footerProps.darkLayout = true;
      // Verify if there is any reset-pwd token in the pathname
      if (new RegExp(`${RESET_PASSWORD}/.+`, 'g').test(pathname)) {
        layoutProps.headerProps.disableLogoLink = true;
      }
      break;
    case pathname === HOME_PAGE && search.includes('code') && search.includes('state'):
      layoutProps.headerProps.hideWidgetDropDown = true;
      layoutProps.headerProps.disableLogoLink = true;
      layoutProps.footerProps.hideUpperFooter = false;
      break;
    case pathname === WELCOME_HOME_PAGE:
      layoutProps.screenTitle = 'welcome_homepage_screen_title';
      if (!userSignedIn) {
        layoutProps.headerProps.darkLayout = true;
        layoutProps.footerProps.darkLayout = true;
        layoutProps.footerProps.hideUpperFooter = false;
      }
      break;
    case pathname === HOME_PAGE:
      layoutProps.screenTitle = 'homepage_screen_title';
      layoutProps.headerProps.darkLayout = true;
      layoutProps.footerProps.darkLayout = true;
      layoutProps.footerProps.hideUpperFooter = false;
      break;
    case pathname.startsWith(REDEEM_PATH):
      layoutProps.screenTitle = 'redeem_screen_title';
      layoutProps.headerProps.hideRegisterLink = true;
      layoutProps.headerProps.hideSignInLink = true;
      layoutProps.footerProps.hideUpperFooter = true;
      layoutProps.headerProps.darkLayout = true;
      layoutProps.footerProps.darkLayout = true;
      break;
    case pathname.startsWith(DASHBOARD_PATH) ||
      pathname.startsWith(COURSES_PATH) ||
      pathname.startsWith(CLASSES_PATH) ||
      pathname.startsWith(STUDENT_ANSWER) ||
      pathname.startsWith(ORGANIZATION_PATH):
      layoutProps.footerProps.hideFooter = false;
      layoutProps.footerProps.hideUpperFooter = true;

      if (!termsAndConditionsAccepted) layoutProps.headerProps.disableLogoLink = true;
      if (isVisibleSelfSelectedRoleModal) layoutProps.headerProps.disableLogoLink = true;

      switch (true) {
        case pathname === DASHBOARD_PATH:
          layoutProps.screenTitle = 'dashboard_screen_title';
          break;
        case pathname.includes(SINGLE_COURSE_PATH):
          layoutProps.screenTitle = 'product_screen_title';
          break;
        case pathname === CLASSES_PATH:
          layoutProps.screenTitle = 'classes_screen_title';
          break;
        case pathname.includes(PROGRESS):
          layoutProps.screenTitle = 'progress_screen_title';
          break;
        case pathname.includes(STUDENT_ANSWER):
          layoutProps.screenTitle = 'student_answer_screen_title';
          break;
        case pathname.includes(CLASSWORK):
          layoutProps.screenTitle = 'classwork_screen_title';
          break;
        case pathname.includes(PEOPLE):
          layoutProps.screenTitle = 'people_screen_title';
          break;
        case pathname.includes(SINGLE_CLASS_PATH) && pathname.includes(CLASS_ASSESSMENT_PATH):
          layoutProps.screenTitle = 'class_assessment_screen_title';
          break;
        case pathname.includes(STUDENTS):
          layoutProps.screenTitle = 'org_students_screen_title';
          break;
        case pathname.includes(STAFF):
          layoutProps.screenTitle = 'org_staff_screen_title';
          break;
        case pathname.includes(CLASSES):
          layoutProps.screenTitle = 'org_class_screen_title';
          break;
        case pathname.includes(PLACEMENT_TESTS):
          layoutProps.screenTitle = 'org_placementTests_screen_title';
          break;
        case pathname.includes(LICENCES):
          layoutProps.screenTitle = 'org_licences_screen_title';
          break;
        default: // do nothing
      }
      break;
    case pathname.startsWith(SUPPORT_PATH):
      layoutProps.screenTitle = 'help_support_screen_title';
      layoutProps.headerProps.hideBurgerMenu = true;
      layoutProps.headerProps.hideHelpLink = true;
      layoutProps.footerProps.hideFooter = false;
      layoutProps.footerProps.hideUpperFooter = true;
      if (!userSignedIn) {
        layoutProps.headerProps.darkLayout = true;
        layoutProps.footerProps.darkLayout = true;
      }
      break;
    case pathname.startsWith(PRODUCT_PAGE):
      layoutProps.headerProps.hideBurgerMenu = true;
      break;
    default:
      layoutProps.screenTitle = 'courses_screen_title';
  }

  if (isLtiMode()) {
    layoutProps.headerProps.hideHelpLink = true;
  }

  return layoutProps;
};

export default evaluateLayoutProps;
