import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import ScrollContainer from '../components/ScrollContainer/ScrollContainer';
import PanelNavigationLink from '../components/PanelNavigationLink/PanelNavigationLink';
import StudentsWithoutLicenceGroup from '../components/StudentsWithoutLicenceGroup/StudentsWithoutLicenceGroup';
import PopoutNavFooter from '../components/PopoutNavFooter/PopoutNavFooter';
import styles from './StudentLicenceView.scss';
import LoadingSpinner from '../components/LoadingSpinner/LoadingSpinner';
import SubSectionSkeletonLoader from '../components/SkeletonLoader/SubSectionSkeletonLoader';
import { featureIsEnabled } from '../globals/envSettings';
import { formatDate } from '../structure/HubClassLayout/Services/getClassTestData.js';
import USER_ROLES from '../globals/userRoles';
import colors from '../globals/colors';
import SVGIcon, { GLYPHS } from '../components/SVGIcon/SVGIcon';

const getStudentsWithoutLicence = (usersByRole, testData, onlineTestPanel, selectedStudentIds) => {
  let studentsWithoutLicence;

  if (onlineTestPanel) {
    studentsWithoutLicence = selectedStudentIds
      .filter(
        studentId =>
          !Object.keys(testData.licenceStudentsList).includes(studentId) ||
          (Object.keys(testData.licenceStudentsList).includes(studentId) &&
            moment(testData.licenceStudentsList[studentId].expiresOn).diff(testData.endDate, 'days', true) < 0)
      )
      .reduce((cur, key) => Object.assign(cur, { [key]: usersByRole[USER_ROLES.LEARNER][key] }), {});
  } else {
    studentsWithoutLicence = Object.keys(usersByRole[USER_ROLES.LEARNER])
      .filter(key => testData?.studentsWithoutLicence.includes(key))
      .reduce((cur, key) => Object.assign(cur, { [key]: usersByRole[USER_ROLES.LEARNER][key] }), {});
  }

  return studentsWithoutLicence;
};

const getStudentsWithLicence = (usersByRole, testData, onlineTestPanel, selectedStudentIds) => {
  let studentsWithLicence;

  if (onlineTestPanel) {
    studentsWithLicence = selectedStudentIds
      .filter(
        studentId =>
          Object.keys(testData.licenceStudentsList).includes(studentId) &&
          moment(testData.licenceStudentsList[studentId].expiresOn).diff(testData.endDate, 'days', true) > 0
      )
      .reduce((cur, key) => Object.assign(cur, { [key]: usersByRole[USER_ROLES.LEARNER][key] }), {});
  } else {
    studentsWithLicence = Object.keys(usersByRole[USER_ROLES.LEARNER])
      .filter(key => !testData?.studentsWithoutLicence.includes(key))
      .reduce((cur, key) => Object.assign(cur, { [key]: usersByRole[USER_ROLES.LEARNER][key] }), {});
  }
  return studentsWithLicence;
};

function StudentLicenceView({
  testData,
  localizedContent: content,
  backAction,
  usersByRole,
  isLoading,
  closeAction,
  onlineTestPanel = false,
  selectedStudentIds = []
}) {
  return (
    <ScrollContainer
      title={content?.panel_title}
      headerContent={
        <div>
          <div className="text-right">
            {closeAction ? (
              <PanelNavigationLink isLhs={false} text={content?.close_button} action={closeAction} />
            ) : null}
          </div>

          <div className={`${styles.testContainer}`}>
            <div className={styles.testInfoContainer}>
              <div className={styles.imgContainer}>
                <SVGIcon glyph={GLYPHS.OUP_LOGO_O} />
              </div>

              <div className={styles.mainInfoContainer}>
                <div className={styles.topInfo}>
                  <span className={styles.panelHeading}>
                    <h2>
                      {!!testData?.title && testData.title}{' '}
                      {!!testData?.assessmentTitle && `(${testData.assessmentTitle})`}
                    </h2>
                  </span>
                </div>
                <div className={styles.dateContainer}>
                  <p>
                    <span>{content?.start}</span> {formatDate(testData?.testStartDate, content)}
                  </p>
                  <p>
                    <span>{content?.due}</span> {formatDate(testData?.testEndDate, content)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      footerContent={
        <div>
          <PopoutNavFooter backAction={() => backAction()} />
        </div>
      }
    >
      <div>
        {!featureIsEnabled('skeleton-loader') && isLoading ? <LoadingSpinner /> : null}
        {featureIsEnabled('skeleton-loader') && isLoading ? (
          <SubSectionSkeletonLoader
            largeRectWidth="60%"
            smallRectWidth="40%"
            speed={2}
            foregroundColor={colors.COLOR_GREY_DISABLED}
            backgroundColor={colors.COLOR_WHITE}
          />
        ) : null}

        {!isLoading && (
          <div className={styles.smallTitleOne}>
            <h3 className={styles.smallTitleSpace}>{content?.panel_subtitle_students_without_licence}</h3>
          </div>
        )}

        {!isLoading && !!Object.keys(usersByRole[USER_ROLES.LEARNER]).length && (
          <StudentsWithoutLicenceGroup
            title="Students"
            items={getStudentsWithoutLicence(usersByRole, testData, onlineTestPanel, selectedStudentIds)}
            withoutLicence
          />
        )}

        {!isLoading &&
          !!Object.keys(getStudentsWithLicence(usersByRole, testData, onlineTestPanel, selectedStudentIds)).length && (
            <div className={styles.smallTitleTwo}>
              <h3 className={styles.smallTitleSpace}> {content?.panel_subtitle_students_with_licence}</h3>
            </div>
          )}

        {!isLoading && !!Object.keys(usersByRole[USER_ROLES.LEARNER]).length && (
          <StudentsWithoutLicenceGroup
            title="Students"
            items={getStudentsWithLicence(usersByRole, testData, onlineTestPanel, selectedStudentIds)}
            withoutLicence={false}
          />
        )}
      </div>
    </ScrollContainer>
  );
}

StudentLicenceView.propTypes = {
  backAction: PropTypes.func.isRequired,
  closeAction: PropTypes.func,
  usersByRole: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  localizedContent: PropTypes.object.isRequired,
  testData: PropTypes.object,
  onlineTestPanel: PropTypes.bool,
  selectedStudentIds: PropTypes.array
};

export default StudentLicenceView;
