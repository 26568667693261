import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { pick } from 'lodash';
import { connect } from 'react-redux';

import {
  cancelInvitationStates,
  componentStates as userProfileStates
} from '../../../redux/reducers/user/userProfile.reducer';

import {
  SendInvitationReminderProcessing,
  SendInvitationReminderSuccess,
  SendInvitationReminderFailure
} from '../../../components';

class ResendInvitation extends Component {
  handleClosePanel = () => {
    const { closePopoutAction } = this.props;
    closePopoutAction();
  };

  render() {
    const { formState, userProfileState, isSendReminderSubmitting, cancelInvitationState } = this.props;

    switch (true) {
      case cancelInvitationState === cancelInvitationStates.PENDING:
        return <SendInvitationReminderProcessing />;
      case userProfileState === userProfileStates.PROFILE_COMPLETE && isSendReminderSubmitting:
        return (
          <SendInvitationReminderSuccess
            {...pick(this.props, ['firstName', 'lastName'])}
            onDone={this.handleClosePanel}
          />
        );
      case cancelInvitationState === cancelInvitationStates.ERROR:
        return <SendInvitationReminderFailure onDone={this._handleClosePanel} />;
      default:
        return (
          <div>
            <p>Error. Missing a state for `{formState}`.</p>
          </div>
        );
    }
  }
}

ResendInvitation.propTypes = {
  contextName: PropTypes.string.isRequired,
  isSendReminderSubmitting: PropTypes.bool,
  cancelInvitationState: PropTypes.string.isRequired,
  userProfileState: PropTypes.bool,
  formState: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  closePopoutAction: PropTypes.func,
  onComplete: PropTypes.func
};

export default connect(
  state => ({
    isSendReminderSubmitting: state.userProfile.isSendingReminder,
    cancelInvitationState: state.userProfile.cancelInvitationState,
    userProfileState: state.userProfile.componentState,
    lastName: state.userProfile.lastName,
    firstName: state.userProfile.firstName,
    formState: state.resendInvitation.formState,
    errorOccurred: state.resendInvitation.errorOccurred
  }),
  {}
)(ResendInvitation);
