import React from 'react';
import PropTypes from 'prop-types';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import styles from './PlaceholderClassTile.scss';

function PlaceholderClassTile({ onClickFirstButton, firstButtonText, firstButtonTestHook }) {
  return (
    <button
      className={styles.addClassroom}
      onClick={onClickFirstButton}
      data-testid={firstButtonTestHook}
      type="button"
    >
      <div className={styles.addContainer}>
        <span className={styles.addText}>{firstButtonText}</span>
        <div className={styles.addIcon}>
          <SVGIcon glyph={GLYPHS.ICON_PLUS} />
        </div>
      </div>
    </button>
  );
}

export default PlaceholderClassTile;

PlaceholderClassTile.propTypes = {
  firstButtonText: PropTypes.string,
  onClickFirstButton: PropTypes.func,
  firstButtonTestHook: PropTypes.string
};
