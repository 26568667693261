import { takeLatest } from 'redux-saga/effects';
import * as t from '../../../../actionTypes';

import getStudentsInSession from './getStudentsInSession';

function* getStudentsInTestSession() {
  yield takeLatest(t.GET_STUDENTS_IN_TEST_SESSION, payload => getStudentsInSession(payload));
}

export default getStudentsInTestSession;
