import * as t from '../actionTypes';

export const loadClassAssessments = data => ({
  type: t.GET_CLASS_ASSESSMENTS,
  payload: data
});

export const loadClassAssessmentsSuccess = payload => ({
  type: t.GET_CLASS_ASSESSMENTS_SUCCESS,
  payload
});

export const loadClassAssessmentsFailure = payload => ({
  type: t.GET_CLASS_ASSESSMENTS_FAILURE,
  payload
});
