import PropTypes from 'prop-types';
import React from 'react';
import withLocalizedContent from '../../language/withLocalizedContent';
import Button from '../Button/Button';
import Dropdown from '../Dropdown/Dropdown';
import TextInput from '../TextInput/TextInput';
import ValidationStatus from '../ValidationStatus/ValidationStatus';
import { isHubMode } from '../../utils/platform.js';

function ContactUsForm({
  name,
  email,
  countryCode,
  message,
  createChangeHandler = () => {},
  createBlurHandler = () => {},
  localizedContent: { contactUsForm: localizedContent, countryCodes },
  errors = {},
  onSubmit
}) {
  const allInputsFilled = !!name && !!email && !!countryCode && !!message;
  const disabled = Object.values(errors).includes(true) || !Object.values(errors).length || !allInputsFilled;
  const countries = Object.keys(countryCodes).map(code => ({ text: countryCodes[code], value: code }));

  return (
    <div>
      <div className="col">
        <ValidationStatus forId="name" isActive={errors.name} message={localizedContent.name_error_text}>
          <TextInput
            id="name"
            label={localizedContent.name_label}
            placeholder={localizedContent.name_placeholder}
            value={name}
            onChange={createChangeHandler('name')}
            onBlur={createBlurHandler('name')}
          />
        </ValidationStatus>
        <ValidationStatus
          forId="email"
          isActive={errors.ces10 || errors.email}
          message={localizedContent.email_error_text}
        >
          <TextInput
            id="email"
            label={localizedContent.email_label}
            placeholder={localizedContent.email_placeholder}
            value={email}
            onChange={createChangeHandler('email')}
            onBlur={createBlurHandler('email')}
          />
        </ValidationStatus>
        <ValidationStatus
          forId="countryCode"
          isActive={errors.countryCode}
          message={localizedContent.country_error_text}
        >
          <Dropdown
            id="countryCode"
            label={localizedContent.country_label}
            options={[{ text: 'Please select', value: '' }, ...countries]}
            value={countryCode}
            onChange={createChangeHandler('countryCode')}
            onBlur={createBlurHandler('countryCode')}
            required
          />
        </ValidationStatus>
        <ValidationStatus forId="message" isActive={errors.message} message={localizedContent.message_error_text}>
          <TextInput
            id="message"
            textArea
            label={localizedContent.comment_label}
            placeholder={isHubMode() ? localizedContent.hub_comment_placeholder : localizedContent.comment_placeholder}
            value={message}
            onChange={createChangeHandler('message')}
            onBlur={createBlurHandler('message')}
          />
        </ValidationStatus>
      </div>
      <div className="row cols-right pad-top2 pad-right2">
        <div className="col4">
          <Button
            id="submit-form"
            text={localizedContent.button_contact_us_submit_form_text}
            disabled={disabled}
            onClick={onSubmit}
          />
        </div>
      </div>
    </div>
  );
}

ContactUsForm.propTypes = {
  name: PropTypes.string,
  email: PropTypes.string,
  countryCode: PropTypes.string,
  message: PropTypes.string,
  createChangeHandler: PropTypes.func.isRequired,
  createBlurHandler: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  localizedContent: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

export default withLocalizedContent('contactUsForm', 'countryCodes')(ContactUsForm);
