import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
// Redux
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { HubLayoutConstants } from '../../../globals/hubConstants';
import colors from '../../../globals/colors';
import { handlePeopleSelectionPage } from '../../../redux/actions/hubPeople';
import withLocalizedContent from '../../../language/withLocalizedContent';

import { initialiseInstance } from '../../../redux/reducers/data/search.reducer';
import HubEmptyState from '../../../components/HubEmptyState/HubEmptyState';
import HubListHeader from '../../HubListPage/HubListHeader';
import DataRefresher from '../../../components/DataRefresher/DataRefresher';
import { GLYPHS } from '../../../components/SVGIcon/SVGIcon';

// Load Constants
import { SIZES as thumbnailSizes } from '../../../components/Thumbnail/Thumbnail';

// Load Services
import loadLMSConfig from '../../HubDashboardLayout/Services/loadLMSConfig';

// Load Components
import SubSectionSkeletonLoader from '../../../components/SkeletonLoader/SubSectionSkeletonLoader';
import HubFilterBarSkeleton from '../../../components/HubFilterBar/HubFilterBarSkeleton';
import PaginationButtons from '../../../components/PaginationButtons/PaginationButtons';
import styles from './LMSConfigTools.scss';
import Tools from './Tools';

const { MAX_LMS_TOOLS_PER_PAGE, HOW_TO_ADD_AN_LTI_URL } = HubLayoutConstants;

class LMSConfigTools extends Component {
  // constructor(props) {
  //   super(props);
  //   this.state = { filtersEmptyState: false };
  // }

  componentDidMount() {
    const {
      props: { loadTools, orgId, userId }
    } = this;
    loadLMSConfig(loadTools, orgId, userId);
  }

  componentWillUnmount() {}

  render() {
    const {
      props: {
        hubContent,
        breakpoint,
        arelmsConfigToolsLoading,
        actions: { toggleDeploymentToolModalAction },
        userDropdownActions: { setDeploymentToolEditAction, setDeploymentToolArchiveAction },
        organization,
        orgId,
        userId,
        userRole,
        lmsConfigTools,
        loadTools,
        handlePageSelectionAction,
        page,
        startSlice,
        endSlice
      }
    } = this;

    const actions = {
      editDeployment: (e, tool) => setDeploymentToolEditAction(e, tool),
      archiveDeployment: (e, tool) => setDeploymentToolArchiveAction(e, tool)
    };

    const orgTitle = `${organization.name} | ${hubContent.org_lms_config_deployments}`;

    const headerItem = {
      name: hubContent.my_deployments_page_title,
      buttonText: hubContent.add,
      action: toggleDeploymentToolModalAction
    };

    const noToolsEmptyStateLinks = [
      {
        link: HOW_TO_ADD_AN_LTI_URL,
        text: hubContent.tools_page_how_to_add_an_lti_tool_title
      }
    ];

    const noToolsEmptyStateButtons = [
      {
        showButton: true,
        isPrimaryButton: true,
        text: `+ ${hubContent.add_deployments}`,
        action: toggleDeploymentToolModalAction
      }
    ];

    function getEmptyState() {
      const jsx = (
        <HubEmptyState
          glyphIcon={GLYPHS.ICON_INTEGRATION}
          glyphSize={thumbnailSizes.HEADING}
          customClassName="toolsPageFilteredEmptyState"
          title={hubContent.tools_page_empty_state_title}
          subTitle={hubContent.tools_page_empty_state_subtitle}
          links={noToolsEmptyStateLinks}
          buttons={noToolsEmptyStateButtons}
        />
      );
      return jsx;
    }

    function getState() {
      const jsx = (
        <div>
          <HubListHeader item={headerItem} showCheckboxHeader={false} hasHeaderButtonAccess />
          <div className={styles.rowContainer}>
            <Tools
              actions={actions}
              breakpoint={breakpoint}
              items={lmsConfigTools.slice(startSlice, endSlice)}
              hubContent={hubContent}
              userRole={userRole}
            />
          </div>
        </div>
      );
      return jsx;
    }

    function ToolsState() {
      const jsx = lmsConfigTools.length > 0 ? getState() : getEmptyState();
      return jsx;
    }

    const customFilterComponent = <HubFilterBarSkeleton />;

    return (
      <div className={styles.toolsContainer}>
        <Helmet title={orgTitle} />
        <DataRefresher
          loading={arelmsConfigToolsLoading}
          refreshData={() => {
            loadLMSConfig(loadTools, orgId, userId);
          }}
        />

        {/* <pre>{JSON.stringify(HubLayoutConstants, null, 2)}</pre> */}

        {arelmsConfigToolsLoading ? (
          <SubSectionSkeletonLoader
            tabName=""
            panelName=""
            speed={2}
            foregroundColor={colors.COLOR_GREY_DISABLED2}
            backgroundColor={colors.COLOR_WHITE}
            customEnhancedComponent={customFilterComponent}
          />
        ) : (
          <ToolsState />
        )}

        {!arelmsConfigToolsLoading && lmsConfigTools.length > MAX_LMS_TOOLS_PER_PAGE ? (
          <div className={styles.paginationButtonsContainer}>
            <PaginationButtons
              idPrefix="organizationClassesSearch"
              value={page}
              numberOfPages={Math.ceil(lmsConfigTools.length / 10)}
              onClick={handlePageSelectionAction}
              aria={{ 'aria-controls': 'searchResults' }}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

export default compose(
  withLocalizedContent('mySchoolLibraryTab'),
  connect(
    ({
      identity: { userId = '', currentOrganisationId: orgId = '', role: userRole = '' } = {},
      organisations: { data: orgs = {} } = {},
      search: { lmsConfig: { data: lmsConfigTools = [], loading: arelmsConfigToolsLoading = false } = {} } = {},
      hubPeople: { page = 0, startSlice = 0, endSlice = 0 } = {}
    }) => ({
      userId,
      userRole,
      orgId,
      organization: orgs[orgId] || {},
      lmsConfigTools,
      arelmsConfigToolsLoading,
      page,
      startSlice,
      endSlice
    }),
    {
      loadTools: initialiseInstance,
      handlePageSelectionAction: handlePeopleSelectionPage
    }
  )
)(withRouter(LMSConfigTools));

LMSConfigTools.defaultProps = {
  userId: '',
  orgId: ''
};

LMSConfigTools.propTypes = {
  hubContent: PropTypes.object,
  orgId: PropTypes.string,
  userId: PropTypes.string,
  userRole: PropTypes.string.isRequired,
  organization: PropTypes.object.isRequired,
  arelmsConfigToolsLoading: PropTypes.bool.isRequired,
  lmsConfigTools: PropTypes.object.isRequired,
  loadTools: PropTypes.func.isRequired,
  actions: PropTypes.object.isRequired,
  userDropdownActions: PropTypes.object.isRequired,
  breakpoint: PropTypes.object.isRequired,
  handlePageSelectionAction: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  startSlice: PropTypes.number.isRequired,
  endSlice: PropTypes.number.isRequired
};
