// Constants
import userRoles from '../../../globals/userRoles';
import breakpoints from '../../../globals/breakpoints';

const getClassworkLicencesHeaderItems = (
  content,
  { addClassworkMaterial = null },
  { showAssignedTeacherData = false, userRole, breakpoint = '', assignmentState = '' }
) => {
  let status = content.classwork_header_status_class;
  let nameFirstRow = content.classwork_header_name_mine;
  const nameSecondRow = content.classwork_header_name_students;
  const nameThirdRow = content.classwork_header_name_teachers;

  if (showAssignedTeacherData || userRole === userRoles.LEARNER) {
    status = content.classwork_header_status_self;
  }

  if (breakpoint === breakpoints.XXS) {
    status = content.org_menu_licences;
  }

  if (assignmentState === 'manage') {
    nameFirstRow = content.classwork_header_name_all;
  }

  return [
    {
      name: nameFirstRow,
      status,
      buttonText: content.add,
      action: addClassworkMaterial
    },
    assignmentState === 'manage'
      ? {
          name: nameSecondRow,
          status,
          buttonText: content.add,
          action: addClassworkMaterial
        }
      : null,
    assignmentState === 'manage'
      ? {
          name: nameThirdRow,
          status,
          buttonText: content.add,
          action: addClassworkMaterial
        }
      : null
  ];
};
export default getClassworkLicencesHeaderItems;
