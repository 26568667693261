import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { compose } from 'recompose';

import PropTypes from 'prop-types';
import TextLink from '@oup/shared-front-end/src/components/TextLink';
import styles from '../ManageJoiningCode.scss';
import withLocalizedContent from '../../../../../../language/withLocalizedContent';
import StudentsCounting from '../../../../../../components/PlacementTestOnBoardingWizard/AddStudents/StudentsCounting/StudentsCounting';
import getOptProductsCatalogueUrl from '../../../../../../utils/opt/getOptProductsCatalogueUrl';

function PlacesRemainingInSession({
  localizedContent: { manageJoiningCode: content, placementTests: placementTestsContent },
  enabled,
  places,
  error,
  setError,
  availableCredits,
  placesForUnknownStudents,
  differenceFromMax,
  setDifferenceFromMax,
  decrementPlacesInSession,
  incrementPlacesInSession
}) {
  const currentOrganisationId = useSelector(state => state.identity.currentOrganisationId);
  const { customId: customOrgId = '' } = useSelector(state => state.organisations.data[currentOrganisationId]);
  useEffect(() => {
    if (places > availableCredits + placesForUnknownStudents) {
      setDifferenceFromMax();
      setError(true);
    } else {
      setError(false);
    }
  }, [places]);
  return (
    <div>
      <div className={styles.placesRemaining}>
        <span className={styles.placesRemainingText}>{content.placesRemainingInSession}</span>
        <StudentsCounting
          disabled={!enabled}
          value={places}
          onDecrease={() => {
            decrementPlacesInSession();
          }}
          onIncrease={() => {
            incrementPlacesInSession();
          }}
          hasError={error}
          errorMessage={`${content.notEnoughLicences} ${differenceFromMax} ${content.additionalLicencesNeeded} (${availableCredits}
              ${content.licencesRemaining}).`}
          disabledClass={styles.disabledColor}
        />
      </div>

      {error && (
        <div className={styles.errorMessageContainer}>
          <TextLink to={getOptProductsCatalogueUrl(customOrgId)} target="_blank">
            {placementTestsContent.placement_test_add_students_page_buy_more_link_text}
          </TextLink>
        </div>
      )}
    </div>
  );
}

PlacesRemainingInSession.propTypes = {
  localizedContent: PropTypes.object,
  enabled: PropTypes.bool,
  places: PropTypes.number,
  error: PropTypes.bool,
  setError: PropTypes.func,
  availableCredits: PropTypes.number,
  placesForUnknownStudents: PropTypes.number,
  differenceFromMax: PropTypes.number,
  setDifferenceFromMax: PropTypes.func,
  decrementPlacesInSession: PropTypes.func,
  incrementPlacesInSession: PropTypes.func
};

export default compose(
  withLocalizedContent('manageJoiningCode', 'placementTests'),
  connect(
    ({ loadPlacementTestsReducer: { codeDetails } }) => ({
      codeDetails
    }),
    {}
  )
)(PlacesRemainingInSession);
