import React from 'react';
import PropTypes from 'prop-types';
// Components
import HubModalLayout from '../HubModalLayout/HubModalLayout';
import Button, { buttonTypes } from '../Button/Button';
// Constants
import { HubIllustrationConstants } from '../../globals/hubConstants';
// Styles
import styles from './HubPreviewWarningModal.scss';
import HubEmptyStateRestyle from '../HubEmptyStateRestyle/HubEmptyStateRestyle';

function HubPreviewWarningModal({ testId, ariaLabel, closeModal, hubContent, closeWarningInfoModal }) {
  return (
    <div className={`${styles.outerPreviewInfoModal} ${testId ? styles.open : ''}`}>
      <HubModalLayout isOpen={!!testId} id="previewInfoId" closeAction={closeModal} ariaLabel={ariaLabel}>
        {testId ? (
          <div className={styles.modalContainer}>
            <div className={styles.modalHeader}>
              <Button
                text={hubContent.close}
                type={buttonTypes.CLOSE_BORDER_GRAY}
                onClick={e => {
                  e.stopPropagation();
                  closeWarningInfoModal();
                }}
              />
            </div>

            <div className={styles.modalContent}>
              <HubEmptyStateRestyle
                iconSrc={HubIllustrationConstants.WARNING}
                title={hubContent.warning_modal_title}
                bodyText={hubContent.warning_modal_subtitle}
              />
            </div>

            <div className={styles.modalFooter}>
              <Button text={hubContent.cancel} type={buttonTypes.CANCEL} onClick={() => closeWarningInfoModal()} />
              <Button
                text={hubContent.close_test}
                type={buttonTypes.PRIMARY}
                onClick={() => {
                  closeWarningInfoModal();
                  closeModal();
                }}
              />
            </div>
          </div>
        ) : null}
      </HubModalLayout>
    </div>
  );
}
HubPreviewWarningModal.propTypes = {
  testId: PropTypes.string.isRequired,
  ariaLabel: PropTypes.string,
  closeModal: PropTypes.func.isRequired,
  hubContent: PropTypes.object.isRequired,
  closeWarningInfoModal: PropTypes.func
};

export default HubPreviewWarningModal;
