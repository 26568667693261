import React from 'react';
import PropTypes from 'prop-types';
// Styles
import styles from './HubPersonProfileTabs.scss';
// Components
import HubPersonProfileTab from './HubPersonProfileTab';

function HubPersonProfileTabs({ tabs = {}, selectTab, selectedTab }) {
  return (
    <div className={styles.tabsContainer}>
      {Object.keys(tabs).map(tab => (
        <div className={styles.tabContainer} key={tab}>
          <HubPersonProfileTab tabs={tabs} tab={tab} selectTab={selectTab} selectedTab={selectedTab} />
        </div>
      ))}
    </div>
  );
}

HubPersonProfileTabs.propTypes = {
  tabs: PropTypes.object.isRequired,
  selectTab: PropTypes.func.isRequired,
  selectedTab: PropTypes.string.isRequired
};

export default HubPersonProfileTabs;
