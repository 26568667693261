import * as t from '../actionTypes.js';

export const getProductCreditsRequest = (params = {}) => ({
  type: t.GET_PRODUCT_CREDITS_REQUEST,
  payload: params
});

export const getProductCreditsSuccess = products => ({
  type: t.GET_PRODUCT_CREDITS_SUCCESS,
  payload: products
});

export const getProductCreditsFailure = error => ({
  type: t.GET_PRODUCT_CREDITS_FAILURE,
  payload: error
});

export const addProductCreditsRequest = (params = {}) => ({
  type: t.ADD_PRODUCT_CREDITS_REQUEST,
  payload: params
});

export const addProductCreditsSuccess = params => ({
  type: t.ADD_PRODUCT_CREDITS_SUCCESS,
  payload: params
});

export const addProductCreditsFailure = error => ({
  type: t.ADD_PRODUCT_CREDITS_FAILURE,
  payload: error
});

export const clearProductCredits = () => ({
  type: t.CLEAR_PRODUCT_CREDITS
});
