import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { Wizard } from '@oup/shared-front-end/src/components/Wizard';
import SiteHeader from '@oup/shared-front-end/src/components/SiteHeader';
import RedeemCodeWizardFooter from './RedeemCodeWizardFooter';
import { HubLayoutConstants } from '../../globals/hubConstants';
import withLocalizedContent from '../../language/withLocalizedContent';
import IntroStep from './IntroStep';
import EnterRedeemCodeStep from './EnterRedeemCodeStep';

function RedeemCodeWizard({ localizedContent: { redeemCodePage: content }, isOpen }) {
  return isOpen ? (
    <div data-testid="REDEEM_CODE_WIZARD_CONTAINER">
      <Wizard
        header={
          <SiteHeader
            helpButton={{
              text: content.help_and_support_button_text,
              onClick: () => window.open(HubLayoutConstants.HELP_AND_SUPPORT_URL, '_blank')
            }}
            secondaryText={content.oxford_english_hub}
          />
        }
        footer={<RedeemCodeWizardFooter content={content} />}
      >
        <IntroStep content={content} />
        <EnterRedeemCodeStep content={content} />
      </Wizard>
    </div>
  ) : null;
}

RedeemCodeWizard.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  localizedContent: PropTypes.object.isRequired
};

export default compose(withLocalizedContent('redeemCodePage'))(RedeemCodeWizard);
