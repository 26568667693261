import randomize from 'randomatic';

const PREFIXES = ['fish', 'owl', 'cat', 'bee', 'ant', 'fox', 'tree', 'sea', 'blue', 'red'];

/**
 * Create a new random password string.
 *
 * @return {string}
 */
export default function generatePassword() {
  const index = randomize('?', 1, { chars: Object.keys(PREFIXES).join('') });
  const password = PREFIXES[index];

  return password + randomize(password.length < 4 ? '00' : '0');
}
