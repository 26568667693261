import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { useWizard } from '@oup/shared-front-end/src/components/Wizard';
import WizardStep from '@oup/shared-front-end/src/components/Wizard/WizardStep';
import animationTimeout from '../../ClassOnboardingWizard/animationUtils/animationTimeout';
import animationStyles from '../../ClassOnboardingWizard/animationUtils/animationStyles.scss';
import withLocalizedContent from '../../../language/withLocalizedContent';
import { featureIsEnabled } from '../../../globals/envSettings';
import NotificationsAndReminders from './NotificationsAndReminders';
import { setEditedPlacementTest, setPlacementTest } from '../../../redux/reducers/placementTestSessionCreate';

function EmailNotificationsAndReminders({
  placementTestSessionName,
  localizedContent: { placementTests: placementTestsContent },
  placementTestSessionUpdated,
  setEditedPlacementTestAction
}) {
  const { handleStep, isLoading, setIsPrimaryButtonDisabled, nextStep } = useWizard();
  const [stepOut, setStepOut] = useState(false);
  const [isNotificationsAndRemindersValid, setIsNotificationsAndRemindersValid] = useState(false);

  const [editedSendEmailsToStudents, setEditedSendEmailsToStudents] = useState(true);
  const [editedNotificationReminders, setEditedNotificationReminders] = useState([]);

  const canShowStudentResults = featureIsEnabled('show-test-results-to-students');

  setIsPrimaryButtonDisabled(isLoading || !isNotificationsAndRemindersValid);

  const dependencyArrayBasedOnFlag = canShowStudentResults ? [] : [placementTestSessionUpdated];
  useEffect(() => {
    if (placementTestSessionUpdated) {
      nextStep();
    }
  }, dependencyArrayBasedOnFlag);

  const fadeOut = stepOut ? animationStyles.fadeOut : '';

  const handleNotifications = () => {
    setEditedPlacementTestAction({
      sendEmailsToStudents: editedSendEmailsToStudents,
      notificationReminders: editedNotificationReminders
    });
  };

  handleStep(async () => {
    setStepOut(true);
    handleNotifications();
    await animationTimeout();
  });

  return (
    <div
      data-testid="ONBOARDING_WIZARD_CREATE_A_PLACEMENT_TEST_SESSION_EMAIL_NOTIFICATIONS_CONTAINER"
      className={`${fadeOut}`}
    >
      <WizardStep
        titleText={`${placementTestsContent.placement_test_notification_page_title} ${placementTestSessionName ||
          placementTestsContent.placement_test_notification_page_session_name_fallback}`}
      >
        <NotificationsAndReminders
          setIsValid={setIsNotificationsAndRemindersValid}
          sendEmailsToStudents={editedSendEmailsToStudents}
          notificationReminders={editedNotificationReminders}
          setSendEmailsToStudents={setEditedSendEmailsToStudents}
          setNotificationReminders={setEditedNotificationReminders}
        />
      </WizardStep>
    </div>
  );
}

EmailNotificationsAndReminders.propTypes = {
  placementTestSessionName: PropTypes.string,
  localizedContent: PropTypes.object.isRequired,
  placementTestSessionUpdated: PropTypes.bool,
  setEditedPlacementTestAction: PropTypes.func
};

export default compose(
  withLocalizedContent('placementTests'),
  connect(
    ({ placementTestSessionCreate, placementOnboardingWizard }) => ({
      placementTestSessionName: placementTestSessionCreate.placementTestSessionNameValue,
      placementTestSessionUpdated: placementOnboardingWizard.placementTestSessionUpdated
    }),
    {
      setEditedPlacementTestAction: setEditedPlacementTest,
      setPlacementTestAction: setPlacementTest
    }
  )
)(EmailNotificationsAndReminders);
