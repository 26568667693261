import classnames from 'classnames';
import { pick } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import PopoutPanelIconHeading, {
  types as popoutPanelIconHeadingTypes
} from '../PopoutPanelIconHeading/PopoutPanelIconHeading';
import withLocalizedContent from '../../language/withLocalizedContent';
import ContactUsDetails from '../ContactUsDetails/ContactUsDetails';
import ErrorStatus from '../ErrorStatus/ErrorStatus';
import ContactUsForm from './ContactUsForm';
import styles from './ContactUsOlb.scss';
import ContactUsSuccess from './ContactUsSuccess';
import { isHubMode } from '../../utils/platform.js';

class ContactUsOlb extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      countryCode: '',
      message: ''
    };
  }

  _createChangeHandler = name => value => {
    this.setState({ [name]: value });
  };

  _createBlurHandler = name => () => {
    const { validateInput } = this.props;
    validateInput(pick(this.state, [name]), isHubMode() ? 'HUB' : 'OLB');
  };

  _onSubmit = () => {
    const { submit } = this.props;
    submit({
      platformCode: isHubMode() ? 'HUB' : 'OLB',
      ...pick(this.state, ['name', 'email', 'countryCode', 'message'])
    });
  };

  _onReset = () => {
    const { reset } = this.props;
    this.setState(
      {
        name: '',
        email: '',
        countryCode: '',
        message: ''
      },
      () => reset(isHubMode() ? 'HUB' : 'OLB')
    );
  };

  _renderContent() {
    const {
      localizedContent: { contactUsOlb: localizedContent },
      submitting,
      failure,
      success,
      errors
    } = this.props;
    const { name, email, countryCode, message } = this.state;

    switch (true) {
      case submitting:
        return (
          <PopoutPanelIconHeading
            type={popoutPanelIconHeadingTypes.LOADING}
            title={localizedContent.form_submitting_text}
          />
        );
      case failure:
        return (
          <ErrorStatus
            title={localizedContent.error_title}
            subtitle={localizedContent.error_subtitle}
            buttonText={localizedContent.error_button_text}
            buttonOnClickHandler={this._onReset}
          />
        );
      case success:
        return (
          <ContactUsSuccess
            onReset={this._onReset}
            title={localizedContent.form_sent_title_text}
            subtitle={localizedContent.form_sent_subtitle_text}
            bodyText={localizedContent.form_sent_body_text}
            buttonText={localizedContent.button_contact_form_complete_text}
          />
        );
      default:
        return (
          <ContactUsForm
            createChangeHandler={this._createChangeHandler}
            createBlurHandler={this._createBlurHandler}
            name={name}
            email={email}
            countryCode={countryCode}
            message={message}
            errors={errors}
            onSubmit={this._onSubmit}
          />
        );
    }
  }

  render() {
    const {
      localizedContent: { contactUsOlb: localizedContent }
    } = this.props;

    return (
      <div className="grid">
        <div className={classnames('row', styles.container)}>
          <div className="col md8">
            <div className={styles.inputContainer}>
              <div className={classnames('pad4', styles.mainContent)}>{this._renderContent()}</div>
              <div className={classnames('pad-top4 pad-bot4', styles.verticalDividerContainer)}>
                <div className={classnames(styles.verticalDivider)} />
              </div>
            </div>
          </div>
          <div className="col md4">
            <div className="pad4">
              <em className={styles.header}>{localizedContent.contact_information_body_text_title}</em>
              <ContactUsDetails glossary={localizedContent} hideAddress />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ContactUsOlb.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  validateInput: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  failure: PropTypes.bool,
  success: PropTypes.bool,
  errors: PropTypes.object
};

ContactUsOlb.defaultProps = {
  errors: {}
};

export default withLocalizedContent('contactUsOlb')(ContactUsOlb);
