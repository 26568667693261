const getManageClassStatusText = (usertype = '', content = {}) => {
  let greenText = '';
  let blueText = '';

  // Format  all users have licences
  if (!usertype) {
    greenText = content.classwork_manage_status_all;
  }
  if (usertype === 'student') {
    greenText = content.classwork_manage_status_students_all;
  }
  if (usertype === 'teacher') {
    greenText = content.classwork_manage_status_teachers_all;
  }

  // Format users can redeem access codes

  if (!usertype) {
    blueText = content.students_and_teachers_can_redeem_access_codes;
  }
  if (usertype === 'student') {
    blueText = content.students_can_redeem_access_codes;
  }
  if (usertype === 'teacher') {
    blueText = content.teachers_can_redeem_access_codes;
  }

  return {
    greenText,
    blueText
  };
};

export default getManageClassStatusText;
