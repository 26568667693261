export default function setAdaptedTestsInCourses(adaptedTests, courses, currentCourse) {
  // we need to make a copy of the courses object, and replace only the provided adapted tests
  // after that we will overwrite the hubCourses.courses in redux
  const coursesCopy = JSON.parse(JSON.stringify(courses));
  const courseKeys = Object.keys(courses);
  for (let i = 0; i < courseKeys.length; i += 1) {
    if (courseKeys[i] === currentCourse.id) {
      if (coursesCopy[courseKeys[i]].interactiveFolders) {
        const interactiveFolders = coursesCopy[courseKeys[i]].interactiveFolders;
        const interactiveFolderKeys = Object.keys(coursesCopy[courseKeys[i]].interactiveFolders);
        for (let j = 0; j < interactiveFolderKeys.length; j += 1) {
          if (
            interactiveFolders[interactiveFolderKeys[j]].digitalResourceId &&
            adaptedTests[interactiveFolders[interactiveFolderKeys[j]].digitalResourceId]
          ) {
            interactiveFolders[interactiveFolderKeys[j]].adaptedTests =
              adaptedTests[interactiveFolders[interactiveFolderKeys[j]].digitalResourceId];
          } else {
            delete interactiveFolders[interactiveFolderKeys[j]].adaptedTests;
          }
        }
      }
    }
  }
  return coursesCopy;
}
