import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import TextInput from '@oup/shared-front-end/src/components/TextInput';
import { PLACEMENT_TEST_STATUS } from '@oup/shared-node-browser/constants';
import { setEditedPlacementTest } from '../../redux/reducers/placementTestSessionCreate';
import withLocalizedContent from '../../language/withLocalizedContent';
import { getRoundHour, setHourOnDate } from '../../globals/dateFormats';
import styles from './OnlineTestDateTime.scss';

const today = new Date(Date.now());
const tomorrow = new Date(Date.now() + 3600 * 1000 * 24);
const currentRoundedHour = getRoundHour([today.getHours(), ':', today.getMinutes()].join(''));
const initialStartDateTime = setHourOnDate(today, currentRoundedHour);
const initialEndDateTime = setHourOnDate(tomorrow, currentRoundedHour);
const initialErrors = {
  startDateTime: '',
  endDateTime: ''
};

function OnlineTestDateTime({
  localizedContent: { onlineTestDateTime: onlineTestDateTimeContent },
  showTooltipStartDateTime = true,
  showTooltipEndDateTime = true,
  onSelectStartDateTime,
  onSelectEndDateTime,
  defaultStartDateTime,
  defaultEndDateTime,
  setEditedPlacementTestAction,
  placementTestStatus
}) {
  const [startDateTime, setStartDateTime] = useState(defaultStartDateTime || initialStartDateTime);
  const [endDateTime, setEndDateTime] = useState(defaultEndDateTime || initialEndDateTime);
  const [errors, setErrors] = useState(initialErrors);

  const handleOnChangeStartDateTime = ({ target: { value } }) => {
    setEditedPlacementTestAction({ testStartDate: new Date(value).toISOString() });
    setStartDateTime(new Date(value));
  };

  const handleOnChangeEndDateTime = ({ target: { value } }) => {
    setEditedPlacementTestAction({ testEndDate: new Date(value).toISOString() });
    setEndDateTime(new Date(value));
  };

  // "yyyy-mm-ddThh:mm" format
  const formatDate = date => {
    const dateFormatted = [
      date.getFullYear(),
      '-',
      (+date.getMonth() + 1).toString().padStart(2, '0'),
      '-',
      date
        .getDate()
        .toString()
        .padStart(2, '0'),
      'T',
      date
        .getHours()
        .toString()
        .padStart(2, '0'),
      ':',
      date
        .getMinutes()
        .toString()
        .padStart(2, '0')
    ].join('');
    return dateFormatted;
  };

  const validateDateTime = () => {
    switch (true) {
      // Test start date cannot be invalid
      case startDateTime.toString() === 'Invalid Date':
        setErrors({
          ...errors,
          startDateTime: onlineTestDateTimeContent.online_test_time_startdate_error_date_invalid
        });
        break;
      // Test close date cannot be invalid
      case endDateTime.toString() === 'Invalid Date':
        setErrors({ ...errors, endDateTime: onlineTestDateTimeContent.online_test_time_enddate_error_date_invalid });
        break;
      // Test close date and time cannot be the same as the open time
      case formatDate(startDateTime) === formatDate(endDateTime):
        setErrors({ ...errors, endDateTime: onlineTestDateTimeContent.online_test_time_enddate_error_hour_same });
        break;
      // Test close date and time cannot be in the past
      case endDateTime < new Date():
        setErrors({ ...errors, endDateTime: onlineTestDateTimeContent.online_test_time_enddate_past_now });
        break;
      // Test close date cannot be earlier than open date
      case startDateTime > endDateTime:
        setErrors({ ...errors, endDateTime: onlineTestDateTimeContent.online_test_time_enddate_error_date_earlier });
        break;
      default:
        setErrors(initialErrors);
    }
  };

  useEffect(() => {
    validateDateTime();
    if (startDateTime.toString() !== 'Invalid Date') onSelectStartDateTime(startDateTime, errors.startDateTime);
    if (endDateTime.toString() !== 'Invalid Date') onSelectEndDateTime(endDateTime, errors.endDateTime);
  }, [startDateTime, endDateTime, errors.startDateTime, errors.endDateTime]);

  return (
    <div className={styles.onlineTestDateTimeContainer}>
      <div>
        <h3>{onlineTestDateTimeContent.online_test_time_startdate_header}</h3>
        {showTooltipStartDateTime && <p>{onlineTestDateTimeContent.online_test_time_tooltip_startdate}</p>}
        <TextInput
          type="datetime-local"
          id="startDateTime"
          labelHidden="start date time"
          max="2050-01-01"
          value={formatDate(startDateTime)}
          onChange={handleOnChangeStartDateTime}
          state={(() => {
            if (errors.startDateTime !== '') {
              return 'invalid';
            }
            return 'valid';
          })()}
          validationMessage={errors.startDateTime}
          disabled={placementTestStatus === PLACEMENT_TEST_STATUS.ACTIVE}
        />
      </div>
      <div>
        <h3>{onlineTestDateTimeContent.online_test_time_enddate_header}</h3>
        {showTooltipEndDateTime && <p>{onlineTestDateTimeContent.online_test_time_tooltip_enddate}</p>}
        <TextInput
          type="datetime-local"
          id="endDateTime"
          labelHidden="end date time"
          max="2050-01-01"
          value={formatDate(endDateTime)}
          onChange={handleOnChangeEndDateTime}
          state={(() => {
            if (errors.endDateTime !== '') {
              return 'invalid';
            }
            return 'valid';
          })()}
          validationMessage={errors.endDateTime}
        />
      </div>
    </div>
  );
}

OnlineTestDateTime.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  showTooltipStartDateTime: PropTypes.bool,
  showTooltipEndDateTime: PropTypes.bool,
  onSelectStartDateTime: PropTypes.func,
  onSelectEndDateTime: PropTypes.func,
  defaultStartDateTime: PropTypes.object,
  defaultEndDateTime: PropTypes.object,
  setEditedPlacementTestAction: PropTypes.func,
  placementTestStatus: PropTypes.string
};

export default compose(
  withLocalizedContent('onlineTestDateTime'),
  connect(null, {
    setEditedPlacementTestAction: setEditedPlacementTest
  })
)(OnlineTestDateTime);
