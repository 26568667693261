import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useWizard } from '@oup/shared-front-end/src/components/Wizard';
// Components
import WizardStep from '@oup/shared-front-end/src/components/Wizard/WizardStep';
import Button from '@oup/shared-front-end/src/components/Button';
import LicenceListItem from '../../ListItems/LicenceListItem/LicenceListItem';
import PopoutPanel from '../../PopoutPanel/PopoutPanel';
import ProductFinderPanel from '../../ProductFinder/ProductFinderPanel';
import SVGIcon, { GLYPHS } from '../../SVGIcon/SVGIcon';
// Utils
import { assignmentContexts } from '../../../globals/assignmentConstants';
import preventBodyScroll from '../../../utils/dom/preventBodyScroll';
// Constants
import { productFinderFormStates } from '../../../redux/reducers/productFinder.reducer';
import { productInformationContext } from '../../../globals/hubConstants';
// Styles
import styles from './AssignProductOnboardingWizard.scss';

function AssignProductOnboardingWizard({
  content,
  className,
  breakpoint,
  classId,
  userDetails,
  userId,
  orgId,
  licenceItems,
  formState,
  role,
  isOpen
}) {
  const { setIsPrimaryButtonDisabled } = useWizard();
  const [assignLearningMaterialPanelOpen, setAssignLearningMaterialPanelOpen] = useState(false);

  useEffect(() => {
    setIsPrimaryButtonDisabled(formState === productFinderFormStates.SUBMITTING);
  }, [formState]);

  useEffect(() => {
    preventBodyScroll(isOpen);
  });

  const getLearningMaterialItems = () => {
    const studentIdList = Object.keys(userDetails?.students || {});
    const teacherIdList = [userId];
    return licenceItems.map(licenceItem => {
      const id = licenceItem.productid;
      return (
        <div key={id} data-testid={`ONBOARDING_WIZARD_LEARNING_MATERIAL_${id}`}>
          <LicenceListItem
            hubContent={content}
            currentUsersList={{ [id]: licenceItem }}
            classroomId={classId}
            licencesContext={assignmentContexts.CLASS_ONBOARDING_WIZARD}
            userAssignments={{ [id]: licenceItem }}
            classTeachersId={teacherIdList}
            classStudentsId={studentIdList}
            isTeacherAssignedToClass
            id={id}
            showStatus={false}
            showDropdown={false}
            userRole={role}
            isUserProfilePage
            breakpoint={breakpoint}
            productInformationContext={productInformationContext.PRODUCT_FINDER}
          />
        </div>
      );
    });
  };

  const getClassOnboardingUsers = () => {
    const studentIdList = Object.keys(userDetails?.students || {});
    return { teacherIdList: [userId], studentIdList: [...studentIdList] };
  };

  return (
    <div data-testid="ONBOARDING_WIZARD_ASSIGN_LEARNING_MATERIALS_CONTAINER">
      <div className={styles.stepContainer}>
        <WizardStep
          titleText={content.assign_learning_material_title.replace('{className}', className)}
          subtitleText={content.add_learning_material_info}
        >
          <div>
            <Button
              dataAttributes={{ testId: 'ONBOARDING_WIZARD_ASSIGN_LEARNING_MATERIAL_BUTTON' }}
              text={content.add_course_material}
              onClick={() => {
                setAssignLearningMaterialPanelOpen(true);
              }}
              variant="outline"
              icon={{ placement: 'left', component: <SVGIcon glyph={GLYPHS.ICON_PLUS} /> }}
            />

            {licenceItems.length ? <div className={styles.productsContainer}>{getLearningMaterialItems()}</div> : null}
          </div>
        </WizardStep>
      </div>

      <PopoutPanel
        ariaLabel={content.assign_material_to_class_panel_aria}
        isOpen={assignLearningMaterialPanelOpen}
        dataTestId="ASSIGN_LEARNING_MATERIAL_PANEL_ONBOARDING_WIZARD"
      >
        <ProductFinderPanel
          orgId={orgId}
          classId={classId}
          selectedUsers={getClassOnboardingUsers()}
          contextName={className}
          onClose={() => {
            setAssignLearningMaterialPanelOpen(false);
          }}
          onComplete={() => {
            setAssignLearningMaterialPanelOpen(false);
          }}
          context={assignmentContexts.CLASS_ONBOARDING_WIZARD}
        />
      </PopoutPanel>
    </div>
  );
}

AssignProductOnboardingWizard.propTypes = {
  content: PropTypes.object,
  className: PropTypes.string,
  classId: PropTypes.string,
  userDetails: PropTypes.object,
  userId: PropTypes.string,
  orgId: PropTypes.string,
  licenceItems: PropTypes.object,
  role: PropTypes.string,
  breakpoint: PropTypes.string,
  formState: PropTypes.string,
  isOpen: PropTypes.bool.isRequired
};

export default connect(
  ({ classOnboardingWizard, identity, assignLearningMaterial }) => ({
    className: classOnboardingWizard.classNameValue,
    classId: classOnboardingWizard.classId,
    orgId: identity.currentOrganisationId,
    userId: identity.userId,
    userDetails: classOnboardingWizard.userDetails,
    licenceItems: classOnboardingWizard?.learningMaterialDetails?.learningMaterials || [],
    role: identity.role,
    formState: assignLearningMaterial.formState
  }),
  null
)(AssignProductOnboardingWizard);
