import React from 'react';
import PropTypes from 'prop-types';
import { upperFirst } from 'lodash';
// Components
import ScrollContainer from '../../../../components/ScrollContainer/ScrollContainer';
import PopoutPanelIconHeading, {
  types as iconTypes
} from '../../../../components/PopoutPanelIconHeading/PopoutPanelIconHeading';
import PopoutActionFooter from '../../../../components/PopoutActionFooter/PopoutActionFooter';

function CancelInvitationSuccessPanel({ onComplete, userEmail, removedUserType, hubContent }) {
  return (
    <ScrollContainer
      footerContent={<PopoutActionFooter primaryButtonText={hubContent.done} primaryButtonAction={onComplete} />}
    >
      <PopoutPanelIconHeading
        title={`${upperFirst(removedUserType)} ${hubContent.cancel_invitation_users_are_removed}`}
        type={iconTypes.PROCESSING}
      >
        <div className="pad4">
          {hubContent.cancel_invitation_complete_text_1}
          <span className="block pad1 gin-top1 gin-bot1 color-default-bg" style={{ borderRadius: '4px' }}>
            <em>{userEmail}</em>
          </span>
          <span className="block">{hubContent.cancel_invitation_complete_text_2}</span>
          <span className="block gin2 gin-top4">{hubContent.cancel_invitation_complete_text_3}</span>
        </div>
      </PopoutPanelIconHeading>
    </ScrollContainer>
  );
}

export default CancelInvitationSuccessPanel;

CancelInvitationSuccessPanel.propTypes = {
  onComplete: PropTypes.func.isRequired,
  hubContent: PropTypes.object.isRequired,
  userEmail: PropTypes.string.isRequired,
  removedUserType: PropTypes.string.isRequired
};
