import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Link from '../../../components/Link/Link';
import styles from './ClassSubMenu.scss';

function ClassSubMenuItem({ subMenuItem, pathname, dataTestId = '' }) {
  return (
    <li>
      <div>
        <Link
          dataTestId={dataTestId}
          to={subMenuItem.path}
          className={classnames([styles.classSubMenuItem], {
            [styles.classSubMenuItemActive]: pathname.startsWith(subMenuItem.path)
          })}
        >
          <div className={styles.buttonName}>{subMenuItem.name}</div>
        </Link>
      </div>
    </li>
  );
}

export default ClassSubMenuItem;

ClassSubMenuItem.propTypes = {
  subMenuItem: PropTypes.object.isRequired,
  pathname: PropTypes.string.isRequired,
  dataTestId: PropTypes.string
};
