import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// Redux
import {
  getProductLocksRequest,
  productLocksResetState,
  updateProductLocksRequest
} from '../../redux/actions/productLocks.js';
import { getProductStructureRequest, productStuctureResetState } from '../../redux/actions/productStructure.js';
// Components
import PopoutPanelIconHeading, { types } from '../PopoutPanelIconHeading/PopoutPanelIconHeading.js';
import ManageProductLocksLoadingError from './formStates/ManageProductLocksLoadingError.js';
import ManageProductLocksForm from './formStates/ManageProductLocksForm.js';
import { requestStatus } from '../../redux/reducers/productLocks.reducer.js';
import ManageProductLocksConfirmation from './formStates/ManageProductLocksConfirmation.js';
import ManageProductLocksError from './formStates/ManageProductLocksError.js';
import SidePanel from '../SidePanel/SidePanel.js';
import ManageProductLocksWarningModal from './ManageProductLocksWarningModal.js';
// Utils
import { getCurrentPlatform } from '../../utils/platform.js';
// Styles
import styles from './ManageProductLocks.scss';

function ManageProductLocks({
  isOpen,
  onClosePanel,
  content,
  orgId,
  classId,
  productDetails = {},
  getProductLocksRequestAction,
  getProductStructureRequestAction,
  productStuctureResetStateAction,
  productLocksResetStateAction,
  updateProductLocksRequestAction,
  getProductLocksStatus,
  updateProductLocksStatus,
  getProductStructureStatus
}) {
  const formStates = {
    LOADING: 'LOADING',
    LOADING_ERROR: 'LOADING_ERROR',
    EDITING: 'EDITING',
    SUBMITTING: 'SUBMITTING',
    CONFIRMATION: 'CONFIRMATION',
    ERROR: 'ERROR'
  };

  const [formState, setFormState] = useState(formStates.LOADING);
  const [isWarningModalOpen, setWarningModalOpen] = useState(false);

  useEffect(() => {
    switch (true) {
      case updateProductLocksStatus.isLoading:
        return setFormState(formStates.SUBMITTING);
      case updateProductLocksStatus.success:
        return setFormState(formStates.CONFIRMATION);
      case updateProductLocksStatus.error:
        return setFormState(formStates.ERROR);
      default:
        return null;
    }
  }, [updateProductLocksStatus]);

  useEffect(() => {
    if (isOpen && formState === formStates.LOADING) {
      getProductLocksRequestAction({ orgId, classId, isbn: productDetails.isbn });
      getProductStructureRequestAction({ isbn: productDetails.isbn, depth: '1' });
    }
  }, [isOpen, formState]);

  useEffect(() => {
    if (getProductLocksStatus === requestStatus.SUCCESS && getProductStructureStatus === requestStatus.SUCCESS) {
      return setFormState(formStates.EDITING);
    }
    if (getProductLocksStatus === requestStatus.ERROR || getProductStructureStatus === requestStatus.ERROR) {
      return setFormState(formStates.LOADING_ERROR);
    }
    return null;
  }, [getProductLocksStatus, getProductStructureStatus]);

  const resetTheForm = () => {
    productLocksResetStateAction();
    productStuctureResetStateAction();
    setFormState(formStates.LOADING);
  };

  const handleClosePanel = () => {
    resetTheForm();
    onClosePanel();
  };

  const getContent = () => {
    switch (formState) {
      case formStates.LOADING: {
        return (
          <PopoutPanelIconHeading type={types.LOADING} title={content.loading_resource_subtitle} hideCloseButton />
        );
      }
      case formStates.LOADING_ERROR: {
        return <ManageProductLocksLoadingError content={content} resetStateAction={resetTheForm} />;
      }
      case formStates.EDITING: {
        return (
          <ManageProductLocksForm
            productDetails={productDetails}
            content={content}
            onSave={units => {
              updateProductLocksRequestAction(
                { lockedNodes: units, platform: getCurrentPlatform() },
                { orgId, classId, isbn: productDetails.isbn }
              );
              setFormState(formStates.SUBMITTING);
            }}
          />
        );
      }
      case formStates.SUBMITTING: {
        return <PopoutPanelIconHeading type={types.LOADING} title={content.loading_resource_subtitle} />;
      }
      case formStates.ERROR: {
        return (
          <ManageProductLocksError
            resetStateAction={() => {
              setFormState(formStates.EDITING);
            }}
            closePanelAction={handleClosePanel}
            content={content}
          />
        );
      }
      case formStates.CONFIRMATION: {
        return <ManageProductLocksConfirmation closePanelAction={handleClosePanel} content={content} />;
      }
      default:
        return <ManageProductLocksForm content={content} />;
    }
  };

  return (
    <>
      <div className={styles.manageStudentsAccessPanelContainer}>
        <SidePanel
          id="ManageStudentAccessPanel"
          ariaLabel={content.renegerate_sign_in_card_aria_label}
          isOpen={isOpen}
          onClose={() => {
            if (formState === formStates.EDITING) {
              setWarningModalOpen(true);
            } else {
              handleClosePanel();
            }
          }}
        >
          {getContent()}
        </SidePanel>
      </div>
      <ManageProductLocksWarningModal
        content={content}
        isOpen={isWarningModalOpen}
        closeWarningModal={() => {
          setWarningModalOpen(false);
        }}
        closePanel={() => {
          productLocksResetStateAction();
          handleClosePanel();
        }}
      />
    </>
  );
}

ManageProductLocks.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClosePanel: PropTypes.func.isRequired,
  content: PropTypes.object.isRequired,
  classId: PropTypes.string,
  orgId: PropTypes.string.isRequired,
  productDetails: PropTypes.object,
  getProductLocksRequestAction: PropTypes.func.isRequired,
  getProductStructureRequestAction: PropTypes.func.isRequired,
  productLocksResetStateAction: PropTypes.func.isRequired,
  productStuctureResetStateAction: PropTypes.func.isRequired,
  updateProductLocksRequestAction: PropTypes.func.isRequired,
  getProductLocksStatus: PropTypes.string,
  updateProductLocksStatus: PropTypes.object,
  getProductStructureStatus: PropTypes.string
};

export default connect(
  ({ identity, productLocks, productStructure }) => ({
    orgId: identity.currentOrganisationId,
    getProductLocksStatus: productLocks.getProductLocksStatus,
    getProductStructureStatus: productStructure.status,
    updateProductLocksStatus: productLocks.updateProductLocksStatus
  }),
  {
    getProductLocksRequestAction: getProductLocksRequest,
    getProductStructureRequestAction: getProductStructureRequest,
    productLocksResetStateAction: productLocksResetState,
    productStuctureResetStateAction: productStuctureResetState,
    updateProductLocksRequestAction: updateProductLocksRequest
  }
)(ManageProductLocks);
