import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import Footer from '@oup/shared-front-end/src/components/Wizard/Footer';
import { ICON_LEFT } from '@oup/shared-front-end/src/svg/oup';
import APP_CONSTANTS from '@oup/shared-node-browser/constants';
import TextLink from '@oup/shared-front-end/src/components/TextLink';
import { PopoutPanelIconHeading } from '../../../index';
import { types as popoutPanelIconHeadingTypes } from '../../../PopoutPanelIconHeading/PopoutPanelIconHeading';
import PanelHeading from '../../../PanelHeading/PanelHeading';
import ScrollContainer from '../../../ScrollContainer/ScrollContainer';
import StudentsCounting from '../StudentsCounting/StudentsCounting';
import withLocalizedContent from '../../../../language/withLocalizedContent';
import styles from './SetNumberOfPlacesForUnknownStudentsPanel.scss';
import { HubIllustrationConstants, HubIllustrationAltText } from '../../../../globals/hubConstants.js';
import PopoutPanelIllustrationHeading from '../../../PopoutPanelIllustrationHeading/PopoutPanelIllustrationHeading.js';
import PopoutActionFooter from '../../../PopoutActionFooter/PopoutActionFooter.js';

function PlacementTestSetNumberOfPlacesForUnknownStudentsPanel({
  onClose,
  placementTestSessionName,
  localizedContent: { placementTests: placementTestContent, hubGlossary: hubContent },
  onBack,
  placesForUnknownStudents = 0,
  getEditedNumberOfPlaces
}) {
  const [{ numberOfStudents, loading, success, fail }, setState] = useState({
    numberOfStudents: placesForUnknownStudents,
    loading: false,
    success: false,
    fail: false
  });

  useEffect(() => {
    setState(prevState => ({ ...prevState, numberOfStudents: placesForUnknownStudents }));
  }, [placesForUnknownStudents]);

  const handleClose = () => {
    setState(prevState => ({ ...prevState, loading: false, success: false, fail: false }));
    onClose();
  };

  const handleTryAgain = () => {
    setState(prevState => ({ ...prevState, loading: false, success: false, fail: false }));
  };

  const handleNextBtn = () => {
    getEditedNumberOfPlaces(numberOfStudents);
    setState(prevState => ({ ...prevState, loading: false, success: true, fail: false }));
  };

  const increaseNumberOfStudents = () => {
    setState(prevState => ({ ...prevState, numberOfStudents: prevState.numberOfStudents + 1 }));
  };

  const decreaseNumberOfStudents = () => {
    setState(prevState => ({
      ...prevState,
      numberOfStudents: prevState.numberOfStudents > 0 ? prevState.numberOfStudents - 1 : 0
    }));
  };

  const renderFooter = () => {
    if (loading) {
      return null;
    }
    if (success) {
      return <PopoutActionFooter primaryButtonText={hubContent.done_button} primaryButtonAction={handleClose} />;
    }
    if (fail) {
      return <PopoutActionFooter primaryButtonText={hubContent.try_again} primaryButtonAction={handleTryAgain} />;
    }
    return (
      <Footer
        primaryButtonLabel={placementTestContent.set_number_of_places_for_unknown_students_panel_submit_button}
        secondaryButtonLabel={hubContent.button_back_text}
        primaryButtonAction={handleNextBtn}
        secondaryButtonAction={onBack}
        primaryButtonAttributes={{
          dataAttributes: {
            testid: 'PLACEMENT_TEST_ONBOARDING_WIZARD_SET_NUMBER_OF_PLACES_FOR_UNKNOWN_STUDENTS_MODAL_NEXT_BUTTON'
          }
        }}
        secondaryButtonAttributes={{
          icon: <ICON_LEFT />,
          dataAttributes: {
            testid: 'PLACEMENT_TEST_ONBOARDING_WIZARD_SET_NUMBER_OF_PLACES_FOR_UNKNOWN_STUDENTS_MODAL_CANCEL_BUTTON'
          }
        }}
      />
    );
  };

  const renderHeader = () => {
    if (loading || success || fail) return null;
    return (
      <PanelHeading
        title={placementTestContent.set_number_of_places_for_unknown_students_panel_title}
        subtitle={(placementTestContent.set_number_of_places_for_unknown_students_panel_subtitle || '').replace(
          '[sessionName]',
          placementTestSessionName
        )}
      />
    );
  };

  const renderContent = () => {
    if (loading) {
      return (
        <PopoutPanelIconHeading
          type={popoutPanelIconHeadingTypes.LOADING}
          title={placementTestContent.waiting_message_title}
          subtitle={placementTestContent.waiting_message_subtitle}
        />
      );
    }
    if (success) {
      return (
        <div className={styles.setNumberOfPlacesResponseContainer}>
          <PopoutPanelIllustrationHeading
            type={popoutPanelIconHeadingTypes.VALID}
            title={(placementTestContent.set_number_of_places_for_unknown_students_panel_success_title || '')
              .replace('[numberOfPlaces]', numberOfStudents)
              .replace('[sessionName]', placementTestSessionName)}
            subtitle={(
              placementTestContent.set_number_of_places_for_unknown_students_panel_success_subtitle || ''
            ).replace('[numberOfPlaces]', numberOfStudents)}
            illustrationSrc={HubIllustrationConstants.SUCCESS}
            illustrationAltText={HubIllustrationAltText.SUCCESS}
          />
          <TextLink target="_blank" to={APP_CONSTANTS.HUB_SUPPORT_CONTACT_US}>
            {placementTestContent.placement_test_add_students_page_learn_about_joining_codes}
          </TextLink>
        </div>
      );
    }
    if (fail) {
      return (
        <>
          <div className={styles.setNumberOfPlacesResponseContainer}>
            <PopoutPanelIllustrationHeading
              type={popoutPanelIconHeadingTypes.ERROR}
              title={placementTestContent.set_number_of_places_for_unknown_students_panel_fail_title}
              illustrationSrc={HubIllustrationConstants.ERROR}
              illustrationAltText={HubIllustrationAltText.ERROR}
            />
          </div>
          <hr />
        </>
      );
    }
    return (
      <div className={styles.setNumberOfPlacesContent}>
        <StudentsCounting
          label={placementTestContent.placement_test_set_number_of_places_for_unknown_students_label}
          value={numberOfStudents}
          onIncrease={increaseNumberOfStudents}
          onDecrease={decreaseNumberOfStudents}
          hasError={false}
          errorMessage={placementTestContent.placement_test_add_students_page_counter_error_message}
        />
        <div className={styles.infoSection}>
          <p>
            {numberOfStudents > 0
              ? placementTestContent.set_number_of_places_for_unknown_students_panel_success_subtitle.replace(
                  '[numberOfPlaces]',
                  numberOfStudents
                )
              : placementTestContent.placement_test_set_number_of_places_for_unknown_students_info}
          </p>
          <TextLink target="_blank" to={APP_CONSTANTS.HUB_SUPPORT_CONTACT_US}>
            {placementTestContent.placement_test_add_students_page_learn_about_joining_codes}
          </TextLink>
        </div>
      </div>
    );
  };

  return (
    <ScrollContainer headerContent={renderHeader()} footerContent={renderFooter()}>
      {renderContent()}
    </ScrollContainer>
  );
}

PlacementTestSetNumberOfPlacesForUnknownStudentsPanel.propTypes = {
  onClose: PropTypes.func.isRequired,
  placementTestSessionName: PropTypes.string.isRequired,
  localizedContent: PropTypes.object.isRequired,
  onBack: PropTypes.func,
  placesForUnknownStudents: PropTypes.number,
  getEditedNumberOfPlaces: PropTypes.func
};

export default compose(
  withLocalizedContent('placementTests', 'hubGlossary'),
  connect(
    ({ placementTestSessionCreate }) => ({
      placesForUnknownStudents:
        placementTestSessionCreate.editedPlacementTest?.placesForUnknownStudents ||
        placementTestSessionCreate.placementTest.placesForUnknownStudents
    }),
    {}
  )
)(PlacementTestSetNumberOfPlacesForUnknownStudentsPanel);
