import { HubLayoutConstants } from '../../../globals/hubConstants';

const getClassPeopleDropdownOptions = (
  item = {},
  classroomId = '',
  hideRemoveOption = false,
  isSupervisedUser = false,
  showSendPasswordResetEmail = false
) => {
  const { orgArchiveStatus = '', orgInviteStatus = '', classInviteStatus = '' } = item;
  const {
    PEOPLE_STATUS: { ARCHIVED, PENDING }
  } = HubLayoutConstants;
  return {
    isArchived: orgArchiveStatus === ARCHIVED,
    isPending: orgInviteStatus === PENDING || classInviteStatus === PENDING,
    isActive: item[`classArchiveStatus-${classroomId}`] !== ARCHIVED,
    hideRemoveOption,
    isSupervisedUser,
    showSendPasswordResetEmail
  };
};

export default getClassPeopleDropdownOptions;
