// eslint-disable-next-line import/prefer-default-export
export const peopleSortOptions = (idPrefix, sort, content) => [
  {
    text: content.sortOption_firstName,
    id: `${idPrefix}-by-firstName`,
    name: 'searchOrder',
    value: 'firstName:asc',
    checked: ['firstName:asc', 'firstName:desc'].includes(sort)
  },
  {
    text: content.sortOption_lastName,
    id: `${idPrefix}-by-lastName`,
    name: 'searchOrder',
    value: 'lastName:asc',
    checked: ['lastName:asc', 'lastName:desc'].includes(sort)
  },
  {
    text: content.sortOption_dateAdded,
    id: `${idPrefix}-by-date`,
    name: 'searchOrder',
    value: 'date:desc',
    checked: ['date:desc', 'date:asc'].includes(sort)
  }
];

export const peopleFilterOptions = (idPrefix, isFilterCheckedArr, filterAction, content) => [
  {
    text: content.class_people_filter_active,
    id: `${idPrefix}-filterActive`,
    name: `${idPrefix}-filterActive`,
    value: 'active',
    checked: isFilterCheckedArr[0] || false,
    onChange: filterAction
  },
  {
    text: content.class_people_filter_invited,
    id: `${idPrefix}-filterInvited`,
    name: `${idPrefix}-filterInvited`,
    value: 'invited',
    checked: isFilterCheckedArr[1] || false,
    onChange: filterAction
  },
  {
    text: content.class_people_filter_removed,
    id: `${idPrefix}-filterArchived`,
    name: `${idPrefix}-filterArchived`,
    value: 'archived',
    checked: isFilterCheckedArr[2] || false,
    onChange: filterAction
  }
];

export const peopleFilterOptionsForLti = (idPrefix, isFilterCheckedArr, filterAction, content) => [
  {
    text: content.class_people_filter_active,
    id: `${idPrefix}-filterActive`,
    name: `${idPrefix}-filterActive`,
    value: 'active',
    checked: isFilterCheckedArr[0] || false,
    onChange: filterAction
  },
  {
    text: content.class_people_filter_removed,
    id: `${idPrefix}-filterArchived`,
    name: `${idPrefix}-filterArchived`,
    value: 'archived',
    checked: isFilterCheckedArr[2] || false,
    onChange: filterAction
  }
];

export const classSortOptions = (idPrefix, sort, content) => [
  {
    text: content.sort_name_classes,
    id: `${idPrefix}-by-lastName`,
    name: 'searchOrder',
    value: 'name:asc',
    checked: ['name:asc', 'name:desc'].includes(sort)
  },
  {
    text: content.sort_date_created_classes,
    id: `${idPrefix}-by-date`,
    name: 'searchOrder',
    value: 'date:desc',
    checked: ['date:asc', 'date:desc'].includes(sort)
  }
];

export const classworkSortOptions = (idPrefix, sort, content) => [
  {
    text: content.sortOption_materialName,
    id: `${idPrefix}-by-materialName`,
    name: 'searchOrder',
    value: 'name:asc',
    checked: ['name:asc', 'name:desc'].includes(sort)
  }
];

export const classworkFilterOptions = (
  idPrefix,
  isFilterCheckedArr,
  filterAction,
  content,
  withUIFiltering = false
) => {
  let filters = withUIFiltering
    ? [
        {
          text: content.classwork_manage_status_all,
          id: `${idPrefix}-filterAll`,
          name: `${idPrefix}-filterAll`,
          value: 'haveLicenceAll',
          checked: isFilterCheckedArr[0],
          onChange: filterAction
        },
        {
          text: content.classwork_manage_status_some_need,
          id: `${idPrefix}-filterSome`,
          name: `${idPrefix}-filterSome`,
          value: 'haveLicenceSome',
          checked: isFilterCheckedArr[1],
          onChange: filterAction
        },
        {
          text: content.classwork_manage_status_none,
          id: `${idPrefix}-filterNone`,
          name: `${idPrefix}-filterNone`,
          value: 'haveLicenceNone',
          checked: isFilterCheckedArr[2],
          onChange: filterAction
        }
      ]
    : [
        {
          text: content.classwork_removed_materials,
          id: `${idPrefix}-filterArchived`,
          name: `${idPrefix}-filterArchived`,
          value: 'archived',
          checked: isFilterCheckedArr[0],
          onChange: filterAction
        }
      ];

  if (idPrefix.indexOf('profile') > -1)
    filters = [
      {
        text: content.class_classwork_filter_not_started,
        id: `${idPrefix}-filterNotStarted`,
        name: `${idPrefix}-filterNotStarted`,
        value: 'notStarted',
        checked: isFilterCheckedArr[0], // not started
        onChange: filterAction
      },
      {
        text: content.class_classwork_filter_active,
        id: `${idPrefix}-filterActive`,
        name: `${idPrefix}-filterActive`,
        value: 'active',
        checked: isFilterCheckedArr[1], // active
        onChange: filterAction
      },
      {
        text: content.class_classwork_filter_expiring,
        id: `${idPrefix}-filterExpiring`,
        name: `${idPrefix}-filterExpiring`,
        value: 'expiring',
        checked: isFilterCheckedArr[2], // expiring
        onChange: filterAction
      },
      {
        text: content.class_classwork_filter_expired,
        id: `${idPrefix}-filterExpired`,
        name: `${idPrefix}-filterExpired`,
        value: 'expired',
        checked: isFilterCheckedArr[3], // expired
        onChange: filterAction
      },
      {
        text: content.class_classwork_filter_no_licence,
        id: `${idPrefix}-filterNoLicence`,
        name: `${idPrefix}-filterNoLicence`,
        value: 'noLicence',
        checked: isFilterCheckedArr[4], // no licence
        onChange: filterAction
      }
    ];

  return filters;
};

export const assessmentFilterOptions = (idPrefix, isFilterCheckedArr, filterAction, content) => [
  {
    text: content.filter_completed_assessment,
    id: `${idPrefix}-filterCompletedClasses`,
    name: `${idPrefix}-filterCompletedClasses`,
    value: 'completed',
    checked: isFilterCheckedArr[0] || false,
    onChange: filterAction
  },
  {
    text: content.filter_active_assessment,
    id: `${idPrefix}-filterActiveClasses`,
    name: `${idPrefix}-filterActiveClasses`,
    value: 'active',
    checked: isFilterCheckedArr[1] || false,
    onChange: filterAction
  },
  {
    text: content.filter_upcoming_assessment,
    id: `${idPrefix}-filterUpcomingClasses`,
    name: `${idPrefix}-filterUpcomingClasses`,
    value: 'upcoming',
    checked: isFilterCheckedArr[2] || false,
    onChange: filterAction
  }
];

export const assessmentSortOptions = (idPrefix, sort, content) => [
  {
    text: content.sort_due_date_assessment,
    id: `${idPrefix}-by-dueDate`,
    name: 'searchOrder',
    value: 'dueDate:desc',
    checked: ['dueDate:asc', 'dueDate:desc'].includes(sort)
  },
  {
    text: content.sort_start_date_assessment,
    id: `${idPrefix}-by-startDate`,
    name: 'searchOrder',
    value: 'startDate:desc',
    checked: ['startDate:asc', 'startDate:desc'].includes(sort)
  },
  {
    text: content.sort_test_name_assessment,
    id: `${idPrefix}-by-testName`,
    name: 'searchOrder',
    value: 'testName:asc',
    checked: ['testName:asc', 'testName:desc'].includes(sort)
  }
];

export const placementTestFilterOptions = (idPrefix, isFilterCheckedArr, filterAction, content) => [
  {
    text: content.filter_draft_placement_test,
    id: `${idPrefix}-filterDraftPlacementTests`,
    name: `${idPrefix}-filterDraftPlacementTests`,
    value: 'DRAFT',
    checked: isFilterCheckedArr[0] || false,
    onChange: filterAction
  },
  {
    text: content.filter_active_placement_test,
    id: `${idPrefix}-filterStartedPlacementTests`,
    name: `${idPrefix}-filterStartedPlacementTests`,
    value: 'ACTIVE',
    checked: isFilterCheckedArr[1] || false,
    onChange: filterAction
  },
  {
    text: content.filter_upcoming_placement_test,
    id: `${idPrefix}-filterNotStartedPlacementTests`,
    name: `${idPrefix}-filterNotStartedPlacementTests`,
    value: 'UPCOMING',
    checked: isFilterCheckedArr[2] || false,
    onChange: filterAction
  },
  {
    text: content.filter_completed_placement_test,
    id: `${idPrefix}-filterCompletedPlacementTests`,
    name: `${idPrefix}-filterCompletedPlacementTests`,
    value: 'COMPLETED',
    checked: isFilterCheckedArr[3] || false,
    onChange: filterAction
  }
];

export const placementTestSortOptions = (idPrefix, sort, content) => [
  {
    text: content.sort_placement_test_end_date,
    id: `${idPrefix}-by-testEndDate`,
    name: 'searchOrder',
    value: 'testEndDate:asc',
    checked: ['testEndDate:asc', 'testEndDate:desc'].includes(sort)
  },
  {
    text: content.sort_placement_test_start_date,
    id: `${idPrefix}-by-testStartDate`,
    name: 'searchOrder',
    value: 'testStartDate:desc',
    checked: ['testStartDate:asc', 'testStartDate:desc'].includes(sort)
  },
  {
    text: content.sort_placement_test_session_name,
    id: `${idPrefix}-by-sessionName`,
    name: 'searchOrder',
    value: 'sessionName:asc',
    checked: ['sessionName:asc', 'sessionName:desc'].includes(sort)
  },
  {
    text: content.sort_date_created_placement_test,
    id: `${idPrefix}-by-dateCreated`,
    name: 'searchOrder',
    value: 'dateCreated:desc',
    checked: ['dateCreated:asc', 'dateCreated:desc'].includes(sort)
  }
];
