import React from 'react';
import PropTypes from 'prop-types';
// Styles
import styles from './HubPersonProfileTabs.scss';

function HubPersonProfileTab({ tabs, tab, selectTab, selectedTab }) {
  return (
    <button
      type="button"
      className={`${styles.tabButton} ${tab === selectedTab ? styles.active : ''}`}
      onClick={() => selectTab(tab)}
    >
      {tabs[tab].name}
    </button>
  );
}

HubPersonProfileTab.propTypes = {
  tab: PropTypes.string.isRequired,
  tabs: PropTypes.object.isRequired,
  selectTab: PropTypes.func.isRequired,
  selectedTab: PropTypes.string.isRequired
};

export default HubPersonProfileTab;
