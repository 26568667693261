import * as t from '../actionTypes.js';

export const findProductRequest = (params = {}) => ({
  type: t.FIND_PRODUCT_REQUEST,
  payload: params
});

export const findProductSuccess = products => ({
  type: t.FIND_PRODUCT_SUCCESS,
  payload: products
});

export const findProductFailure = error => ({
  type: t.FIND_PRODUCT_FAILURE,
  payload: error
});

export const clearFoundProduct = () => ({
  type: t.CLEAR_FOUND_PRODUCT
});
