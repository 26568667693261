import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import APP_CONSTANTS from '@oup/shared-node-browser/constants';
import styles from './HubPersonProfilePage.scss';
import userRoles, { roleIsAtLeast } from '../../../globals/userRoles';

// Services
import getPeopleColour from '../../HubDashboardLayout/Services/getPeopleColour';
import getPeopleInitials from '../../HubDashboardLayout/Services/getPeopleInitials';
import getClassLink from '../../HubDashboardLayout/Services/getClassLink';
import getPeopleStatusIconData from '../../HubDashboardLayout/Services/getPeopleStatusIconData';
import getClassPeopleDropdownOptions from '../../HubDashboardLayout/Services/getClassPeopleDropdownOptions';
import getUserPanelType from '../../HubDashboardLayout/Services/getUserPaneltype';
import { loadClassDetails } from '../../../redux/reducers/data/classrooms';
import { getOrgStatusType } from '../../HubDashboardLayout/Services/getStatusType';
import getDropdownItems from '../../../components/HubDropdownMenu/Services/getDropdownItems';
import getOrganizationClassLink from '../../HubDashboardLayout/Utils/getOrganizationClassLink';

// Constants
import { HubLayoutConstants, INSTANCE_ROLES, SINGLE_CLASS_PAGES_CONTEXT } from '../../../globals/hubConstants';
import colors from '../../../globals/colors';

// Redux
import { initialiseInstance } from '../../../redux/reducers/data/search.reducer';

// HOC
import withLocalizedContent from '../../../language/withLocalizedContent';

// Components
import SVGIcon, { GLYPHS } from '../../../components/SVGIcon/SVGIcon';
import HubDropdownMenu from '../../../components/HubDropdownMenu/HubDropdownMenu';
import LinkWithIcon from '../../../components/LinkWithIcon/LinkWithIcon';
import HubPersonProfileTabs from './HubPersonProfileTabs';
import SubSectionSkeletonLoader from '../../../components/SkeletonLoader/SubSectionSkeletonLoader';
import HubPersonProfileClasses from './HubPersonProfileClasses';
import HubPersonProfileLicenses from './HubPersonProfileLicenses';
import DataRefresher from '../../../components/DataRefresher/DataRefresher';

class HubPersonProfilePage extends Component {
  static getInitialiseSearchParams = (type, orgId, classId) => {
    const finalObject = { active: true, invited: true, archived: true };
    finalObject.roles = [userRoles.LEARNER, userRoles.ORG_ADMIN, userRoles.TEACHER_ADMIN, userRoles.TEACHER];
    switch (type) {
      case INSTANCE_ROLES.ORG_STUDENTS:
        finalObject.isOrgStudentTab = true;
        finalObject.orgId = orgId;
        break;
      case INSTANCE_ROLES.ORG_STAFF:
        finalObject.isOrgStaffTab = true;
        finalObject.orgId = orgId;
        if (classId) {
          finalObject.classId = classId;
        }
        break;
      default:
    }
    return finalObject;
  };

  constructor(props) {
    super(props);
    const {
      localizedContent: { hubGlossary: hubContent }
    } = this.props;

    this.tabs = {
      learningMaterial: {
        name: hubContent.learning_material,
        component: <div />
      },
      classes: {
        name: hubContent.organization_classes,
        component: <div />
      }
    };
  }

  componentDidMount() {
    const {
      match: { params },
      initialiseSearch,
      orgId,
      personDetails
    } = this.props;

    if (!personDetails)
      if (params.list === 'students') {
        initialiseSearch(INSTANCE_ROLES.ORG_STUDENTS, false, {
          roles: [userRoles.LEARNER],
          active: true,
          invited: true,
          archived: true,
          isOrgStudentTab: true,
          orgId
        });
      } else if (params.list === 'staff') {
        initialiseSearch(INSTANCE_ROLES.ORG_STAFF, false, {
          roles: [userRoles.ORG_ADMIN, userRoles.TEACHER_ADMIN, userRoles.TEACHER],
          active: true,
          invited: true,
          isOrgStaffTab: true,
          archived: true,
          orgId
        });
      }
  }

  redirect = () => {
    const {
      history: { push },
      match: {
        params: { list = '', classroomId = '' }
      },
      context
    } = this.props;

    if (!list) {
      if (context === SINGLE_CLASS_PAGES_CONTEXT.ORG_CONTEXT) {
        push(`${getOrganizationClassLink(classroomId)}/people`);
      } else {
        push(`${getClassLink(classroomId)}/people`);
      }
      return;
    }

    push(`${HubLayoutConstants.PATH_NAMES.ORGANIZATION_PATH}${HubLayoutConstants.PATH_NAMES[list.toUpperCase()]}`);
  };

  selectTab = (tab = '') => {
    const {
      history: { push },
      match: { params = {} }
    } = this.props;

    push(
      `${HubLayoutConstants.PATH_NAMES.ORGANIZATION_PATH}${HubLayoutConstants.PATH_NAMES[params.list.toUpperCase()]}/${
        params.personId
      }/${tab}`
    );
  };

  _triggerInitialiseSearch = () => {
    const {
      initialiseSearch,
      match: { params },
      loadClass,
      orgId
    } = this.props;

    if (params.list === 'students') {
      return initialiseSearch(
        INSTANCE_ROLES.ORG_STUDENTS,
        false,
        HubPersonProfilePage.getInitialiseSearchParams(INSTANCE_ROLES.ORG_STUDENTS, orgId, null)
      );
    }
    if (params.list === 'staff') {
      return initialiseSearch(
        INSTANCE_ROLES.ORG_STAFF,
        false,
        HubPersonProfilePage.getInitialiseSearchParams(INSTANCE_ROLES.ORG_STAFF, orgId, null)
      );
    }
    if (params.classroomId) {
      return [
        new Promise(resolve => {
          resolve(
            initialiseSearch(
              INSTANCE_ROLES.ORG_STUDENTS,
              false,
              HubPersonProfilePage.getInitialiseSearchParams(INSTANCE_ROLES.ORG_STAFF, orgId, null)
            )
          );
        }),
        new Promise(resolve => {
          resolve(
            initialiseSearch(
              INSTANCE_ROLES.ORG_STAFF,
              false,
              HubPersonProfilePage.getInitialiseSearchParams(INSTANCE_ROLES.ORG_STAFF, orgId, params.classroomId)
            )
          );
        }),
        new Promise(resolve => {
          resolve(loadClass(orgId, params.classroomId));
        })
      ];
    }
    return () => ({});
  };

  _getNameOfUser = () => {
    const { personDetails } = this.props;
    return `${personDetails?.firstname || ''} ${personDetails?.lastname || ''}`;
  };

  render() {
    const {
      personDetails = {},
      localizedContent: { hubGlossary: hubContent },
      match: {
        params: { list = '', tab = '', personId = '', classroomId = '' }
      },
      loadingResults,
      orgTitle,
      userRole,
      orgId,
      history,
      location,
      userDropdownActions = {},
      classTitle = '',
      userId,
      isTeacherAssignedToClass,
      currentOrganisationLti
    } = this.props;
    const {
      LICENCES_CONTEXT: { ORG, CLASS },
      PEOPLE_PANEL_TYPES: {
        ASSIGN_MATERIALS_TO_USERS,
        ASSIGN_MATERIAL,
        RESEND_INVITATION,
        RESET_PASSWORD_REQUEST,
        REGENERATE_SUPERVISED_USER_SIGN_IN_CARD
      }
    } = HubLayoutConstants;
    const isOrgPage = !!tab;
    const initials = getPeopleInitials(personDetails.firstname, personDetails.lastname);
    const peopleColour = getPeopleColour(personDetails.roleName);
    const statusType = isOrgPage
      ? getOrgStatusType(personDetails, hubContent)
      : getPeopleStatusIconData(personDetails, classroomId, hubContent);
    const roleType = roleIsAtLeast(userRoles.TEACHER, personDetails.roleName) ? userRoles.TEACHER : userRoles.LEARNER;
    const removeUserPanelType = isOrgPage ? 'archiveUsers' : getUserPanelType(personDetails.roleName);
    const context = personDetails.roleName === userRoles.LEARNER ? APP_CONSTANTS.ORG_STUDENTS : APP_CONSTANTS.ORG_STAFF;

    const personUsername = personDetails?.username;
    const personSimpleUsername = personUsername?.slice(0, personUsername.indexOf('@'));

    const dropdownOptions = isOrgPage
      ? {
          isPending: personDetails?.orgInviteStatus === HubLayoutConstants.PEOPLE_STATUS.PENDING,
          canChangeRole: personDetails.roleName !== userRoles.LEARNER,
          isSupervisedUser: personDetails.isSupervised
        }
      : getClassPeopleDropdownOptions(
          personDetails,
          classroomId,
          false,
          personDetails.isSupervised,
          personDetails.roleName === userRoles.LEARNER
        );

    const dropdownActions = {
      removeUser: e => {
        e.stopPropagation();
        return isOrgPage
          ? userDropdownActions.removeUser(e, removeUserPanelType, {
              selectedUsers: [personId],
              selectedUserId: personId,
              context
            })
          : userDropdownActions.removeUser(e, removeUserPanelType, {
              selectedUsers: [personId],
              selectedUserId: personId
            });
      },
      editUser: e =>
        userDropdownActions.editUser(e, 'editUser', {
          selectedUserId: personId,
          selectedUsers: [personId],
          context: '',
          role: personDetails.roleName,
          userDetails: personDetails
        }),
      sendInvitationReminder: e =>
        location.pathname.includes('myOrganization')
          ? userDropdownActions.editUser(e, 'editUser/sendInvitationReminder', {
              selectedUserId: personId,
              selectedUsers: [personId],
              context: '',
              role: personDetails.roleName,
              userDetails: personDetails
            })
          : userDropdownActions.invitationReminder(e, RESEND_INVITATION, { selectedUserId: personId }),
      sendPasswordResetEmail: e =>
        location.pathname.includes('myOrganization')
          ? userDropdownActions.editUser(e, 'editUser/sendPasswordResetEmail', {
              selectedUsers: [personId],
              context: '',
              role: personDetails.roleName,
              userDetails: personDetails
            })
          : userDropdownActions.sendPasswordResetEmail(e, RESET_PASSWORD_REQUEST, {
              selectedUserId: personId,
              selectedUsers: [personId],
              selectedUserEmail: personDetails.email
            }),
      cancelInvitation: e =>
        userDropdownActions.cancelInvitation(e, 'cancelInvitation', {
          selectedUsers: [personId],
          context: '',
          role: personDetails.roleName
        }),
      assignMaterial: e => {
        if (e) {
          e?.stopPropagation();
        }
        return isOrgPage
          ? userDropdownActions.assignMaterial(e, ASSIGN_MATERIAL, {
              selectedUsers: [personId],
              selectedUserId: personId,
              context,
              contextName: this._getNameOfUser()
            })
          : userDropdownActions.assignMaterial(e, ASSIGN_MATERIALS_TO_USERS, {
              role: roleType,
              selectedUsers: [personId],
              selectedUserId: personId,
              contextName: this._getNameOfUser()
            });
      },
      regenerateSupervisedUserSignInCard: e =>
        userDropdownActions.regenerateSupervisedUserSignInCard(e, REGENERATE_SUPERVISED_USER_SIGN_IN_CARD, {
          selectedUserId: personId,
          selectedUsers: [personId],
          userDetails: {
            firstName: personDetails.firstname,
            lastName: personDetails.lastname,
            userName: personDetails.username
          }
        })
    };

    const listName = list.charAt(0).toUpperCase() + list.slice(1);
    const organisationTitle = isOrgPage
      ? `${orgTitle} | ${listName} | Profile Page | ${this.tabs[tab]?.name}`
      : `${orgTitle} | ${listName} | Profile Page`;
    const classroomTitle = `${orgTitle} | ${classTitle} | People | Profile Page`;
    const pageTitle = isOrgPage ? organisationTitle : classroomTitle;
    const dropdownTypeData = isOrgPage ? `org${list.charAt(0).toUpperCase()}${list.slice(1)}` : 'people';
    const isSelfSelectedOrgAdmin = userRole === userRoles.ORG_ADMIN && userId === personId;
    const isActive =
      personDetails.orgInviteStatus === 'ACCEPTED' ||
      personDetails.orgInviteStatus === 'NONE' ||
      isSelfSelectedOrgAdmin;
    const backLabelText = isOrgPage
      ? (() => (list === 'students' ? hubContent.show_all_students : hubContent.show_all_staff))()
      : hubContent.show_all_people;
    const personUsertype =
      personDetails.roleName === userRoles.LEARNER
        ? HubLayoutConstants.TARGET_USERTYPE.STUDENT
        : HubLayoutConstants.TARGET_USERTYPE.TEACHER;

    return (
      <div className={styles.personProfilePage}>
        <Helmet title={pageTitle} />

        <DataRefresher loading={loadingResults} refreshData={() => this._triggerInitialiseSearch()} />
        {loadingResults ? (
          <SubSectionSkeletonLoader
            tabName=""
            panelName=""
            speed={2}
            foregroundColor={colors.COLOR_GREY_DISABLED}
            backgroundColor={colors.COLOR_WHITE}
          />
        ) : (
          <div>
            <div className={styles.header}>
              <div className={styles.leftContainer}>
                <LinkWithIcon
                  isLhs
                  id="PopoutNavFooterBackLink"
                  action={this.redirect}
                  text={backLabelText}
                  glyph={GLYPHS.ICON_LEFT}
                  customClassName={styles.backButton}
                />
              </div>
              <div className={styles.rightContainer}>
                <LinkWithIcon
                  id="PopoutNavFooterBackLink"
                  action={this.redirect}
                  text="Close"
                  glyph={GLYPHS.ICON_CLOSE}
                  customClassName={styles.closeButton}
                />
              </div>
            </div>
            <div className={styles.userMainContainer}>
              <div className={styles.userLeftContainer}>
                <div className={styles.userDetailsCard}>
                  <div className={`${styles.userInfoDot} ${styles[peopleColour]}`}>{initials}</div>
                  <div className={styles.userInfoContainer}>
                    <div className={styles.userDetails}>
                      <span>{`${personDetails.firstname} ${personDetails.lastname}`}</span>
                      <span>{currentOrganisationLti ? personSimpleUsername : personUsername}</span>
                    </div>
                    <div className={styles.orgDetails}>
                      <div className={styles.svgContainer}>
                        <SVGIcon glyph={statusType.icon} fill={statusType.iconColour} />
                      </div>
                      <span className={`${styles[statusType.status.toLowerCase()] || ''}`}>
                        {statusType.text} {statusType.date} {statusType.subtext || ''}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.userRightContainer}>
                <HubDropdownMenu
                  dropdownData={getDropdownItems(
                    dropdownTypeData,
                    hubContent,
                    dropdownOptions,
                    dropdownActions,
                    userRole,
                    isSelfSelectedOrgAdmin,
                    currentOrganisationLti
                  )}
                  parentId={personId}
                  customClassname={styles.dropDownMenu}
                  dropdownTop={false}
                />
              </div>
            </div>
            {!tab && <div className={styles.border} />}
            {tab && isActive && <HubPersonProfileTabs tabs={this.tabs} selectedTab={tab} selectTab={this.selectTab} />}
            {tab === 'learningMaterial' || classroomId ? (
              <HubPersonProfileLicenses
                personId={personId}
                personUsertype={personUsertype}
                orgId={orgId}
                classroomId={classroomId}
                licencesContext={isOrgPage ? ORG : CLASS}
                isTeacherAssignedToClass={isTeacherAssignedToClass}
                assignMaterial={e => dropdownActions.assignMaterial(e)}
                personFirstname={personDetails.firstname}
                isActive={isActive}
              />
            ) : (
              <HubPersonProfileClasses
                personId={personId}
                hubContent={hubContent}
                history={history}
                personFirstname={personDetails.firstname}
                userRole={userRole}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

export default compose(
  withRouter,
  withLocalizedContent('hubGlossary'),
  connect(
    (state, props) => ({
      personDetails: get(state, ['people', 'data', props.match.params.personId]),
      loadingResults:
        get(state, ['search', INSTANCE_ROLES.ORG_STAFF, 'loading']) ||
        get(state, ['search', INSTANCE_ROLES.ORG_STUDENTS, 'loading']),
      orgId: state.identity.currentOrganisationId,
      orgTitle: get(state, ['organisations', 'data', state.identity.currentOrganisationId, 'name']),
      userRole: get(state, ['identity', 'role']),
      userId: get(state, ['identity', 'userId']),
      currentOrganisationLti: state.identity.currentOrganisationLti
    }),
    {
      initialiseSearch: initialiseInstance,
      loadClass: loadClassDetails
    }
  )
)(HubPersonProfilePage);

HubPersonProfilePage.propTypes = {
  personDetails: PropTypes.object,
  localizedContent: PropTypes.object,
  match: PropTypes.object,
  loadingResults: PropTypes.bool,
  push: PropTypes.func,
  initialiseSearch: PropTypes.func,
  orgId: PropTypes.string,
  orgTitle: PropTypes.string,
  userDropdownActions: PropTypes.object,
  userRole: PropTypes.string,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  isTeacherAssignedToClass: PropTypes.bool,
  classTitle: PropTypes.string,
  userId: PropTypes.string,
  loadClass: PropTypes.func,
  context: PropTypes.string.isRequired,
  currentOrganisationLti: PropTypes.bool
};
