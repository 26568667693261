import React from 'react';
import PropTypes from 'prop-types';
import Link from '../Link/Link';
import styles from './ViewAllTilesCard.scss';

function ViewAllTilesCard({ slicedAmount, limitCardLink, limitCardText }) {
  return (
    <Link to={limitCardLink} className={styles.showAllContainer}>
      <span>+{slicedAmount}</span>
      <span>{limitCardText}</span>
    </Link>
  );
}

export default ViewAllTilesCard;

ViewAllTilesCard.propTypes = {
  slicedAmount: PropTypes.number.isRequired,
  limitCardLink: PropTypes.string.isRequired,
  limitCardText: PropTypes.string.isRequired
};
