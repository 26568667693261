import { isEltAssessmentMode, isEbookSampleMode } from '../utils/platform';
import userRoles from './userRoles.js';
import { XAPI_PARAMS } from './cptConstants';
import { getFullUrl } from '../utils/url';

import { isLocal } from './envSettings.js';

export default function getUrl({ type = '', orgId, userId = '', userRole = '' }) {
  const isUserOrLearner = userRole === userRoles.USER || userRole === userRoles.LEARNER;
  const pathname = window.location.pathname;

  switch (type) {
    case 'EDIT_USER':
      if (isEltAssessmentMode()) {
        return `/org/${orgId}/students/editUser`;
      }
      if (isEbookSampleMode() && isUserOrLearner) {
        return '/myProfile/learningMaterial/editUser';
      }
      return isUserOrLearner ? `${pathname}/editUser` : '/myProfile/myClasses/editUser';
    case 'PROFILE_USER':
      return `/org/${orgId}/user/${userId}`;
    default:
      return '/mySchool';
  }
}

/**
 * Removes all query parameters from the url, and rewrites the url in the browser
 * @param {Boolean} onlyAction true if only actionId should be removed from query parameters
 */
export const removeQueryParams = (onlyAction = false) => {
  const url = new URL(window.location);
  if (onlyAction) {
    url.searchParams.delete('activityId');
  } else {
    url.search = '';
  }

  window.history.pushState({}, '', url);
};

export const getActivityLink = (baseUrl, activityId) => {
  if (baseUrl.searchParams.get('uId')) {
    baseUrl.searchParams.delete('uId');
  }
  baseUrl.searchParams.set('activityId', activityId);
  return baseUrl.pathname + baseUrl.search;
};

export const addXapiParams = (contentUrl, params) => {
  const CONTENT_PATH = isLocal()
    ? `http://${window.location.hostname}:${window.location.port}`
    : `https://${window.location.hostname}`;
  const { token, endpoint, profile } = params;
  const fullUrl = getFullUrl(endpoint, window.location);
  const url = new URL(`${CONTENT_PATH}${contentUrl}`);

  url.searchParams.set(XAPI_PARAMS.AUTH, token.launchToken);
  url.searchParams.set(XAPI_PARAMS.ENDPOINT, fullUrl);

  if (profile) {
    url.searchParams.set(XAPI_PARAMS.PROFILE, profile);
  }

  return url.href;
};
